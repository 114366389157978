import React from 'react';
import { AskCloverpopBotIcon } from "../../../../../icons/custom_icons";

const SystemMessage = ({children}) => {
  return (
    <div className="d-flex align-items-center mb-2">
      <div className="sm-avatar">
        <AskCloverpopBotIcon />
      </div>
      <div className="ms-2 w-100 p-2 bg-light rounded border-top-start-90">
        { children }
      </div>
    </div>
  );
};

export default SystemMessage;

import React, { useMemo } from 'react';
import { connect } from "react-redux";
import { TooltipWrapper } from "../../../bubble_tooltip/BubbleTooltip";
import EditGreyPencil from "../shared/EditGreyPencil";
import { DRIVER_STATES_DATA } from "../../../models/driver_state";

const Icon = ({ driverObject }) => <>
  <div className={`d-flex ${DRIVER_STATES_DATA.completed.color} ${driverObject.state === DRIVER_STATES_DATA.completed.state ? '' : 'd-none'}`}>
    <i className={DRIVER_STATES_DATA.completed.icon_classes} />
  </div>
  <div className={`d-flex ${DRIVER_STATES_DATA.unstarted.color} ${driverObject.state === DRIVER_STATES_DATA.unstarted.state ? '' : 'd-none'}`}>
    <i className={DRIVER_STATES_DATA.unstarted.icon_classes} />
  </div>
  <div className={`d-flex ${DRIVER_STATES_DATA.in_flight.color} ${driverObject.state === DRIVER_STATES_DATA.in_flight.state ? '' : 'd-none'}`}>
    <i className={DRIVER_STATES_DATA.in_flight.icon_classes} />
  </div>
</>

const DriverStatus = ({ driverObject, contactsData, sidebar }) => {
  const editMode =  useMemo(() =>
    sidebar.driverInputSidebar && sidebar.driverSlug === driverObject.driver.slug,
    [sidebar.driverInputSidebar, sidebar.driverSlug])
  if(editMode) return <EditGreyPencil />

  const answeredByUserEmail = driverObject.driver.answered_by_user
  return <TooltipWrapper bubbleIcon={`driver-status-${driverObject.driver.slug}`}
                         tooltipText={driverObject.statusTooltipText(contactsData, answeredByUserEmail)} isDriver={true}>
    <div className="ms-1 mt-1">
      <Icon driverObject={driverObject} />
    </div>
  </TooltipWrapper>
}
const mapStateToProps = ({ sidebar }) => ({ sidebar });
export default connect(mapStateToProps, {})(DriverStatus);
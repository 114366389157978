import React, {useState} from 'react';
import {connect} from "react-redux";
import {isBlank} from "../../helpers/common";
import Modal from "react-bootstrap/Modal";
import {ModalDoneFooter} from "../../common/modals";
import DecisionAssignToBlock from "./helpers/DecisionAssignToBlock";
import ModalHeaderTitle from "../../common/ModalHeaderTitle";
import {updateDecision} from "../../store/modals/actions";
import {submitEntryCallback} from "./entry_modal/helpers";

const decisionUpdateData = (assignedToUser = '') => {
  return {
    template: {
      assigned_collaborator: assignedToUser
    }
  };
}

export const showDecisionAssign = (modal) => modal.type === "DecisionAssignModal" && modal.shown;

const DecisionAssignModal = ({
                              show, onClose, decision,
                              key = '', updateDecision
                            }) => {

  if(isBlank(decision)) return null;

  const [assignedToUser, setAssignedToUser] = useState(decision.assigned_collaborator_email);
  const submissionCallback = submitEntryCallback(updateDecision,
    decisionUpdateData(assignedToUser), onClose
  );

  return <Modal key={key} backdrop="static" size="lg" show={show} onHide={onClose}>
    <Modal.Body>
      <form>
        <ModalHeaderTitle {...{ onClose, title: 'Assign decision' }} />
        <DecisionAssignToBlock {...{ assignedToUser, setAssignedToUser, className: 'mt-3' }} />
      </form>
    </Modal.Body>
    <ModalDoneFooter onClose={submissionCallback} />
  </Modal>
};

const mapStateToProps = ({ modal }) => ({ decision: modal.decision })
export default connect(mapStateToProps, {updateDecision})(DecisionAssignModal);

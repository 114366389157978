import React, { Fragment, useRef } from 'react';
import { connect } from "react-redux";
import { collectChatMessages } from "../../../helpers/discussion_helpers";
import MessageRow from "./MessageRow";
import DiscussionInput from "./DiscussionInput";
import { loadContacts } from "../../../store/contacts/actions";
import { isVisitor } from "../../../helpers/user_helpers"
import { checkLoadingEffect } from "../../../helpers/callbacks_helpers";

const lastMessageId = (messageId, messagesLength, messageNumber) =>
  messagesLength === messageNumber + 1 ? messageId : null

const displayMessages = (messages) => {
  const messagesLength = messages.length
  return messages.map((message, i) =>
    <MessageRow key={`message-row-${message.id}`} message={message} lastMessageId={lastMessageId(message.id, messagesLength, i)}/>
  )
}

export const DiscussionSection = ({decision, current_user, contactsData, loadContacts}) => {
  const messages = collectChatMessages(decision);
  const messageEl = useRef(null);

  checkLoadingEffect(contactsData, loadContacts)
  return <Fragment>
    <div className="chat">
      <div className="card border-0">
        <div className="card-body p-0">
          <div className={`chat-body px-3 ${isVisitor(current_user) ? 'full-height-chat' : ''}`} ref={messageEl}>
            {
              messages.length > 0 ?
                displayMessages(messages) :
                <div className="text-muted">Send a comment to start the decision discussion.</div>
            }
          </div>
        </div>
      </div>
      <div className="card-footer px-3 pt-3" hidden={isVisitor(current_user)}>
        <DiscussionInput />
      </div>
    </div>
  </Fragment>
}
const mapStateToProps = ({ decision, current_user, contacts }) => ({
  decision, current_user,
  contactsData: contacts
});
const mapDispatchToProps = (dispatch) => ({
  loadContacts: () => dispatch(loadContacts())
});
export default connect(mapStateToProps, mapDispatchToProps)(DiscussionSection);

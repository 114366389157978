import React from 'react';
import ReactPaginate from 'react-paginate';

const previousLabel = <span><i className="fas fa-chevron-left" /> Previous</span>
const nextLabel = <span>Next <i className="fas fa-chevron-right" /></span>

export const Pagination = ({ page, totalPages, setPage }) =>
  <ReactPaginate pageCount={totalPages}
                 pageRangeDisplayed={5}
                 marginPagesDisplayed={2}
                 breakClassName={'page-item'}
                 breakLinkClassName={'page-link shadow-none border-0 px-0'}
                 previousLabel={previousLabel}
                 previousLinkClassName={'page-link shadow-none border-0 pe-1'}
                 nextLabel={nextLabel}
                 nextLinkClassName={'page-link shadow-none border-0 ps-1'}
                 onPageChange={data => setPage(data.selected+1)}
                 containerClassName={'pagination m-auto justify-content-center'}
                 subContainerClassName={'pages pagination'}
                 activeClassName={'active'}
                 pageLinkClassName={'page-link shadow-none border-0 rounded'}
                 pageClassName={'page-item'}
                 forcePage={page - 1}
                 previousClassName={`page-item previous ${page === 1 ? 'd-none' : ''}`}
                 nextClassName={`page-item next ${page === totalPages ? 'd-none' : ''}`}
                 disableInitialCallback={true}
  />

import React from 'react';
import {TooltipWrapper} from "../../../bubble_tooltip/BubbleTooltip";

const Icon = () =>
  <div className="d-flex text-light-gray">
    <i className="fa-duotone fa-circle square-24"/>
  </div>

export default ({ template = null, driverData = null }) => {
  const bubbleIcon = template ? `template-status-${template.slug}` : `driver-status-${driverData.slug}`
  return <TooltipWrapper bubbleIcon={bubbleIcon}
                         tooltipText={'Unstarted'} isDriver={true}>
    <div className="ms-1 my-auto">
      <Icon />
    </div>
  </TooltipWrapper>
}

import { LOAD_REPORT_DATA, RESET_REPORT_DATA, UPDATE_REPORT_FAILED, UPDATE_REPORT_DATA } from "./types";
import EntryPoint from "../../EntryPoint";
import {
  createReportDataSource,
  destroyReportDataSource,
  loadReportRequest,
  replaceReportDataSource,
  updateReportDataSource,
  removeReportRequest,
  loadReportDecisionsData
} from "../../utils/Api";
import {failedResponseHandler, isResponseFailed} from "../../helpers/store_helpers";
import {isPresent} from "../../helpers/common";
import { BASE_SECTION } from "../homepage/reducers";

export const loadReport = (data = {}) => (dispatch) => {
  const { objectSlug } = EntryPoint.instance;
  dispatch(updateReportData({ loaded: false, loading: true }));
  loadReportRequest({ objectSlug }, data).then((response) => {
    const { data } = response;
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateReportFailure });

    const { report } = data;
    if (isPresent(report)) dispatch(updateReportData({ ...report, loaded: true, loading: false }));
  })
}

// Report Decisions
export const loadReportDecisions = (data = {}) => (dispatch, getState) => {
  const { objectSlug } = EntryPoint.instance;
  const { decision_slug } = data;
  dispatch(updateReportData({ decisions: { ...BASE_SECTION, loading: true }, ...data }))
  const { sort_order, current_page } = getState().report;

  loadReportDecisionsData(objectSlug, { sort_order, page: current_page, decision_slug }).then((response) => {
    const { data } = response;
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateReportFailure });

    const { decisions, current_page, total_pages, total_count } = data
    dispatch(
      updateReportData({
        decisions: { data: decisions, loaded: true, loading: false },
        current_page, total_pages, total_count
      })
    )
  })
}

// Report Data Sources
export function destroyReportSource(slug, data) {
  return (dispatch) => {
    const { objectSlug } = EntryPoint.instance;

    destroyReportDataSource({ objectSlug, slug, data }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateReportFailure });

      const { data } = response;
      const { data_sources } = data;
      dispatch(updateReportData({ data_sources }));
    })
  }
}
export function createReportSource(data, config = {}, callback = () => {}) {
  return (dispatch) => {
    const { objectSlug } = EntryPoint.instance;
    createReportDataSource({ objectSlug, data, config }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateReportFailure }, callback)

      const { data } = response;
      const { data_sources } = data;
      dispatch(updateReportData({ data_sources }));
      callback(true);
    })
  }
}
export function replaceReportSource(slug, data, config = {}, callback = () => {}) {
  return (dispatch) => {
    const { objectSlug } = EntryPoint.instance;

    replaceReportDataSource({ objectSlug, slug, data, config }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateReportFailure }, callback)

      const { data } = response;
      const { data_sources } = data;
      dispatch(updateReportData({ data_sources }));
      callback(true);
    })
  }
}
export function updateReportSource(slug, data) {
  return (dispatch) => {
    const { objectSlug } = EntryPoint.instance;

    updateReportDataSource({ objectSlug, slug, data }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateReportFailure });

      const { data } = response;
      const { data_sources } = data;
      dispatch(updateReportData({ data_sources }));
    })
  }
}

export function saveUploadingSources(sources) {
  return (dispatch) => {
    dispatch(updateReportData({ uploading_sources: sources }));
  }
}

export const updateReport = (data, callback) => (dispatch) => {}

export const removeReport = (slug, callback) => (dispatch) => {
  removeReportRequest(slug).then((response) => {
    const { data } = response;
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateReportFailure }, callback);

    const { report } = data;
    if (isPresent(report)) dispatch(updateReportData({ ...report, loaded: true, loading: false }));
    callback(true)
  })
}
// Common functions
export const loadReportData = (data) => ({
  type: LOAD_REPORT_DATA,
  payload: {
    ...data
  }
});

export const updateReportData = (data) => ({
  type: UPDATE_REPORT_DATA,
  payload: {
    ...data
  }
});

export const resetReportData = () => ({
  type: RESET_REPORT_DATA
});

export const updateReportFailure = error => ({
  type: UPDATE_REPORT_FAILED,
  payload: {
    error,
    loading: false
  }
});

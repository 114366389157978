import React, { Fragment } from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Loader } from "../../common/Loader";
import { loadPreviewTemplateCallbacks, mapDispatchToProps, mapStateToProps } from "../../template_preview";
import { isDSightTemplate } from "../../helpers/template_helpers";
import { isTemplateLoaded } from "../../template_view";
import TemplateDetailsPanel from "../../template_preview/side_panel/TemplateDetailsPanel";
import TemplateTreePanel from "../../template_preview/side_panel/TemplateTreePanel";
import TemplatePlaybookPanel from "../../template_view/side_panel/TemplatePlaybookPanel";
import AlertsSection from "../../alerts";
import TemplatePreviewHeader from "../../template_preview/header";
import DSightDecisionModal, { showDSightDecisionModal } from "../template_view/modals/DSightDecisionModal";
import DSightDriverModal, { showDSightDriverModal } from "../template_view/modals/DSightDriverModal";
import { closeModal } from "../../store/modals/actions";
import SidebarTreeNavigationPreview from "../../template_preview/navigation";
import { isStoreLoading } from "../../helpers/callbacks_helpers";
import DSightTemplatePreviewTree from "../../tree_view/accordion_tree/DSightTemplatePreviewTree";

export const Sidebars = () =>
  <Fragment>
    <div className="sidebars">
      <TemplateDetailsPanel key="decision-details-side-panel" />
      <TemplateTreePanel key="template-tree-side-panel" forceEditLink={true} preview={true} />
      <TemplatePlaybookPanel preview={true} addClass={'left-side-panel-position tree-page'} />
    </div>
  </Fragment>

export const Wrapper = ({ children, modal, closeModal, ...opts }) =>
  <Fragment>
    <AlertsSection />
    <TemplatePreviewHeader isDsight={true} />
      <div className="d-flex overflow-hidden">
        <SidebarTreeNavigationPreview {...opts} />
        {children}
      </div>
    <Sidebars />
    <div className="modals">
      <DSightDecisionModal shown={showDSightDecisionModal(modal)} onClose={closeModal} scope={'templates'} />
      <DSightDriverModal shown={showDSightDriverModal(modal)} onClose={closeModal} scope={'templates'} />
    </div>
  </Fragment>

export const DSightTemplatePreview = ({template, modal, match, closeModal, ...opts}) => {
  loadPreviewTemplateCallbacks({ template, match, ...opts })

  if (isTemplateLoaded(template, match) && !isDSightTemplate(template)) {
    const history = useHistory();
    history.push(`/templates/${template.slug}/preview`);
  }

  if (isStoreLoading(template)) {
    return <Wrapper modal={modal} closeModal={closeModal}>
      <Loader />
    </Wrapper>;
  } else {
    return <Wrapper modal={modal} closeModal={closeModal}>
      <DSightTemplatePreviewTree />
    </Wrapper>;
  }
}
export const newMapStateToProps = ({ modal, ...opts }) => ({
  ...mapStateToProps(opts),
  modal
});
export const newMapDispatchToProps = (dispatch) => ({
  ...mapDispatchToProps(dispatch),
  closeModal: () => { dispatch(closeModal()) }
})
export default connect(newMapStateToProps, newMapDispatchToProps)(DSightTemplatePreview);


import React, {useState} from 'react';
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import {isBlank, isPresent} from "../../../../helpers/common";
import {RichTextEditor} from "../../../../common/RichTextEditor";
import AccordionWrapper from "../../../../common/AccordionWrapper";

const NextStepsInput = ({ object, nextStepsDescription, setNextStepsDescription }) => {
  const description = object.next_steps_description

  const accordionObject = {
    header: <h3 className="mb-0">Next steps</h3>,
    body: <RichTextEditor quillId={'NextStepsInput'} value={nextStepsDescription} setValue={setNextStepsDescription}
                          placeholder="Enter any actions to take" />,
    defaultExpand: isPresent(description)
  };

  return <AccordionWrapper accordionObject={accordionObject} className='mb-3' />
}

export default NextStepsInput;

import {
  otherChoicesBlock, averageBuyIn, enteredBySection
} from "./commonBlocks";
import {currencyFormat, isBlank} from "../../../helpers/common";
import Decision from "../../../models/decision";
import * as moment from "moment/moment";
import React from "react";
import {dataSourcesBlockSection} from "./DataSources";
import {dSightAnalysisBlock} from "./DSightBlocks";
import {baseBlockSection, baseHeadParagraph, baseRichBlock, paragraphBorder} from "./wrappers";
import {finalDecisionSection} from "./FinalDecision";
import {HeadingLevel} from "docx";

export const DecisionSection = (decision, drivers, data_sources) => {
  const decisionObj = new Decision(decision, drivers)
  if(!decisionObj.isDecisionRecorded) return [];

  return [
    baseHeadParagraph({text: 'What was decided', heading: isBlank(decision.collaboration_context) ? HeadingLevel.HEADING_5 : HeadingLevel.HEADING_2}),
    ...finalDecisionSection({obj: decisionObj, instance: 6}),
    ...baseBlockSection({header: 'Rationale', text: decision.final_decision_reasons, instance: 1}),
    ...baseBlockSection({header: 'Next steps', text: decision.next_steps_description, instance: 2}),
    ...baseBlockSection({header: 'Expected results', text: decision.expected_results, instance: 3}),
    ...baseBlockSection({header: 'Expected opportunity value', text: currencyFormat(decision.expected_opportunity)}),
    ...baseBlockSection({header: 'Expected investment or cost', text: currencyFormat(decision.expected_investment)}),
    ...baseBlockSection({header: 'Decision Date', text: decision.historical_decided_at ? moment(decision.historical_decided_at).format('DD MMM, yyyy') : ''}),
    enteredBySection({user: decision.deciding_user, time: decision.decided_at}),
    ...dSightAnalysisBlock(decision),
    ...otherChoicesBlock({obj: decisionObj}),
    ...averageBuyIn(decisionObj),
    ...dataSourcesBlockSection({data_sources}),
    paragraphBorder(),
  ]
}
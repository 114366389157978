import React from 'react';
import { connect } from "react-redux";
import ChoiceItem, { sortedRatedChoices } from './ChoiceItem';
import Decision from "../../../../models/decision";

const PickyChoices = ({decision, drivers}) =>
  <div>
    {
      sortedRatedChoices(decision, drivers).map((choice) =>
        <ChoiceItem choice={choice} key={`picky-choice-${choice.slug}`} selected={choice.final_decision} />
      )}
  </div>

const mapStateToProps = ({ sidebar, tree }) => ({ decision: sidebar.decision, drivers: tree.drivers });
export default connect(mapStateToProps)(PickyChoices);

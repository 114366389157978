import React from 'react';
import { connect } from "react-redux";
import { isDecider } from "../../../helpers/user_helpers";
import ShareDropdown from "../ShareDropdown";

const RenderShareDropdown = ({ current_user }) =>
  !isDecider(current_user) ? null :
    <li className="nav-item ms-2">
      <ShareDropdown dropdownClassName="transparent-btn" title=''
                     btnClassName="btn btn-secondary btn-lg btn-lg-round header-toggle-light dropdown-toggle-header pt-2"
                     btnId="share-header-dropdown"/>
    </li>

const mapStateToProps = ({ current_user }) => ({ current_user });
export default connect(mapStateToProps)(RenderShareDropdown);

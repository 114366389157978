import React, { useEffect, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../common/CloseIcon";
import { ModalDoneFooter } from "../../common/modals";
import { isBlank, isPresent } from "../../helpers/common";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { createNewTemplate } from "../../store/template/actions";
import { initAutofocusInput } from "./helpers";
import DecisionTitleRow from "../../tree_wizard/helpers/DecisionTitleRow";
import { getSectionSlugFromParam } from "../../helpers/set_helpers";
import { resetTemplateSetData } from "../../store/template_set/common_actions";
import { reloadTemplatesData } from "../../store/homepage/actions";

export const showCreateTemplateModal = (modal) => modal.type === "CreateTemplateModal" && modal.shown;
export const openCreateTemplateModal = ({ openModal, ...opts }) => openModal({ ...opts, type: 'CreateTemplateModal' })

const CreateTemplateModal = ({
                               shown, onClose = () => {},
                               isTemplateSet = false,
                               template_set, createNewTemplate, resetTemplateSetData, reloadTemplatesData
                             }) => {
  const [description, setDescription] = useState('');
  const [submit, setSubmit] = useState(false);
  const history = useHistory();

  const inputRef = initAutofocusInput([shown])

  useEffect(() => {
    setDescription('')
    setSubmit(false)
  }, [])

  const onCloseModal = () => {
    onClose();
    setDescription('')
    setSubmit(false)
  }

  const onCreate = () => {
    setSubmit(true)
    const data = { description, complete: true }
    if (isTemplateSet) {
      data.template_set_slug = template_set.slug
      data.section_slug = getSectionSlugFromParam(document)
    }
    createNewTemplate(data, (slug, completed = false) => {
      onCloseModal()
      if (isPresent(slug)) history.push(`/templates/${slug}${completed ? '' : '/wizard'}`)
      if (completed) {
        resetTemplateSetData()
        reloadTemplatesData()
      }
    })
  }

  return <Modal size="md" backdrop="static" show={shown} onHide={onCloseModal}>
    <Modal.Body>
      <CloseIcon onClose={onCloseModal} />
      <h2>Create a template</h2>
      <DecisionTitleRow {...{ description, setDescription, submit, createTemplateModal: true }} ref={inputRef} />
    </Modal.Body>
    <ModalDoneFooter onClose={onCreate} disabled={isBlank(description) || submit} />
  </Modal>
}
const mapStateToProps = ({ current_user, template_set }) => ({
  current_user, template_set
});
export const mapDispatchToProps = (dispatch) => ({
  createNewTemplate: (data, callback) => { dispatch(createNewTemplate(data, callback)) },
  reloadTemplatesData: () => { dispatch(reloadTemplatesData()) },
  resetTemplateSetData: () => { dispatch(resetTemplateSetData()) }
})
export default connect(mapStateToProps, mapDispatchToProps)(CreateTemplateModal);

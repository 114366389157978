import React from 'react';
import Form from "react-bootstrap/Form";
import { flow } from "../../helpers/decision_flows";

const CheckForm = ({type = 'radio', label = '', onClick = () => {},
                            name = 'group', className = '', checked = false}) => {
  return <Form.Check
    className={`d-flex align-items-center ${className}`}
    defaultChecked={checked}
    type={type}
    name={name}
    label={label}
    id={`${type}-${label}`}
    onClick={onClick} />
}

export default ({flowEnum, setFlowEnum}) =>
  <div className={`w-100 `}>
    <div className="row">
      <h3>What do you want to do?</h3>
      <Form>
        <CheckForm label={'Start a decision tree'}
                   className='mb-2'
                   checked={flowEnum === flow.TREE_RECOMMENDATION}
                   onClick={() => setFlowEnum(flow.TREE_RECOMMENDATION)} />
        <CheckForm label={'Record a decision'}
                   className='mb-0'
                   checked={flowEnum === flow.TREE_HISTORICAL}
                   onClick={() => setFlowEnum(flow.TREE_HISTORICAL)} />
      </Form>
    </div>
  </div>

import React, {useState, useEffect, useCallback, useMemo} from "react";
import { useHistory } from "react-router-dom";
import DropdownWithSearch from "../../common/DropdownWithSearch"
import { SmallLoader } from "../../common/Loader";
import { isBlank } from "../../helpers/common";

const ScenarioName = ({ forecast_simulator_scenario, scenarioIsLoaded }) => {
  const history = useHistory();

  const allOptions = useMemo(() => [
    ...forecast_simulator_scenario.config_scenarios
                                  .filter(hash => hash.id !== forecast_simulator_scenario.scenario_id)
                                  .filter(hash => isBlank(hash.default_scenario))
  ], [forecast_simulator_scenario.config_scenarios])

  const [selectedOption, setSelectedOption] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredScenarios, setFilteredScenarios] = useState(allOptions)
  useEffect(() => {
      setSelectedOption(forecast_simulator_scenario.scenario.data?.attributes?.display_name)
  }, [forecast_simulator_scenario.scenario]);
  useEffect(() => {
    setFilteredScenarios(
      allOptions.filter(hash =>
        isBlank(searchQuery.trim()) || hash.display_name.toLowerCase().includes(searchQuery.trim().toLowerCase())
      )
    )
  }, [searchQuery, forecast_simulator_scenario.config_scenarios]);
  const onSelectScenario = useCallback(scenario_id => {
    history.push(`/forecast_simulator/scenarios/${scenario_id}`)
  }, [])

  return <div className="text-start mx-3">
    <div className="fs-6 lh-1 mb-1">Simulated scenario</div>
    {
      scenarioIsLoaded ?
        <div className="fs-5 lh-lg" style={{marginBottom: '-6px'}} hidden={!scenarioIsLoaded}>
          <DropdownWithSearch {...{
            selectedOption, setSelectedOption, searchQuery, setSearchQuery,
            onClick: onSelectScenario,
            options: filteredScenarios}}
          />
        </div> :
        <SmallLoader />
    }
  </div>
}
export default ScenarioName;

import React, {createRef, useCallback} from "react";
import {RichMutedText} from "../../../common/RichTextEditor";
import CollaboratorsInviteRow from "./CollaboratorsInviteRow";
import Switcher from "../../../common/Switcher";
import {checkSetInvitesEffect} from "../../../helpers/wizard_helpers";
import {connect} from "react-redux";
import {loadContacts} from "../../../store/contacts/actions";
import {Typeahead} from "react-bootstrap-typeahead";
import {DEFAULT_TYPEHEAD_ATTRIBUTES} from "../../../tree_view/modals/helpers/share_helpers";
import {onChangeInvites, renderTag} from "./helpers/collaboration_helpers";
import {renderContactItem} from "../../../tree_view/side_panel/helpers/collaborators_helpers";
import {
  fetchAddSetMembers,
  onKeyDownCollaborator
} from "./CollaboratorsDecisionStep";
import {isGroupContact} from "../../../helpers/decision_helpers";
import Button from "react-bootstrap/Button";
import {isBlank} from "../../../helpers/common";

const CollaboratorsBlock = ({wizard, playbook_notes, decision, contactsData, loadContacts,
                             decision_set, invites, setInvites, rateSupport, setRateSupport, classNames}) => {

  const filteredInvites = invites.filter(invite => invite.email !== decision.user.email)
  const filterSetInvitesToAdd = fetchAddSetMembers(decision, decision_set, invites)
  const ref = createRef();
  let filteredContacts;
  filteredContacts = useCallback(() =>
    contactsData.contacts.filter(contact => {
      if (isGroupContact(contact)) return true;
      if (contact.value === decision.user.email) return false;

      return !invites.some(invite => invite.email === contact.value);
    })
    , [contactsData.contacts.length, invites.length, decision.user.email]
  );

  const addSetMembers = () => setInvites([...invites, ...filterSetInvitesToAdd])
  const removeInvite = (invite) => setInvites(invites.filter(i => i.email !== invite.email))

  checkSetInvitesEffect(wizard, contactsData, loadContacts, setInvites);

  const InputField = () => <Typeahead
    {...DEFAULT_TYPEHEAD_ATTRIBUTES}
    ref={ref} selected={[]}
    id="collaboration-invite-input" labelKey="value"
    onChange={(selected) => onChangeInvites(selected, invites, setInvites, contactsData)}
    options={filteredContacts()}
    renderMenuItemChildren={renderContactItem}
    onKeyDown={(e) => onKeyDownCollaborator(e, ref, invites, setInvites, contactsData)}
    renderToken={(option, events, index) => renderTag(option, events, index, contactsData)}
  />

  return <div className={`bg-white rounded p-3 my-3 ms-xl-3`}>
    <div className="w-100 mb-3" hidden={isBlank(playbook_notes.collaborators)}>
      <RichMutedText text={playbook_notes.collaborators} />
    </div>
    <div className="w-100">
      <h3>Collaborators</h3>
      <CollaboratorsInviteRow user={ decision.user } email={decision.user.email} slug={'decider'} />
      { filteredInvites.map(invite => <CollaboratorsInviteRow {...invite} key={`invite-row-${invite.slug}`} onRemoveClick={() => removeInvite(invite)} />) }
      <Button onClick={addSetMembers} hidden={isBlank(filterSetInvitesToAdd)} disabled={wizard.submit} className="btn-secondary w-100 mt-1">
        Add all decision flow members
      </Button>
      <div className="w-100 mb-3 mt-2">
        <InputField />
      </div>
      <div className={`w-100 ${classNames}`}>
        <Switcher label={'Allow collaborators to rate their level of support for what was decided.'}
                  value={rateSupport} onChange={(checked) => setRateSupport(checked)} />
      </div>
    </div>
  </div>
}
const mapStateToProps = ({ wizard, decision, playbook_notes, contacts, decision_set}) => ({
  wizard, decision, playbook_notes, decision_set,
  contactsData: contacts
});
const mapDispatchToProps = (dispatch) => ({
  loadContacts: (callback) => {
    dispatch(loadContacts(callback));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(CollaboratorsBlock);

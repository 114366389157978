import React, { useEffect, useMemo, useState } from 'react';
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { isBlank } from "../../helpers/common";
import CategoriesForm from "../../decision_categories/CategoriesForm";
import { saveWizardStepData } from "../../store/wizard/actions";
import { stepKeyWithCategories, wizardStepDataBy } from "../../helpers/wizard_helpers";
import { categorizationStepState } from "../steps_wizard/steps/TreeDecisionCategorizationStep";
import { ModalBodyWithClose, ModalDoneFooterP0 } from "../../common/modals";
import { CategorizationPBSection } from "../../template_view/side_panel/sections/PlaybookNotesSection";
import SelectCategory from "../helpers/SelectCategory";
import { fetchRequiredCategories } from "../../helpers/categories_helpers";

export const DecisionCategoriesModal = ({
                                          wizard, playbook_notes, shown,
                                          onClose = () => {}, org_categories,
                                          saveWizardStepData
                                        }) => {
  const stepData = wizardStepDataBy(wizard, stepKeyWithCategories(wizard))
  const {
    setCategoriesData, categoriesData,
    selectedCategories, onSelectCategory, submitCategoriesData,
    ...hash
  } = categorizationStepState({ wizard, org_categories })

  const [submitState, setSubmitState] = useState(false)

  useEffect(() => { if (shown) setCategoriesData(stepData['categories']) }, [shown])

  const reset = () => {
    setCategoriesData(stepData['categories'])
    close()
  }
  const close = () => onClose()
  const submitData = () => {
    setSubmitState(true)
    saveWizardStepData(stepKeyWithCategories(wizard), {
      response: { step_index: wizard.step_index },
      ...submitCategoriesData(),
      finish_later: true
    }, success => {
      setSubmitState(false)
      if (success) close();
    })
  }

  const requiredCategories = useMemo(() => fetchRequiredCategories(org_categories, selectedCategories), [org_categories, selectedCategories])

  const isRequired = () => {
    if (isBlank(requiredCategories)) return false;

    return requiredCategories.some(category => !category.options.some(option => categoriesData.includes(option.id)))
  }

  return <Modal size="md" backdrop="static" show={shown} onHide={reset}>
    <ModalBodyWithClose bsPrefix="categories-modal" header="Categories" onClose={reset}>
      <CategorizationPBSection classParams={'mb-3 mt-2-negative'} playbook_notes={playbook_notes} sidePanelStyle={true} showNA={false}/>
      <CategoriesForm className={'w-100 mb-3'}
                      {...{...hash, submitState, selectedCategories, categoriesData}} />
      <SelectCategory selected={selectedCategories}
                      onSelect={onSelectCategory}
                      submitState={submitState} />
    </ModalBodyWithClose>
    <ModalDoneFooterP0 disabled={isRequired() || submitState} onClose={submitData} />
  </Modal>
}
const mapStateToProps = ({ wizard, template, playbook_notes, org_categories }) => ({ wizard, template, playbook_notes, org_categories });
export default connect(mapStateToProps, { saveWizardStepData })(DecisionCategoriesModal);

const THIS_POINT_ONLY_ITEM = 'This point only';
const RESET_ITEM = 'Reset';
const DUPLICATE_TO_ITEM = 'Duplicate to';
const ALL_VISIBLE_TIME_PERIODS_ITEM = 'All visible time periods in this row';
const TIME_PERIODS_ITEM = 'All time periods in this row';

const buildParamsToReset = (rowNode, colDefs) =>
  (colDefs.map((colDef) => ({ node: { id: rowNode.id }, colDef })))

const buildColDefs = (allFuturePeriodsByTimeScale) =>
  (allFuturePeriodsByTimeScale.map(period => ({ colId: period.id, field: period.name })))

const resetItem = (params, colDefs, onResetCells, allFuturePeriodsByTimeScale) => {
  const rowNode = params.node;

  const action = (visible = false) => {
    const consideredColDefs = visible ?
      colDefs.filter(colDef => colDef.editable) :
      buildColDefs(allFuturePeriodsByTimeScale)
    onResetCells(rowNode, buildParamsToReset(rowNode, consideredColDefs))
  }

  return {
    name: RESET_ITEM,
    subMenu: [
      {
        name: THIS_POINT_ONLY_ITEM,
        action: () => onResetCells(rowNode, buildParamsToReset([params.column.colDef]))
      },
      {
        name: ALL_VISIBLE_TIME_PERIODS_ITEM,
        action: () => action(true)
      },
      {
        name: TIME_PERIODS_ITEM,
        action
      },
    ],
  };
}

const duplicateToItem = (params, colDefs, onCellValueChanged, allFuturePeriodsByTimeScale) => {
  const rowNode = params.node;

  const action = (visible = false) => {
    const valuesToUpdate = [];
    const consideredColDefs = visible ?
      colDefs.filter(colDef => colDef.editable) :
      buildColDefs(allFuturePeriodsByTimeScale)

    consideredColDefs.forEach(colDef => {
      valuesToUpdate.push({
        node: { id: rowNode.id, prevValue: rowNode.data[colDef.field], value: params.value },
        colDef
      });
    });
    const callback = () => {
      consideredColDefs.forEach(colDef => rowNode.setData({...rowNode.data, [colDef.field]: params.value}));
    }
    onCellValueChanged(valuesToUpdate, callback);
  }

  return {
    name: DUPLICATE_TO_ITEM,
    subMenu: [
      {
        name: ALL_VISIBLE_TIME_PERIODS_ITEM,
        action: () => action(true),
      },
      {
        name: TIME_PERIODS_ITEM,
        action
      },
    ],
  };
}

const applyLargeScaleInputItem = (openLargeScalePanel) =>
  ({ name: 'Apply large-scale input...', action: openLargeScalePanel });

export const getContextMenuItems = (params, editable = false, colDefs = [], onCellValueChanged = () => {},
                                    onResetCells = () => {}, allFuturePeriodsByTimeScale, openLargeScalePanel) => {
  if (editable) {
    return [
      resetItem(params, colDefs, onResetCells, allFuturePeriodsByTimeScale),
      duplicateToItem(params, colDefs, onCellValueChanged, allFuturePeriodsByTimeScale),
      applyLargeScaleInputItem(openLargeScalePanel),
      'separator',
      'copy'
    ];
  } else {
    return [
      duplicateToItem(params, colDefs, onCellValueChanged, allFuturePeriodsByTimeScale),
      applyLargeScaleInputItem(openLargeScalePanel),
      'separator',
      'copy'
    ]
  }
}

import React, { useMemo } from 'react';
import { connect } from "react-redux";
import { isBlank } from "../../helpers/common";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../common/CloseIcon";
import { ModalDoneFooter } from "../../common/modals";
import { RichTextEditor } from "../../common/RichTextEditor";
import QuestionRow from "./helpers/QuestionRow";
import DriverTypesRow, { useDefaultDriverTypesEffect } from "./helpers/DriverTypesRow";
import WizardDataSources from "../../tree_wizard/data_sources";
import { saveUploadingSources } from  "../../store/modals/actions";
import {updateDriver} from "../../store/modals/actions";

const SummaryRow = ({ answer, setAnswer }) =>
  <div className="mb-3">
    <h3>Summary</h3>
    <RichTextEditor quillId={'Summary'}
                    value={answer} setValue={setAnswer}
                    placeholder="Enter a brief summary"
    />
  </div>

const DetailsRow = ({ explanation, setExplanation, optional = true }) =>
  <div className="mb-3">
    <h3>
      Details
      { optional ?  <span className="h5 fw-normal text-muted">(optional)</span> : null }
    </h3>
    <RichTextEditor quillId={'Details'}
                    value={explanation} setValue={setExplanation}
                    placeholder="Enter any additional details, explanation, or analysis"
    />
  </div>

const HistoricalDriverDetailsModal = ({
                                        title, submitState = false,
                                        question,
                                        orgDriverTypes, show,
                                        key = '', isDSight = false,
                                        onClose, onSubmitModal = null, setDriverTypeSlug,
                                        ...opts
                                      }) => {

  const availableDriverTypes = useMemo(() => orgDriverTypes.available_types, [orgDriverTypes]);

  useDefaultDriverTypesEffect(orgDriverTypes);

  return <Modal key={key} size="lg" backdrop="static" show={show} onHide={onClose}>
    <Modal.Body>
      <CloseIcon onClose={onClose} />
      <h2>{title || 'Edit driver'}</h2>
      <form>
        <QuestionRow {...{ ...opts, question, isDSight }} />
        <SummaryRow {...opts} />
        <DetailsRow {...opts} optional={false} />
        <WizardDataSources {...opts} isDriverModal={true} isHistoricalDecision={true} />
        <DriverTypesRow {...{ ...opts, isDSight, availableDriverTypes }} />
      </form>
    </Modal.Body>
    <ModalDoneFooter onClose={onSubmitModal || onClose} disabled={isBlank(question) || submitState} />
  </Modal>
};

const mapStateToProps = ({ org_driver_types }) => {
  return {
    orgDriverTypes: org_driver_types,
  }
};
export default connect(mapStateToProps, {
  updateDriver, saveUploadingSources
})(HistoricalDriverDetailsModal);

import React, { Fragment, useEffect } from 'react';
import TreeHeader from "./header";
import Tree from "./accordion_tree/Tree";
import { loadDecisionTree } from "../store/tree/actions";
import { updateTreeData } from "../store/tree/common_actions";
import { connect } from "react-redux";
import DecisionDetailsPanel from "./side_panel/DecisionDetailsPanel";
import DiscussionPanel from "./side_panel/DiscussionPanel";
import DecisionInputLeftPanel from "./side_panel/DecisionInputRightPanel";
import DriverInputRightPanel from "./side_panel/DriverInputRightPanel";
import RecommendationInputRightPanel from "./side_panel/RecommendationInputRightPanel";
import DecisionPlaybookPanel from "./side_panel/DecisionPlaybookPanel";
import TreePanel from "./side_panel/TreePanel";
import 'react-sliding-side-panel/lib/index.css';
import AlertsSection from "../alerts";
import HelpPanel from "./side_panel/HelpPanel";
import EntryPoint, { updateEntryPointData } from "../EntryPoint";
import { loadOrgCategories } from "../store/org_categories/actions";
import { loadOrgDriverTypes } from "../store/org_driver_types/actions";
import { Loader } from "../common/Loader";
import { getWindowDimensions, LARGE_SCREEN_WIDTH } from "../store";
import {
  closeDriverInputSidebarBySlug,
  setDecisionInputSidebarOpen,
  setDecisionOrderSidebarOpen,
  setDecisionSidebarOpen,
  setDiscussionPanelOpen,
  setDriverInputSidebarOpen,
  setFinalDecisionSidebarOpen,
  setRecommendationDetailsSidebarOpen,
  setTreeSidebarOpen, setLinkPanelOpen
} from "../store/sidebar/actions";
import { openModal, closeAndResetDriverDetailsModal, closeModal } from "../store/modals/actions";
import { updateTreeChannelData } from "../store/channels/actions"
import { isBlank, isPresent } from "../helpers/common";
import { driversToArray } from "../helpers/drivers_helpers";
import Decision from "../models/decision";
import Driver from '../models/driver';
import { useHistory } from "react-router";
import { isInFlightDecision } from "../helpers/home_decision_helpers";
import { SidebarTreeNavigation } from "./navigation";
import { initTreeChannelSubscription, isDriverInEditModeByOthers, } from "../helpers/channel_helpers";
import { TREE_CHANNEL_ACTIONS } from "../../../channels/tree_channel";
import DecisionSetPanel from "./side_panel/DecisionSetPanel";
import { loadDecisionSet } from "../store/decision_set/actions";
import { checkLoadingEffect, isStoreLoading } from "../helpers/callbacks_helpers";
import { loadContacts } from "../store/contacts/actions";
import LinkPanel from "./side_panel/link_panel/LinkPanel";
import StartDuplicateDecisionModal, { showStartDuplicateDecisionModal } from "./modals/StartDuplicateDecisionModal";
import RatingsAndWeightsPanel from "./side_panel/RatingsAndWeightsPanel";
import DriverAssignModal, { showDriverAssign } from "../tree_editor/modals/DriverAssignModal";
import RecommendationAssignModal, { showRecommendationAssign } from "../tree_view/modals/RecommendationAssignModal";
import DecisionAssignModal, { showDecisionAssign } from "../tree_view/modals/DecisionAssignModal";
const { width } = getWindowDimensions();

export const Sidebars = ({ showDecisionSet = false }) =>
  <Fragment>
    <div className="sidebars">
      <RatingsAndWeightsPanel key="ratings-and-weights-panel"/>
      <RecommendationInputRightPanel key="recommendation-input-side-panel"/>
      <DriverInputRightPanel key="driver-input-side-panel"/>
      <DecisionInputLeftPanel key="decision-input-side-panel"/>
      <DecisionDetailsPanel key="decision-details-side-panel"/>
      <TreePanel key="tree-side-panel" />
      <DiscussionPanel key="discussion-side-panel"/>
      <DecisionPlaybookPanel key="playbook-side-panel" addClass="left-side-panel-position tree-page"/>
      <LinkPanel key="link-side-panel" />
      {
        showDecisionSet ?
          <DecisionSetPanel addClass="left-side-panel-position tree-page" isDecision={true} /> :
          null
      }
      <div className="help-side-panels">
        <HelpPanel/>
      </div>
    </div>
  </Fragment>

export const Wrapper = ({ children, modal, closeModal, ...opts }) =>
  <Fragment>
    <AlertsSection/>
    <TreeHeader/>
    <div className="d-flex overflow-hidden">
      <SidebarTreeNavigation {...opts} />
      {children}
    </div>
    <Sidebars {...opts} />
    <div className="modals">
      <DecisionAssignModal key={`decision-assign-modal-${modal.slug}`} show={showDecisionAssign(modal)} onClose={closeModal} />
      <RecommendationAssignModal key={`recommendation-assign-modal-${modal.slug}`} show={showRecommendationAssign(modal)} onClose={closeModal} />
      <DriverAssignModal key={`driver-assign-modal-${modal.slug}`} show={showDriverAssign(modal)} onClose={closeModal} />
      <StartDuplicateDecisionModal shown={showStartDuplicateDecisionModal(modal)} onClose={closeModal} />
    </div>
  </Fragment>

export const handleUserLanding = (decision, openFinalDecisionSideBar, openRecommendationDetailsSideBar, openDecisionDetailsSideBar) => {
  if( width >= LARGE_SCREEN_WIDTH) {
    const decisionObject = new Decision(decision);
    if (decisionObject.isDecisionRecorded) {
      openFinalDecisionSideBar();
    } else if(decisionObject.isEnteredRecommendation) {
      openRecommendationDetailsSideBar();
    } else if(isInFlightDecision(decisionObject)) {
      return false;
    } else {
      openDecisionDetailsSideBar();
    }
  }
};

export const loadDecisionTreeCallback = ({
                                           history,
                                           tree,
                                           current_user,
                                           loadDecisionTree,
                                           openFinalDecisionSideBar,
                                           openDecisionOrderSideBar,
                                           openDecisionDetailsSideBar,
                                           setTreeSidebarOpen,
                                           openDiscussionSideBar,
                                           openRecommendationDetailsSideBar,
                                           setDecisionInputSidebarOpen,
                                           setDriverInputSidebarOpen,
                                           openModal,
                                           updateTreeChannelData,
                                           closeDriverInputSidebarBySlug,
                                           setLinkPanelOpen,
                                           closeAndResetDriverDetailsModal,
                                           updateTreeData
                                         }) => {
  useEffect(() => {
    if (tree.loaded || tree.loading) return;

    loadDecisionTree((success, decision = {}, tree_data = {}) => {
      if (success) {
        initTreeChannelSubscription(decision, current_user, updateTreeChannelData, closeDriverInputSidebarBySlug, closeAndResetDriverDetailsModal)
        const getParams = new URLSearchParams(document.location.search)
        switch (getParams.get('side_bar_open')) {
          case 'decision':
            openFinalDecisionSideBar();
            break;
          case 'details':
            openDecisionDetailsSideBar();
            break;
          case 'discussion':
            openDiscussionSideBar();
            break;
          case 'decision_tree':
            setTreeSidebarOpen()
            break;
          case 'link_panel':
            setLinkPanelOpen()
            break;
        }
        switch (getParams.get('left_side_bar_open')) {
          case 'decision_order':
            openDecisionOrderSideBar();
            return;
          case 'decision':
            openFinalDecisionSideBar();
            break;
          case 'recommendation_details':
            openRecommendationDetailsSideBar();
            break;
        }
        switch (getParams.get('modal_open')) {
          case 'buy-in':
            openFinalDecisionSideBar();
            break;
          case 'decision':
            setTimeout(() => setDecisionInputSidebarOpen(), 100)

            getParams.delete('modal_open');
            history.replace({ search: getParams.toString() });
            break;
        }
        const driverOpenSlug = getParams.get('driver_open')
        if (isPresent(driverOpenSlug)) {
          const driverData = driversToArray(tree_data.drivers).find((driverData) => driverData.driver.slug === driverOpenSlug)
          if(isPresent(driverData)) {
            if (new Driver(driverData.driver, driverData.driver_sources_slugs, {}, new Decision(decision)).isCompleted) {
              updateTreeData({ scroll_to_driver: driverOpenSlug });
            } else {
              if(!isDriverInEditModeByOthers(tree_data.channels, TREE_CHANNEL_ACTIONS.edit_driver_answer, driverOpenSlug, current_user))
              setTimeout(() => setDriverInputSidebarOpen(driverOpenSlug), 100)
            }
          }
          getParams.delete('driver_open');
          history.replace({ search: getParams.toString() });
          return;
        }
        if (isPresent(getParams.get('decision_set'))) {
          openDecisionOrderSideBar();
          return;
        }
        if (isBlank(getParams.get('side_bar_open'))) {
          handleUserLanding(decision, openFinalDecisionSideBar, openRecommendationDetailsSideBar, openDecisionDetailsSideBar);
        }
      } else if(decision?.status === 403) {
        document.location.reload()
      }
    });
  }, [tree.loaded]);
}

export const loadTreeViewerEffects = ({
                                        match,
                                        tree, decision, current_user, contactsData, signIn, org_categories,
                                        org_driver_types, loadOrgCategories, loadOrgDriverTypes, loadContacts, updateTreeData
                                      }) => {
  useEffect(() => {
    updateEntryPointData(match, 'decisions')
    if (tree.loaded && isPresent(match?.params?.id) && decision.slug !== match?.params?.id) {
      updateTreeData({ loaded: false })
    }
  }, [match?.params?.id, tree.loaded, decision])
  useEffect(() => {
    if (!org_categories.loaded && !org_categories.loading) loadOrgCategories({ decision_slug: EntryPoint.instance.objectSlug });
  }, [org_categories.loaded])
  checkLoadingEffect(contactsData, loadContacts)
  checkLoadingEffect(org_driver_types, loadOrgDriverTypes);
  useEffect(() => {
    if (tree.loaded && current_user.loaded) {
      if (!signIn.signedIn && !decision.sharable) document.location.href = '/';
    }
  }, [tree.loaded, current_user.loaded])
}

export const loadDecisionSetCallback = ({ tree, decision, decision_set, loadDecisionSet }) => {
  checkLoadingEffect(decision_set,
    () => loadDecisionSet({ scope: 'decision_sets', slug: decision.decision_set_slug, decision_slug: decision.slug }),
    {
      condition: ([tree]) => tree.loaded && isPresent(decision.decision_set_slug),
      another_stores: [tree]
    }
  )
}

const TreeView = ({
                    modal, tree, decision, match, closeModal,
                    ...opts
                  }) => {
  const history = useHistory();
  loadTreeViewerEffects({ tree, decision, match, ...opts })
  loadDecisionTreeCallback({ history, tree, ...opts })
  useEffect(() => {
    if (isPresent(decision.recommendation)) {
      EntryPoint.instance.recommendationSlug = decision.recommendation.slug;
    }
  }, [decision])
  loadDecisionSetCallback({ tree, decision, ...opts })

  if (isStoreLoading(tree)) {
    return <Wrapper {...{ modal, closeModal }}>
      <Loader/>
    </Wrapper>;
  } else {
    return <Wrapper {...{ modal, closeModal, showDecisionSet: isPresent(decision.decision_set_slug) }}>
      <Tree />
    </Wrapper>;
  }
};
export const mapStateToProps = ({ modal, tree, org_categories, decision, current_user, signIn, org_driver_types, decision_set, contacts }) => ({
  modal, tree, org_categories, decision, current_user, signIn, org_driver_types, decision_set, contactsData: contacts
});
export const mapDispatchToProps = (dispatch) => ({
  loadDecisionSet: (data) => dispatch(loadDecisionSet(data)),
  loadDecisionTree: (callback) => { dispatch(loadDecisionTree(callback)); },
  updateTreeData: (data) => { dispatch(updateTreeData(data)); },
  loadOrgCategories: (data = {}) => {
    dispatch(loadOrgCategories(data));
  },
  openDecisionOrderSideBar: () => dispatch(setDecisionOrderSidebarOpen(true)),
  openDecisionTreeSideBar: () => {
    dispatch(setTreeSidebarOpen(true));
  },
  openDecisionDetailsSideBar: () => {
    dispatch(setDecisionSidebarOpen(true));
  },
  setDecisionInputSidebarOpen: () => {
    dispatch(setDecisionInputSidebarOpen(true))
  },
  setDriverInputSidebarOpen: (driverSlug) => {
    dispatch(setDriverInputSidebarOpen(true, driverSlug))
  },
  openFinalDecisionSideBar: () => {
    dispatch(setFinalDecisionSidebarOpen(true));
  },
  openRecommendationDetailsSideBar: () => {
    dispatch(setRecommendationDetailsSidebarOpen(true));
  },
  openDiscussionSideBar: () => {
    dispatch(setDiscussionPanelOpen(true));
  },
  openLinkPanel: () => {
    dispatch(setLinkPanelOpen(true));
  },
  setTreeSidebarOpen: () => {
    dispatch(setTreeSidebarOpen(true));
  },
  openModal: (data = {}) => {
    dispatch(openModal(data));
  },
  loadOrgDriverTypes: () => {
    dispatch(loadOrgDriverTypes())
  },
  updateTreeChannelData: (data) => {
    dispatch(updateTreeChannelData(data))
  },
  closeDriverInputSidebarBySlug: (driverSlug) => {
    dispatch(closeDriverInputSidebarBySlug(driverSlug))
  },
  closeAndResetDriverDetailsModal: (driverSlug) => dispatch(closeAndResetDriverDetailsModal(driverSlug)),
  loadContacts: () => dispatch(loadContacts()),
  closeModal: () => { dispatch(closeModal()) }
});

export default connect(mapStateToProps, mapDispatchToProps)(TreeView);

import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from "react-redux";
import SignInHeader from "./header";
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import { isBlank, isPresent } from "../helpers/common";
import { Link } from "react-router-dom";
import EntryPoint from "../EntryPoint";
import { Routes } from "../routes";
import { updateSignInUserData } from "../store/sign_in/actions";
import parse from 'html-react-parser';

const LoginPage = ({ selectedOrgId = null, disableEmail = false, signIn, updateSignInUserData }) => {
  const [email, setEmail] = useState(EntryPoint.instance.pre_fill_data?.email || signIn.email)
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(false)
  const [useSSO, setUseSSO] = useState(false)
  const [submit, setSubmit] = useState(false)
  const { instance } = EntryPoint
  const [errors, _] = useState({ ...instance.flash })
  const formRef = useRef(null)

  useEffect(() => {
    instance.pre_fill_data = {}
    instance.flash = {}
  }, [])

  const authenticityToken = useMemo(() =>
    document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  , [])

  const onSubmit = useCallback(_ => {
    setSubmit(true)
  }, [])

  const submitForm = useCallback((e) => {
    e.preventDefault();
    onSubmit()
    formRef.current.submit();
    return false;
  }, [])

  const cacheEmail = useCallback(() => updateSignInUserData(email), [email])

  const selectedOrgField = () => {
    if(isBlank(selectedOrgId)) return <></>;

    return <input type={`hidden`} name={'user[selected_org_id]'} value={selectedOrgId}/>
  }

  const parseText = (text) => {
    return isPresent(text) ? parse(text) : null
  }

  return <Fragment>
    <SignInHeader />
    <Container>
      <div className="bg-white rounded p-3 my-3 mx-auto sign_in">
        <div className="header text-center">
          <h2>Log in</h2>
        </div>
        <div className="row" hidden={isBlank(errors['notice'])}>
          <div className="col text-center">
            <div className="alert alert-success mx-auto mb-2">
              {parseText(errors['notice'])}
            </div>
          </div>
        </div>
        <div className="row" hidden={isBlank(errors)}>
          <div className="col mb-3" hidden={isBlank(errors['warning'])}>
            <div className="text-danger w-100 text-center">
              {parseText(errors['warning'])}
            </div>
          </div>
          <div className="col mb-3" hidden={isBlank(errors['error'])}>
            <div className="text-danger w-100 text-center">
              {parseText(errors['error'])}
            </div>
          </div>
          <div className="col mb-3" hidden={isBlank(errors['alert'])}>
            <div className="text-danger w-100 text-center">
              {parseText(errors['alert'])}
            </div>
          </div>
        </div>
        <form action={Routes.sign_in} method={`post`} acceptCharset={`UTF-8`} onSubmit={submitForm} ref={formRef}>
          <input type={`hidden`} name={'authenticity_token'} value={authenticityToken}/>
          {selectedOrgField()}
          <div className="row">
            <div className="col">
              <div className="mb-2">
                <input id="user-email" name="user[email]" type={`email`}
                       className={`form-control rounded ${errors['warning'] || errors['error'] ? 'is-invalid' : ''}`}
                       placeholder={`Email address`} value={email} autoFocus={true}
                       readOnly={disableEmail || submit} onBlur={cacheEmail}
                       onChange={(e) => { setEmail(e.target.value) }}/>
              </div>
            </div>
          </div>
          <div className="row" hidden={useSSO}>
            <div className="col">
              <div className="mb-2">
                <input id="user-password" name="user[password]" type={`password`}
                       className={`form-control rounded ${errors['warning'] || errors['error'] ? 'is-invalid' : ''}`}
                       autoComplete={'off'}
                       placeholder={`Password`} value={password}
                       disabled={useSSO}
                       readOnly={submit}
                       onChange={(e) => { setPassword(e.target.value) }}/>
              </div>
            </div>
          </div>
          <div className="row" hidden={useSSO}>
            <div className="col">
              <div className="mb-3">
                <Button type="submit" className="btn btn-primary w-100" disabled={isBlank(email) || isBlank(password) || submit}>Log in</Button>
              </div>
            </div>
          </div>
          <div className="row" hidden={!useSSO}>
            <div className="col">
              <div className="mb-3">
                <Button type="submit" className="btn btn-primary w-100" disabled={isBlank(email) || submit}>Continue</Button>
              </div>
            </div>
          </div>
          <div className="row" hidden={useSSO}>
            <div className="col">
              <div className="mb-3 lh-checkbox">
                <input type="checkbox"  id="user-remember-me" name="user-remember_me"
                       className="custom-checkbox"
                       checked={rememberMe}
                       onChange={() => { setRememberMe(!rememberMe) }}/>
                <label htmlFor="user-remember-me" className="mb-0">Keep me logged in</label>
              </div>
            </div>
            <div className="col text-end mb-3">
              <a className={`pointer`} onClick={() => setUseSSO(true)}>Log in with SSO</a>
            </div>
          </div>
          <div className="row" hidden={!useSSO}>
            <div className="col text-center align-items-center mb-3">
              <a className={`pointer`} onClick={() => setUseSSO(false)}>Log in without SSO</a>
            </div>
          </div>
        </form>
        <div className="row">
          <div className="col text-center mb-3">
            <div className="or-with-line"><span>OR</span></div>
          </div>
        </div>
        <div className="row" hidden={isBlank(instance.sign_in_google_path)}>
          <div className="col">
            <form action={`/google_sign_in/authorization`} method={`post`} onSubmit={onSubmit}>
              <input type={`hidden`} name={'authenticity_token'} value={authenticityToken}/>
              <input type={`hidden`} name={'proceed_to'} value={instance.sign_in_google_path}/>
              <Button type="submit" className={`btn btn-social btn-google w-100 mb-2`} disabled={submit}>Log in with Google</Button>
            </form>
          </div>
        </div>
        <div className="row" hidden={isBlank(instance.sign_in_microsoft_path)}>
          <div className="col">
            <form action={instance.sign_in_microsoft_path} method={`post`} onSubmit={onSubmit}>
              <input type={`hidden`} name={'authenticity_token'} value={authenticityToken}/>
              <Button type="submit" className={`btn btn-social btn-microsoft w-100 mb-2`} disabled={submit}>Log in with Microsoft</Button>
            </form>
          </div>
        </div>
        <div className="row" >
          <div className="col text-center mt-2">
            <span>Trouble logging in? </span>
            <Link to={Routes.login_link} className={`pointer`}>
              Click here.
            </Link>
          </div>
        </div>
      </div>
    </Container>
  </Fragment>
}
const mapStateToProps = ({ signIn }) => ({ signIn });
const mapDispatchToProps = (dispatch) => ({
  updateSignInUserData: (email) => {
    dispatch(updateSignInUserData({ email }))
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);

import React, { useCallback } from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { redirectToHomeCallback } from "../../helpers/decision_helpers";
import { deleteDecision } from "../../store/tree/actions";
import { resetTree, updateTreeData } from "../../store/tree/common_actions";
import {
  forceReloadHomepageDecisions,
  forceReloadHomepageTemplates,
  resetSearchAndFilters
} from "../../store/homepage/actions";
import { resetSidebars, setTreeSidebarOpen } from "../../store/sidebar/actions";
import { previewWizardTree, resetWizard } from "../../store/wizard/actions";
import { deleteTemplate } from "../../store/template/actions";
import { resetPlaybookNotes } from "../../store/playbook_notes/actions";
import { isPresent, successActions } from "../../helpers/common";
import BaseHamburgerBtn, { BaseHamburgerItem, DeleteItemElement } from "../../common/BaseHamburgerBtn";
import { IconDropdown } from "../../common/dropdowns";
import { resetTemplateSetData } from "../../store/template_set/common_actions";
import { isHistoricalFlow, isTreePreview, isWizardV2, isWizardV3 } from "../../helpers/wizard_helpers";
import { deleteWarning } from "../../helpers/alert_helpers";
import { previewTreeCallback, treeSidebarOpenCallback, wizardSetCallbacks } from "./helpers";
import { resetDecisionSetData } from "../../store/decision_set/common_actions";
import { updateDecisionData } from "../../store/decision/common_actions";

export const deleteObjectCallback = ({ isTemplate, deleteTemplate, deleteDecision, actions, history, setSlug = null }) => () => {
  deleteWarning(confirmed => {
    if (!confirmed) return;

    const action = isTemplate ? deleteTemplate : deleteDecision
    action((success) => {
      if (isPresent(setSlug)) {
        history.push(`/${isTemplate ? 'template_sets' : 'decision_sets'}/${setSlug}`)
      } else {
        redirectToHomeCallback(success, history);
      }
      successActions(success, actions);
    })
  }, { isTemplate })
}

const redirectAfterFinishLater = ({ history, isTemplate, setSlug }) => {
  if (isPresent(setSlug)) {
    history.push(`/${isTemplate ? 'template_sets' : 'decision_sets'}/${setSlug}`)
  } else {
    const tab = isTemplate ? 'templates' : 'create_decisions'
    redirectToHomeCallback(true, history, tab);
  }
}
const redirectAfterComplete = ({ history, isTemplate, slug, setSlug }) => {
  const path = (isPresent(setSlug) && isTemplate) ?
    `/template_sets/${setSlug}` :
    isTemplate ? `/templates/${slug}` : `/decisions/${slug}/tree_viewer`
  history.push(path)
}

export const finishLaterCallback = ({ stepRef, actions, history, wizard, isTemplate = false, setSlug = null }) => () => {
  stepRef?.current?.submitStep({ finish_later: true, preview: isTreePreview(wizard) }, success => {
    redirectAfterFinishLater({ success, isTemplate, history, setSlug })
    successActions(success, actions)
  }, isTemplate);
}

export const startDecisionCallback = ({
                                        stepRef,
                                        wizard, actions, history, slug,
                                        isTemplate = false, setSlug = null,
                                        ...ops
                                      }) => () => {
  const submitData = (isWizardV2(wizard) || isWizardV3(wizard)) ? { complete: true, finish_later: false, preview: isTreePreview(wizard), ...ops } : { ...ops }
  if (wizard.disabledSubmit) return false;

  stepRef?.current?.submitStep(submitData, (success, finish_later = false) => {
    if (success) {
      (finish_later ? redirectAfterFinishLater : redirectAfterComplete)({ isTemplate, history, setSlug, slug })
      successActions(success, actions)
    }
  }, isTemplate);
}

export const DeleteItemSpan = ({ isTemplate }) => <DeleteItemElement name={isTemplate ? 'template' : 'decision'} />

const showStartBtn = (wizard) => {
  if (isWizardV3(wizard) && isHistoricalFlow(wizard)) return null;
  if (isWizardV3(wizard) && !isTreePreview(wizard)) return true;
  if (isWizardV2(wizard)) return true;

  return null;
}

const showPreviewStartBtn = (wizard) => {
  if (isTreePreview(wizard)) return null;
  if (isHistoricalFlow(wizard)) return null;
  if (isWizardV3(wizard)) return true;

  return null;
}

export const ActionsDropdown = ({
                                  btnId, wizard, tree, decision, contactsData,
                                  stepRef, isTemplate = false,
                                  deleteDecision, deleteTemplate, forceReloadHomepageDecisions, forceReloadHomepageTemplates,
                                  resetSidebars, resetWizard, resetTree, resetPlaybookNotes, resetSearchAndFilters,
                                  updateTreeData, updateDecisionData, previewWizardTree, setTreeSidebarOpen,
                                  ...opts
                                }) => {

  const history = useHistory();
  const {
    setObjectSlug, objectSlug, resetSetCallback
  } = wizardSetCallbacks({ ...opts, decision, isTemplate })
  const editDecisionTreeHandler = useCallback(treeSidebarOpenCallback(setTreeSidebarOpen), [])
  const deleteDecisionHandler = useCallback(deleteObjectCallback({
      isTemplate, deleteTemplate, deleteDecision, history, setSlug: setObjectSlug(),
      actions: [isTemplate ? forceReloadHomepageTemplates : forceReloadHomepageDecisions, resetSidebars, resetWizard, resetTree, resetPlaybookNotes, resetSetCallback]
  }), [setObjectSlug()])

  const finishLaterHandler = useCallback(
    finishLaterCallback({
      stepRef, history, isTemplate, setSlug: setObjectSlug(), wizard,
      actions: [
        isTemplate ? forceReloadHomepageTemplates : forceReloadHomepageDecisions,
        resetSearchAndFilters, resetSidebars, resetWizard, resetTree, resetPlaybookNotes, resetSetCallback
      ]
    }), [setObjectSlug(), wizard])

  const startDecisionHandler = startDecisionCallback( {
    stepRef, history, isTemplate, setSlug: setObjectSlug(), wizard, slug: objectSlug(),
    actions: [
      isTemplate ? forceReloadHomepageTemplates : forceReloadHomepageDecisions,
      resetSearchAndFilters, resetSidebars, resetWizard, resetTree, resetPlaybookNotes,
      resetSetCallback, () => updateTreeData({ loaded: false })
    ]
  })
  const previewTree = useCallback(
    previewTreeCallback({
      wizard, tree, decision, contactsData, isTemplate,
      updateTreeData, updateDecisionData, previewWizardTree,
      stepRef
    }), [wizard])

  const iconDropdownEvents = {
    deleteDecisionHandler: 'deleteDecisionHandler',
    finishLaterHandler: 'finishLaterHandler',
    startDecisionHandler: 'startDecisionHandler',
    previewDecisionHandler: 'previewDecisionHandler',
    editDecisionTreeHandler: 'editDecisionTreeHandler'
  }

  const iconDropdownSelect = (eventKey) => {
    switch (eventKey) {
      case iconDropdownEvents.previewDecisionHandler:
        return previewTree()
      case iconDropdownEvents.startDecisionHandler:
        return startDecisionHandler()
      case iconDropdownEvents.deleteDecisionHandler:
        return deleteDecisionHandler()
      case iconDropdownEvents.finishLaterHandler:
        return finishLaterHandler()
      case iconDropdownEvents.editDecisionTreeHandler:
        return editDecisionTreeHandler()
    }
  }

  return <IconDropdown id={btnId} bsPrefix="h5 m-0 btn-link pt-2"
                       className="btn btn-secondary btn-lg-round d-none d-lg-flex p-0"
                       onSelect={iconDropdownSelect}>
    {
      showStartBtn(wizard) &&
        <BaseHamburgerBtn eventKey={iconDropdownEvents.startDecisionHandler} disabled={wizard.submit || wizard.disabledSubmit} title={isTemplate ? 'Done' : ' Start decision'} />
    }
    {
      showPreviewStartBtn(wizard) &&
        <BaseHamburgerBtn eventKey={iconDropdownEvents.previewDecisionHandler} disabled={wizard.submit || wizard.disabledSubmit} title={'Preview decision'} />
    }
    <BaseHamburgerBtn eventKey={iconDropdownEvents.editDecisionTreeHandler} hidden={!isTreePreview(wizard)} disabled={wizard.submit} title="Edit decision tree"/>
    <BaseHamburgerBtn eventKey={iconDropdownEvents.finishLaterHandler} disabled={wizard.submit} title="Save and finish later"/>
    <BaseHamburgerItem eventKey={iconDropdownEvents.deleteDecisionHandler} disabled={wizard.submit}>
      <DeleteItemSpan isTemplate={isTemplate}/>
    </BaseHamburgerItem>
  </IconDropdown>;
}
const mapStateToProps = ({ wizard, decision, template, tree, contacts }) => ({
  wizard, decision, template, tree,
  contactsData: contacts
});
export const headerActionsDispatchProps = (dispatch) => ({
  deleteDecision: (callback) => { dispatch(deleteDecision(callback)) },
  deleteTemplate: (callback) => { dispatch(deleteTemplate(callback)) },
  resetDecisionSet: () => { dispatch(resetDecisionSetData()) },
  resetTemplateSet: () => dispatch(resetTemplateSetData()),
  setTreeSidebarOpen: (flag, mode) => dispatch(setTreeSidebarOpen(flag, mode))
})
const mapDispatchToProps = (dispatch) => ({
  ...headerActionsDispatchProps(dispatch),
  forceReloadHomepageDecisions: () => {
    dispatch(forceReloadHomepageDecisions());
  },
  forceReloadHomepageTemplates: () => {
    dispatch(forceReloadHomepageTemplates());
  },
  resetSidebars: () => {
    dispatch(resetSidebars())
  },
  resetWizard: () => {
    dispatch(resetWizard())
  },
  resetTree: () => {
    dispatch(resetTree())
  },
  resetPlaybookNotes: () => {
    dispatch(resetPlaybookNotes())
  },

  resetSearchAndFilters: () => dispatch(resetSearchAndFilters()),
  updateTreeData: (data) => dispatch(updateTreeData(data)),
  previewWizardTree: () => dispatch(previewWizardTree()),
  updateDecisionData: (data) => dispatch(updateDecisionData(data))
});
const wrapper = React.forwardRef((props, ref) => <ActionsDropdown {...props} stepRef={ref} />)
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(wrapper);

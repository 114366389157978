import { RESET_SIDEBAR_DATA, SET_SIDEBAR_DATA } from "./types";

export const LEFT_PANELS = {
  decisionInputSidebar: false,
  driverInputSidebar: false,
  setSummarySidebar: false,
  recommendationInputSidebar: false,
  largeScaleInput: false
}
export const FILTERED_RIGHT_PANELS = {
  decisionDetailsSidebar: false,
  treeSidebar: false,
  helpSidebar: false, filtersSidebar: false, playbookSidebar: false, linkPanelSidebar: false
}
export const RIGHT_PANELS = {
  ...FILTERED_RIGHT_PANELS,
  discussionSidebar: false,
  setSectionsSidebar: false,
  setDecisionsSidebar: false,
  assignDecisionsSidebar: false,
  dSightTreeSidebar: false,
  decisionOrderSidebar: false,
  ratingsAndWeightsSidebar: false
}
export const CENTER_PANELS = {
  finalDecisionSidebar: false,
  recommendationDetailsSidebar: false
}

export const resetDriverInputSidebar = () => (dispatch) => {
  dispatch(saveSideBarData({ ...LEFT_PANELS }))
}

export const saveSideBarData = (data) => ({
  type: SET_SIDEBAR_DATA,
  payload: {
    ...data
  }
});

export const resetSideBarData = () => ({ type: RESET_SIDEBAR_DATA });

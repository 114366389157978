import {
  addSourceToFlowRequest, addSourceToTemplateRequest,
  createSetData,
  createSetSectionRequest, createTemplateCollaborators, createTemplateSetDataSource,
  destroyTemplateSetDataSource,
  dragSetSectionRequest,
  loadSetData, removeAdmission,
  removeSetRequest,
  removeSetSectionRequest,
  reOrderSetObjectsData,
  replaceTemplateSetDataSource,
  updateSetRequest,
  updateSetSectionRequest,
  updateSetUsers,
  updateTemplateDataRequest, updateTemplateSetDataSource
} from "../../utils/Api";
import { failedResponseHandler, isResponseFailed } from "../../helpers/store_helpers";
import { loadHomepageSuccess, updateHomepageData } from "../homepage/actions";
import { BASE_SECTION } from "../homepage/reducers";
import EntryPoint from "../../EntryPoint";
import {isPresent, uniqueBy} from "../../helpers/common";
import { updateShareData } from "../share/actions";
import {updateDecisionSetData} from "../decision_set/common_actions";
import {dispatchTreeDriversDataSources} from "../tree/actions";
import { updateSetsFailure, updateTemplateSetData } from "./common_actions";

// Template Set
export const loadTemplateSet = ({
                                  scope = null, setSlug = null
                                } = {}) => (dispatch) => {
  const controllerName = scope || EntryPoint.instance.controllerName
  const objectSlug = setSlug || EntryPoint.instance.objectSlug
  loadSetData({ controllerName, objectSlug }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure });

    const { data } = response;
    const { template_set, no_results_image_url, share_data } = data;
    dispatch(updateTemplateSetData({ ...template_set, loaded: true }));
    dispatch(loadHomepageSuccess({ no_results_image_url }));
    dispatch(updateShareData({ ...share_data }));
  })
}

export const createTemplateSet = (data, callback) => (dispatch) => {
  createSetData({ data }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure });

    const { data } = response;
    const { template_set } = data;
    dispatch(updateTemplateSetData({ ...template_set, loaded: true }));
    callback(template_set.slug, true)
  })
}

export const updateTemplateSet = (data, callback) => (dispatch) => {
  const { controllerName, objectSlug } = EntryPoint.instance;
  updateSetRequest({ controllerName, objectSlug, data }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure }, callback);

    const { data } = response;
    const { template_set } = data;
    dispatch(updateTemplateSetData({ ...template_set, loaded: true }));
    callback(true)
  })
}

export const removeTemplateSet = (callback) => (dispatch) => {
  const { controllerName, objectSlug } = EntryPoint.instance;
  removeSetRequest({ controllerName, objectSlug }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure }, callback);

    dispatch(updateTemplateSetData({ loaded: false }));
    callback(true)
  })
}

// Template Set Admissions
export const submitTemplateSetUsers = (data, callback) => (dispatch) => {
  const { controllerName, objectSlug } = EntryPoint.instance
  updateSetUsers({ controllerName, objectSlug, data }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure }, callback);

    const { data } = response;
    const { all_admissions } = data;
    dispatch(updateTemplateSetData({ admissions: [...all_admissions] }));
    callback(true)
  })
}
export const addTemplateSetCollaborators = (data, callback) => (dispatch, getState) => {
  const { controllerName, objectSlug } = EntryPoint.instance;
  createTemplateCollaborators(controllerName, objectSlug, data).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure }, callback);

    const { data } = response;
    const { new_admissions, new_users } = data;
    const { admissions, users } = getState().template_set
    dispatch(updateTemplateSetData({
      admissions: uniqueBy([...admissions, ...new_admissions], 'slug'),
      users: uniqueBy([...users, ...new_users], 'email')
    }));
    callback(true)
  })
}
export const removeTemplateSetCollaborator = (slug, callback) => (dispatch, getState) => {
  const { controllerName, objectSlug } = EntryPoint.instance;
  removeAdmission(controllerName, objectSlug, slug).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure }, callback);

    const { admissions } = getState().template_set;
    dispatch(updateTemplateSetData({ admissions: admissions.filter(a => a.slug !== slug) }));
    callback(true)
  })
}

// Template Set Sections
export const createTemplateSetSection = (data, callback) => (dispatch) => {
  const { controllerName, objectSlug } = EntryPoint.instance;
  createSetSectionRequest({ controllerName, objectSlug, data }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure }, callback);

    const { data } = response;
    const { sections } = data;
    dispatch(updateTemplateSetData({ sections }));
    callback(true)
  })
}

export const updateTemplateSetSection = (slug, data, callback) => (dispatch) => {
  const { controllerName, objectSlug } = EntryPoint.instance;
  updateSetSectionRequest({ controllerName, objectSlug, slug, data }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure }, callback);

    const { data } = response;
    const { sections } = data;
    dispatch(updateTemplateSetData({ sections }));
    callback(true)
  })
}

export const removeTemplateSetSection = (slug, callback) => (dispatch, getState) => {
  const { controllerName, objectSlug } = EntryPoint.instance;
  removeSetSectionRequest({ controllerName, objectSlug, slug }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure }, callback);

    const sections = getState().template_set.sections.filter(s => s.slug !== slug)
    const templates = getState().template_set.templates.map(t => t.section_slug !== slug ? t : {...t, section_slug: null})
    dispatch(updateTemplateSetData({ sections, templates }));
    callback(true)
  })
}

export const dragTemplateSetSection = (slug, data, callback) => (dispatch) => {
  const { controllerName, objectSlug } = EntryPoint.instance;
  dragSetSectionRequest({ controllerName, objectSlug, slug, data }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure }, callback);

    const { data } = response;
    const { sections } = data;
    dispatch(updateTemplateSetData({ sections }));
    callback(true)
  })
}

export const addTemplateToSet = (templateSlug, setSlug, callback, sectionSlug='') => (dispatch, getState) => {
  dispatch(updateTemplateSetData({ loading: true }));
  if (getState().home.admin_templates.loaded) {
    dispatch(updateHomepageData({ admin_templates: { ...getState().home.admin_templates, loading: true } }))
  }
  updateTemplateDataRequest(templateSlug, {
    ...(isPresent(sectionSlug) && { section_slug: sectionSlug }),
    template_set_slug: setSlug, add_to_set: true, section_slug: sectionSlug
  }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure }, callback);

    const { data } = response;
    const { template } = data;
    const setTemplates = [...getState().template_set.templates, template]

    const sections = getState().template_set.sections.map((section) => {
      return section.slug === sectionSlug ? { ...section, templates: [...section.templates, template] } : section;
    })
    dispatch(updateTemplateSetData({ templates: setTemplates, sections, loading: false }))
    if (getState().home.admin_templates.loaded) {
      const adminTemplates = getState().home.admin_templates.data;
      const newTemplates = [...adminTemplates.map(item => item.slug === template.slug ? {...item, ...template } : item)]
      dispatch(updateHomepageData({ admin_templates: { data: newTemplates, loading: false, loaded: true } }))
    }
    callback(true)
  })
}

export const removeTemplateFromSet = (templateSlug) => (dispatch, getState) => {
  const adminTemplates = getState().home.admin_templates.data;
  dispatch(updateTemplateSetData({ loading: true, loaded: false }));
  if(isPresent(adminTemplates))
    dispatch(updateHomepageData({ admin_templates: { ...BASE_SECTION, loading: true, loaded: true } }))
  updateTemplateDataRequest(templateSlug, { template_set_slug: '', remove_from_set: true }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure });

    const { data } = response;
    const { template } = data;
    const templates = getState().template_set.templates.filter(({ slug }) => slug !== templateSlug )
    const newTemplates = [...adminTemplates.map(item => item.slug === template.slug ? {...item, ...template } : item)]
    dispatch(updateTemplateSetData({ ...getState().template_set, templates, loading: false, loaded: true }));
    if(isPresent(adminTemplates))
      dispatch(updateHomepageData({ admin_templates: { data: newTemplates, loading: false, loaded: true } }))
  })
}

export const reorderTemplates = (data = {}, callback) => (dispatch) => {
  const { controllerName, objectSlug } = EntryPoint.instance;
  reOrderSetObjectsData({ controllerName, objectSlug, data: { template_set: data } }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure }, callback);

    const { data } = response;
    const { template_set } = data;
    dispatch(updateTemplateSetData({ ...template_set }));
    callback(true)
  })
}

// Data Sources
export function destroySource(slug, data) {
  return (dispatch) => {
    const { objectSlug, controllerName } = EntryPoint.instance;

    destroyTemplateSetDataSource({ controllerName, objectSlug, slug, data }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure });

      const { data: { data_sources } } = response;
      dispatch(updateTemplateSetData({ data_sources }));
    })
  }
}
export function createSource(data, config = {}, callback = () => {}) {
  return (dispatch) => {
    const { objectSlug, controllerName } = EntryPoint.instance;

    createTemplateSetDataSource({ controllerName, objectSlug, data, config }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure }, callback)

      const { data: { data_sources } } = response;
      dispatch(updateTemplateSetData({ data_sources }));
      callback(true);
    })
  }
}
export function replaceSource(slug, data, config = {}, callback = () => {}) {
  return (dispatch) => {
    const { objectSlug, controllerName } = EntryPoint.instance;

    replaceTemplateSetDataSource({ controllerName, objectSlug, slug, data, config }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure }, callback)

      const { data: { data_sources } } = response;
      dispatch(updateTemplateSetData({ data_sources }));
      callback(true);
    })
  }
}
export function updateSource(slug, data) {
  return (dispatch) => {
    const { objectSlug, controllerName } = EntryPoint.instance;

    updateTemplateSetDataSource({ controllerName, objectSlug, slug, data }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure });

      const { data: { data_sources } } = response;
      dispatch(updateTemplateSetData({ data_sources }));
    })
  }
}

export function addSourceToTemplate(slug, setSlug, data, updateOnlySources) {
  return (dispatch, getState) => {
    addSourceToTemplateRequest({ objectSlug: setSlug, slug, data }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure });

      const { data } = response;
      dispatchTreeDriversDataSources(dispatch, getState, data, updateOnlySources);
    })
  }
}

export function addSourceToTemplateFlow(slug, callback = () => {}) {
  return (dispatch) => {
    const { objectSlug, controllerName } = EntryPoint.instance;
    addSourceToFlowRequest({ controllerName, objectSlug, slug }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure });

      const {data: {data_sources}} = response;
      dispatch(updateTemplateSetData({ data_sources }));
      callback(true);
    })
  }
}

export function saveUploadingSources(sources) {
  return (dispatch) => {
    dispatch(updateDecisionSetData({ uploading_sources: sources }));
  }
}

import React, { Fragment, useEffect, useMemo, useRef } from 'react';
import WizardHeader from "./header";
import { connect } from "react-redux";
import 'react-sliding-side-panel/lib/index.css';
import AlertsSection from "../alerts";
import HelpPanel from "./side_panel/HelpPanel";
import Help from "../help";
import { loadWizardData } from "../store/wizard/actions";
import StepsWizard from "./steps_wizard";
import { updateEntryPointData } from "../EntryPoint";
import { loadOrgCategories } from "../store/org_categories/actions";
import { Loader } from "../common/Loader";
import DecisionPlaybookPanel from "../tree_view/side_panel/DecisionPlaybookPanel";
import DecisionDetailsPanel from "../tree_wizard/side_panel/DecisionDetailsPanel";
import SidebarTreeNavigationWizard from "./navigation";
import WizardTreePanel from "../tree_wizard/side_panel/WizardTreePanel";
import { checkLoadingEffect } from "../helpers/callbacks_helpers";
import LinkPanel from "../tree_view/side_panel/link_panel/LinkPanel";
import { isPresent, successActions } from "../helpers/common";
import { resetTree } from "../store/tree/common_actions";
import { reloadContacts } from "../store/contacts/actions";
import RatingsAndWeightsPanel from "./side_panel/RatingsAndWeightsPanel";
import { loadDecisionSet } from "../store/decision_set/actions";
import { isTreePreview } from "../helpers/wizard_helpers";

const loadDecisionSetCallback = ({ wizard, decision, decision_set, loadDecisionSet }) => {
  checkLoadingEffect(decision_set,
    () => loadDecisionSet({ scope: 'decision_sets', slug: decision.decision_set_slug, decision_slug: decision.slug }),
    {
      condition: ([wizard]) => wizard.loaded && isPresent(decision.decision_set_slug),
      another_stores: [wizard]
    }
  )
}

const TreeView = ({
                    wizard, org_categories, decision, decision_set,
                    loadWizardData, loadOrgCategories, resetTree, reloadContacts, loadDecisionSet,
                    match
                  }) => {
  useEffect(() => {
    updateEntryPointData(match, 'decisions', () => {
      successActions(true, [resetTree, reloadContacts])
    })
  }, [match?.params?.id])

  checkLoadingEffect(org_categories, loadOrgCategories)
  loadDecisionSetCallback({ wizard, decision, decision_set, loadDecisionSet })

  const stepRef = useRef({})
  useEffect(() => { loadWizardData() }, []);

  const Wrapper = useMemo(() => ({children, wizard}) =>
    <Fragment>
      <AlertsSection />
      <WizardHeader ref={stepRef} />
      <div className={`d-flex ${wizard.step_index === 0 ? 'overflow-hidden' : ''}`}>
        {isTreePreview(wizard) ? <SidebarTreeNavigationWizard /> : null}
        {children}
      </div>
      <Sidebars wizard={wizard} />
    </Fragment>, [])

  const Sidebars = useMemo(() => ({wizard}) => <Fragment>
    {isTreePreview(wizard) ? null : <Help />}
    <div className="sidebars">
      <RatingsAndWeightsPanel isTemplate={false}/>
      {isTreePreview(wizard) ?
        <DecisionPlaybookPanel key="playbook-side-panel" preview={false} addClass={'left-side-panel-position tree-page'} /> :
        <DecisionPlaybookPanel key="playbook-side-panel" preview={false} position={'right'} />}
      <DecisionDetailsPanel  key="decision-details-side-panel" />
      <WizardTreePanel key="tree-side-panel" stepRef={stepRef}/>
      <LinkPanel key="link-side-panel" preview={isTreePreview(wizard)} />
      <div className="help-side-panels">
        <HelpPanel />
      </div>
    </div>
  </Fragment>, [])

  if (wizard.loading) {
    return <Wrapper wizard={wizard}>
      <Loader />
    </Wrapper>;
  } else {
    return <Wrapper wizard={wizard}>
      <StepsWizard ref={stepRef} />
    </Wrapper>;
  }
};
const mapStateToProps = ({ wizard, current_user, org_categories, decision, decision_set }) => ({
  wizard, current_user, org_categories, decision, decision_set
});
export default connect(mapStateToProps, { loadWizardData, loadOrgCategories, resetTree, reloadContacts, loadDecisionSet })(TreeView);

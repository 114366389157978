import { isBlank, isPresent } from "./common";

const COLLABORATION_TYPES = ['CollaborationInvite', 'BuyInInvite']
const UNTITLED_DECISION = 'Untitled decision';

export const DSIGHT_TREE_FLOWS = ['d_sight', 'd_sight_recommendation', 'unstarted_d_sight_tree']
export const TREE_HISTORICAL_FLOWS = ['tree_historical', 'd_sight_historical']

const sortFollowups = (a, b) => {
  if (a.created_at > b.created_at) return 1;
  if (a.created_at < b.created_at) return -1;
  return 0;
};

export const dataWithUser = (data, { users }) => ({ ...data, user: users.find(user => user.email === data.user_email) })
export const choiceWithUserVotes = (choice) => ({ ...choice, votes: choice.votes})

const prepareFollowups = (decision) => decision.followups.map(u => dataWithUser(u, decision))

export const getScheduledFollowup = (decision) => prepareFollowups(decision).find(f => isBlank(f.followup_expectation) && isPresent(f.followup_due_date))
export const getLastPendingFollowup = (decision) => prepareFollowups(decision).filter(f => isBlank(f.followup_expectation)).sort(sortFollowups)[0]
export const getLastLegacyFollowup = (decision) => prepareFollowups(decision).filter(followup => isPresent(followup.followup_expectation)).sort((a, b) => a.created_at > b.created_at ? -1 : 1)[0]
export const getLastFollowup = (decision) => prepareFollowups(decision).sort(sortFollowups)[0]

export const inviteWithUser = (invite, decision) => ({ ...invite, user: decision.users.find(user => user.email === invite.email) })
export const approvalWithUser = (approval, decision) => ({ ...approval, user: decision.users.find(user => user.email === approval.user_email) })
export const collectInvites = (decision) => decision.invites.filter(i => i.email !== decision.user.email).filter(i => !['ApprovalInvite', 'ViewerInvite'].includes(i.type)).map(invite => inviteWithUser(invite, decision))
export const collectCollaborationInvites = (decision) => decision.invites.filter(i => COLLABORATION_TYPES.includes(i.type)).filter(i => i.email !== decision.user.email).map(invite => inviteWithUser(invite, decision))
export const collectApprovalInvites = (decision) => decision.invites.filter(i => i.type === 'ApprovalInvite').map(invite => inviteWithUser(invite, decision))
export const collectPendingApprovalInvites = (decision) => collectApprovalInvites(decision).filter(a_i => !decision.user_approvals.some(h => h.user_email === a_i.email))
export const collectUserSupports = (decision) => decision.user_supports.filter(s => s.support > 0).map(support => dataWithUser(support, decision))
export const collectUserSupportsLegacy = (decision) => decision.user_supports.filter(s => s.support >= 0 && !s.support_canceled).map(support => dataWithUser(support, decision))
export const collectUsersVotes = (decision) => decision.choices.filter(choice => !choice.hide_in_choices_section).map(choice => choiceWithUserVotes(choice)).sort((a, b) => a.rank_points > b.rank_points ? -1 : 1)

export const isPendingContact = (contact) => contact?.org_role === 'pending'
export const isExistedUserContact = (contact) => ['member', 'admin'].includes(contact?.org_role)
export const isGuestContact = (contact) => ['guest'].includes(contact?.org_role)
export const isGroupContact = (contact) => contact.full_name && _.includes(['Group', 'All', 'Org'], contact.type)
export const isWebGroupContact = (c) => (c.type === 'Org' || c.group_category === 'web')
export const isGroupItem = (item) => item.label && _.includes(['Group', 'Org', 'DecisionSet'], item.admittable_type)
export const isDecisionSetDecision = (decision) => isPresent(decision.decision_set_slug)
export const isHistoricalDecision = (decision) => TREE_HISTORICAL_FLOWS.includes(decision.flow_enum)
export const canceledApprovals = (decision) => decision.user_approvals.filter(u_a => u_a.canceled)
export const notCanceledApprovalInvites = (decision) => collectApprovalInvites(decision).filter(invite => !canceledApprovals(decision).some(i => i.invite_id === invite.id))
export const isDecisionApprover = (decision, email) => notCanceledApprovalInvites(decision).some(h => h.email === email)

export const redirectToHomeCallback = (success, history, tab = '') => {
  if (success) { history.push(`/homepage${isPresent(tab) ? `/${tab}` : ''}`) }
};

export const redirectToSetsCallback = (success, history, slug = '', isTemplate = true) => {
  if (success) { history.push(`/${ isTemplate ? 'template' : 'decision' }_sets/${slug}`)}
};

export const redirectToRootSuccessCallback = (success) => {
  if (success) document.location.href = "/";
}

export const redirectToSignInSuccessCallback = (success) => {
  if (success) document.location.href = "/users/sign_in";
}

export const redirectToLearningCallback = () => {
  document.location.href = "/learn/details";
}

export const safeDescription = ({ description }) => isPresent(description) ? description : UNTITLED_DECISION

import React, { useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import { isBlank } from "../../../../../helpers/common";
import { RichTextEditor } from "../../../../../common/RichTextEditor";
import { flow } from "../../../../../helpers/decision_flows";

const OpenEndedChoices = ({ decision, openEndedChoice, setOpenEndedChoice, flowEnum }) => {
  if (isBlank(decision)) return null;

  const [loaded, setLoaded] = useState(false);
  const focusElement = useRef(null);

  useEffect(() => {
    if (!loaded && focusElement.current) {
      focusElement.current.focus();
    };
    setLoaded(true);
  }, [decision]);

  return <div>
    <RichTextEditor quillId={`openEndedChoice-${flowEnum}`}
                    value={openEndedChoice}
                    setValue={setOpenEndedChoice}
                    placeholder={
                      flowEnum === flow.D_SIGHT_RECOMMENDATION ?
                        'Enter the recommendation' :
                        'Enter the decision and resulting actions'
                    }
    />
  </div>
};
const mapStateToProps = (state) => ({ decision: state.sidebar.decision });
export default connect(mapStateToProps, {} )(OpenEndedChoices);

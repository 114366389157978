import React from 'react';
import DetailsItem from "../../tree_view/navigation/DetailsItem";
import HelpTemplateItem from "../../template_view/navigation/HelpTemplateItem";
import PlaybookWizardItem from "./PlaybookWizardItem";
import RatingsAndWeightsItem from "../../tree_view/navigation/RatingsAndWeightsItem";
const NavigationItems = () =>
  <>
    <div className="sidebar-top">
      <ul className="list-unstyled m-0 p-3">
        <RatingsAndWeightsItem />
        <DetailsItem />
        <PlaybookWizardItem />
      </ul>
    </div>
    <div className="sidebar-bottom">
      <ul className="list-unstyled m-0 p-3">
        <HelpTemplateItem />
      </ul>
    </div>
  </>
export const SidebarTreeNavigationWizard = () =>
  <div id="sidebar-navigation" className="tree-sidebar bg-light-gray d-none d-lg-block">
    <NavigationItems />
  </div>

export default SidebarTreeNavigationWizard;

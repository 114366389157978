import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { saveWizardStepData, updateWizardData } from "../../../store/wizard/actions";
import { Button, Table } from 'react-bootstrap'
import { isBlank, isPresent } from "../../../helpers/common";
import { ChoiceEntryType } from "../../../models/decision";
import Help from "../../help/index";
import { isLastStep, isWizardV3, WIZARD_STEPS, wizardStep } from "../../../helpers/wizard_helpers";
import * as moment from "moment";
import DecisionDate from '../../../common/DecisionDate';
import { RichTextEditor } from "../../../common/RichTextEditor";
import SubmitStepButton from "./SubmitStepButton";
import DecisionTitleRow from "../../helpers/DecisionTitleRow";
import RateCompareChoicesSection from "../../../tree_view/modals/entry_modal/RateCompareChoicesSection";
import ResponseTypeSection from "../../../tree_view/modals/entry_modal/ResponseTypeSection";
import IncludeRecommendationSection from "../../../tree_view/modals/entry_modal/IncludeRecommendationSection";
import { submitPreviewStepParam, submitTreeStep } from "../../helpers/decision_wizard_helpers";

export const DEFAULT_CHOICES = ['', '', '']

export const isOpenEndedCallback = (responseType) => (type = responseType) => type === ChoiceEntryType.OPEN_ENDED
export const onChangeResponseTypeCallback = (defaultChoices, isOpenEnded, setChoices, setResponseType) => (option) => {
  if (isOpenEnded(option.value)) setChoices(defaultChoices())
  setResponseType(option.value)
}
export const onChangeChoiceCallback = (choices, setChoices) => (value, index) => {
  const newChoices = [...choices]
  newChoices[index] = value
  setChoices(newChoices)
}
export const addNewChoiceCallback = (setNewChoice, choices, setChoices) => () => {
  setNewChoice(true)
  setChoices([...choices, ''])
}

const singleLinePaste = (text, activeElement, onChangeChoice, index) => {
  const startPos = activeElement.selectionStart;
  const endPos = activeElement.selectionEnd;
  const newValue = activeElement.value.substring(0, startPos) + text + activeElement.value.substring(endPos);
  activeElement.value = newValue;
  activeElement.selectionStart = startPos + text.length;
  activeElement.selectionEnd = startPos + text.length;

  const inputEvent = new Event('input', { bubbles: true });
  activeElement.dispatchEvent(inputEvent);
  onChangeChoice(newValue, index);
};

const multiLinePaste = (lines, index, choices, setChoices, setNewChoice) => {
  let newChoicesAdded = false;
  let updatedChoices = [...choices];

  for (let i = index; i < updatedChoices.length && lines.length > 0; i++) {
    if ($(`#decisionChoice-${i}`).hasClass('d-none')) {
      continue;
    }

    if (updatedChoices[i].trim() === '') {
      updatedChoices[i] = lines.shift();
      newChoicesAdded = true;
    }
  }

  updatedChoices = [...updatedChoices, ...lines];
  newChoicesAdded = newChoicesAdded || lines.length > 0;

  setChoices(updatedChoices);
  if (newChoicesAdded) {
    setNewChoice(true);
  }
};

export const onPasteChoiceCallback = (choices, setChoices, setNewChoice, onChangeChoice) => (event, index) => {
  event.preventDefault();
  const pastedText = event.clipboardData.getData('text');
  const lines = pastedText.split(/\r?\n/).map(line => line.trim()).filter(line => line);

  if (lines.length === 0) return;

  const activeElement = document.activeElement;
  if (lines.length === 1 && activeElement) {
    singleLinePaste(lines[0], activeElement, onChangeChoice, index);
  } else {
    multiLinePaste(lines, index, choices, setChoices, setNewChoice);
  }
};

const FramingDecisionStep = ({
                               wizard, decision, playbook_notes,
                               saveWizardStepData, updateWizardData,
                               stepRef
                             }) => {
  const defaultChoices = () => isBlank(wizardStep(wizard)?.choices) ? DEFAULT_CHOICES : wizardStep(wizard)?.choices

  const [submitState, setSubmitState] = useState(false)
  const [context, setContext] = useState(decision.collaboration_context || '')
  const [description, setDescription] = useState(decision.description || '')
  const [treeEnum, setTreeEnum] = useState(decision.flow_enum)
  const [responseType, setResponseType] = useState(decision.choice_entry_widget_type || ChoiceEntryType.OPEN_ENDED)
  const [choices, setChoices] = useState(defaultChoices())
  const [newChoice, setNewChoice] = useState(false);
  const initDate = isPresent(decision.due_date) ? moment(decision.due_date) : null;
  const defaultDate = moment().add(1, 'weeks');
  const [dueDate, setDueDate] = useState(initDate || defaultDate);

  const hideHideRecommendation = isPresent(wizardStep(wizard)?.hide_recommendation)
  const isOpenEnded = isOpenEndedCallback(responseType)
  const onChangeResponseType = onChangeResponseTypeCallback(defaultChoices, isOpenEnded, setChoices, setResponseType)
  const onChangeChoice = onChangeChoiceCallback(choices, setChoices);
  const addNewChoice = addNewChoiceCallback(setNewChoice, choices, setChoices);
  const onPasteChoice = onPasteChoiceCallback(choices, setChoices, setNewChoice, onChangeChoice);

  const [rateCompareChoices, setRateCompareChoices] = useState(isPresent(decision.rate_compare_choices))

  const disabledStep = () => isBlank(description)

  const submitStep = (nav_data = {}, callback = () => {}) => {
    setSubmitState(true)
    saveWizardStepData(WIZARD_STEPS.tree_framing.key, {
      complete: isLastStep(wizard),
      preview: submitPreviewStepParam(wizard),
      finish_later: submitPreviewStepParam(wizard),
      ...nav_data,
      response: { step_index: wizard.step_index },
      choices: isOpenEnded() ? [] : choices,
      decision: {
        description,
        flow_enum: treeEnum,
        due_date: dueDate.toString(),
        collaboration_context: context,
        choice_entry_widget_type: responseType,
        rate_compare_choices: responseType === ChoiceEntryType.OPEN_ENDED ? false : rateCompareChoices
      },
      next_step: true
    }, callback)
  }
  const removeChoice = (index) => {
    $(`#decisionChoice-${index}`).removeClass('d-flex').addClass('d-none');
    onChangeChoice('', index)
  }

  stepRef.current.submitStep = (additional_data = {}, callback = () => {}) => {
    if (disabledStep()) additional_data.finish_later = true

    submitStep(additional_data, (success, wizardData) => {
      setSubmitState(false)
      callback(success, additional_data.finish_later, wizardData)
    })
  };

  useEffect(() => {
    updateWizardData({ disabledSubmit: disabledStep() })
  }, [description])

  const onChangeDate = (date) => setDueDate(moment(date));

  return <div className="d-flex">
    <div className="mx-auto">
      <div className={`bg-white rounded p-3 mt-3 mb-5 tree_wizard d-inline-block`}>
        <Table borderless className="mb-2">
          <tbody>
            <tr>
              <td className="d-flex p-0">
                <h1 className="d-table-cell pe-2">
                  <span className="text-primary">
                    { wizard.step_index === 0 && "Let's make a decision! " || 'Great! ' }
                  </span>
                  Define the decision and select how to answer it.
                </h1>
                <div className="d-table-cell ms-auto">
                  <Help />
                </div>
              </td>
            </tr>
          </tbody>
        </Table>

        {
          isWizardV3(wizard) || isBlank(context) ? null :
            <div className="w-100 mb-3">
              <h3>Context</h3>
              <RichTextEditor quillId={'Context'} value={context} setValue={setContext}
                              placeholder="Explain the reasons for this decision"
              />
            </div>
        }

        <div className="w-100 mb-3">
          <DecisionDate readOnly={wizard.submit || submitState} date={dueDate} onChangeDate={onChangeDate} minDate={new Date()} />
        </div>

        <DecisionTitleRow {...{ playbook_notes, description, setDescription, submit: wizard.submit || submitState }} />
        <IncludeRecommendationSection {...{ treeEnum, setTreeEnum, classNames: 'mb-3 mt-2-negative', hidden: hideHideRecommendation }} />
        <ResponseTypeSection {...{ responseType, onChangeResponseType, submitted: wizard.submit || submitState, classNames: 'mb-3' }} />
        <RateCompareChoicesSection classNames={'mb-3 mt-2-negative'}
                            {...{ rateCompareChoices, setRateCompareChoices, hidden: responseType === ChoiceEntryType.OPEN_ENDED }} />

        <div className="w-100 mb-3" hidden={responseType === ChoiceEntryType.OPEN_ENDED}>
          <h3>Choices</h3>
          {
            choices.map((description, index) => <div key={`decision-choice-${index}`}
                                                     id={`decisionChoice-${index}`}
                                                     className="d-flex mt-1 align-items-center mb-2">
              <input className="form-control"
                     placeholder="Enter a choice"
                     readOnly={wizard.submit || submitState}
                     value={description}
                     onChange={(e) => onChangeChoice(e.target.value, index)}
                     onPaste={(e) => onPasteChoice(e, index)}
                     ref={inputElement => {
                       if (inputElement && newChoice) {
                         inputElement.focus();
                         setNewChoice(false)
                       }
                     }}/>
              <span className="btn btn-light btn-sm btn-sm-round text-danger ms-2 px-2" onClick={() => removeChoice(index)}>
                <i className="fas fa-times w-100"/>
              </span>
            </div>)
          }
          <Button onClick={addNewChoice} disabled={wizard.submit || submitState} className="btn-secondary w-100">Add another choice</Button>
        </div>
        <div className="w-100">
          <SubmitStepButton onClick={() => submitTreeStep(wizard, stepRef)} disabled={wizard.submit || submitState || disabledStep()} />
        </div>
      </div>
    </div>
  </div>
}
const mapStateToProps = ({ wizard, decision, playbook_notes }) => ({ wizard, decision, playbook_notes });
const mapDispatchToProps = (dispatch) => ({
  saveWizardStepData: (step, data, callback) => {
    dispatch(saveWizardStepData(step, data, callback))
  },
  updateWizardData: (data) => dispatch(updateWizardData(data))
});
const wrapper = React.forwardRef((props, ref) => <FramingDecisionStep {...props} stepRef={ref} />)
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(wrapper);

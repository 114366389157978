import React from 'react';
import CloseIcon from "../../common/CloseIcon";
import SetDropdown from "../header/SetDropdown";

export default ({
                  headerName, openSideBar,
                  showMenu = false, isSetUser = false,
                  isManager = false, isTemplateSet = false,
                  addClass = '',
                  ...opts
                }) =>
  <div className="side-panel header">
    <div className="d-flex justify-content-between pt-2 px-3">
      <h2 className={`${addClass}`}>{headerName}</h2>
      {!isManager && !isSetUser || !showMenu ? null :
        <div className="ms-auto">
          <SetDropdown dropdownClassName="transparent-btn" title='' setTitle={`${isTemplateSet ? 'template' : 'decision'}`}
                       btnClassName="btn btn-secondary btn-sm btn-sm-round header-toggle-light dropdown-toggle-header"
                       btnId="share-header-dropdown"
                       {...{ ...opts, isTemplateSet, isManager, isSetUser }}
          />
        </div>
      }
      <div className="ms-2">
        <CloseIcon onClose={() => openSideBar(false)} />
      </div>
    </div>
  </div>

import React from 'react';
import { connect } from "react-redux";
import SlidingPanel from "react-sliding-side-panel";
import { setSetSectionsSidebarOpen } from "../../store/sidebar/actions";
import PanelTreeEditor from "../../tree_editor/set_sections/PanelTreeEditor";
import {isMobile} from "react-device-detect";
import PanelHeader from "../../tree_view/side_panel/PanelHeader";

const DecisionSetSectionsPanel = ({ addClass, sidebar, openSideBar, ...opts }) => {
  const onClose = () => openSideBar(false)

  return <SlidingPanel
    type="left"
    isOpen={sidebar.setSectionsSidebar}
    panelContainerClassName={`sidebar-panel-wrapper set-sections-panel-wrapper details-panel-width template-panel ${addClass} ${isMobile ? 'pb-5 mb-5' : ''}`}
    panelClassName={`sidebar-panel-wrapper-content set-sections-panel-content`}
    size={50}>
    <PanelHeader headerName={'Add or remove sections'} openSideBar={openSideBar} preview={true} />
    <PanelTreeEditor onClose={onClose} {...opts} />
  </SlidingPanel>
}

const mapStateToProps = ({ sidebar }) => ({ sidebar });
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => {
    dispatch(setSetSectionsSidebarOpen(options));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(DecisionSetSectionsPanel);

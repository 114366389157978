import React, {Fragment, useEffect} from 'react';
import { connect } from "react-redux";
import CloseIcon from "../../../common/CloseIcon";
import { openModal } from "../../../store/modals/actions";
import {DriverNotes} from "./QuestionRow";
import DriverEntryModal from "../../modals/DriverEntryModal";
import { saveSideBarData } from "../../../store/sidebar/common_actions";
import { DEFAULT_DRIVER_TYPE_NAME } from "../../../models/driver";
import { isBlank, isPresent } from "../../../helpers/common";
import AssignmentSection from "../sections/AssignmentSection";

const TopRow = ({
                  driverData, openSideBar, driverTypeName, decision, contactsData, children
                }) => {
  if (isBlank(driverData?.driver) || isBlank(decision)) return null;

  const driverNotes = driverData.driver.notes
  const assignedUser = decision.users.find(u => u.email === driverData.driver.assign_to_user)
  const assignedCollaboratorEmail = contactsData.contacts.find(c => c.email === driverData.driver.assign_to_user)?.email

  return <>
    <div className="side-panel header h-auto px-3">
      <div className='d-flex justify-content-between my-auto pt-2'>
        <h2>{driverTypeName || DEFAULT_DRIVER_TYPE_NAME}</h2>
        <div className="d-flex ms-auto">
          { children }
          <CloseIcon classParams="d-inline-block" onClose={() => { openSideBar(false) }} />
        </div>
      </div>
    </div>
    <div className={`${(isPresent(driverNotes) || isPresent(assignedUser) || isPresent(assignedCollaboratorEmail)) ? 'border-bottom mb-3' : ''}`}>
      <AssignmentSection assignedUser={assignedUser} assignedCollaboratorEmail={assignedCollaboratorEmail} addClass="mx-3" />
      <DriverNotes driverNotes={driverNotes} />
    </div>
  </>

}

const DriverTypeOrQuestionRow = ({
                                   driverData, decision, sidebar, tree, channels, user, openSideBar,
                                   openModal, saveSideBarData, contactsData, ...opts
                                }) => {
  const slug = driverData.driver.slug;

  useEffect(() => {
    saveSideBarData({ drivers: tree.drivers })
  }, [tree.drivers])

  return <Fragment>
    <TopRow driverData={driverData} decision={decision} sidebar={sidebar} slug={slug} user={user}
            openModal={openModal} openSideBar={openSideBar} channels={channels}
            driverTypeName={driverData.driver.driver_type_name} contactsData={contactsData} {...opts} />
    <div className="modals">
      <DriverEntryModal key={`left-sidebar-driver-details-modal-${slug}`} driverData={driverData} />
    </div>
  </Fragment>
}

const mapStateToProps = ({ sidebar, tree, decision, channels, current_user, org_driver_types, contacts }) => (
  { sidebar, tree, decision, channels, user: current_user, org_driver_types, contactsData: contacts }
);
export default connect(mapStateToProps, { openModal, saveSideBarData })(DriverTypeOrQuestionRow);

import {pageHeader} from "./sections/PageHeader";
import {pageFooter} from "./sections/PageFooter";
import {pageProperties} from "../index";
import Driver from "../../models/driver";
import {isBlank, isPresent} from "../../helpers/common";
import {baseBlockSection, baseHeadParagraph, baseRichBlock, bookMarkHeader, internalLink} from "./sections/wrappers";
import {
  AlignmentType, convertInchesToTwip,
  HeadingLevel, Paragraph,
  Table,
  TableBorders,
  TableCell,
  TableRow, TextRun, WidthType
} from "docx";
import {calculateRatingScore, RATING_SCALES_DATA} from "../../helpers/drivers_helpers";
import {MAX_SCALE, MIN_SCALE} from "../../models/DriverChoiceRating";
import {driverComments, driverDataSources, driverTypeBlock} from "./KeyDriverPage";
import {tableCellProps} from "../styles/docx_styles";
import {notAnswerRatingBlock} from "./sections/AdditionalSections";
import {MUTED} from "../../pdf/styles/common";

const driverWeight = (driver, driverObj) => {
  if (isBlank(driver.question)) return [];

  const tableWeight = new Table({
    borders: TableBorders.NONE,
    columnWidths: [9500, 1000],
    rows: [
      new TableRow({
        children: [
          new TableCell({
            ...tableCellProps,
            width: {
              size: 9500,
              type: WidthType.DXA,
            },
            children: [
              bookMarkHeader({id: `driver-${driver.slug}`, text: driver.question, heading: HeadingLevel.HEADING_4})
            ],
          }),
          new TableCell({
            ...tableCellProps,
            width: {
              size: 1000,
              type: WidthType.DXA,
            },
            children: [
              baseHeadParagraph({text: `${driverObj.weightPercentage}%`, heading: HeadingLevel.HEADING_4, alignment: AlignmentType.RIGHT}),
            ],
          }),
        ],
      })
    ]
  })

  return isBlank(driver.parent_id) ?
    tableWeight :
    bookMarkHeader({id: `driver-${driver.slug}`, text: driver.question, heading: HeadingLevel.HEADING_4})
}

const driverRatingChoices = (sortedDriverChoicesRatings) => {
  if (isBlank(sortedDriverChoicesRatings)) return [];

  let driverRating = []
  const choicesCount = sortedDriverChoicesRatings.length

  sortedDriverChoicesRatings.forEach((driverChoiceRating, index) => {
    driverRatingChoice({ ...driverChoiceRating}, driverRating, choicesCount, index)
  })

  return driverRating
}

const driverRatingChoice = ({choice, evaluation, rating, driver}, driverRating, choicesCount, index) => {
  if(driver.driver.parent_id && isBlank(evaluation)) return null

  const startScale = RATING_SCALES_DATA[driver.driver?.rating_scale]?.start_scale || MIN_SCALE
  const endScale = RATING_SCALES_DATA[driver.driver?.rating_scale]?.end_scale || MAX_SCALE
  const isYesOrNo = RATING_SCALES_DATA[driver.driver?.rating_scale]?.yes_or_no;
  const isLastElement = (choicesCount - 1) === index

  driverRating.push(
    new Table({
      borders: TableBorders.NONE,
      columnWidths: [9200, 1300],
      rows: [
        new TableRow({
          children: [
            new TableCell({
              ...tableCellProps,
              width: {
                size: 9200,
                type: WidthType.DXA,
              },
              children: [
                baseHeadParagraph({text: choice.description, heading: HeadingLevel.HEADING_4}),
              ],
            }),
            new TableCell({
              ...tableCellProps,
              width: {
                size: 1300,
                type: WidthType.DXA,
              },
              children: [
                new Paragraph({
                  heading: HeadingLevel.HEADING_4,
                  alignment: AlignmentType.RIGHT,
                  children: isPresent(rating) ?
                    isPresent(driver.driver.rating_scale) ?
                      customRating(rating, startScale, endScale, isYesOrNo)
                      :
                      [new TextRun(rating)]
                    :
                    [new TextRun("—")]
                })
              ]
            }),
          ],
        }),
        evaluation ?
          new TableRow({
            children: [
              new TableCell({
                margins: {
                  left: 0,
                  right: 0,
                  bottom: isLastElement ? 0 : convertInchesToTwip(9/72)
                },
                columnSpan: 2,
                children: [
                  ...baseRichBlock({text: evaluation})
                ],
              }),
            ],
          }) : {}
      ]
    }),
  )

  return driverRating
}

const customRating = (rating, startScale, endScale, isYesOrNo) => {
  const isHundredScale = startScale === MIN_SCALE && endScale === MAX_SCALE && !isYesOrNo;
  const result = [];
  if (isYesOrNo) {
    result.push(new TextRun( rating === MAX_SCALE ? 'Yes' : 'No'));
  } else {
    result.push(new TextRun(rating.toString()));
  }
  if (!isHundredScale) {
  result.push(
    new TextRun({
      color: MUTED,
      text: ` (${calculateRatingScore(rating, startScale, endScale)})`,
    })
  )}

  return result;
};

export const DriverSubPage = (decision, driver, org_driver_types, driver_sources_slugs, data_sources, decisionObj) => {
  if (isBlank(driver.question)) return [];

  const driverObj = new Driver(driver, [], {}, decisionObj);
  const sortedDriverChoicesRatings = driverObj.sortedDriverChoicesRatings || []

  return [
    driverWeight(driver, driverObj),
    ...notAnswerRatingBlock(driverObj, sortedDriverChoicesRatings),
    ...driverRatingChoices(sortedDriverChoicesRatings),
    ...baseBlockSection({header: 'Details', text: driver.explanation, instance: 9}),
    ...baseBlockSection({header: 'Confidence', text: isPresent(driver.confidence) ? `${driver.confidence} out of 5` : ''}),
    driverTypeBlock(decision, driver, org_driver_types),
    ...driverComments(driver, decision),
    ...driverDataSources(driver, driver_sources_slugs, data_sources)
  ]
}

export const KeyPerDriverPage = (format, decision, data_sources, driverData, extraImages, org_driver_types, decisionObj) => {
  const {driver, driver_sources_slugs} = driverData

  return {
    ...pageProperties({format}),
    ...pageHeader({head: 'Drivers', description: driver.question, _, anchor: 'anchorForDriversPage', id: '', skipFirstPage: true}),
    ...pageFooter(extraImages),
    children: [
      ...DriverSubPage(decision, driver, org_driver_types, driver_sources_slugs, data_sources, decisionObj)
    ]
  }
}

import React, { useState, useEffect } from 'react';
import Wrapper from '../Wrapper';
import DriverInfo from "../driver/DriverInfo";
import AggregatedData from "../driver/AggregatedData";
import Header from "../driver/Header";
import ContractedContent from "../driver/ContractedContent";
import ExpandedContent from "../driver/ExpandedContent";
import { isLeftSidebarOpen } from "../../../helpers/sidebar_helpers";
import { isDriverInEditModeByOthers, performEditDriverAnswerAction } from "../../../helpers/channel_helpers";
import { TREE_CHANNEL_ACTIONS } from "../../../../../channels/tree_channel";
import { driverInitData, editingDriverAlert } from "../shared/helpers";
import { isVisitor } from "../../../helpers/user_helpers";
import { isPresent } from "../../../helpers/common";

export const assignedDriverUser = (collaborators = [], contactsData = {}, driverObject) => {
  if (!driverObject.isAssigned) return {};

  return driverObject.assignee(contactsData.contacts) ||
    collaborators.find((user) => driverObject.driver.assign_to_user?.toLowerCase() === user.email) ||
    { email: driverObject.driver.assign_to_user }
}

const DriverCard = ({ driverData, tree, indentStep = 1, decisionObject, user, collaborators = [], contactsData, sidebar,
                      channels, setDriverInputSidebarOpen, resetDriverInputSidebar, updateTreeData }) => {
  const { driverObject, childrenDrivers, setShowChildren, showChildren } = driverInitData(tree, driverData, decisionObject);
  const [expanded, setExpanded] = useState(false);
  const assignedUser = assignedDriverUser(collaborators, contactsData, driverObject)
  const slug = driverData.driver.slug;

  useEffect(() => {
    if(expanded && isLeftSidebarOpen(sidebar) && sidebar.driverSlug === slug) setExpanded(false);
  }, [sidebar])

  useEffect(() => {
    const driverSlug = tree.scroll_to_driver_comment.split('-')[0];
    if(driverSlug === slug) setExpanded(true);
  }, [tree.scroll_to_driver_comment])

  useEffect(() => {
    if(isPresent(tree.scroll_to_driver)) {
      const [driverSlug, commentId] = tree.scroll_to_driver.split('-');
      if (driverSlug === slug) {
        setTimeout(() => {
          const selectedEl = document.getElementById(`driver-card-${slug}`);
          if (selectedEl) {
            selectedEl.scrollIntoView({ behavior: 'smooth' });
            setExpanded(true);
          }
          updateTreeData({ scroll_to_driver: '' })
        }, 100)
      }
    }
  }, [tree.scroll_to_driver])

  const onExpandDriverClick = () => setExpanded(!expanded)

  const onEditClick = () => {
    if(isVisitor(user)) return false;

    if(isDriverInEditModeByOthers(channels, TREE_CHANNEL_ACTIONS.edit_driver_answer, slug, user))
      return editingDriverAlert(channels, TREE_CHANNEL_ACTIONS.edit_driver_answer, slug)

    performEditDriverAnswerAction(user, slug, true);
    if(sidebar.driverInputSidebar && sidebar.driverSlug === slug) {
      setDriverInputSidebarOpen(!sidebar.driverInputSidebar);
      return;
    }
    const timeout = sidebar.driverInputSidebar ? 600 : 100;
    if(sidebar.driverInputSidebar) resetDriverInputSidebar();
    setTimeout(() => setDriverInputSidebarOpen(true, slug), timeout)
  }

  const onClickEntireCard = (e) => {
    if(driverObject.isCompleted && !expanded) {
      onExpandDriverClick();
      e.stopPropagation();
    }
    return e;
  }

  return <>
    <Wrapper indentStep={indentStep} onClick={onClickEntireCard} clickable={driverObject.isCompleted && !expanded} expanded={expanded}>
      <div className={`${expanded ? "d-block" : "d-flex"}`} id={`driver-card-${slug}`}>
        <div className="my-auto lh-sm w-100 me-1">
          <Header {...{ user, driverData, driverObject, childrenDrivers, setShowChildren, onExpandDriverClick, onEditClick, expanded }} />
          <DriverInfo {...{ driverData, driverObject, decisionObject, user, hidden: expanded }} />
          <AggregatedData {...{ driverData, hidden: expanded }} />
        </div>
        {
          expanded ?
            <ExpandedContent {...{ driverData, onEditClick } } /> :
            <ContractedContent {...{ driverData, driverObject, assignedUser, contactsData, sidebar } } />
        }
      </div>
    </Wrapper>
    {showChildren && childrenDrivers.map((child) =>
      <DriverCard key={`driver-card-${child.driver.slug}`}
                  {...{
                    driverData: child,
                    indentStep: indentStep + 1,
                    decisionObject, user, collaborators, contactsData, sidebar, channels, tree,
                    setDriverInputSidebarOpen, resetDriverInputSidebar, updateTreeData
                  } } />
    )}
  </>
}
export default DriverCard;

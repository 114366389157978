import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import {
  createSource,
  destroySource,
  replaceSource,
  updateSource,
  attachReport,
  detachReport,
  addSourceToDecision
} from "../../store/wizard/actions";
import StyledDropzone from '../../tree_view/data_sources/DropFiles';
import {qSortArray, isPresent, isBlank} from "../../helpers/common";
import * as moment from "moment";
import {
  DecisionSetSourcesTypes,
  DecisionWizardDataSourcesTypes, Type
} from "../../models/data_source";
import { DataSourcesList, UploadingSourcesList } from "../../template_view/side_panel/sections/DataSourcesSection";
import { generateBatchDataSourceUploadState } from "../../helpers/uploads_callbacks";
import Button from "react-bootstrap/Button";
import SelectExistingSourceRow from "./SelectExistingSourceRow";
import { AddLinkRow } from '../../tree_view/data_sources';

const WizardDataSources = ({ decision, isDriverModal = false, isHistoricalDecision = false,
                             current_user, driver, selectableDriverDataSources,
                             dataSources, driverDataSources, uploadingSources, saveUploadingSources, availableOrgReports,
                             createSource, attachReport, decisionSetSources, addSourceToDecision, onSubmitDrivers = null,
                             ...opts
                           }) => {
  const [selectedSource, setSelectedSource] = useState('');
  const uploadOptions = generateBatchDataSourceUploadState(isDriverModal ? driverDataSources : dataSources, uploadingSources, saveUploadingSources, current_user)
  const [showDropzone, setShowDropzone] = useState(false);
  const focusDropzone = useRef(null);

  const scrollToBottom = () => {
    focusDropzone.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    if (isHistoricalDecision && window.innerWidth > 1199) return;

    scrollToBottom()
  }, [showDropzone])

  const onSuccessCallback = (callback = () => {}) => (success, drivers) => {
    callback(success)
    setTimeout(() => { if (success && isPresent(onSubmitDrivers)) onSubmitDrivers(drivers) }, 50)
  }

  const onSelectSource = (option) => {
    setSelectedSource(option.value);
    const attributes = { slug: option.value, driver_slug: driver.slug };
    createSource(attributes, {}, onSuccessCallback())
  };

  const onSelectReport = (option) => {
    setSelectedSource(option.value);
    const attributes = { data_source: { report_slug: option.value }, driver_slug: driver.slug };
    attachReport(attributes, false, onSuccessCallback())
  };

  const onSelectSetSource = (option) => {
    setSelectedSource(option.value);
    const attributes = { data_source: { set_source_slug: option.value, decision_set_slug: decision.decision_set_slug }, driver_slug: driver.slug }
    addSourceToDecision(attributes, onSuccessCallback());
  };

  const submitLink = (value, callback) => {
    let attributes = { link_url: value, driver_slug: driver.slug };
    createSource(attributes, {}, onSuccessCallback(callback));
  }

  const onUpload = (createParams, config, callback, updateOnlySources = false) => {
    createSource(createParams, config, onSuccessCallback(callback), updateOnlySources);
  }

  return <Fragment>
    <div className="mb-0">
      <h3 htmlFor="driverDataSources">Data sources</h3>
      <ul className="list-group">
        <DataSourcesList driver={driver}
                         isTemplate={false}
                         data_sources={isDriverModal ? driverDataSources : dataSources}
                         fromDriver={isDriverModal}
                         user={current_user} {...uploadOptions} {...opts} />
        <UploadingSourcesList uploading_sources={uploadingSources} user={current_user} {...uploadOptions} {...opts} />
      </ul>
    </div>
    <AddLinkRow onClick={submitLink} />
    <Button className="btn btn-secondary w-100 mt-2"
            onClick={() => setShowDropzone(true)}
            hidden={showDropzone}>
      Add files
    </Button>
    <div className={`mt-2`} hidden={!showDropzone} ref={focusDropzone}>
      <StyledDropzone onCreateSource={onUpload}
                      dataSourceType={Type.DECISION_WIZARD_DATA_SOURCE}
                      driver={driver}
                      driverModal={isDriverModal}
                      onStartUpload={uploadOptions.onStartUpload}
                      onFinishUpload={uploadOptions.onFinishUpload}
                      onProcessUpload={uploadOptions.onProcessUpload} />
    </div>
    <div className="mt-2" hidden={isBlank(availableOrgReports) && isBlank(decisionSetSources) && isBlank(selectableDriverDataSources)}>
      <div className="new-source-entries">
        <SelectExistingSourceRow selectedSource={selectedSource}
                                 onSelectSource={onSelectSource}
                                 onSelectReport={onSelectReport}
                                 onSelectSetSource={onSelectSetSource}
                                 orgReports={availableOrgReports}
                                 isDriverModal={isDriverModal}
                                 decisionSetSources={decisionSetSources}
                                 selectableDriverDataSources={selectableDriverDataSources}
                                 isHistoricalDecision={isHistoricalDecision}
        />
      </div>
    </div>
  </Fragment>
};

const mapStateToProps = ({ decision, tree, modal, current_user, current_org }, { isDriverModal = false, driverData = {} }) => {
  const allDataSources = (isDriverModal ? modal.data_sources : tree.data_sources) || [];
  const uploadingSources = (isDriverModal ? modal.uploading_sources : tree.uploading_sources) || [];
  const relatedDataSources = allDataSources.filter((source) => DecisionWizardDataSourcesTypes.includes(source.type));
  const sortedRelatedDataSources = qSortArray(relatedDataSources, true,(o) => moment(o.created_at));
  const relatedDataSourcesReportSlugs = sortedRelatedDataSources.map((source) => source.report_slug);

  const driver = driverData.driver || {};
  const driverSourcesSlugs = driverData.driver_sources_slugs || [];
  const driverDataSources = allDataSources.filter((source) => driverSourcesSlugs.includes(source.slug));
  const driverDataSourcesReportSlugs = driverDataSources.map((source) => source.report_slug);
  const sortedDriverDataSources = qSortArray(driverDataSources, true,(o) => moment(o.created_at));
  const availableSetSources = allDataSources.filter((source) => DecisionSetSourcesTypes.includes(source.type))
  const availableOrgReports = isDriverModal ? current_org.reports.filter((report) => !driverDataSourcesReportSlugs.includes(report.slug)) :
    current_org.reports.filter((report) => !relatedDataSourcesReportSlugs.includes(report.slug))
  const selectableDriverDataSources = sortedRelatedDataSources.filter((s) => ![Type.LINKED_DECISION_WIZARD_REPORT_DATA_SOURCE].includes(s.type) && !driverSourcesSlugs.includes(s.slug))

  return {
    decision, current_user, driver, uploadingSources, availableOrgReports,
    decisionSetSources: availableSetSources,
    selectableDriverDataSources: selectableDriverDataSources,
    dataSources: sortedRelatedDataSources,
    driverDataSources: sortedDriverDataSources,
  }
};

export default connect(mapStateToProps, {
  createSource, updateSource, destroySource, replaceSource, attachReport, detachReport, addSourceToDecision
})(WizardDataSources);


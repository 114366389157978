import React, { useState } from 'react';
import { connect } from "react-redux";
import { updateContext } from "../../../store/decision/actions";
import { isBlank, isPresent } from "../../../helpers/common";
import { isCollaborator } from "../../../helpers/user_helpers"
import Button from 'react-bootstrap/Button';
import NAElement from "./NAElement";
import { EditIcon } from "../../../common/EditIcon";
import { RichTextEditor, RichTextSection } from "../../../common/RichTextEditor";

const ContextHeaderElement = ({ title = 'Context' }) => <h3>{title}</h3>

export const DisplayContext = ({ text, onClick, hideEdit = null, preview = false, showMoreLess = false, ...opts }) =>
  <>
    <div className="d-flex w-100">
      <div className={'me-auto'}><ContextHeaderElement {...opts} /></div>
      <div hidden={hideEdit}>
        <EditIcon onClick={onClick} />
      </div>
    </div>
    <div className="overflow-auto" hidden={isPresent(text) && !preview}>
      <NAElement />
    </div>
    <div className="overflow-auto" hidden={preview && isBlank(text)}>
      <RichTextSection {...{text, showMoreLess}} />
    </div>
  </>

export const ContextEditForm = ({
                                  value, setValue,
                                  submit, isNewForm,
                                  saveForm, onCancel,
                                  title= 'Context', placeholder= 'Explain the reasons for this decision'
                                }) =>
  <div>
    <ContextHeaderElement title={title} />
    <RichTextEditor quillId={title} className="mb-2" value={value} setValue={setValue} placeholder={placeholder}/>
    <div hidden={!isNewForm}>
      <Button disabled={isBlank(value) || submit} onClick={saveForm} className="w-100">Save</Button>
    </div>
    <div hidden={isNewForm}>
      <div className="d-flex">
        <Button onClick={saveForm} className="w-100 me-1" disabled={submit}>Save</Button>
        <Button onClick={onCancel} disabled={submit} className="w-100 btn-secondary ms-1">Cancel</Button>
      </div>
    </div>
  </div>

export const contextSectionState = ({ context, allowEdit = true, saveFormCallback = () => {} }) => {
  const [isNewForm, setIsNewForm] = useState(isBlank(context))
  const [editMode, setEditMode] = useState(allowEdit && isNewForm)
  const [submit, setSubmit] = useState(false)
  const [value, setValue] = useState(context || '')

  const openEditForm = () => setEditMode(true);
  const closeEditForm = () => {
    setValue(context || '')
    setEditMode(false)
  }
  const saveCallback = (success) => {
    if (success) {
      if (isBlank(value)) {
        setEditMode(true);
        setIsNewForm(true)
      } else {
        setIsNewForm(false)
        setEditMode(false);
      }
    }
    setSubmit(false)
  };

  const saveForm = () => {
    setSubmit(true)
    saveFormCallback(value, saveCallback)
  }

  return {
    isNewForm, editMode, submit,
    value, setValue,
    openEditForm, closeEditForm, saveForm
  }
}

export const ContextSection = ({ decision, user, updateContextData, isLegacy = false }) => {
  if (!isCollaborator(user) && isBlank(decision.collaboration_context))
    return <div className="px-3 pb-3 border-bottom">
      <ContextHeaderElement />
      <NAElement />
    </div>;

  const {
    value, editMode,
    openEditForm, closeEditForm, saveForm,
    ...args
  } = contextSectionState({
    context: decision.collaboration_context,
    allowEdit: isCollaborator(user),
    saveFormCallback: updateContextData
  })

  return <div className="px-3 pb-3 border-bottom">
    {
      editMode ?
        <ContextEditForm value={value} saveForm={saveForm} onCancel={closeEditForm} {...args} /> :
        <DisplayContext onClick={openEditForm} hideEdit={isLegacy || !isCollaborator(user)} text={decision.collaboration_context} showMoreLess={true}/>
    }
  </div>
}
const mapStateToProps = ({ decision, current_user }) => ({ decision, user: current_user });
const mapDispatchToProps = (dispatch) => ({
  updateContextData: (value, callback) => { dispatch(updateContext(value, callback)) }
});
export default connect(mapStateToProps, mapDispatchToProps)(ContextSection);

import React, { useState } from 'react';
import { RichTextEditor } from "./RichTextEditor";

export const CategorizationNotesRow = ({ categorizationNotes, setCategorizationNotes }) =>
  <div className="w-100 mb-3">
    <h3>Categorization notes</h3>
    <RichTextEditor quillId={'Categorization notes'} value={categorizationNotes} setValue={setCategorizationNotes}
                    placeholder="Enter any comments or instructions for selecting categories"
    />
  </div>

export const categorizationNotesState = ({ categorization = '' }) => {
  const [categorizationNotes, setCategorizationNotes] = useState(categorization || '')
  const categorizationNotesRow = <CategorizationNotesRow categorizationNotes={categorizationNotes} setCategorizationNotes={setCategorizationNotes}/>

  return {
    categorizationNotes,
    categorizationNotesRow
  }
}

import React from 'react';

export default () =>
  <>
    <p>
      Decision flows allow grouping decisions by process or topic.
    </p>
    <p>
      Decisions can be added, removed, or sorted. Decision flow managers can optionally number decisions in the Details panel.
    </p>
    <p>
      Add, remove, or sort sections to create and manage sub-groupings or parallel tracks for decisions.
    </p>
  </>

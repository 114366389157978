import { useEffect, useMemo, useState } from "react";
import { ForecastConfig } from "../../models/forecast/ForecastConfig";
import {
  ForecastScenario,
  YEAR_HEADER_KEY,
  ROW_DRIVER_ID_KEY
} from "../../models/forecast/ForecastScenario";
import { isPresent, uniqueBy } from "../../helpers/common";
import { getContextMenuItems } from "./ag_grid_context_menu";
import CustomLoadingOverlay from "./custom_loading_overlay";
import AgGridApi from "./ag_grid_api";

const BASE_HEADER_OPTIONS = {
  sortable: true,
  filter: true,
  floatingFilter: true,
  resizable: true,
  width: 150
}

export const isFuturePeriod = (timeScale, period) => {
  try {
    if(timeScale === YEAR_HEADER_KEY) return Number(period) >= new Date().getFullYear();

    return Number(period.split('_')[1]) >= new Date().getFullYear();
  } catch (e) {
    console.error("Error while parsing header", period, e);
    return false
  }
}

const isEditableDriver = (config, driver, timeScale) => config.isEditableDriver(timeScale, driver);

const formatHeader = (field, config, scenario, timeScale) => {
  let definitions = { field, ...BASE_HEADER_OPTIONS };
  if(field === ROW_DRIVER_ID_KEY) {
    definitions.hide = true;
  }
  if(scenario.groupFields.includes(field)) {
    definitions.enableRowGroup = true
  }
  if(scenario.periodHeadersNames.includes(field) && isFuturePeriod(timeScale, field)) {
    definitions.editable = true;
    definitions.colId = scenario.getPeriodIdByPeriodName(field);
    definitions.type = 'editableColumn';
  }
  return definitions;
}


export const agGridInit = (forecast_simulator_scenario, setRunModelActive, updateCells, setUpdateCells, gridRef, updateScenario, setLargeScalePanelOpen) => {
  const config = useMemo(() =>
      new ForecastConfig(forecast_simulator_scenario.config),
    [forecast_simulator_scenario.config]
  )
  const forecastScenario = useMemo(() =>
      new ForecastScenario(forecast_simulator_scenario.scenario, config),
    [forecast_simulator_scenario.scenario, config]
  )
  const [timeScale, setTimeScale] = useState(forecastScenario.viewOptions.timeScale);
  const [editedCells, setEditedCells] = useState(forecastScenario.updateData.edited_cells || []);
  const [updatedCells, setUpdatedCells] = useState([]);
  const [colDefs, setColDefs] = useState(
    forecastScenario.viewHeaders.map(header =>
      formatHeader(header, config, forecastScenario, timeScale)
    )
  );
  const [rowData, setRowData] = useState([]);
  const editedCellsIds = useMemo(() => editedCells.map(cellData => cellData.id), [editedCells]);
  const updatedCellsIds = useMemo(() => updatedCells.map(cellData => cellData.id), [updatedCells]);
  useEffect(() => {
    if(editedCells.length) {
      setRunModelActive(true);
    }
  }, [editedCells])

  useEffect(() => {
    if(updateCells) {
      setUpdatedCells([...updatedCells, ...editedCells]);
      setEditedCells([]);
      setUpdateCells(false);
    }
  }, [updateCells])

  useEffect(() => {
    if(isPresent(forecastScenario.viewOptions.timeScale)) {
      setTimeScale(forecastScenario.viewOptions.timeScale);
      forecastScenario.setTimeScale(forecastScenario.viewOptions.timeScale);
      setColDefs(forecastScenario.viewHeaders.map(header => formatHeader(header, config, forecastScenario, forecastScenario.viewOptions.timeScale)));
      setRowData(forecastScenario.preparedAggregatedRows);
    }
  }, [forecastScenario.viewOptions.timeScale, forecastScenario.viewOptions.from, forecastScenario.viewOptions.to])

  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: '#FFFFFF' };
    }
  };
  const getRowId = useMemo(() => ((params) => params.data[ROW_DRIVER_ID_KEY]), []);
  const rowCellIdKey = (params) => `${params.node.id}_${params.colDef.colId}`;
  const findOrBuildCell = (list, params) => {
    const cellId = rowCellIdKey(params);
    return list.find(cellData => cellData.id === cellId) ||
      { id: cellId, default_value: params.node.prevValue || params.oldValue };
  }

  const onResetCells = (rowNode, list) => {
    const cellIds = list.map(params => rowCellIdKey(params));
    const filteredCells = [...editedCells.filter(cellData => !cellIds.includes(cellData.id))]
    updateScenario(forecast_simulator_scenario.scenario_id, { update_data: { edited_cells: filteredCells } },
      (status, errors) => {
      if (status) {
        list.forEach(params => {
          const cellId = rowCellIdKey(params);
          const cell = editedCells.find(cellData => cellData.id === cellId);
          if(cell) rowNode.setData({ ...rowNode.data, [params.colDef.field]: cell.default_value });
        })
        setEditedCells(filteredCells);
      }
    }, false);
  }

  const onCellValueChanged = (list, callback = () => {}) => {
    const preparedCells = list.map(params =>
      ({ ...findOrBuildCell(editedCells, params), value: params.newValue || params.node.value })
    )
    const allEditedCells = uniqueBy([...editedCells, ...preparedCells], 'id');

    updateScenario(forecast_simulator_scenario.scenario_id, {
      update_data: { edited_cells: allEditedCells }
    }, (status, errors) => {
      if (status) {
        callback();
        setEditedCells(allEditedCells);
      }
    }, false);
  };

  const onGridReady = () => {
    const prepareRows = async () => {
      return new Promise((resolve, reject) => {
        try {
          const rows = forecastScenario.preparedAggregatedRows;
          resolve(rows);
        } catch (error) {
          reject(error);
        }
      });
    };
    prepareRows().then((rows) => {
      setRowData(rows);
    }).catch((error) => {
      console.error('Error preparing rows:', error);
    });
  };
  const openLargeScalePanel = () => setLargeScalePanelOpen(true);

  return {
    rowData,
    rowStyle: { background: '#FBFCFE' },
    columnDefs: colDefs,
    rowGroupPanelShow: 'always',
    rowGroupPanelSuppressSort: true,
    getRowStyle,
    getRowId,
    groupDisplayType: 'multipleColumns',
    onGridReady,
    columnTypes: {
      editableColumn: {
        editable: (params) => isEditableDriver(config, params.node.data.Facts, timeScale),
        cellStyle: (params) => {
          if(updatedCellsIds.includes(rowCellIdKey(params))) {
            return { backgroundColor: "#D7F6ED", fontStyle: 'italic', color: '#2477B2' };
          }
          if(editedCellsIds.includes(rowCellIdKey(params))) {
            return { backgroundColor: '#FBF5D8', fontStyle: 'italic', color: '#2477B2' };
          }
          if (isEditableDriver(config, params.node.data.Facts, timeScale)) {
            return { backgroundColor: '#E5F5FF' };
          }
        },
        onCellValueChanged: (params) => onCellValueChanged([params])
      },
    },
    getContextMenuItems: (params) => {
      const periodName = params.column.colDef.field;
      if(forecastScenario.periodHeadersNames.includes(periodName)) {
        const driverHeader = params.node.data.Facts;
        const editable = isEditableDriver(config, driverHeader, timeScale) && isFuturePeriod(timeScale, periodName);
        return getContextMenuItems(params, editable, colDefs, onCellValueChanged, onResetCells, forecastScenario.allFuturePeriodsByTimeScale, openLargeScalePanel);
      }
      return [];
    },
    loadingOverlayComponent: CustomLoadingOverlay,
    loadingOverlayComponentParams: {
      loadingMessage: 'One moment please...',
    },
    stateUpdated: () => {
      new AgGridApi(gridRef.current?.api)?.onBtHide;
    }
  }
}

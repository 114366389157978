import React from 'react';
import { connect } from "react-redux";
import BaseHamburgerBtn, { BaseDropdownBtn, DeleteItemElement } from "../../common/BaseHamburgerBtn";
import { HamburgerMenuIconBtn } from "../../common/dropdowns";
import { openSettingsModal } from "../modals/SettingsModal";
import { openModal } from "../../store/modals/actions";
import { openEditNameModal } from "../modals/EditNameModal";

export const settingsCallback = (openModal) => () => openSettingsModal({ openModal })
export const editNameCallback = (openModal) => () => openEditNameModal({ openModal })

export const HamburgerDropdown = ({ processBackClick, openModal, deleteCallback }) => {

  return <HamburgerMenuIconBtn>
    <BaseHamburgerBtn onSelect={settingsCallback(openModal)} title="Settings" />
    <BaseHamburgerBtn onSelect={() => {}} title="Save as a new scenario" />
    <BaseHamburgerBtn onSelect={editNameCallback(openModal)} title="Edit scenario name" />
    <BaseHamburgerBtn onSelect={() => {}} title="Create a decision" />
    <BaseHamburgerBtn onSelect={() => {}} title="Generate Excel file" />
    <BaseDropdownBtn onSelect={deleteCallback}>
      <DeleteItemElement name="scenario" />
    </BaseDropdownBtn>
    <BaseHamburgerBtn onSelect={processBackClick} title="Go to homepage" />
  </HamburgerMenuIconBtn>
}

const mapStateToProps = ({}) => ({});
export default connect(mapStateToProps, { openModal })(HamburgerDropdown);

import React, { Fragment } from 'react';
import GoalsSection from "./GoalsSection";
import DSightSection from "./DSightSection";
import DecisionHistorySection from "./DecisionHistorySection";
import AskCloverpopSection from "./AskCloverpopSection";

export default ({ ...opts }) =>
  <Fragment>
    <GoalsSection  {...opts} />
    {opts.askCloverpopEnabled && <AskCloverpopSection /> }
    <div className="mt-4"><DSightSection {...opts} /></div>
    <div className="mt-3"><DecisionHistorySection {...opts} /></div>
  </Fragment>

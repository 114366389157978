import React from 'react';
import { connect } from "react-redux";
import SlidingPanel from "react-sliding-side-panel";
import { setTreeSidebarOpen } from "../../store/sidebar/actions";
import CloseIcon from "../../common/CloseIcon";
import PanelTreeEditor from "../../tree_editor/tree_view/PanelTreeEditor";
import { TREE_MODES } from "../../helpers/drivers_helpers";

export const TemplateTreePanel = ({
                                    preview = false, forceEditLink = false,
                                    sidebar, template, openSideBar
                                  }) => {
  const onClose = () => openSideBar(false)

  return <SlidingPanel
    type="left"
    isOpen={sidebar.treeSidebar}
    panelContainerClassName="sidebar-panel-wrapper template-tree-panel-wrapper decision-tree-panel-width left-side-panel-position tree-page"
    panelClassName="sidebar-panel-wrapper-content template-tree-panel-content h-auto"
    size={50}>
    <div className="mt-2 me-3 float-end">
      <CloseIcon onClose={onClose}/>
    </div>
    <PanelTreeEditor treeMode={TREE_MODES.edit}
                     zTreeId={'templateTree'}
                     preview={preview}
                     isTemplate={true}
                     onClose={onClose}
                     forceEditLink={forceEditLink} />
  </SlidingPanel>
}

const mapStateToProps = ({ sidebar, template }) => ({ sidebar, template });
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => {
    dispatch(setTreeSidebarOpen(options));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(TemplateTreePanel);

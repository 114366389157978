import $ from "jquery";
import React, { useEffect, useRef } from "react";
import { connect, Provider } from "react-redux";
import ReactDOM, { render } from "react-dom";
import { isBlank, isPresent } from "../../helpers/common";
import {
  calcAnswered, calcIsInFlight,
} from "../../tree_view/accordion_tree/shared/helpers";
import EntryPoint from "../../EntryPoint";
import ZTreeDriverNode from "./nodes/ZTreeDriverNode";
import ZTreeHoverDriverNode from "./nodes/ZTreeHoverDriverNode";
import { dragDriver } from "../../store/tree/actions";
import { updateTreeData } from "../../store/tree/common_actions";
import { openModal } from "../../store/modals/actions";
import { TREE_MODES } from "../../helpers/drivers_helpers";
import { MOVE_TYPES } from "../wizard/ZTree";
import Decision from "../../models/decision";

// unmount React components rendered during setup zTree with jquery
export const onUnmountZTree = (actions = () => {}, zTreeId) => {
  const treeObj = $.fn.zTree.getZTreeObj(zTreeId);
  if(treeObj) {
    const nodes = treeObj.getNodes();
    unmoutReactTreeNodes(nodes);
    actions()
    treeObj.destroy();
  }
}
export const unmoutReactTreeNodes = (treeNodes) => {
  treeNodes.forEach((treeNode) => {
    const $nodeEl = $(`#${treeNode.tId}_a`);
    if($nodeEl.length)
      ReactDOM.unmountComponentAtNode($nodeEl[0]);
    if(isPresent(treeNode.children)) unmoutReactTreeNodes(treeNode.children);
  })
}
// ZTree lib setting params
export const BASE_ZTREE_SETTING = {
  view: { dblClickExpand: false, selectedMulti: false, showLine: true },
  data: { simpleData: { enable: true, idKey: "slug", pIdKey: "pSlug", rootPId: "" }, key: { name: "question" } },
}

const addDiyDom = (treeId, treeNode, collaborators, disableQuestion = false) => {
  const $driverRow = $(`#${treeNode.tId}_a`);
  render(<Provider store={EntryPoint.instance.store}>
    <ZTreeDriverNode key={`ztree-node-${treeNode.slug}`} treeNode={treeNode} collaborators={collaborators} disableQuestion={disableQuestion} />
  </Provider>, $driverRow[0]);
}

const addHoverDom = (treeId, treeNode, isTemplate) => {
  const $driverRow = $(`#${treeNode.tId}_a`);
  render(<Provider store={EntryPoint.instance.store}>
    <ZTreeHoverDriverNode key={`ztree-hover-node-${treeNode.slug}`} treeNode={treeNode} isTemplate={isTemplate} />
  </Provider>, $driverRow[0]);
}

const removeHoverDom = (treeId, treeNode, collaborators) => addDiyDom(treeId, treeNode, collaborators);

export const beforeCollapseCallback = (treeId, treeNode, object) => treeNode.isParent && treeNode.slug !== object.slug;

export const preventDropCallback = (targetNode, moveType) => isBlank(targetNode) || isBlank(moveType) ||
  (targetNode.isDecision && (moveType === MOVE_TYPES.next || moveType === MOVE_TYPES.prev))

const dragDriverRequest = (dragDriver, treeNodes, targetNode, moveType, updateTreeData) => dragDriver({
  slug: treeNodes[0].slug,
  target_slug: targetNode.slug,
  move_type: moveType,
  is_decision: targetNode.isDecision
}, (success) => {
  if(success) updateTreeData({ copied_ztree_node: {}, selected_ztree_node: {} })
});

const zTreeEditModeSettings = (decision, collaborators, isTemplate, updateTreeData, dragDriver) => {
  return {
    edit: { enable: true, drag: { isCopy: true, isMove: true }, showRemoveBtn: false, showRenameBtn: false },
    view: {
      addDiyDom: (treeId, treeNode) => addDiyDom(treeId, treeNode, collaborators),
      addHoverDom: (treeId, treeNode) => addHoverDom(treeId, treeNode, isTemplate),
      removeHoverDom: (treeId, treeNode) => removeHoverDom(treeId, treeNode, collaborators),
    },
    callback: {
      beforeCollapse: (treeId, treeNode) => beforeCollapseCallback(treeId, treeNode, decision),
      beforeClick: (treeId, treeNode) => {
        updateTreeData({ selected_ztree_node: treeNode });
        return true;
      },
      beforeDrag: (treeId, treeNodes) => !treeNodes.some((node) => !node.drag),
      beforeDrop: (treeId, treeNodes, targetNode, moveType) => {
        if(preventDropCallback(targetNode, moveType)) return false;

        dragDriverRequest(dragDriver, treeNodes, targetNode, moveType, updateTreeData);
        return true
      }
    }
  }
}

const zTreeAssignModeSetting = (decision, collaborators, drivers, data_sources, openModal) => {
  const decisionObj = new Decision(decision)

  return {
    view: { addDiyDom: (treeId, treeNode) => addDiyDom(treeId, treeNode, collaborators, treeNode.answered) },
    callback: {
      beforeCollapse: (treeId, treeNode) => beforeCollapseCallback(treeId, treeNode, decision),
      beforeClick: (treeId, treeNode) => {
        if(treeNode.isDecision && !decisionObj.isRecordedOrRecommended) {
          isPresent(decision.recommendation) ?
            openModal({decision, drivers, data_sources, slug: treeNode.slug, type: 'RecommendationAssignModal'}):
            openModal({decision, drivers, data_sources, slug: treeNode.slug, type: 'DecisionAssignModal'})
        } else if(!treeNode.answered) {
          openModal({decision, drivers, data_sources, slug: treeNode.slug, type: 'DriverAssignModal'})
        }
        return true;
      }
    }
  }
}

const generateDriverObject = ({
                                driver = {}, driver_sources_slugs = [], pSlug = null, default_user_avatar_url = '',
                                children = [], isDecision = false, isTemplate =  false,
                                decision = {}
                              }) => {
  const answered = !isTemplate ? calcAnswered(isDecision, driver, driver_sources_slugs, decision) : false;
  const inFlight =  !isTemplate ? calcIsInFlight(isDecision, driver, driver_sources_slugs, decision) : false
  const commented = !isTemplate && !isDecision && answered && isPresent(driver.comments);
  const comments_size = !isTemplate && !isDecision && isPresent(driver.comments) ? driver.comments.length : 0;
  const has_notes = !isDecision && !answered && isPresent(driver.notes);
  const assigned = !answered && (isPresent(driver.assign_to_user) || isPresent(driver.assignedCollaboratorEmail));

  const filtered_children = (children || []).filter(hash => isPresent(hash['driver']['question']))
  const children_empty = (children || []).find(hash => isBlank(hash['driver']['question']))
  const result = {
    isDecision, answered, commented, has_notes, assigned, comments_size, inFlight,
    isTemplate, default_user_avatar_url,
    assignedToUser: isDecision ? driver.assignedCollaboratorEmail : driver.assign_to_user,
    notes: driver.notes,
    driverTypeSlug: driver.driver_type_slug,
    pSlug: pSlug,
    slug: driver.slug,
    open: true,
    question: driver.question,
    childrenEmptyDriver: children_empty,
    iconSkin: inFlight ? 'in-flight' : (answered ? 'answered' : 'non-answered'),
    drag: !isDecision,
    drop: !isDecision
  };

  if (filtered_children.length > 0) result['children'] = filtered_children.map(hash =>
    generateDriverObject({ ...hash, pSlug: driver.slug, isTemplate, decision, default_user_avatar_url })
  )
  return result;
}

const ZTree = ({ zTreeId = 'decisionTree', tree, decision, channels, isTemplate = false,
                 treeMode = TREE_MODES.edit,
                 collaborators = [], updateTreeData, dragDriver,
                 openModal }) => {

  const ref = useRef(null)
  useEffect(() => {
    let stateSettings = ''
    switch (treeMode) {
      case TREE_MODES.edit:
        stateSettings = zTreeEditModeSettings(decision, collaborators, isTemplate, updateTreeData, dragDriver)
        break
      case TREE_MODES.assign:
        stateSettings = zTreeAssignModeSetting(decision, collaborators, tree.drivers, tree.data_sources, openModal)
        break
    }
    const setting = { ...BASE_ZTREE_SETTING, ...stateSettings };
    const decisionObj = new Decision(decision);
    const assignedCollaboratorEmail = decisionObj.isRecordedOrRecommended ? null : decisionObj.assignedCollaboratorUser;
    const nodes = generateDriverObject({
      driver: { ...decision, question: decision.description, assignedCollaboratorEmail: assignedCollaboratorEmail },
      children: tree.drivers,
      pSlug: decision.slug,
      default_user_avatar_url: tree.default_user_avatar_url,
      isDecision: true,
      isTemplate,
      decision
    });
    $.fn.zTree.init($(ref.current), setting, nodes);
    return () =>
      onUnmountZTree(() => updateTreeData({ selected_ztree_node: {}, copied_ztree_node: {} }), zTreeId);
  }, [decision, treeMode, tree.drivers, channels.tree, isTemplate])

  useEffect(() => {
    if(isBlank(tree.copied_ztree_node)) {
      $.fn.zTree.getZTreeObj(zTreeId).cancelSelectedNode()
    }
  }, [tree.copied_ztree_node])

  return <ul id={zTreeId} className="ztree p-0" ref={ref} />
}
const mapStateToProps = ({ tree, decision, template, channels },  { isTemplate }) => ({
  tree, channels, decision: isTemplate ? template : decision
});
const mapDispatchToProps = (dispatch) => ({
  openModal: (data) => dispatch(openModal(data)),
  updateTreeData: (data) => dispatch(updateTreeData(data)),
  dragDriver: (data, callback) => dispatch(dragDriver(data, callback))
});
export default connect(mapStateToProps, mapDispatchToProps)(ZTree);

import React from 'react';
import { connect } from "react-redux";
import TemplateCard from './cards/TemplateCard';
import TemplatePreviewWizardDriverCard from './cards/TemplatePreviewWizardDriverCard';
import { openModal } from "../../store/modals/actions";
import {treeInitData} from "./shared/helpers";

const TemplateWizardPreviewTree = ({ tree, template, user, contactsData, openModal }) => {
  const { rootDrivers } = treeInitData(tree, {})

  const onTemplateClick = () => openModal({
    decision: template,
    drivers: tree.drivers,
    data_sources: tree.data_sources,
    slug: template.slug,
    type: 'TemplateDecisionDetailsModal'
  })

  return <div className="container-fluid p-0">
    <div className={`px-2 my-2 mx-auto vertical-tree`}>
      <TemplateCard {... { template, onTemplateClick } } />
      {
        rootDrivers.map(driverData =>
          <TemplatePreviewWizardDriverCard key={`template-wizard-driver-card-${driverData.driver.slug}`}
                              {...{ driverData, tree, contactsData, user, template, openModal } } />
        )
      }
    </div>
  </div>
}
const mapStateToProps = ({ tree, template, modal, current_user, contacts }) => ({
  tree, template, modal,
  user: current_user,
  contactsData: contacts
});
export default connect(mapStateToProps, { openModal })(TemplateWizardPreviewTree);
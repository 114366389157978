import React, { useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import Decision from "../../../../models/decision";
import { isBlank, isPresent } from "../../../../helpers/common";
import {
  createChoice,
  destroyChoice,
  updateChoice,
  updateFinalChoices
} from "../../../../store/sidebar/actions";
import { RichTextEditor } from "../../../../common/RichTextEditor";
import { saveSideBarData } from "../../../../store/sidebar/common_actions";
import { isDoneClicked } from "../../../../helpers/sidebar_helpers";
import { focusToEnd } from "../../../../helpers/rich_text_helpers";

const OpenEndedChoices = ({ sidebar,
                            decision,
                            createChoice,
                            updateChoice,
                            destroyChoice,
                            updateFinalChoices }) => {
  if (isBlank(decision)) return null;

  const decisionObj = new Decision(decision);
  const openEndedChoices = decisionObj.openEndedChoice;
  const previousOpenEndedChoice = openEndedChoices[0];
  const previousOpenEndedChoiceDescription = previousOpenEndedChoice?.description || ''
  const [loading, setLoading] = useState(true);
  const [openEndedChoice, setOpenEndedChoice] = useState(previousOpenEndedChoiceDescription);
  const focusElement = useRef(null);

  useEffect(() => {
    if(!loading && isPresent(focusElement.current)) focusToEnd(focusElement);
    if(!loading && !sidebar.decisionInputSidebar) {
      return () => {
        onChangeOpenEndedChoice()
      }
    }
    setLoading(false)
  }, [sidebar.decisionInputSidebar, focusElement.current])

  const sidebarSubmitted = () => isDoneClicked('decision-input');

  const onChangeOpenEndedChoice = (ignoreSidebarUpdate = true) => {
    if (openEndedChoice.trim() === previousOpenEndedChoiceDescription.trim())
      return;

    if (isBlank(openEndedChoice) && isPresent(previousOpenEndedChoice)) {
      destroyChoice(previousOpenEndedChoice.slug, ignoreSidebarUpdate);
    } else if (isPresent(openEndedChoice) && isBlank(previousOpenEndedChoice)) {
      createChoice({choice: {description: openEndedChoice, ignore_line_breaks: true, open_ended: true }}, updateFinalChoices, ignoreSidebarUpdate, !sidebarSubmitted());
    } else if (isPresent(previousOpenEndedChoice)) {
      updateChoice(previousOpenEndedChoice.slug, {choice: {description: openEndedChoice, ignore_line_breaks: true }}, updateFinalChoices, ignoreSidebarUpdate);
    }
  };

  return <RichTextEditor quillId={'openEndedChoice'}
                         value={openEndedChoice}
                         setValue={setOpenEndedChoice}
                         onBlur={() => onChangeOpenEndedChoice(false)}
                         placeholder="Enter what was decided"
                         ref={focusElement}
  />
};
const mapStateToProps = (state) => ({ decision: state.sidebar.decision, sidebar: state.sidebar });
export default connect(mapStateToProps, {
  createChoice, updateChoice, destroyChoice, updateFinalChoices, saveSideBarData
})(OpenEndedChoices);

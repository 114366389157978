import {
  TEMPLATE_LOAD_DATA,
  TEMPLATE_LOAD_DATA_FAILED,
  TEMPLATE_LOAD_DATA_STARTED,
  TEMPLATE_RESET_DATA,
  TEMPLATE_UPDATE_DATA
} from "./types";

const initialState = {
    loading: false,
    loaded: false,
    submit: false,
    error: '',
    slug: '',
    name: '',
    description: '',
    choice_entry_widget_type: '',
    collaboration_context: '',
    flow: '',
    data_sources: [],
    drivers: [],
    category_options: [],
    selected_categories: [],
    choices: [],
    admissions: [],
    users: [],
    d_sight: {},
    template_set_slug: '',
    template_set_name: '',
    playbook: false,
    rate_compare_choices: false,
    hide_recommendation: false,
    assigned_collaborator_email: ''
};

export function templateReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case TEMPLATE_LOAD_DATA: {
            return {
                ...state,
                ...action.payload,
                error: '',
                loading: false
            };
        }
        case TEMPLATE_UPDATE_DATA: {
            return {
                ...state,
                ...action.payload,
                error: '',
                loading: false
            };
        }
        case TEMPLATE_RESET_DATA: {
            return {
                ...initialState,
                ...action.payload,
                loading: false
            };
        }
        case TEMPLATE_LOAD_DATA_STARTED: {
            return {
                ...state,
                ...initialState,
                ...action.payload
            }
        }
        case TEMPLATE_LOAD_DATA_FAILED: {
            return {
                ...state,
                ...action.payload,
                loading: false
            }
        }
        default:
            return state;
    }
}

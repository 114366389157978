import { updateDecisionData } from "../store/decision/common_actions";
import { saveSideBarData } from "../store/sidebar/common_actions";
import { updateDecisionSetData } from "../store/decision_set/common_actions";

const ERROR_MESSAGE = 'Something went wrong.';

export const isResponseFailed = ({ data, ok }) => !ok || (data['status'] !== 'ok' && !isResponseRedirected({data}));

export const isResponseRedirected = ({ data }) => data['status'] ==='redirect'

export const dispatchError = (dispatch, {
  data, callback,
  default_error_message = ERROR_MESSAGE
}) => {
  if (data['status'] === 'error') {
    dispatch(callback(data['message'] || default_error_message));
  } else {
    dispatch(callback(default_error_message));
  }
};

export const dispatchErrorWithCallback = (dispatch, {
  data, callback,
  default_error_message = ERROR_MESSAGE
}, afterCallback = () => {}) => {
  const errorMessage = data['status'] === 'error' ? data['message'] || default_error_message : default_error_message
  dispatch(callback(errorMessage));

  if (data['status'] === 'error') {
    afterCallback(false, data['errors'] || { base: errorMessage })
  } else {
    afterCallback(false, { base: errorMessage })
  }
}

export const failedResponseHandler = (dispatch, options, callback = () => {}) => {
  callback(false, options);
  dispatchError(dispatch, options);
}

export const failedResponseHandlerWithDispatchCallback = (dispatch, options, callback = () => {}) => {
  dispatchErrorWithCallback(dispatch, options, callback);
}

export const partialDeepEqual = (newData, prevData)  => {
  const keys1 = Object.keys(newData);
  for (const key of keys1) {
    const val1 = newData[key];
    const val2 = prevData[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      areObjects && !deepEqual(val1, val2) ||
      !areObjects && val1 !== val2
    ) {
      return false;
    }
  }
  return true;
}
export const deepEqual = (object1, object2)  => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      areObjects && !deepEqual(val1, val2) ||
      !areObjects && val1 !== val2
    ) {
      return false;
    }
  }
  return true;
}
const isObject = (object) => object != null && typeof object === 'object'

export const updateDecisionDataInStores = (dispatch, getStore, decisionData, { updateSidebar = true, updateDecisionSet = true } = {}) => {
  if (isDecisionChanged(getStore, decisionData)) {
    dispatch(updateDecisionData(decisionData))

    if (updateSidebar) {
      const { decisionInputSidebar, recommendationInputSidebar } = getStore().sidebar
      if(decisionInputSidebar || recommendationInputSidebar) {
        dispatch(saveSideBarData({ decision: { ...getStore().sidebar.decision, ...decisionData } }))
      }
    }
    if (updateDecisionSet) {
      const decisions = getStore().decision_set.decisions.map((decision) => {
        return decision.slug === decisionData.slug ? decisionData : decision
      });
      const sections = getStore().decision_set.sections.map((section) => {
        return { ...section, decisions: section.decisions.map((decision) => {
            return decision.slug === decisionData.slug ? decisionData : decision
        }) }
      })
      dispatch(updateDecisionSetData({ ...getStore().decision_set, decisions, sections, loading: false, loaded: true }));
    }
  }
}
export const isDecisionChanged = (getState, newDecisionData) => !partialDeepEqual(newDecisionData, getState().decision)

import React from 'react';
import { connect } from "react-redux";
import { setDecisionSidebarOpen } from "../../store/sidebar/actions";
import SlidingPanel from "react-sliding-side-panel";
import ContextSection from "../../tree_view/side_panel/sections/ContextSection";
import CollaboratorsSection from "./sections/CollaboratorsSection";
import DecisionDueDateSection from "../../tree_view/side_panel/sections/DecisionDueDateSection";
import CategoriesSection from "./sections/CategoriesSection";
import PanelHeader from "../../tree_view/side_panel/PanelHeader";
import { isMobile } from "react-device-detect";
import WizardDataSources from "../data_sources";
import { saveUploadingSources } from "../../store/tree/actions";

export const DecisionDetailsPanel = ({ wizard, sidebar, openSideBar, saveUploadingSources }) =>
  wizard.loaded && <SlidingPanel
    type="left"
    isOpen={sidebar.decisionDetailsSidebar}
    panelContainerClassName={`sidebar-panel-wrapper decision-details-panel-wrapper details-panel-width decision-panel left-side-panel-position tree-page  ${isMobile ? 'pb-5 mb-5' : ''}`}
    panelClassName={`sidebar-panel-wrapper-content decision-details-panel-content`}
    size={52}
  >
    <PanelHeader headerName={'Details'} openSideBar={openSideBar} preview={true} />
    <ContextSection />
    <DecisionDueDateSection />
    <CategoriesSection />
    <CollaboratorsSection />
    <div className="p-3 border-bottom">
      <WizardDataSources saveUploadingSources={saveUploadingSources} />
    </div>
  </SlidingPanel>
const mapStateToProps = ({ sidebar, wizard }) => ({ sidebar, wizard });
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => {
    dispatch(setDecisionSidebarOpen(options));
  },
  saveUploadingSources: (sources) => dispatch(saveUploadingSources(sources)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DecisionDetailsPanel);

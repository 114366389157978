import { isPresent, qSortArray } from "../../helpers/common";
import { ForecastBaseModel } from "./Base";
import * as moment from "moment";

export class ForecastTimePeriod extends ForecastBaseModel {
  get name() {
    return this.attributes.name;
  }
  get timeScaleId() {
    return parseInt(this.attributes.forecast_time_scale_id);
  }
  get startDate() {
    return moment(this.attributes.start_date);
  }
  get endDate() {
    return moment(this.attributes.end_date);
  }
  get timeScaleKey() {
    return this.timeScale?.key;
  }
}

export class ForecastTimeScale extends ForecastBaseModel {

  fillPeriods(periods) {
    this.timePeriods = qSortArray(
      periods.filter(period => period.timeScaleId === this.id),
      true, (period) => period.startDate
    )
    this.timePeriods.forEach(period => period.timeScale = this);
  }

  get displayName() {
    return this.attributes.display_name;
  }
  get key() {
    return this.attributes.config_key;
  }
  get isVisible() {
    return isPresent(this.attributes.visible);;
  }

  get isInitData() {
    return isPresent(this.attributes.config_loaded);
  }
}

import React, { useMemo } from 'react';
import { connect } from "react-redux";
import {
  copyChoices,
  setDecisionInputSidebarOpen,
  setRecommendationInputSidebarOpen
} from "../../store/sidebar/actions";
import BaseHamburgerBtn from "../../common/BaseHamburgerBtn";
import { isBlank } from "../../helpers/common";
import Decision from "../../models/decision";
import Recommendation from "../../models/recommendation";
import { isCollaborator, isDecider } from "../../helpers/user_helpers";
import { isPresent } from "../../../ms_teams/helpers/common";

const EnterDecisionButton = ({
                               tree, decision, copyChoices, openDecisionInputSidebar,
                               title = 'Enter decision', current_user,
                               openRecommendationInputSidebar
                            }) => {

  const decisionObject = useMemo(() => new Decision(decision, tree.drivers), [decision, tree])
  const recommendation = decision.recommendation;
  const recommendationObject = new Recommendation(recommendation);
  const onDecisionEntryOpen = () => {
    if(isBlank(decisionObject.decisionChoices) && isPresent(recommendationObject.recommendationChoices)) {
      copyChoices((success) => {
        if (success) openDecisionInputSidebar(true)
      })
    } else {
      openDecisionInputSidebar(true)
    }
  }

  if (isBlank(decision)) return null;
  if (!isDecider(current_user) && !isCollaborator(current_user)) return null;
  if (decisionObject.isDecisionRecorded) return null;

  if (recommendationObject.isEnteredRecommendation || isBlank(recommendation) || isBlank(recommendationObject)) {
    return <BaseHamburgerBtn onSelect={onDecisionEntryOpen} title={title} />;
  } else {
    return <BaseHamburgerBtn onSelect={openRecommendationInputSidebar} title={title} />;
  }
}

const mapStateToProps = ({ tree, decision, current_user }) => ({
  tree, decision, current_user
});
const mapDispatchToProps = (dispatch) => ({
  openRecommendationInputSidebar: () => {
    dispatch(setRecommendationInputSidebarOpen(true));
  },
  copyChoices: (callback) => {
    dispatch(copyChoices(callback))
  },
  openDecisionInputSidebar: () => {
    dispatch(setDecisionInputSidebarOpen(true));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(EnterDecisionButton);

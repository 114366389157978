import {convertInchesToTwip, TextRun, UnderlineType} from "docx";
import {baseParagraph, externalLink} from "../tree_view/sections/wrappers";
import {analyzeAndReplaceLinks} from "../../pdf/helpers/common";
import {BASE_INDENT} from "../styles/number_styles";

const LINK_EXPR = /<a\s+href="([^"]+)"[^>]*>(.*?)<\/a>/
const BRAKES_BEFORE_LISTS_EXPR = /<br>(?=\s*<(ul|ol)>)|<br>(?=$)/gi;

const processLink = (line, result, node) => {
  const matchLink = line.match(LINK_EXPR);

  if (matchLink) {
    const link = matchLink[1];
    const formattingOptions = []
    collectFormats(formattingOptions, node);
    const format = Object.assign({}, ...formattingOptions);

    result.push(externalLink({ linkText: node.textContent, link, format }));
    result.push(new TextRun({
      text: line.replace(matchLink[0], ''),
    }));
  } else {
    result.push(new TextRun(line));
  }
};

const indentLevel = (listItem) => listItem?.getAttribute('class')?.replace('ql-indent-', '') || 0;
const removeBrakesBeforeLists = (text) => text.replace(BRAKES_BEFORE_LISTS_EXPR, '').replace(/(<p><br><\/p>)/g, ' ')
const processBreak = () => new TextRun({ break: 1 });

const processText = (textContent, formattingOptions = []) => {
  const format = Object.assign({}, ...formattingOptions);
  return new TextRun({
    ...format,
    text: textContent
  });
};

const processList = (node, paragraphs, resultText, alignment, style, type, instance) => {
  paragraphs.push(baseParagraph(resultText, alignment, style));
  resultText.splice(0, resultText.length);
  const listItems = Array.from(node.childNodes).filter(childNode => childNode.tagName === 'LI');

  const listText = listItems.map(listItem => {
    const listItemText = [];
    Array.from(listItem.childNodes).forEach(nodeChild => {
      processNode(nodeChild, listItemText, paragraphs, alignment, style);
    });
    return { text: listItemText, level: indentLevel(listItem), instance: instance };
  });

  const bulletList = (level) => type === 'UL' ?
    { numbering: { reference: "rich-bullet-points", level } } : {}
  const orderListList = (level, instance) => type === 'OL' ?
    { numbering: { reference: `rich-numbering-${instance}`, level, instance: instance } } : {}

  listText.forEach(({ text, level, instance }) => {
    return paragraphs.push(baseParagraph(text, alignment, 'normalPara', bulletList(level), orderListList(level, instance)));
  });
};

const processParagraph = (node, paragraphs, resultText, alignment, style) => {
  paragraphs.push(baseParagraph(resultText, alignment, style));
  resultText.splice(0, resultText.length);

  const itemsText = [];
  Array.from(node.childNodes).map(listItem => {
    processNode(listItem, itemsText, paragraphs, alignment, style, {}, []);
  });

  const indent = convertInchesToTwip(BASE_INDENT * indentLevel(node));
  paragraphs.push(baseParagraph(itemsText, alignment, 'normalPara', {}, {}, { indent: { left: indent } }));
};

const collectFormats = (formattingOptions, node) => {
  switch (node.tagName) {
    case 'STRONG':
      formattingOptions.push({ bold: true });
      break;
    case 'EM':
      formattingOptions.push({ italics: true });
      break;
    case 'S':
      formattingOptions.push({ strike: true });
      break;
    case 'U':
      formattingOptions.push({ underline: {type: UnderlineType.SINGLE} });
      break;
  }

  node.childNodes.forEach(childNode => {
    collectFormats(formattingOptions, childNode);
  });
}

const processNestedTags = (node, resultText) => {
  const formattingOptions = [];
  collectFormats(formattingOptions, node);
  resultText.push(processText(node.textContent, formattingOptions));
};

const processNode = (node, resultText, paragraphs, alignment, style, instance) => {
  if (node.nodeType === Node.ELEMENT_NODE) {
    switch (node.tagName) {
      case 'A':
        return processLink(node.outerHTML, resultText, node);
      case 'STRONG': case 'EM': case 'S': case 'U':
        return processNestedTags(node, resultText);
      case 'BR':
        return resultText.push(processBreak());
      case 'UL': case 'OL':
        return processList(node, paragraphs, resultText, alignment, style, node.tagName, instance)
      case 'P':
        return processParagraph(node, paragraphs, resultText, alignment, style)
    }
  } else if (node.nodeType === Node.TEXT_NODE) {
    resultText.push(processText(node.textContent));
  }
}

export const richText = (html, alignment, style, instance) => {
  const parser = new DOMParser();
  const text = removeBrakesBeforeLists(analyzeAndReplaceLinks(html))
  const doc = parser.parseFromString(text, 'text/html');
  const paragraphs = [];
  let resultText = [];

  doc.body.childNodes.forEach(node => processNode(node, resultText, paragraphs, alignment, style, instance));
  paragraphs.push(baseParagraph(resultText, alignment, style));

  return paragraphs;
};

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import {saveReportWizardStepData, updateWizardData} from "../../../store/wizard/actions";
import { Button, Table } from 'react-bootstrap'
import { isBlank } from "../../../helpers/common";
import { NAME_INPUT_LIMIT } from "../../../models/report";
import Help from "../../help";
import {isLastStep, WIZARD_STEPS, wizardStepDataBy} from "../../../helpers/wizard_helpers";
import { RichTextEditor } from "../../../common/RichTextEditor";
import CharCounter from "../../../common/CharCounter";
import { initAutofocusInput } from "../../../homepage/modals/helpers";
import DataSources from '../../data_sources';
import {
  createReportSource,
  destroyReportSource,
  replaceReportSource,
  saveUploadingSources,
  updateReportSource
} from "../../../store/report/actions";

export const ReportDetailsStep = ({
                                      wizard, report, current_user,
                                      saveWizardStepData, updateWizardData,
                                      stepRef, ...opts
                                    }) => {
  const [showChar, setShowChar] = useState(false);
  const [submitState, setSubmitState] = useState(false)
  const stepData = wizardStepDataBy(wizard, WIZARD_STEPS.report_details.key)
  const [name, setName] = useState(stepData.name || '')
  const [notes, setNotes] = useState(stepData.notes || '')
  const nameInputRef = initAutofocusInput()
  const disabledStep = () => isBlank(name)
  const selectPropertiesData = state => state.wizard.flow_steps.find(step => step.step === 'report_properties') || {}
  const propertiesData = useSelector(selectPropertiesData)

  const submitStep = (nav_data = {}, callback = () => {}) => {
    setSubmitState(true)
    saveWizardStepData(WIZARD_STEPS.report_details.key, {
      complete: isLastStep(wizard),
      ...nav_data,
      wizard: { step_index: wizard.step_index },
      ...propertiesData,
      report: {
        name,
        notes,
        data_sources: []
      },
      next_step: true, complete: true
    }, callback)
  }

  stepRef.current = {
    submitStep: (additional_data = {}, callback = () => {}) => {
      submitStep(additional_data, (success) => {
        setSubmitState(false)
        callback(success)
      })
    }
  }

  useEffect(() => {
    updateWizardData({ disabledSubmit: disabledStep() })
  }, [name])

  return <div className="d-flex">
    <div className="mx-auto">
      <div className={`bg-white rounded p-3 mt-3 mb-5 tree_wizard d-inline-block`}>
        <Table borderless className="mb-2">
          <tbody>
          <tr>
            <td className="d-flex p-0">
              <h1 className="d-table-cell">
                Add the name, links or files, and comments or instructions.
              </h1>
              <div className="d-table-cell ms-auto">
                <Help />
              </div>
            </td>
          </tr>
          </tbody>
        </Table>
        <div className="w-100 mb-3">
          <h3>Name</h3>
          <div className={'mb-3'}>
            <CharCounter show={showChar} field={name} limit={NAME_INPUT_LIMIT}/>
            <input className="form-control"
                   id="reportName"
                   placeholder="Enter the report name"
                   value={name}
                   readOnly={submitState} ref={nameInputRef}
                   maxLength={NAME_INPUT_LIMIT}
                   onChange={(e) => setName(e.target.value)}
                   onBlur={() => setShowChar(false)}
                   onFocus={() => setShowChar(true)}
            />
          </div>
        </div>
        <div className="w-100 mb-3">
          <DataSources current_user={current_user} {...opts} />
        </div>
        <div className="w-100 mb-3">
          <h3>Notes</h3>
          <RichTextEditor quillId={'Notes'} value={notes} setValue={setNotes}
                          placeholder="Enter any comments or instructions"
          />
        </div>
        <div className="w-100">
        <Button onClick={() => submitStep()} disabled={wizard.submit || submitState || disabledStep()} className="w-100 mt-3">
          Done
        </Button>
        </div>
      </div>
    </div>
  </div>
}

const mapStateToProps = ({ wizard, report, current_user }) => ({ wizard, report, current_user });
const mapDispatchToProps = (dispatch) => ({
  saveWizardStepData: (step, data, callback) => {
    dispatch(saveReportWizardStepData(step, data, callback))
  },
  updateWizardData: (data) => dispatch(updateWizardData(data)),
  createSource: (data, config, callback) => dispatch(createReportSource(data, config, callback)),
  destroySource: (slug, data) => dispatch(destroyReportSource(slug, data)),
  replaceSource: (slug, data, config, callback) => dispatch(replaceReportSource(slug, data, config, callback)),
  saveUploadingSources: (sources) => dispatch(saveUploadingSources(sources)),
  updateSource: (slug, data) => dispatch(updateReportSource(slug, data))
});
const wrapper = React.forwardRef((props, ref) => <ReportDetailsStep {...props} stepRef={ref} />)
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(wrapper);

import React, { useCallback, useMemo } from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { collectChatMessages } from "../../helpers/discussion_helpers";
import { resetSidebars, setDiscussionPanelOpen, setFinalDecisionSidebarOpen } from "../../store/sidebar/actions";
import { isBlank, successActions } from "../../helpers/common";
import { isVisitor } from "../../helpers/user_helpers";
import Decision from "../../models/decision";
import { resetTree } from "../../store/tree/common_actions";
import { forceReloadHomepageDecisions } from "../../store/homepage/actions";
import { redirectToHomeCallback, redirectToSignInSuccessCallback } from "../../helpers/decision_helpers";
import { reloadContacts } from "../../store/contacts/actions";
import { resetPlaybookNotes } from "../../store/playbook_notes/actions";
import DecisionTreeHamburgerButton from "../../template_view/header/DecisionTreeHamburgerButton";
import DetailsHamburgerButton from "../../template_view/header/DetailsHamburgerButton";
import PlaybookHamburgerButton from "../../template_view/header/PlaybookHamburgerButton";
import BaseHamburgerBtn from "../../common/BaseHamburgerBtn";
import { HamburgerMenuIconBtn } from "../../common/dropdowns";
import { isPlaybookNotesPresent } from "../../helpers/playbook_helpers";
import EnterDecisionButton from "../../template_view/header/EnterDecisionButton";
import HelpHamburgerButton from "../../template_view/header/HelpHamburgerButton";
import DecisionSetPanelHamburgerButton from "../../template_view/header/DecisionSetPanelHamburgerButton";
import { treeChannelIsConnected } from "../../helpers/channel_helpers";
import { resetChannelsData } from "../../store/channels/actions";
import EntryPoint from "../../EntryPoint";
import { resetDecisionState } from "../../store/decision/common_actions";
import RateCompareHamburgerButton from "./buttons/RateCompareHamburgerButton";

export const resetDispatch = (dispatch) => ({
  resetSidebars: () => dispatch(resetSidebars()),
  resetTree: () => dispatch(resetTree()),
  forceReloadHomepageDecisions: () => dispatch(forceReloadHomepageDecisions()),
  reloadContacts: () => dispatch(reloadContacts()),
  resetPlaybookNotes: () => dispatch(resetPlaybookNotes()),
  resetDecisionState: () => dispatch(resetDecisionState()),
  resetChannelsData: () => dispatch(resetChannelsData())
})
export const redirectToHomepageCallback = ({
                                             current_user, history,
                                             resetSidebars, resetTree, resetPlaybookNotes, forceReloadHomepageDecisions,
                                             reloadContacts, resetDecisionState, resetChannelsData
                                          }) => () => {
  if (isBlank(current_user.email)) { return redirectToSignInSuccessCallback(true); }

  EntryPoint.instance.recommendationSlug = null;
  if(treeChannelIsConnected()) {
    EntryPoint.instance.TreeChannelSubscription.unsubscribe();
    EntryPoint.instance.TreeChannelSubscription = null
  }
  successActions(true, [resetSidebars, resetTree, resetPlaybookNotes, forceReloadHomepageDecisions,
    reloadContacts, resetDecisionState, resetChannelsData])
  redirectToHomeCallback(true, history);
}

const DecisionItem = ({ decisionObject, openFinalDecisionSideBar }) => {
  if (!decisionObject.isDecisionRecorded) return null;

  return <BaseHamburgerBtn onSelect={openFinalDecisionSideBar} title="Show decision" />;
}

const DiscussionItem = ({ decision, current_user, openDiscussionSideBar }) => {
  const messages = useMemo(() => collectChatMessages(decision), [decision]);
  if (isBlank(messages) && isVisitor(current_user)) return null;

  return <BaseHamburgerBtn onSelect={openDiscussionSideBar} title="Show comments" />
}

export const HamburgerDropdown = ({
                                    decision, current_user, showDecisionSet,
                                    openFinalDecisionSideBar, openDiscussionSideBar,
                                    ...opts
                                  }) => {
  const history = useHistory();
  const decisionObject = useMemo(() => new Decision(decision), [decision]);

  const processGoToHomepage = useCallback(redirectToHomepageCallback({
    current_user, history, ...opts
  }), [current_user])

  return <HamburgerMenuIconBtn>
    <DecisionItem {...{ decisionObject, openFinalDecisionSideBar }} />
    <RateCompareHamburgerButton hidden={decisionObject.isRateAndCompareDriverChoicesHidden} />
    <EnterDecisionButton />
    <DecisionTreeHamburgerButton title={"Edit decision tree"} />
    <DetailsHamburgerButton />
    { isPlaybookNotesPresent(decision) ? <PlaybookHamburgerButton /> : null }
    <DiscussionItem {...{ decision, current_user, openDiscussionSideBar }} />
    { showDecisionSet ? <DecisionSetPanelHamburgerButton /> : null }
    <HelpHamburgerButton />
    <BaseHamburgerBtn onSelect={processGoToHomepage} title="Go to homepage" />
  </HamburgerMenuIconBtn>
}

const mapStateToProps = ({ decision, share_data, current_user }) => ({
  decision, share_data, current_user
});
const mapDispatchToProps = (dispatch) => ({
  ...resetDispatch(dispatch),
  openFinalDecisionSideBar: () => {
    dispatch(setFinalDecisionSidebarOpen(true));
  },
  openDiscussionSideBar: () => {
    dispatch(setDiscussionPanelOpen(true));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(HamburgerDropdown);

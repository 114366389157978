import React from 'react';
import { connect } from "react-redux";
import PreviewDecisionCard from './cards/PreviewDecisionCard';
import PreviewDecisionWizardDriverCard from './cards/PreviewDecisionWizardDriverCard';
import { openModal } from "../../store/modals/actions";
import { treeInitData } from "./shared/helpers";
import { fetchContacts } from "../../tree_wizard/steps_wizard/steps/helpers/tree_builder_step";

const WizardPreviewTree = ({ tree, wizard, decision, user, contactsData, openModal, children = null }) => {
  const { decisionObject, rootDrivers } = treeInitData(tree, decision)
  const collaborators = fetchContacts(wizard, contactsData, true)

  const onDecisionClick = () => openModal({
    decision,
    drivers: tree.drivers,
    data_sources: tree.data_sources,
    slug: decision.slug,
    type: 'DecisionDetailsModal'
  })

  return <div className="container-fluid p-0">
    <div className={`px-2 my-2 mx-auto vertical-tree`}>
      <PreviewDecisionCard {... { decision, user, onDecisionClick, contactsData, decisionObject, collaborators } } />
      {
        rootDrivers.map(driverData =>
          <PreviewDecisionWizardDriverCard key={`template-wizard-driver-card-${driverData.driver.slug}`}
                                           {...{ driverData, decisionObject, tree, user, decision, openModal, collaborators } } />
        )
      }
      { children }
    </div>
  </div>
}
const mapStateToProps = ({ tree, wizard, decision, current_user, playbook_notes, contacts, channels }) => ({
  tree, decision, playbook_notes, channels, wizard,
  user: current_user,
  contactsData: contacts
});
export default connect(mapStateToProps, { openModal })(WizardPreviewTree);

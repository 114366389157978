import React, {useState} from 'react';
import { connect } from "react-redux";
import {isBlank, isPresent} from "../../../helpers/common";
import Button from 'react-bootstrap/Button';
import { decisionObjects, isNonUser } from "./helpers";
import RateBuyInModal from "../../modals/RateBuyInModal";
import Moment from "moment/moment";

const RenderRateBuyInButton = ({ decision, current_user }) => {
  const {decisionObject} = decisionObjects(decision)
  const [showRateBuyInModal, setShowRateBuyInModal] = useState(false)
  const current_date = Moment(new Date()).format('YYYY-MM-DD')
  const followup_due_date = decisionObject.lastFollowup.followup_due_date
  const userSupport = decisionObject.findSupportForUser(current_user)

  if (isNonUser(current_user)) return null;
  if (isBlank(decision) || !decision.rate_support) return null;
  if (!decisionObject.isDecisionRecorded || decisionObject.isDecisionWithResults) return null;
  if ((isPresent(followup_due_date) && (followup_due_date <= current_date))) return null;
  if (isPresent(userSupport.support)) return null;

  return <div>
    <Button onClick={() => setShowRateBuyInModal(true)} className="btn btn-primary w-100 d-none d-lg-flex align-items-center">
      Rate your level of support
    </Button>
    <div className="modals">
      <RateBuyInModal shown={showRateBuyInModal} onClose={() => setShowRateBuyInModal(false)} />
    </div>
  </div>
}
const mapStateToProps = ({ current_user, decision, sidebar }) => ({
  current_user, decision, sidebar
});
export default connect(mapStateToProps, null)(RenderRateBuyInButton);

import React from "react";
import {connect} from "react-redux";
import SlidingPanel from "react-sliding-side-panel";
import {isMobile} from "react-device-detect";
import PanelHeader from "../../tree_view/side_panel/PanelHeader";
import { setLargeScalePanelOpen } from "../../store/sidebar/actions";

const LargeScaleInput = ({ sidebar, openSideBar }) => {
return <SlidingPanel
  type={'left'}
  isOpen={sidebar.largeScaleInput}
  panelContainerClassName={`sidebar-panel-wrapper left-side-panel-position`}
  panelClassName={`${isMobile ? '' : 'd-flex flex-column'} sidebar-panel-wrapper-content large-scale-input-panel-content`}
  size={50}>
  <PanelHeader headerName={'Large-scale input'} openSideBar={openSideBar} preview={true} />
</SlidingPanel>
}
const mapStateToProps = ({ sidebar }) => ({ sidebar });
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => {
    dispatch(setLargeScalePanelOpen(options));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(LargeScaleInput);

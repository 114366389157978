import React from 'react';

export default ({ title=null, items=[], selectedItem, onClick, padding = 'px-4' }) =>
  <div className="d-inline-flex mw-fit-content">
    <h3>{title}</h3>
    <ul className="nav nav-pills nav-fill bg-light-gray text-dark user-notifiers rounded">
      {
        items.map(item =>
          <li key={item.value} className="nav-item">
            <a className={`nav-link text-dark ${selectedItem === item.value ? 'bg-white' : 'pointer'} ${padding}`} onClick={() => onClick(item.value)}>
              {item.body}
            </a>
          </li>
        )
      }
    </ul>
  </div>

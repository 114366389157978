import React from 'react';
import { connect } from "react-redux";
import ChoiceItem, {sortedRatedRecommendationChoices} from "./ChoiceItem";
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import Recommendation from "../../../../models/recommendation";
import { updateRecommendationFinalChoices } from "../../../../store/sidebar/actions";

const SortableItem = SortableElement(({choice}) =>
  <div className="choice-item">
    <ChoiceItem choice={choice} key={`ranked-choice-${choice.slug}`} selected={choice.final_decision} />
  </div>
);

const SortableList = SortableContainer(({choices}) =>
  <div>
    {choices.map((choice, index) => (
      <SortableItem key={`item-${choice.slug}`} index={index} choice={choice} />
    ))}
  </div>
);

const sortedRatedRecommendationRankedChoices = (decision, drivers) => {
  const recommendationObj = new Recommendation(decision.recommendation);
  const rankedChoices = recommendationObj.sortedChoices(true).filter(choice => choice.final_decision)
  const notRankedChoicesSorted = sortedRatedRecommendationChoices(decision, drivers).filter(choice => !choice.final_decision)
  return rankedChoices.concat(notRankedChoicesSorted)
}

const RankedChoices = ({decision, drivers, updateRecommendationFinalChoices}) => {
  const choices = sortedRatedRecommendationRankedChoices(decision, drivers);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const sortedChoices = arrayMoveImmutable(choices, oldIndex, newIndex).map((choice, index) => {
      return { slug: choice.slug, order_rank: index }
    });
    updateRecommendationFinalChoices(choices[oldIndex].slug, { sorted_choices: sortedChoices, resort: true })
  };
  return <SortableList choices={choices} onSortEnd={onSortEnd} helperClass="on-modal" distance={1} />;
};

const mapStateToProps = ({ sidebar, tree }) => ({ decision: sidebar.decision, drivers: tree.drivers });
export default connect(mapStateToProps, { updateRecommendationFinalChoices })(RankedChoices);

import React from "react";
import {connect} from "react-redux";
import {isBlank} from "../../../../ms_teams/helpers/common";
import UserAvatarImage from "../../../common/UserAvatarImage";
import { userName } from "../../../helpers/user_helpers";

const AssignmentSection = ({assignedUser, addClass = '', assignedCollaboratorEmail }) => {
  if (isBlank(assignedUser) && isBlank(assignedCollaboratorEmail)) return null
  const name = userName(assignedUser, assignedCollaboratorEmail)

  return <div className={`${addClass} mb-2`}>
    <div className="d-flex align-items-center">
      <UserAvatarImage user={assignedUser} pendingUserEmail={assignedCollaboratorEmail} />
      <div className="ms-2">
        Assigned to {name}
      </div>
    </div>
  </div>
}
const mapStateToProps = ({ tree }) =>
  ({ default_user_avatar_url: tree.default_user_avatar_url });

export default connect(mapStateToProps)(AssignmentSection);

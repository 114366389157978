import React, { useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import Recommendation from "../../../../models/recommendation";
import { isBlank, isPresent } from "../../../../helpers/common";
import {
  createRecommendationChoice,
  destroyRecommendationChoice,
  setRecommendationInputSidebarOpen,
  updateRecommendationChoice,
  updateRecommendationFinalChoices
} from "../../../../store/sidebar/actions";
import { RichTextEditor } from "../../../../common/RichTextEditor";
import { isDSightDecision } from "../../../../helpers/home_decision_helpers";
import { saveSideBarData } from "../../../../store/sidebar/common_actions";
import { isDoneClicked } from "../../../../helpers/sidebar_helpers";
import { focusToEnd } from "../../../../helpers/rich_text_helpers";

const OpenEndedChoices = ({ sidebar,
                            decision,
                            createRecommendationChoice,
                            updateRecommendationChoice,
                            destroyRecommendationChoice,
                            updateRecommendationFinalChoices,
                            setRecommendationInputSidebarOpen }) => {
  const recommendation = decision.recommendation;
  if (isBlank(recommendation)) return null;

  const recommendationObj = new Recommendation(recommendation);
  const openEndedChoices = recommendationObj.openEndedChoice;
  const previousOpenEndedChoice = openEndedChoices[0];
  const previousOpenEndedChoiceDescription = previousOpenEndedChoice?.description || ''
  const [loading, setLoading] = useState(true);
  const [openEndedChoice, setOpenEndedChoice] = useState(previousOpenEndedChoiceDescription);
  const focusElement = useRef(null);

  useEffect(() => {
    if(!loading && isPresent(focusElement.current)) focusToEnd(focusElement)
    if(!loading && !sidebar.recommendationInputSidebar) {
      return () => {
        onChangeOpenEndedChoice(_, true);
      }
    }
    setLoading(false)
  }, [sidebar.recommendationInputSidebar, focusElement.current])

  const sidebarSubmitted = () => isDoneClicked('recommendation-input')

  const onChangeOpenEndedChoice = (e, ignoreSidebarUpdate = true) => {
    if (openEndedChoice.trim() === previousOpenEndedChoiceDescription.trim()) return e;

    if(isBlank(openEndedChoice) && isPresent(previousOpenEndedChoice)) {
      destroyRecommendationChoice(previousOpenEndedChoice.slug, ignoreSidebarUpdate);
      if(isDSightDecision(decision)) setRecommendationInputSidebarOpen(false);
    } else if(isPresent(openEndedChoice) && isBlank(previousOpenEndedChoice)) {
      createRecommendationChoice({choice: {description: openEndedChoice, ignore_line_breaks: true, open_ended: true }}, updateRecommendationFinalChoices, ignoreSidebarUpdate, !sidebarSubmitted());
    } else if(isPresent(previousOpenEndedChoice)) {
      updateRecommendationChoice(previousOpenEndedChoice.slug, {choice: {description: openEndedChoice, ignore_line_breaks: true }}, updateRecommendationFinalChoices, ignoreSidebarUpdate);
    }
  };

  return <RichTextEditor quillId={'openEndedChoice'}
                         value={openEndedChoice}
                         setValue={setOpenEndedChoice}
                         onBlur={(e) => onChangeOpenEndedChoice(e, false)}
                         placeholder="Enter the recommendation"
                         ref={focusElement}
  />
};

const mapStateToProps = (state) => ({ decision: state.sidebar.decision, sidebar: state.sidebar });
export default connect(mapStateToProps, {
  createRecommendationChoice, updateRecommendationChoice, destroyRecommendationChoice, updateRecommendationFinalChoices,
  setRecommendationInputSidebarOpen, saveSideBarData
})(OpenEndedChoices);

import React, {Fragment, useMemo} from 'react';
import { connect } from "react-redux";
import {isDecider, isPublicVisitor} from "../../../helpers/user_helpers";
import Button from 'react-bootstrap/Button';
import ShareDropdown, { hideDecisionDuplicate, hideTemplateDuplicate } from "../../header/ShareDropdown";
import { deleteAndStartActions, mapStartDeleteDispatchToProps } from "../../header/helpers";
import {isDSightDecision} from "../../../helpers/home_decision_helpers";
import {isGuest} from "../../../helpers/home_helpers";
import DownloadPDF from "../../header/DownloadPDF";
import DownloadDOCX from "../../header/DownloadDOCX";

export const MoreActionsSection = ({ user, decision, current_org, isDetailsPanel, modal, ...opts }) => {
  const isDeciderUser = useMemo(() => isDecider(user), [user])
  if (!isDeciderUser && (isGuest(user) || isDSightDecision(decision))) return null;
  if (isPublicVisitor(user)) return null;
  if (current_org.slug !== user.org_slug) return null;

  const {
    deleteDecisionHandler, startDecision, startTemplate, onEditDecision, editDecisionTree, assignDriversTree
  } = deleteAndStartActions({
    decision,
    ...opts
  })

  return <Fragment>
    <div className={'p-3 border-bottom'}>
      <h3>More actions</h3>
      {
        isDeciderUser && <ShareDropdown dropdownClassName="sharing-button" isDetailsPanel={isDetailsPanel} title={"Share decision"}
                                       btnClassName="btn btn-secondary w-100 mb-2" btnId="share-details-dropdown" />
      }
      <Button onClick={onEditDecision} className="btn btn-secondary w-100 mb-2" hidden={isDSightDecision(decision) || !isDeciderUser}>
        Edit decision
      </Button>
      <Button onClick={editDecisionTree} className="btn btn-secondary w-100 mb-2" hidden={isDSightDecision(decision) || !isDeciderUser}>
        Edit decision tree
      </Button>
      <Button onClick={assignDriversTree} className="btn btn-secondary w-100 mb-2" hidden={isDSightDecision(decision) || !isDeciderUser}>
        Assign incomplete drivers
      </Button>
      {
        !hideDecisionDuplicate(decision, user) && !isDeciderUser &&
          <Fragment>
            <DownloadPDF isButton={true} />
            <DownloadDOCX isButton={true} />
          </Fragment>
      }
      <Button onClick={startDecision} className="btn btn-secondary w-100 mb-2" hidden={hideDecisionDuplicate(decision, user)}>
        Duplicate as a new decision
      </Button>
      <Button onClick={startTemplate} className="btn btn-secondary w-100 mb-2" hidden={hideTemplateDuplicate(decision, user)}>
        Duplicate as a template
      </Button>
      <Button onClick={deleteDecisionHandler} hidden={!isDeciderUser} className="btn-light text-danger w-100 mb-0">
        Delete decision
      </Button>
    </div>
  </Fragment>
}
const mapStateToProps = ({ current_user, decision, modal, current_org }) => ({ user: current_user, decision, modal, current_org });
export default connect(mapStateToProps, mapStartDeleteDispatchToProps)(MoreActionsSection);

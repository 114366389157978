import React, { Fragment } from "react";
import { connect } from "react-redux";
import AlertsSection from "../alerts";
import SidebarTreeNavigationTemplateSet from "../template_set_view/navigation";
import SetHeader from "./header";
import DecisionSetModal, { showDecisionSetModal } from "../homepage/modals/DecisionSetModal";
import { closeModal } from "../store/modals/actions";
import { loadTemplateSet } from "../store/template_set/actions";
import { updateTemplateSetData } from "../store/template_set/common_actions";
import TemplatesSection from "../template_set_view/TemplatesSection";
import TemplateSetDetailsPanel from "../template_set_view/side_panel/TemplateSetDetailsPanel";
import DecisionOrderPanel from "../template_set_view/side_panel/DecisionOrderPanel";
import { resetTemplate } from "../store/template/common_actions";
import { checkLoadingTemplateSet } from "../template_set_view";
import { setDecisionOrderSidebarOpen } from "../store/sidebar/actions";
import { resetTree } from "../store/tree/common_actions";

const Sidebars = () =>
  <Fragment>
    <div className="sidebars">
      <TemplateSetDetailsPanel preview={true} />
      <DecisionOrderPanel addClass="left-side-panel-position tree-page" preview={true} />
    </div>
  </Fragment>

const Wrapper = ({ children, modal, closeModal }) =>
  <Fragment>
    <AlertsSection/>
    <SetHeader />
    <div className="d-flex">
      <SidebarTreeNavigationTemplateSet isDecisionSet={true} preview={true} />
      {children}
    </div>
    <Sidebars/>
    <div className="modals">
      <DecisionSetModal shown={showDecisionSetModal(modal)} onClose={closeModal} />
    </div>
  </Fragment>

const TemplateSetPreview = ({ template_set, ...opts }) => {
  checkLoadingTemplateSet({ ...opts, template_set })

  return <Wrapper {...opts}>
    <div className="w-100 wrapper-container width-fit-content">
      <div className="home container mt-3">
        <TemplatesSection preview={true} />
      </div>
    </div>
  </Wrapper>
}
const mapStateToProps = ({ home, template_set, template, modal }) => ({ home, template_set, template, modal });
const mapDispatchToProps = (dispatch) => ({
  closeDecisionOrderSideBar: () => dispatch(setDecisionOrderSidebarOpen(false)),
  loadTemplateSet: () => dispatch(loadTemplateSet()),
  resetTemplate: () => dispatch(resetTemplate()),
  resetTree: () => dispatch(resetTree()),
  updateTemplateSetData: (data) => { dispatch(updateTemplateSetData(data)) },
  closeModal: () => { dispatch(closeModal()) }
});
export default connect(mapStateToProps, mapDispatchToProps)(TemplateSetPreview);

import React from 'react';
import {View, Text, Link, Svg, Circle} from '@react-pdf/renderer';
import styles from '../../styles/common'
import * as moment from "moment";
import {currencyFormat, isBlank, isPresent, pluralize, qSortArray, sortSourceByType} from "../../../helpers/common";
import Decision from "../../../models/decision";
import {pdfTextParser } from "../../helpers/common";
import {
  AnswerBlock, DataSourceBlock, DataSourceImage, NotAnswerBlock, QuestionBlock
} from "./KeyDriverPage";
import { MIN_DECISION_OTHER_CHOICES_COUNT } from "../../../tree_view/side_panel/sections/OtherChoicesSection";
import Analysis from "../../../models/d_sight/Analysis";
import { Html } from "react-pdf-html";
import { isDSightDecision } from "../../../helpers/home_decision_helpers";
import {ChoiceWeights} from "../../../models/DriverChoiceRating";
import {SectionBlockRow} from "./DecisionDetailsPage";
import DataSource, {PDF_IMAGE_FILE_TYPES} from "../../../models/data_source";
import {BlockSeparate} from "./Cover";
import {modifyRichText, quillIndentStyles} from "../../helpers/rich_text";
import Driver from "../../../models/driver";
import { userName } from "../../../helpers/user_helpers";

export const baseBlockMargin = [styles.mb3]

export const BaseDecisionSection = ({ sectionName, sectionText }) =>
  <View style={baseBlockMargin}>
    <Text style={[styles.h2]}>{sectionName}</Text>
    <View style={[styles.text]}>
      <Html stylesheet={quillIndentStyles()} style={[styles.text]}>{modifyRichText(sectionText)}</Html>
    </View>
  </View>

export const RankedDecisionBlock = ({ decisionObj }) =>
  <View style={[...baseBlockMargin]}>
    { decisionObj.finalDecisions.map((c, index) =>
      <View style={[styles.text, styles.mt1]} key={`ranked-choice-${c.slug}`}>
        {pdfTextParser(`${index+1}. ${c.description}`)}
      </View>
    )}
  </View>

export const PickyDecisionBlock = ({ decisionObj }) =>
  <View style={[...baseBlockMargin]}>
    { decisionObj.finalDecisions.map(c =>
      <View style={[styles.text]} key={`picky-choice-${c.slug}`}>
        {pdfTextParser(`${c.description}`, true)}
      </View>
    )}
  </View>

export const FinalDecisionBlock = ({ decisionObj }) => {
  if (isBlank(decisionObj.finalDecisions)) return null;

  if (decisionObj.isRankedList) return <RankedDecisionBlock decisionObj={decisionObj} />
  if (decisionObj.isPicky) return <PickyDecisionBlock decisionObj={decisionObj} />

  return <View style={[styles.text, baseBlockMargin]}>
      <Html stylesheet={quillIndentStyles()} style={[styles.text]}>{modifyRichText(decisionObj.finalDecisions.map(c => c.description).join(', '))}</Html>
  </View>
}
const ChoiceItem = ({choice}) =>
  <View style={[styles.text, {marginBottom: 4.5}]}>
    {pdfTextParser(`${choice.description}`, true)}
  </View>

export const OtherChoicesBlock = ({ obj, isRecommendation = false }) => {
  if(obj.isDecisionRecorded && isRecommendation) return null;

  const otherConsideredChoices = isRecommendation ? obj.recommendation.considered_choices : obj.decision.considered_choices
  const Header = () => <Text style={styles.h2}>Other choices considered</Text>

  if (obj.isOpenEnded && isPresent(otherConsideredChoices)) {
    return <View style={[...baseBlockMargin]}>
      <Header />
      { otherConsideredChoices.map(choice => <ChoiceItem choice={choice} key={`choice-${choice.slug}`} />) }
    </View>;
  }

  if (obj.isOpenEnded || isBlank(obj.otherChoices)) return null;
  if (obj.otherChoices.length < MIN_DECISION_OTHER_CHOICES_COUNT) return null;

  return <View style={[...baseBlockMargin]}>
    <Header />
    { obj.otherChoices.map(choice => <ChoiceItem choice={choice} key={`choice-${choice.slug}`} />) }
  </View>;
}

export const RationaleBlock = ({decision}) => {
    if (isBlank(decision.final_decision_reasons)) return null;

    return <BaseDecisionSection sectionName={'Rationale'} sectionText={decision.final_decision_reasons} />
}
export const NextStepsDescriptionBlock = ({decision}) => {
  if (isBlank(decision.next_steps_description)) return null;

  return <BaseDecisionSection sectionName={'Next steps'} sectionText={decision.next_steps_description} />
}
export const ExpectedResultsBlock = ({decision}) => {
  if (isBlank(decision.expected_results)) return null;

  return <BaseDecisionSection sectionName={'Expected results'} sectionText={decision.expected_results} />
}
export const ExpectedOpportunityBlock = ({ object }) => {
  const expectedOpportunity = object.expected_opportunity
  if (isBlank(expectedOpportunity)) return null;

  return <BaseDecisionSection sectionName={'Expected opportunity value'} sectionText={currencyFormat(expectedOpportunity)} />
}
export const ExpectedInvestmentBlock = ({ object }) => {
  const expectedInvestment = object.expected_investment
  if (isBlank(expectedInvestment)) return null;

  return <BaseDecisionSection sectionName={'Expected investment or cost'} sectionText={currencyFormat(expectedInvestment)} />
}
const DecisionHistoricalDate = ({ decision }) => {
  if (isBlank(decision.historical_decided_at)) return null;

  return <BaseDecisionSection sectionName={'Decision Date'}
                              sectionText={moment(decision.historical_decided_at).format('DD MMM, yyyy')} />
}
const DeciderBlock = ({ decision }) =>
  <View style={[styles.row, baseBlockMargin]}>
    <Text style={[styles.text, styles.muted]}>
      Entered by {userName(decision.deciding_user)}&nbsp;{moment(decision.decided_at).format('DD MMM, yyyy')}
    </Text>
  </View>

const AverageBuyIn = ({ decisionObj }) => {
  if (!decisionObj.isDisplayBuyIn) return null;

  return <View style={[...baseBlockMargin]}>
    <Text style={[styles.h2]}>Buy-in</Text>
    <View style={[styles.row]}>
      <Text style={styles.text}>{decisionObj.avgSupport}&nbsp;out of 5&nbsp;</Text>
      <Text style={[styles.text, styles.muted]}>&nbsp;({pluralize(decisionObj.userSupports.length, 'rating')})</Text>
    </View>
  </View>
}

const KeyDriverBlock = ({ driver, index = 0, isLast=false }) => {
  if (isBlank(driver) || isBlank(driver.question)) return null;

  const driverObj = new Driver(driver);

  return <View>
    <QuestionBlock driver={driver} subStyles={[styles.h3]} />
    {
      driverObj.isAnswered || isPresent(driver.answer) ?
      <AnswerBlock {...{driver, isLast}} /> :
      <NotAnswerBlock {...{driver, isLast}} />
    }
  </View>
}
export const KeyDriversBlock = ({drivers, decision, subStyles = []}) => {
  const decisionObj = new Decision(decision);
  if (isBlank(drivers) || !decisionObj.isRateAndCompareDriverChoicesHidden) return null;

  return <View style={[...subStyles]}>
    <SectionBlockRow header={'Key drivers'} >
      {drivers.map((d, index) => <KeyDriverBlock {...d} isLast={drivers.length===(index+1)} index={index} key={`driver-block-${d.driver.slug}`} />)}
      </SectionBlockRow>
  </View>
}
export const WeightedRatingChoicesBlock = ({ drivers, decision }) => {
  const decisionObj = new Decision(decision, drivers);
  if (decisionObj.sortedChoicesOrRecommendations(true).length === 0) return null;
  if (decisionObj.isRateAndCompareDriverChoicesHidden) return null;

  const keyDrivers =  decisionObj.keyDrivers

  return <SectionBlockRow header='Weighted rating of choices'>
    {
      decisionObj.sortedRatedChoices.map((choice, index) =>
        <WeightedRatingChoice choice={choice} index={index} keyDrivers={keyDrivers} key={`choice-row-${choice.slug}`} />
      )
    }
  </SectionBlockRow>
}
export const WeightedRatingChoice = ({ choice, keyDrivers, index = 0 }) => {
  const obj = new ChoiceWeights(choice, keyDrivers)

  return <View style={[index === 0 ? '' : styles.mt1, styles.row, {maxWidth: '100%'}]}>
    <Text style={{maxWidth: '90%'}}> {choice.description} </Text>
    <Text style={[{marginLeft: 'auto'}, {marginRight: 0}]}> {obj.weightedRatingScore} </Text>
  </View>
}
export const DriverWeightsBlock = ({ drivers, decision }) => {
  const decisionObj = new Decision(decision, drivers)
  const keyDrivers =  decisionObj.keyDrivers
  if (isBlank(drivers) || decisionObj.isRateAndCompareDriverChoicesHidden || keyDrivers.length === 0) return null;

  return <SectionBlockRow header={'Driver weights'}>
    {
      keyDrivers.map(driver => <DriverWeight driver={driver} key={`driver-row-${driver.driver.slug}`} />)
    }
  </SectionBlockRow>
}
const DriverWeight = ({ driver}) => {
  if (isBlank(driver.question)) return null;

  return <View style={[styles.row, {maxWidth: '100%'}]}>
    <Link src={`#${driver.slug}`} style={[{maxWidth: '90%'}, styles.linkText, {lineHeight: 1.5}]}>
      <Text> {driver.question} </Text>
    </Link>
    <Text style={[{marginLeft: 'auto'}, {marginRight: 0}]}> {`${driver.weightPercentage}%`} </Text>
  </View>
}
export const DSightAnswerBlock = ({ insightsData }) => {
  if (isBlank(insightsData.answerInsight)) return null;

  return <View>
    <Text>{insightsData.answer}</Text>
  </View>
}

export const DSightInsightsBlock = ({ insightsData }) => {
  if (isBlank(insightsData.orderedInsights())) return null;

  return <View>
    {insightsData.orderedInsights().map(insight => <DSightInsightBlock insight={insight} />)}
  </View>
}

export const DSightInsightBlock = ({ insight }) => {
  if (isBlank(insight)) return null;

  return <View style={[styles.row, styles.mt1, styles.text]}>
    <Text>•</Text>
    <Text style={styles.list}>{insight.insight}</Text>
  </View>
}

export const DSightAnalysisBlock = ({ decision }) => {
  if (!isDSightDecision(decision)) return null;

  const insightsData = new Analysis(decision.d_sight).insightData

  return <View style={baseBlockMargin}>
    <BlockSeparate viewStyles = {[styles.mb3]} />
    <Text style={[styles.h2]}>D-Sight analysis</Text>
    <Text style={[styles.h3]}>{decision.description}</Text>
    <DSightAnswerBlock insightsData={insightsData} />
    <DSightInsightsBlock insightsData={insightsData} />
  </View>
}

export const DataSourcesBlockSection = ({data_sources, fromRecommendation= false}) => {
  const dataSources = fromRecommendation ? data_sources.filter((source) => source.hide_in_recommendation_panel === false) :
                                           data_sources.filter((source) => source.hide_in_decision_panel === false)

  if (isBlank(dataSources)) return null;
  const sortedDataSources = sortSourceByType(dataSources, PDF_IMAGE_FILE_TYPES)

  return <View style={baseBlockMargin}>
    <Text style={[styles.h2]}>Data Sources</Text>
    {sortedDataSources.map((data_source, index) => {
      const sourceObj = new DataSource(data_source);
      return sourceObj.isPdfImage ?
        <DataSourceImage data_source={data_source} key={`data-source-${data_source.slug}-${data_source.key}`} isLast={sortedDataSources.length - 1 === index }/>:
        <DataSourceBlock data_source={data_source} index={index} key={`data-source-${data_source.slug}-${data_source.key}`} />
    })}
  </View>
}

const DecisionBlockSection = ({ decision, data_sources, drivers = [], subStyles = [] }) => {
  const decisionObj = new Decision(decision, drivers)
  if(!decisionObj.isDecisionRecorded) return null;

  return <View style={isPresent(decision.collaboration_context) ? '' : [...subStyles]}>
    <SectionBlockRow header={'What was decided'} blockSeparateMarginTop={false}>
      <FinalDecisionBlock decisionObj={decisionObj} />
      <RationaleBlock decision={decision} />
      <NextStepsDescriptionBlock decision={decision} />
      <ExpectedResultsBlock decision={decision} />
      <ExpectedOpportunityBlock object={decision} />
      <ExpectedInvestmentBlock object={decision} />
      <DecisionHistoricalDate decision={decision} />
      <DeciderBlock decision={decision} />
      <DSightAnalysisBlock decision={decision} />
      <OtherChoicesBlock obj={decisionObj} />
      <AverageBuyIn decisionObj={decisionObj} displayStyles={isBlank(data_sources)}/>
      <DataSourcesBlockSection data_sources={data_sources}/>
    </SectionBlockRow>
  </View>
}
export default DecisionBlockSection;

import React, {useRef, useState} from "react";
import {generateBatchDataSourceUploadState} from "../../helpers/uploads_callbacks";
import {DataSourcesList, UploadingSourcesList} from "../../template_view/side_panel/sections/DataSourcesSection";
import {AddLinkRow} from "../../tree_view/data_sources";
import Button from "react-bootstrap/Button";
import StyledDropzone from "../../tree_view/data_sources/DropFiles";
import AccordionWrapper from "../../common/AccordionWrapper";
import {connect} from "react-redux";
import {
  createSourceQuickDriver, destroySourceQuickDriver, updateSourceQuickDriver
} from "../../store/quick_driver_entry_page/actions";

const DriverSourceSection = ({
                               driver, current_user, createSourceQuickDriver,
                               destroySourceQuickDriver, updateSourceQuickDriver
                             }) => {
  const [dataSources, setDataSources] = useState([]);
  const [uploadingSources, setUploadingSources] = useState([]);
  const [showDropzone, setShowDropzone] = useState(false);
  const focusDropzone = useRef(null);

  const saveUploadingSources = (sources) => setUploadingSources(sources)
  const uploadOptions = generateBatchDataSourceUploadState(dataSources, uploadingSources, saveUploadingSources, current_user)

  const onSuccessCallback = (callback = () => {}, justCreated = {}) => (success, { data_source }) => {
    if (success) {
      callback(success)
      const dataSource = {...data_source, ...justCreated}
      setDataSources(prev => [...prev, dataSource])
    }
  }

  const submitLink = (value, callback) => {
    let createParams = {
      decision_data_source: {
        link_url: value,
        added_by_email: current_user.email
      }
    };
    createParams.driver_slug = driver.driver.slug
    const justCreated = {just_created: true}
    createSourceQuickDriver(createParams, {}, onSuccessCallback(callback, justCreated));
  }

  const onUpload = (createParams, config, callback) => {
    createSourceQuickDriver(createParams, config, onSuccessCallback(callback, {}));
  }

  const onRemoveSource = (slug) => {
    const callback = (success) => {
      if (success) {
        const newSources = dataSources.filter(source => source.slug !== slug)
        setDataSources(newSources)
      }
    }
    destroySourceQuickDriver(slug, {}, callback)
  }
  const onUpdateSource = (slug, data) => {
    const callback = (success, data_source) => {
      if (success) {
        setDataSources((prevData) =>
          prevData.map((item) => (item.slug === data_source.slug ? data_source : item))
        );
      }
    }
    updateSourceQuickDriver(slug, data, callback)
  }

  const accordionObject = {
    header: <h3 className='mb-0'>Provide data sources</h3>,
    body: <>
      <div className="mb-0">
        <ul className="list-group">
          <DataSourcesList driver={driver.driver} data_sources={dataSources}
                           destroySource={onRemoveSource} fromDriver={true} user={current_user}
                           updateSource={onUpdateSource}
                           isTemplate={false}
                           {...uploadOptions} />
          <UploadingSourcesList uploading_sources={uploadingSources} user={current_user} isTemplate={false} {...uploadOptions} />
        </ul>
      </div>
      <AddLinkRow onClick={submitLink} />
      <Button className="btn btn-secondary w-100 mt-2 mb-1"
              onClick={() => setShowDropzone(true)}
              hidden={showDropzone}>
        Add files
      </Button>
      <div className={`mt-2 mb-1`} hidden={!showDropzone} ref={focusDropzone}>
        <StyledDropzone onCreateSource={onUpload}
                        driver={driver.driver}
                        onStartUpload={uploadOptions.onStartUpload}
                        onFinishUpload={uploadOptions.onFinishUpload}
                        onProcessUpload={uploadOptions.onProcessUpload} />
      </div>
    </>,
    defaultExpand: false
  };

  return <AccordionWrapper accordionObject={accordionObject} className='px-3' />
}

export default connect(null, {createSourceQuickDriver, destroySourceQuickDriver, updateSourceQuickDriver})(DriverSourceSection);

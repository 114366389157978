import React from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import HamburgerDropdown, {
  CommonHamburgerItems,
  mapDispatchToProps
} from './HamburgerDropdown'
import { redirectToHomeCallback } from "../../helpers/decision_helpers";
import { successActions } from "../../helpers/common";
import { IconDropdown } from "../../common/dropdowns";
import OrgLogo from "../../tree_view/header/OrgLogo";

const TemplateViewHeader = ({
                              tree, template, current_org,
                              resetSidebars, resetTree, resetTemplate, resetPlaybookNotes, setTreeSidebarOpen,
                              ...opts
                            }) => {
  const { error } = tree;
  if (error || !template.loaded) return <nav className="navbar"/>;

  const history = useHistory();
  const processBackClick = () => {
    successActions(true, [resetSidebars, resetTree, resetTemplate, resetPlaybookNotes]);
    redirectToHomeCallback(true, history);
  }

  return <header className="decision-tree-header">
    <nav className="navbar navbar-expand bd-navbar px-3">
      <div className="d-flex align-items-center active">
        <div className="logo-dropdown pointer d-none d-lg-flex me-2">
          <OrgLogo current_org={current_org} processBackClick={processBackClick} />
        </div>
        <h5>Template</h5>
      </div>
      <div className="navbar-nav ms-auto">
        <div className='d-lg-none'>
          <HamburgerDropdown {...opts} />
        </div>
        <ul className="navbar-nav ms-auto d-none d-lg-flex align-items-center">
          <li className={`nav-item ms-2`}>
            <IconDropdown bsPrefix="h5 m-0 btn-link pt-2" className="btn btn-secondary btn-lg-round d-none d-lg-flex p-0">
              <CommonHamburgerItems template={template} {...opts}
                                    resetTemplate={resetTemplate} resetPlaybookNotes={resetPlaybookNotes}
                                    resetSidebars={resetSidebars} resetTree={resetTree}
                                    setTreeSidebarOpen={setTreeSidebarOpen} />
            </IconDropdown>
          </li>
        </ul>
      </div>
    </nav>
  </header>;
}

const mapStateToProps = ({ tree, current_user, current_org, template }) => ({
  tree, current_user, template, current_org
});
export default connect(mapStateToProps, mapDispatchToProps)(TemplateViewHeader);

import React from 'react';
import { setDiscussionPanelOpen } from "../../store/sidebar/actions";
import { connect } from "react-redux";
import { isBlank } from "../../helpers/common";
import { isVisitor } from "../../helpers/user_helpers";
import { collectFeedbacksAndDriverComments } from "../../helpers/discussion_helpers";

export const CommentsItem = ({ decision, current_user, sidebar, openDiscussionSideBar}) => {
  const messages = collectFeedbacksAndDriverComments(decision);
  if (isBlank(messages) && isVisitor(current_user))  return null

  return <li className={sidebar.discussionSidebar ? 'active' : ''}>
    <div className="side-row text-nowrap pointer" onClick={() => openDiscussionSideBar(!sidebar.discussionSidebar)}>
      <span className="icon d-inline-block text-center">
        <i className="fas fa-comment fa-lg" />
      </span>
      <span className="d-none d-lg-inline tab-title">Comments</span>
      <div className="rounded-pill badge-pill-char comments me-2" hidden={messages.length < 1}>{messages.length}</div>
    </div>
  </li>
}

const mapStateToProps = ({ decision, current_user, sidebar }) => ({ decision, current_user, sidebar });
const mapDispatchToProps = (dispatch) => ({
  openDiscussionSideBar: (flag) => { dispatch(setDiscussionPanelOpen(flag)) }
});
export default connect(mapStateToProps, mapDispatchToProps)(CommentsItem);

import React, { useMemo } from "react"
import SectionWrapper from "../../tree_view/side_panel/sections/SectionWrapper";
import EntryPoint from "../../EntryPoint";

const WAITING_SLACK_TEXT = 'Cloverpop is waiting for you in Slack!' ;
const ADD_APP_TEXT = 'Add the Cloverpop app to Slack';

export default () => {
  const { instance } = EntryPoint;
  const href = useMemo(() => `https://slack.com/oauth/v2/authorize?client_id=${instance.slack_bone_client_id}&scope=${instance.slack_bone_bot_user_scopes}&user_scope=${instance.slack_bone_user_scope}`, [])

  return <div className="container-fluid">
    <div className={`px-3 px-md-0 my-3 mx-auto vertical-tree sign_in`}>
      <SectionWrapper>
        <div className='m-3 text-center'>
          <h1 className='mb-2'>{WAITING_SLACK_TEXT}</h1>
          <img className=""
               src="https://s3-eu-west-1.amazonaws.com/com.doodle.wp.assets.prod/uploads/2018/10/28124746/party_popper.png"
               alt="" width="205" height="204"/>
          <a className='btn btn-primary w-100' href={href}>
            {ADD_APP_TEXT}
          </a>
        </div>
      </SectionWrapper>
    </div>
  </div>
}

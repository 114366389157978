import React from 'react';
import Recommendation from "../../../models/recommendation";
import { isBlank } from "../../../helpers/common";
import TopChoice from "./choices/TopChoice";
import RankedChoices from "./choices/RankedChoices";
import PickyChoices from "./choices/PickyChoices";
import OpenEndedChoices from "./choices/OpenEndedChoices";

const ChoicesSection = ({ decision }) => {
  if (isBlank(decision?.recommendation)) return null;

  const recommendationObj = new Recommendation(decision.recommendation);

  if(recommendationObj.isRankedList) {
    return <RankedChoices />
  } else if(recommendationObj.isPicky) {
    return <PickyChoices />
  } else if(recommendationObj.isTopChoice) {
    return <TopChoice />
  } else if (recommendationObj.isOpenEnded) {
    return <OpenEndedChoices />
  } {
    return null;
  }
}
export default ChoicesSection;

import React, {useMemo} from "react";
import {connect} from "react-redux";
import { HowToMakeDecisionPBSection } from "../../../template_view/side_panel/sections/PlaybookNotesSection";
import AssignmentSection from "./../sections/AssignmentSection";
import {isBlank, isPresent} from "../../../helpers/common";
import Decision from "../../../models/decision";

const AssignedToSection = ({sidebar, playbook_notes}) => {
  if(isBlank(sidebar.decision)) return null;

  const decisionObj = new Decision(sidebar.decision);
  const assignedUser = useMemo(() => decisionObj.recommendationAssignedUser, [sidebar.decision])
  const assignedCollaboratorEmail = useMemo(() => decisionObj.recommendation.assignedCollaboratorUser, [sidebar.decision])

  return <div className={`px-3 ${isPresent(playbook_notes.how_to) || isPresent(assignedUser) || isPresent(assignedCollaboratorEmail) ? 'border-bottom mb-3' : ''}`}>
    <AssignmentSection assignedUser={assignedUser} assignedCollaboratorEmail={assignedCollaboratorEmail} />
    <HowToMakeDecisionPBSection playbook_notes={playbook_notes} showNA={false} isDecision={true}/>
  </div>
}

const mapStateToProps = ({sidebar, playbook_notes }) => ({ sidebar, playbook_notes });
export default connect(mapStateToProps, {})(AssignedToSection);

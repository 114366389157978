import {RichTextEditor} from "../../common/RichTextEditor";
import {DETAILS_PLACEHOLDER} from "../../tree_view/side_panel/driver_input/DetailsInput";
import AccordionWrapper from "../../common/AccordionWrapper";
import React from "react";

const DetailsInput = ({ driver, onChangeDriver }) => {
  const {explanation, slug} = driver

  const accordionObject = {
    header: <h3>Add details</h3>,
    body: <RichTextEditor quillId={`details-input-${slug}`} className="mb-2"
                          value={explanation || ''}
                          setValue={newValue => onChangeDriver(newValue, 'explanation')}
                          placeholder={DETAILS_PLACEHOLDER} />,
    defaultExpand: false
  };

  return <AccordionWrapper accordionObject={accordionObject} className='mb-2 px-3' />
}

export default DetailsInput

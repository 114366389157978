import React from 'react';
import { TooltipWrapper } from "../../../bubble_tooltip/BubbleTooltip";

const Icon = () => <div className="d-flex text-light-gray">
  <i className="fa-duotone fa-circle square-24" />
</div>

export default ({ driverObject }) => {
  return <TooltipWrapper bubbleIcon={`wizard-driver-status-${driverObject.driver.slug}`} tooltipText={'Unstarted'} isDriver={true}>
    <div className="ms-1 mt-1">
      <Icon />
    </div>
  </TooltipWrapper>
}

import React from 'react'
import { connect } from "react-redux";
import { DoneBtnW100 } from "../../common/DoneButton";
import { isMobile } from "react-device-detect";
import ZTree from "./ZTree";
import { Loader } from "../../common/Loader";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { reorderDecisions } from "../../store/decision_set/actions";
import { reorderTemplates } from "../../store/template_set/actions";
import AssignDecisionModal, {showAssignDecisionModal} from "../modals/AssignDecisionModal";
import { openModal, closeModal } from "../../store/modals/actions";

const Footer = ({ onClose }) =>
  <div className="row">
    <div className="col-12">
      <DoneBtnW100 onClose={onClose} />
    </div>
  </div>

const dragDecisionAction = (isTemplateSet, reorderDecisions, reorderTemplates) =>
  isTemplateSet ? reorderTemplates : reorderDecisions

const PanelTreeEditor = ({
                           set,
                           modal,
                           preview = false,
                           isTemplateSet = false,
                           isDSight= false,
                           assignDecisions = false,
                           allowGuest = true,
                           onClose,
                           reorderDecisions,
                           reorderTemplates,
                           openNodal,
                           transferDataAndCloseModal,
                           closeModal,
                           ...opts
                        }) => {
  if(!set.loaded) return <Loader />;

  const history = useHistory();

  const onAddDecision = () => {
    onClose();
    if(isTemplateSet) {
      history.push(`/template_sets/${set.slug}/add_template`)
    } else {
      history.push(`/decision_sets/${set.slug}/add_decision`)
    }
  }

  return (
    <div className="pt-0 p-3">
      <div className="tree-container mb-2">
        <div className={`tree-body ${assignDecisions ? 'assign-decisions-mode' : 'edit-mode'}`}>
          <ZTree {...{ set, isTemplateSet, preview, isDSight, assignDecisions }}
                 zTreeId={assignDecisions ? 'decisionTreeAssignments' : 'decisionTree'}
                 dragDecisionAction={dragDecisionAction(isTemplateSet, reorderDecisions, reorderTemplates)} {...opts} />
        </div>
      </div>
      <div className={`${(isMobile ? "footer-done-space" : "")}`}>
        <div hidden={assignDecisions} className="row mb-2">
          <div className="col-12">
            <Button onClick={onAddDecision} className="btn-secondary w-100">Add a {isTemplateSet ? 'template' : 'decision'}</Button>
          </div>
        </div>
        <Footer onClose={onClose} />
      </div>
      <div className="modals">
        <AssignDecisionModal key={'set-sections-add-section'}
                             isTemplateSet={isTemplateSet}
                             allowGuest={allowGuest}
                             show={showAssignDecisionModal(modal)}
                             allowPending={!assignDecisions}
                             onClose={closeModal} />
      </div>
    </div>
  )
}
const mapStateToProps = ({ modal, decision_set, template_set }, { isTemplateSet }) => ({
  modal, set: isTemplateSet ? template_set : decision_set
});
export default connect(mapStateToProps, {
  reorderDecisions, reorderTemplates, openModal, closeModal
})(PanelTreeEditor);

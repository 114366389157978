import React, {useEffect, useMemo, useRef, useState} from 'react';
import { connect } from "react-redux";
import {
  isHomepageSectionLoaded,
  isHomepageSectionLoading,
  loadDecisionSets,
  needToLoadHomepageSection
} from "../../../store/homepage/actions";
import {isBlank, paginateArray, qSortArray} from "../../../helpers/common";
import { Loader } from "../../../common/Loader";
import DecisionSetTile from "../tiles/DecisionSetTile";
import DecisionSet from "../../../models/decision_set"
import {Pagination} from "../../../common/Pagination";
import {refreshPaginationCallback, TABS_PER_PAGE} from "./AssignedDriversSection";
import {scrollTopSection} from "./DecisionsSection";

const DecisionsSetsSection = ({ home, loadDecisionSets }) => {
  useEffect(() => {
    if (needToLoadHomepageSection(home, 'decision_sets')) {
      loadDecisionSets()
    }
  }, [home.decision_sets.loaded])

  const inFlightSets = home.decision_sets.data.filter((decision_set) => new DecisionSet(decision_set).isInFlight)
  const tiles = qSortArray(inFlightSets,false, d => d.created_at)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const refHeader = useRef(null)

  const recordsPerPage = useMemo(() => paginateArray(tiles, page, TABS_PER_PAGE), [page, tiles])

  refreshPaginationCallback(tiles, setTotalPages, page, setPage)

  if (isBlank(tiles) && home.decision_sets.loaded) return null;

  return <div>
    <div className="row scroll-margin-tab" ref={refHeader}>
      <div className="col">
        <h1>In-flight decision flow</h1>
      </div>
    </div>
    <div className="row" hidden={isHomepageSectionLoading(home, 'decision_sets')}>
      {
        recordsPerPage.map((t) =>
          <DecisionSetTile decision_set={t} key={`decisions-center-decision-set-tile-${t.slug}`} />
        )
      }
    </div>
    <div className="row" hidden={isHomepageSectionLoaded(home, 'decision_sets')}>
      <Loader />
    </div>
    <div className="row mb-3" hidden={home.decision_sets.loading || totalPages < 2} onClick={() => scrollTopSection(refHeader)}>
      <Pagination page={page} totalPages={totalPages} setPage={setPage} />
    </div>
  </div>
}
const mapStateToProps = ({ home }) => ({ home });
export default connect(mapStateToProps, { loadDecisionSets })(DecisionsSetsSection);

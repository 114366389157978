import React, { useEffect, useMemo, useState } from 'react';
import { connect } from "react-redux";
import { convertBase64, isBlank, isPresent } from "../helpers/common";
import Button from 'react-bootstrap/Button';
import { IMAGE_FILE_TYPES } from "../models/data_source";
import { deleteAccount, updateProfile } from "../store/homepage/actions";
import Alerts from "../../js/alerts";
import EntryPoint from "../EntryPoint";
import Warning from "./warning"
import UserAvatarImage, { isDefaultAvatarUrl } from "../common/UserAvatarImage";

export const MAX_FILE_SIZE = 2097152;

const NOTIFIERS = {
  email: 'email',
  slack: 'slack',
  teams: 'teams'
}


export const UserProfile = ({
                              current_user, home,
                              updateProfile, deleteAccount,
                              current_org
                            }) => {
  const [firstName, setFirstName] = useState(current_user.first_name)
  const [lastName, setLastName] = useState(current_user.last_name)
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState({})
  const [successUpdate, setSuccessUpdate] = useState(false)
  const [warningType, setWarningType] = useState('')
  const [notifier, setNotifier] = useState(current_user.notifier)
  const [avatarUrl, setAvatarUrl] = useState(isPresent(current_user.large_avatar_url) ? current_user.large_avatar_url : home.default_user_avatar_url)
  const [uploadedAvatar, setUploadedAvatar] = useState(null)
  const [submit, setSubmit] = useState(false)

  useEffect(() => {
    const {instance} = EntryPoint
    setWarningType(instance.flash['warning_type'])
    setTimeout(() => {
      if (isPresent(instance.flash['warning_type'])) {
        delete instance.flash['warning'];
        delete instance.flash['warning_type'];
      }
      if (isPresent(instance.flash['error'])) {
        Alerts.error({ title: 'Error!', text: instance.flash['error'] })
        delete instance.flash['error'];
      }
    }, 500)
  }, [home, warningType])
  useEffect(() => {
    hideSuccessAlert();
    if (current_user.loaded) {
      setPassword('')
      setFirstName(current_user.first_name)
      setLastName(current_user.last_name)
      setNotifier(current_user.notifier)
      setAvatarUrl(isPresent(current_user.large_avatar_url) ? current_user.large_avatar_url : home.default_user_avatar_url)
      setUploadedAvatar(null)
      setSubmit(false)
      setErrors({})
    } else {
      setSubmit(true)
    }
  }, [current_user, current_user.loaded])

  const updateUser = () => {
    const profileData = new FormData();
    profileData.append('user[first_name]', firstName);
    profileData.append('user[last_name]', lastName);
    if (isPresent(notifier))  profileData.append('user[notifier]', notifier);
    if (isPresent(uploadedAvatar)) profileData.append('user[avatar]', uploadedAvatar);
    if (isPresent(password)) {
      profileData.append('user[password]', password)
      profileData.append('user[password_confirmation]', password)
    }
    setSubmit(true)
    setErrors({})
    updateProfile(profileData, (success, errors) => {
      setSubmit(false)
      setPassword('')
      setUploadedAvatar(null)
      if (success) {
        showSuccessAlert();
      } else if (isPresent(errors) && isPresent(Object.keys(errors))) {
        setErrors(errors)
      }
    })
  }

  const deleteUser = () => {
    setSubmit(true)
    Alerts.warning({
      title: 'Are you sure you would like to delete your ' +
        `${home.user_orgs.length > 1 ? `Cloverpop access for ${current_org.name}` : 'account' }?`
    }).then(confirmed => {
      if (confirmed) {
        deleteAccount(success => {
          if (success) document.location.reload();
        })
      } else {
        setSubmit(false)
      }
    }, () => { setSubmit(false) })
  }

  const showSuccessAlert = () => {
    setSuccessUpdate(true);
  }

  const hideSuccessAlert = () => {
    setSuccessUpdate(false);
  }

  const changeAvatar = (event) => {
    hideSuccessAlert();
    const selectedFile = event.target.files[0]
    if (selectedFile) {
      if (selectedFile.size <= MAX_FILE_SIZE) {
        setUploadedAvatar(selectedFile)
        setErrors({ ...errors, avatar: null })
        convertBase64(selectedFile).then(base64 => {
          setAvatarUrl(base64)
        })
      } else {
        setErrors({ ...errors, avatar: 'Please upload an image less than 2MB.' })
      }
    }
  }

  const changeFirstName = (value) => {
    hideSuccessAlert();
    setErrors({ ...errors, first_name: '' })

    if (isBlank(value.trim())) setErrors({ ...errors, first_name: 'Please enter your first name.' })

    setFirstName(value)
  }

  const changeLastName = (value) => {
    hideSuccessAlert();
    setErrors({ ...errors, last_name: '' })

    if (isBlank(value.trim())) setErrors({ ...errors, last_name: 'Please enter your last name.' })

    setLastName(value)
  }

  const changePassword = (value) => {
    hideSuccessAlert();
    setPassword(value)
  }

  const isDataChanged = useMemo(() =>
      current_user.first_name !== firstName || current_user.last_name !== lastName ||
      isPresent(password) || current_user.notifier !== notifier || isPresent(uploadedAvatar)
  , [current_user, firstName, lastName, password, notifier, uploadedAvatar])

  const isBlankMainData = useMemo(() =>
    isBlank(firstName.trim()) || isBlank(lastName.trim()) || isBlank(notifier)
  , [current_user, firstName, lastName, notifier])

  const hiddenFileInput = React.useRef(null);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  return <div className="container home my-3 mx-auto">
    <div className="mx-auto profile-container bg-white p-3 rounded">
      <div className="header text-center">
        <h2>Manage profile</h2>
      </div>
      <div className={`${ successUpdate ? 'alert alert-success text-center fw-bolder' : 'd-none' }`}>
        <span>Your profile has been updated.</span>
      </div>
      <div className="row">
        <div className="col-12" hidden={isBlank(errors['avatar'])}>
          <div className="alert alert-warning mx-auto text-center" role="alert">
            {errors['avatar']}
          </div>
        </div>
        <div className="col-12 col-sm-6">
          <div className="user-image">
            <UserAvatarImage user={current_user} src={isDefaultAvatarUrl(avatarUrl) ? null : avatarUrl} size='lg' />
            <div className="btn btn-primary btn-sm btn-sm-round d-inline-flex justify-content-center align-items-center" onClick={handleClick} >
              <i className="fas fa-pencil-alt w-100" />
            </div>
            <input type="file" onChange={changeAvatar} accept={IMAGE_FILE_TYPES.join(',')} ref={hiddenFileInput} className={`d-none`} />
          </div>
        </div>
        <div className="col-12 col-sm-6 mt-3 my-sm-auto">
          <h3>Email</h3>
          <div className="text-ellipsis">{current_user.email}</div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12 col-sm-6 mb-3">
          <div>
            <h3 htmlFor="user-first-name">First name</h3>
            <input id="user-first-name" name="user[first_name]"
                   value={firstName}
                   className={`form-control rounded ${errors['first_name'] ? 'is-invalid' : ''}`}
                   placeholder={`First name`} maxLength={30}
                   disabled={submit}
                   onChange={(e) => { changeFirstName(e.target.value) }}/>
            <span className={`${ errors['first_name'] ? 'd-block mt-1 text-danger' : 'd-none' }`}>
              {errors['first_name']}
            </span>
          </div>
        </div>
        <div className="col-12 col-sm-6 mb-3">
          <div>
            <h3 htmlFor="user-last-name">Last name</h3>
            <input id="user-last-name" name="user[last_name]"
                   value={lastName}
                   className={`form-control rounded ${errors['last_name'] ? 'is-invalid' : ''}`}
                   placeholder={`Last name`} maxLength={30}
                   disabled={submit}
                   onChange={(e) => { changeLastName(e.target.value) }}/>
            <span className={` ${ errors['last_name'] ? 'd-block mt-1 text-danger' : 'd-none' }`}>
              {errors['last_name']}
            </span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div>
            <h3 htmlFor="user-password">Change password</h3>
            <input id="user-password" name="user[password]" type={`password`}
                   className={`form-control rounded ${errors['password'] || errors['base'] ? 'is-invalid' : ''}`}
                   autoComplete={'off'}
                   placeholder={`Enter a new password`} value={password}
                   disabled={submit}
                   onChange={(e) => { changePassword(e.target.value) }}/>
            <span className={`d-block mt-1 ${ errors['password'] || errors['base'] ? 'text-danger' : 'text-muted' }`}>
              Your password must be between 6 and 128 characters long, contain at least one symbol,
              and avoid repeating the same character more than twice in a row.
            </span>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12 mb-2">
          <h3>Send notifications via:</h3>
          <ul className="nav nav-pills nav-fill bg-light-gray text-dark user-notifiers rounded">
            <li className="nav-item">
              <a className={`nav-link text-dark ${NOTIFIERS.email === notifier ? 'bg-white' : 'pointer'}`} onClick={() => setNotifier(NOTIFIERS.email)}>
                Email
              </a>
            </li>
            {
              isPresent(home.slack_notifier_path) ?
                <li className="nav-item">
                  <a className={`nav-link text-dark ${NOTIFIERS.slack === notifier ? 'bg-white' : 'pointer'}`} href={home.slack_notifier_path}>Slack</a>
                </li> :
                null
            }
            {
              isPresent(home.ms_teams_notifier_path) ?
                <li className="nav-item">
                  <a className={`nav-link text-dark ${NOTIFIERS.teams === notifier ? 'bg-white' : 'pointer'}`} href={home.ms_teams_notifier_path}>Teams</a>
                </li> :
                null
            }
          </ul>
          <div className={`d-block mt-1 ${ warningType ? 'text-danger' : 'd-none' }`}>
            <Warning type={warningType}/>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <Button className={`w-100 btn-brimary`} disabled={isBlankMainData || !isDataChanged || submit}
                  onClick={updateUser}>Save changes</Button>
        </div>
        <div className="col-12 mt-3">
          <Button className={`w-100 btn-light text-danger`} onClick={deleteUser}>Delete account</Button>
        </div>
      </div>
    </div>
  </div>
}
const mapStateToProps = ({ current_user, home, current_org }) => ({
  current_user, home, current_org
});
const mapDispatchToProps = (dispatch) => ({
  updateProfile: (data, callback) => {
    dispatch(updateProfile(data, callback))
  },
  deleteAccount: (callback) => {
    dispatch(deleteAccount(callback))
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);

import React from 'react';
import { connect } from "react-redux";
import DSightDecisionCard from './cards/DSightDecisionCard';
import DriverCard from './cards/DriverCard';
import {
  setDriverInputSidebarOpen,
  setDecisionInputSidebarOpen,
  setRecommendationInputSidebarOpen,
  setFinalDecisionSidebarOpen
} from "../../store/sidebar/actions";
import { updateTreeData } from "../../store/tree/common_actions";
import { closeModal } from "../../store/modals/actions";
import { isLeftSidebarOpen } from "../../helpers/sidebar_helpers";
import FollowupResultsModal, { showFollowupResultsModal } from "../modals/FollowupResultsModal";
import { DriversHeader } from "./Tree";
import { Link } from "react-router-dom";
import { resetDriverInputSidebar } from "../../store/sidebar/common_actions";
import {
  treeInitData,
  useDecisionRecordedSidebarHandlerEffect,
  useRecommendationFlowChangedEffect
} from "./shared/helpers";
import { fetchContacts } from "../../tree_wizard/steps_wizard/steps/helpers/tree_builder_step";

const PoweredByDsightIcon = ({ decision }) =>
  <Link to={`/dsight/decisions/${decision.slug}/analysis`} className='dsight-powered-logo--link' target={`_blank`} />

const DSightTree = ({ tree, decision, user, sidebar, channels, modal, contactsData, setDriverInputSidebarOpen,
                      setDecisionInputSidebarOpen, setRecommendationInputSidebarOpen, closeModal,
                      resetDriverInputSidebar, setFinalDecisionSidebarOpen, updateTreeData }) => {
  const { decisionObject, rootDrivers } = treeInitData(tree, decision);
  const collaborators = fetchContacts({}, contactsData, true, true)

  useDecisionRecordedSidebarHandlerEffect(sidebar, decisionObject, setFinalDecisionSidebarOpen)
  useRecommendationFlowChangedEffect(sidebar, decisionObject, setRecommendationInputSidebarOpen,
    setDecisionInputSidebarOpen)

  return <div className="container-fluid p-0">
    <div className={`px-3 px-md-0 m-3 ${isLeftSidebarOpen(sidebar) ? 'vertical-tree-grow' : 'mx-auto'} vertical-tree`}>
      <DSightDecisionCard {... { decision, sidebar, collaborators, contactsData, decisionObject, user, setDecisionInputSidebarOpen, setRecommendationInputSidebarOpen } } />
      <DriversHeader {... { decisionObject, rootDrivers } }/>
      {
        rootDrivers.map(driverData =>
          <DriverCard key={`driver-card-${driverData.driver.slug}`}
                      indentStep={decisionObject.isRecordedOrRecommended ? 0 : 1}
                      {...{
                        driverData, collaborators, decisionObject, user, tree, sidebar, channels, contactsData,
                        setDriverInputSidebarOpen, resetDriverInputSidebar, updateTreeData
                      } } />
        )
      }
    </div>
    <PoweredByDsightIcon decision={decision} />
    <div className="modals">
      <FollowupResultsModal key={`followup-results-modal-${modal.slug}`} show={showFollowupResultsModal(modal)} onClose={closeModal}/>
    </div>
  </div>
}
const mapStateToProps = ({ tree, decision, modal, current_user, sidebar, channels, contacts }) => ({
  tree, modal, sidebar, channels,
  decision: decision,
  user: current_user,
  contactsData: contacts
});
export default connect(mapStateToProps, {
  closeModal, setDriverInputSidebarOpen, setDecisionInputSidebarOpen,
  setRecommendationInputSidebarOpen, resetDriverInputSidebar, setFinalDecisionSidebarOpen, updateTreeData
})(DSightTree);

import * as moment from "moment";
import { isPresent } from "../helpers/common";

export const sortRankedChoices = (a, b) => {
  const aFinalRank = a.final_decision_rank || Infinity;
  const bFinalRank = b.final_decision_rank || Infinity;
  const aCreatedAt = moment(a.created_at);
  const bCreatedAt = moment(b.created_at);
  if (aFinalRank === bFinalRank) {
    return (aCreatedAt < bCreatedAt) ? -1 : (aCreatedAt > bCreatedAt) ? 1 : 0;
  } else {
    return (aFinalRank < bFinalRank) ? -1 : 1;
  }
}

export const sortPickedChoices = (a, b) => {
  if (isPresent(a.order_rank) && isPresent(b.order_rank)) {
    if (a.order_rank > b.order_rank) return 1;
    if (b.order_rank > a.order_rank) return -1;
  }
  const aCreatedAt = moment(a.created_at);
  const bCreatedAt = moment(b.created_at);
  return (aCreatedAt < bCreatedAt) ? -1 : (aCreatedAt > bCreatedAt) ? 1 : 0;
};

export const sortChoicesByCreatedAt = (o) => moment(o.created_at)

import React from 'react';
import { connect } from "react-redux";
import { setDecisionSidebarOpen } from "../../store/sidebar/actions";
import SlidingPanel from "react-sliding-side-panel";
import SetDescriptionSection from "./sections/SetDescriptionSection";
import SetMoreActionsSection from "./sections/SetMoreActionsSection";
import PanelHeader from "../../tree_view/side_panel/PanelHeader";
import { isMobile } from "react-device-detect";
import { removeTemplateSet, updateTemplateSet } from "../../store/template_set/actions";
import { resetTemplateSetData } from "../../store/template_set/common_actions";
import { TABS_URLS } from "../../helpers/home_helpers";
import { forceReloadHomepageTemplates } from "../../store/homepage/actions";
import SequenceSection from "./sections/SequenceSection";
import DataSourcesSection from "./sections/DataSourcesSection";
import CollaboratorsSection from "./sections/CollaboratorsSection";

export const TemplateSetDetailsPanel = ({
                                          sidebar, template_set,
                                          openSideBar, updateTemplateSet, removeTemplateSet,
                                          forceReloadHomepageTemplates, resetTemplateSet,
                                          ...opts
                                        }) =>
  <SlidingPanel
    type="left"
    isOpen={sidebar.decisionDetailsSidebar}
    panelContainerClassName={`sidebar-panel-wrapper decision-details-panel-wrapper details-panel-width template-panel left-side-panel-position tree-page ${isMobile ? 'pb-5 mb-5' : ''}`}
    panelClassName={`sidebar-panel-wrapper-content decision-details-panel-content`}
    size={52}
  >
    <PanelHeader headerName={'Details'} openSideBar={openSideBar} preview={true} />
    <SetDescriptionSection updateSet={updateTemplateSet} set={template_set} set_key='template_set' {...opts} classNames='mb-3' />
    <CollaboratorsSection preview={opts.preview} />
    <DataSourcesSection addClass="p-3 border-bottom" preview={opts.preview} />
    <SetMoreActionsSection isTemplateSet={true} set={template_set} setTitle='template' homeTab={TABS_URLS.templates} {...opts}
                           removeSet={removeTemplateSet} resetActions={[resetTemplateSet, forceReloadHomepageTemplates]} />
    <SequenceSection {...{ ...opts, isTemplateSet: true }} />
  </SlidingPanel>

const mapStateToProps = ({ sidebar, template_set }) => ({ sidebar, template_set });
const mapDispatchToProps = (dispatch) => ({
  forceReloadHomepageTemplates: () => dispatch(forceReloadHomepageTemplates()),
  openSideBar: (options) => { dispatch(setDecisionSidebarOpen(options)); },
  updateTemplateSet: (value, callback) => { dispatch(updateTemplateSet(value, callback)) },
  removeTemplateSet: (callback) => dispatch(removeTemplateSet(callback)),
  resetTemplateSet: () => dispatch(resetTemplateSetData())
});
export default connect(mapStateToProps, mapDispatchToProps)(TemplateSetDetailsPanel);

import { getBasePath } from "../helpers/routes_helpers";
import { isPresent } from "../helpers/common";
import apisauce from 'apisauce'

const isSameOriginAndIframe = () => {
  // Check if the request is from the same origin
  const isSameOrigin = document.referrer.startsWith(window.location.origin);
  // Check if the request is made from an iframe
  const isIframe = window.self !== window.top;
  return isSameOrigin && isIframe;
};

const headers = {
  'Cache-Control': 'no-cache',
  Accept: 'application/json',
  // 'Content-Type': 'application/json',
  'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
};
const api = apisauce.create({   // base URL is read from the "constructor"
  getBasePath,
  // here are some default headers
  headers,
  // 10 second timeout...
  timeout: 30000
});

const filesUploadApi = apisauce.create({   // base URL is read from the "constructor"
  getBasePath,
  // here are some default headers
  headers: { ...headers, 'Content-Type': 'multipart/form-data' },
  // 10 second timeout...
  timeout: 3000000
});

// Add an interceptor for the requests
api.addRequestTransform(request => {
  request.headers['X-Same-Origin-Iframe'] = isSameOriginAndIframe().toString()
});
// Wizard API
export const loadWizardDataRequest = ({controllerName = 'decisions', objectSlug}) => api.get(`/${controllerName}/${objectSlug}/tree_wizard/fetch_data`)
export const saveWizardStep = (objectSlug, step, data) => api.patch(`/decisions/${objectSlug}/tree_wizard`, { ...data, step })
export const saveTemplateWizardStep = (objectSlug, step, data) => api.patch(`/templates/${objectSlug}/wizard`, { ...data, step })

// Decision Wizard Data Source API
export const createWizardDataSource = ({controllerName = 'decisions', objectSlug, data, config}) => filesUploadApi.post(`/${controllerName}/${objectSlug}/tree_wizard/data_sources`, data, config)
export const updateWizardDataSource = ({controllerName = 'decisions', objectSlug, slug, data}) => api.patch(`/${controllerName}/${objectSlug}/tree_wizard/data_sources/${slug}`, data)
export const replaceWizardDataSource = ({controllerName = 'decisions', objectSlug, slug, data, config}) => filesUploadApi.patch(`/${controllerName}/${objectSlug}/tree_wizard/data_sources/${slug}/replace`, data, config)
export const destroyWizardDataSource = ({controllerName = 'decisions', objectSlug, slug, data}) => api.delete(`/${controllerName}/${objectSlug}/tree_wizard/data_sources/${slug}`, {}, { data })

// Report Wizard API
export const loadReportWizardDataRequest = ({ objectSlug }) => api.get(`/reports/${objectSlug}/report_wizard/fetch_data`)
export const saveReportWizardStep = (objectSlug, step, data) => api.patch(`/reports/${objectSlug}/report_wizard`, { ...data, step })
export const createReportWizardRequest = (objectSlug) => api.post(`/reports/${objectSlug}/report_wizard`)
export const removeReportWizardRequest = (objectSlug) => api.delete(`/reports/${objectSlug}/report_wizard`)

// Decision Tree API
export const loadDecisionDriversData = ({ controllerName = 'decisions', objectSlug, data = {} }) => api.get(`/${controllerName}/${objectSlug}/decision_trees/fetch_decision_tree`, { ...data })
export const updateTreeDataRequest = ({ controllerName = 'decisions', objectSlug, data }) => api.patch(`/${controllerName}/${objectSlug}/decision_trees`, data)
export const updateTreeFlowRequest = ({ objectSlug, data }) => api.patch(`/decisions/${objectSlug}/decision_trees/update_flow`, data)
export const updateTreeDecisionSetRequest = ({ decision_id, decision_set_slug, section_slug }) => api.post(`/decision_sets/${decision_set_slug}/decisions/${decision_id}/update_decision_set`, { section_slug });

// Legacy Decision API
export const loadLegacyDecisionData = ({ controllerName = 'legacy_dsp', objectSlug, data = {} }) => api.get(`/${controllerName}/${objectSlug}/fetch_decision_tree`, { ...data })

// Decision API
export const updateDecisionContext = ({controllerName = 'decisions', objectSlug, value}) => api.post(`/${controllerName}/${objectSlug}/update_collaboration_context`, { collaboration_context: value })
export const transferDecision = ({controllerName = 'decisions', objectSlug, new_decider_email}) => api.post(`/${controllerName}/${objectSlug}/transfer_decision`, { new_decider_email })
export const deleteDecisionRequest = ({controllerName = 'decisions', objectSlug}) => api.delete(`/${controllerName}/${objectSlug}`)
export const updateRecordDecisionData = ({objectSlug, data}) => api.patch(`/decisions/${objectSlug}`, data);
export const updateDecisionAssignedDeciderData = ({objectSlug, assigned_decider}) => api.post(`/decisions/${objectSlug}/assigned_decider`, { assigned_decider });
export const copyRecommendationInputFields = ({objectSlug}) => api.post(`/decisions/${objectSlug}/copy_recommendation_fields`);

// Template/Decision Sets API
export const loadSetData = ({controllerName = 'template_sets', objectSlug, ...opts}) => api.get(`/${controllerName}/${objectSlug}/fetch`, { ...opts })
export const createSetData = ({controllerName = 'template_sets', data}) => api.post(`/${controllerName}`, data);
export const reOrderSetObjectsData = ({controllerName = 'template_sets', objectSlug, data}) => api.post(`/${controllerName}/${objectSlug}/reorder`, data);
export const updateSetRequest = ({controllerName = 'template_sets', objectSlug, data}) => api.patch(`/${controllerName}/${objectSlug}`, data)
export const updateSetSummaryRequest = ({controllerName = 'decision_sets', objectSlug, data}) => api.post(`/${controllerName}/${objectSlug}/summary`, data)
export const removeSetRequest = ({controllerName = 'template_sets', objectSlug}) => api.delete(`/${controllerName}/${objectSlug}`)
export const addSetInvitesRequest = ({controllerName = 'template_sets', objectSlug, invites}) => api.post(`/${controllerName}/${objectSlug}/invites`, { decision_set: { invites } })
export const removeSetInviteRequest = ({controllerName = 'template_sets', objectSlug, slug}) => api.delete(`/${controllerName}/${objectSlug}/invites/${slug}`)
export const transferSet = ({controllerName = 'decision_set', objectSlug, new_manager_email}) => api.post(`/${controllerName}/${objectSlug}/transfer_set`, { new_manager_email })
export const startSetDecisionRequest = ({controllerName = 'template_sets', objectSlug, slug, ...data }) => api.post(`/${controllerName}/${objectSlug}/decisions/${slug}/start`, { ...data })
export const isStartedSetDecisionRequest = ({controllerName = 'template_sets', objectSlug, slug}) => api.post(`/${controllerName}/${objectSlug}/decisions/${slug}/check_started`)
export const removeDecisionFromSetRequest = ({controllerName = 'template_sets', objectSlug, slug}) => api.post(`/${controllerName}/${objectSlug}/decisions/${slug}/remove_from_set`)
export const updateSetUsers = ({controllerName = 'template_sets', objectSlug, data}) => api.post(`/${controllerName}/${objectSlug}/admissions`, data)
export const assignDraftDecisionRequest = ({ controllerName = 'template_sets', objectSlug, slug, data }) => api.patch(`/${controllerName}/${objectSlug}/decisions/${slug}/assign_draft`, data);

// Decision Sets Sections API
export const createSetSectionRequest = ({controllerName = 'template_sets', objectSlug, data}) => api.post(`/${controllerName}/${objectSlug}/sections`, data)
export const updateSetSectionRequest = ({controllerName = 'template_sets', objectSlug, slug, data}) => api.patch(`/${controllerName}/${objectSlug}/sections/${slug}`, data)
export const dragSetSectionRequest = ({controllerName = 'template_sets', objectSlug, slug, data}) => api.patch(`/${controllerName}/${objectSlug}/sections/${slug}/drag`, data)
export const removeSetSectionRequest = ({controllerName = 'template_sets', objectSlug, slug}) => api.delete(`/${controllerName}/${objectSlug}/sections/${slug}`)
export const copySetSectionRequest = ({controllerName = 'template_sets', objectSlug, slug, data}) => api.patch(`/${controllerName}/${objectSlug}/sections/${slug}/copy`, data)

// Playbook Notes API
export const updateTemplateNotesData = ({ objectSlug, update_data }) => api.patch(`/templates/${objectSlug}/playbook_notes`, { playbook_note: update_data })
export const updateDecisionNotesData = ({ objectSlug, update_data }) => api.patch(`/decisions/${objectSlug}/playbook_notes`, { playbook_note: update_data })

// Decision ChatGPT API
export const fetchDriverSuggestions = (objectSlug, data) => api.post(`/decisions/${objectSlug}/chat_gpt/fetch_driver_suggestions`, { ...data })
export const createDriversFromSuggestions = (objectSlug, data) => api.post(`/decisions/${objectSlug}/chat_gpt/create_drivers`, { data })

// Decision Recommendation API
export const createRecommendationData = ({objectSlug, data}) => api.post(`/decisions/${objectSlug}/decision_recommendations`, data);
export const updateRecommendationData = ({objectSlug, recommendationSlug, data}) => api.patch(`/decisions/${objectSlug}/decision_recommendations/${recommendationSlug}`, data);
export const copyRecommendationChoices = ({objectSlug, recommendationSlug}) => api.post(`/decisions/${objectSlug}/decision_recommendations/${recommendationSlug}/copy_choices`);
export const updateDecisionAssignedRecommenderData = ({objectSlug, recommendationSlug, assigned_recommender}) => api.post(`/decisions/${objectSlug}/decision_recommendations/${recommendationSlug}/assigned_recommender`, { assigned_recommender });

// Decision Recommendation Choices API
export const createDecisionRecommendationChoice = ({controllerName = 'decisions', objectSlug, recommendationSlug, data}) => api.post(`/${controllerName}/${objectSlug}/recommendation/${recommendationSlug}/choices`, { ...data })
export const updateDecisionRecommendationChoice = ({controllerName = 'decisions', objectSlug, recommendationSlug, slug, data}) => api.patch(`/${controllerName}/${objectSlug}/recommendation/${recommendationSlug}/choices/${slug}`, { ...data })
export const destroyDecisionRecommendationChoice = ({controllerName = 'decisions', objectSlug, recommendationSlug, slug, data}) => api.delete(`/${controllerName}/${objectSlug}/recommendation/${recommendationSlug}/choices/${slug}`)
export const resetDecisionRecommendationChoices = ({controllerName = 'decisions', objectSlug, recommendationSlug }) => api.post(`/${controllerName}/${objectSlug}/recommendation/${recommendationSlug}/choices/reset_final_choices`)
export const updateDecisionRecommendationFinalChoices = ({controllerName = 'decisions', objectSlug, recommendationSlug, slug, data}) => api.patch(`/${controllerName}/${objectSlug}/recommendation/${recommendationSlug}/choices/${slug}/update_final_choices`, { ...data })
export const updateRecommendationChoicesDraftRequest = ({controllerName = 'decisions', objectSlug, recommendationSlug, data}) => api.post(`/${controllerName}/${objectSlug}/recommendation/${recommendationSlug}/choices/update_choices_draft`, data)
export const createRecommendationConsideredChoiceRequest = ({controllerName = 'decisions', objectSlug, recommendationSlug, data}) => api.post(`/${controllerName}/${objectSlug}/recommendation/${recommendationSlug}/choices/create_considered_choice`, { ...data })
export const createRecommendationConsideredChoicesRequest = ({controllerName = 'decisions', objectSlug, recommendationSlug, data}) => api.post(`/${controllerName}/${objectSlug}/recommendation/${recommendationSlug}/choices/create_considered_choices`, { ...data })

// Decision Tags API
export const updateDecisionTags = ({controllerName = 'decisions', objectSlug, decision_tags}) => api.patch(`/${controllerName}/${objectSlug}/decision_tags`, { decision: { decision_tags } })

// Decision Approval Invites API
export const createApprovalInvite = ({controllerName = 'decisions', objectSlug, data}) => api.post(`/${controllerName}/${objectSlug}/approval_invites`, { ...data })

// Decision Invites API
export const loadCollaborationContacts = (decision_id = null, invite_yourself = false) => api.get(`/contact_lists`, {decision_id, invite_yourself: invite_yourself || isPresent(decision_id)})
export const updateCollaborationContacts = ({controllerName = 'decisions', objectSlug, data}) => api.post(`/${controllerName}/${objectSlug}/invites_actions`, { ...data })
export const removeInvite = ({controllerName = 'decisions', objectSlug, slug}) => api.delete(`/${controllerName}/${objectSlug}/invites_actions/${slug}`)

// Decision Admissions API
export const loadDecisionAdmissions = (options={}) => api.get(`/decision_admissions`, options)

// Non user driver page
export const loadQuickDriverDataRequest = (objectSlug) => api.get(`/quick_driver_entry/${objectSlug}/fetch_data`)
export const updateQuickDriverData = ({objectSlug, data}) => api.patch(`/quick_driver_entry/${objectSlug}/update_driver`, data);
export const createSourceQuickDriverRequest = ({objectSlug, data, config}) => filesUploadApi.post(`/quick_driver_entry/${objectSlug}/create_source`, data, config)
export const destroyDataSourceQuickDriverRequest = ({objectSlug, driverData}) => api.delete(`/quick_driver_entry/${objectSlug}/destroy_source/`, driverData)
export const updateDataSourceQuickDriverRequest = ({objectSlug, driverData}) => api.patch(`/quick_driver_entry/${objectSlug}/update_source/`, driverData )

// Search Filters API
export const loadSearchFilters = (options={}) => api.get(`/search_filters`, options)
export const updateSearchFilters = (options={}) => api.patch(`/search_filters/update_settings`, options)

// Decision Share API
export const reloadShareDataRequest = ({controllerName = 'decisions', objectSlug}) => api.post(`/${controllerName}/${objectSlug}/share`)
export const addShareRequest = ({controllerName = 'decisions', objectSlug, decision_admissions_attributes, invites= null, additional_data = {}}) => api.post(`/${controllerName}/${objectSlug}/share`, { ...additional_data, decision: { decision_admissions_attributes, invites } })
export const removeShareRequest = ({controllerName = 'decisions', objectSlug, slug, decision_admissions_attributes}) => api.delete(`/${controllerName}/${objectSlug}/share/${slug}`, {}, { data: { decision: { decision_admissions_attributes } } })
export const sharePublicLink = (objectSlug) => api.post(`/share_decisions/${objectSlug}`)
export const disableSharePublicLink = (objectSlug) => api.delete(`/share_decisions/${objectSlug}`)

// Decision Feedbacks API
export const postMessageRequest = ({objectSlug, ...options}) => api.post(`/decisions/${objectSlug}/feedbacks`, options)
export const updateMessageRequest = ({objectSlug, slug, description, ...opts}) => api.patch(`/decisions/${objectSlug}/feedbacks/${slug}`, { feedback: { description }, ...opts })
export const deleteMessageRequest = ({objectSlug, slug}) => api.delete(`/decisions/${objectSlug}/feedbacks/${slug}`)
export const ToggleMessageLikeRequest = ({objectSlug, slug}) => api.post(`/decisions/${objectSlug}/feedbacks/${slug}/toggle_like`)

// Decision Choices API
export const createDecisionChoice = ({controllerName = 'decisions', objectSlug, data}) => api.post(`/${controllerName}/${objectSlug}/choices`, { ...data })
export const updateDecisionChoice = ({controllerName = 'decisions', objectSlug, slug, data}) => api.patch(`/${controllerName}/${objectSlug}/choices/${slug}`, { ...data })
export const destroyDecisionChoice = ({controllerName = 'decisions', objectSlug, slug, data}) => api.delete(`/${controllerName}/${objectSlug}/choices/${slug}`)
export const resetChoices = ({controllerName = 'decisions', objectSlug}) => api.post(`/${controllerName}/${objectSlug}/choices/reset_final_choices`)
export const updateDecisionFinalChoices = ({controllerName = 'decisions', objectSlug, slug, data}) => api.patch(`/${controllerName}/${objectSlug}/choices/${slug}/update_final_choices`, { ...data })
export const updateChoicesDraftRequest = ({controllerName = 'decisions', objectSlug, data}) => api.post(`/${controllerName}/${objectSlug}/choices/update_choices_draft`, data)
export const createDecisionConsideredChoiceRequest = ({controllerName = 'decisions', objectSlug, data}) => api.post(`/${controllerName}/${objectSlug}/choices/create_considered_choice`, { ...data })
export const createDecisionConsideredChoicesRequest = ({controllerName = 'decisions', objectSlug, data}) => api.post(`/${controllerName}/${objectSlug}/choices/create_considered_choices`, { ...data })

// Decision User Approval API
export const saveDecisionUserApproval = (objectSlug, data) => api.post(`/decisions/${objectSlug}/decision_user_approvals`, { ...data })
export const updateDecisionUserApproval = (objectSlug, data) => api.patch(`/decisions/${objectSlug}/decision_user_approvals`, { ...data })

// Decision User Support API
export const saveDecisionUserSupport = (objectSlug, data) => api.post(`/decisions/${objectSlug}/decision_user_supports`, { ...data })
export const toggleLikeDecisionUserSupport = (objectSlug, slug) => api.post(`/decisions/${objectSlug}/decision_user_supports/${slug}/toggle_like`)

// Decision Followups API
export const scheduleNewFollowupDueDate = ({objectSlug, data}) => api.post(`/decisions/${objectSlug}/decision_followups/create_due_date`, { ...data })
export const updateFollowupDueDate = ({objectSlug, slug, data}) => api.patch(`/decisions/${objectSlug}/decision_followups/${slug}/set_due_date`, { ...data })
export const cancelFollowup = (objectSlug) => api.post(`/decisions/${objectSlug}/decision_followups/cancel`)
export const followupNowRequest = ({objectSlug, data}) => api.post(`/decisions/${objectSlug}/decision_followups`, { ...data })
export const updateFollowupRequest = ({objectSlug, slug, data}) => api.patch(`/decisions/${objectSlug}/decision_followups/${slug}`, { ...data })

// Decision Data Sources API
export const createDataSource = ({controllerName = 'decisions', objectSlug, data, config}) => filesUploadApi.post(`/${controllerName}/${objectSlug}/decision_data_sources`, data, config)
export const updateDataSource = ({controllerName = 'decisions', objectSlug, slug, data}) => api.patch(`/${controllerName}/${objectSlug}/decision_data_sources/${slug}`, data)
export const replaceDataSource = ({controllerName = 'decisions', objectSlug, slug, data, config}) => filesUploadApi.patch(`/${controllerName}/${objectSlug}/decision_data_sources/${slug}/replace`, data, config)
export const destroyDataSource = ({controllerName = 'decisions', objectSlug, slug, data}) => api.delete(`/${controllerName}/${objectSlug}/decision_data_sources/${slug}`, {}, { data })
export const addSourceToFlowRequest = ({controllerName = 'decisions', objectSlug, slug}) => api.post(`/${controllerName}/${objectSlug}/decision_data_sources/${slug}/add_to_flow`)

// Decision Set Data Sources API
export const createSetDataSource = ({controllerName = 'decision_sets', objectSlug, data, config}) => filesUploadApi.post(`/${controllerName}/${objectSlug}/data_sources`, data, config)
export const updateSetDataSource = ({controllerName = 'decision_sets', objectSlug, slug, data}) => api.patch(`/${controllerName}/${objectSlug}/data_sources/${slug}`, data)
export const replaceSetDataSource = ({controllerName = 'decision_sets', objectSlug, slug, data, config}) => filesUploadApi.patch(`/${controllerName}/${objectSlug}/data_sources/${slug}/replace`, data, config)
export const destroySetDataSource = ({controllerName = 'decision_sets', objectSlug, slug, data}) => api.delete(`/${controllerName}/${objectSlug}/data_sources/${slug}`, {}, { data })
export const addSourceToDecisionRequest = ({controllerName = 'decision_sets', objectSlug, slug, data }) => api.post(`/${controllerName}/${objectSlug}/data_sources/${slug}/add_to_decision`, data)

// Template Set Data Sources API
export const createTemplateSetDataSource = ({controllerName = 'template_sets', objectSlug, data, config}) => filesUploadApi.post(`/${controllerName}/${objectSlug}/data_sources`, data, config)
export const updateTemplateSetDataSource = ({controllerName = 'template_sets', objectSlug, slug, data}) => api.patch(`/${controllerName}/${objectSlug}/data_sources/${slug}`, data)
export const replaceTemplateSetDataSource = ({controllerName = 'template_sets', objectSlug, slug, data, config}) => filesUploadApi.patch(`/${controllerName}/${objectSlug}/data_sources/${slug}/replace`, data, config)
export const destroyTemplateSetDataSource = ({controllerName = 'template_sets', objectSlug, slug, data}) => api.delete(`/${controllerName}/${objectSlug}/data_sources/${slug}`, {}, { data })
export const addSourceToTemplateRequest = ({controllerName = 'template_sets', objectSlug, slug, data }) => api.post(`/${controllerName}/${objectSlug}/data_sources/${slug}/add_to_template`, data)

// Report Data Sources API
export const createReportDataSource = ({ objectSlug, data, config}) => filesUploadApi.post(`/reports/${objectSlug}/report_data_sources`, data, config)
export const updateReportDataSource = ({ objectSlug, slug, data}) => api.patch(`/reports/${objectSlug}/report_data_sources/${slug}`, data)
export const replaceReportDataSource = ({ objectSlug, slug, data, config}) => filesUploadApi.patch(`/reports/${objectSlug}/report_data_sources/${slug}/replace`, data, config)
export const destroyReportDataSource = ({ objectSlug, slug, data}) => api.delete(`/reports/${objectSlug}/report_data_sources/${slug}`, {}, { data })

// Report Decisions API
export const loadReportDecisionsData = (objectSlug, data) => api.get(`/reports/${objectSlug}/report_decisions`, data)

// Decision Reports API
export const createDecisionReportData = ({controllerName = 'decisions', objectSlug, reportSlug}, data) => api.post(`/${controllerName}/${objectSlug}/decision_reports/${reportSlug}`, data)
export const destroyDecisionReportData = ({controllerName = 'decisions', objectSlug, reportSlug}, data) => api.delete(`/${controllerName}/${objectSlug}/decision_reports/${reportSlug}`, data)

// Decision Drivers API
export const removeTreeNodeData = ({controllerName = 'decisions', objectSlug, slug}) => api.delete(`/${controllerName}/${objectSlug}/decision_drivers/${slug}`)
export const updateDriverData = ({controllerName = 'decisions', objectSlug, slug, data}) => api.patch(`/${controllerName}/${objectSlug}/decision_drivers/${slug}`, data);
export const copyDriverData = ({controllerName = 'decisions', objectSlug, slug, data}) => api.post(`/${controllerName}/${objectSlug}/decision_drivers/${slug}/copy`, data);
export const updateDriverWeightsData = ({controllerName = 'decisions', objectSlug, data}) => api.post(`/${controllerName}/${objectSlug}/decision_drivers/update_weights`, data);
export const dragDriverData = ({controllerName = 'decisions', objectSlug, slug, data}) => api.post(`/${controllerName}/${objectSlug}/decision_drivers/${slug}/drag`, data);

// Decision Drivers Feedbacks API
export const postDriverCommentData = ({controllerName = 'decisions', objectSlug, driverSlug, data}) => api.post(`/${controllerName}/${objectSlug}/drivers/${driverSlug}/feedbacks`, data);
export const updateDriverCommentData = ({controllerName = 'decisions', objectSlug, driverSlug, slug, data}) => api.patch(`/${controllerName}/${objectSlug}/drivers/${driverSlug}/feedbacks/${slug}`, data);
export const removeDriverCommentData = ({controllerName = 'decisions', objectSlug, driverSlug, slug}) => api.delete(`/${controllerName}/${objectSlug}/drivers/${driverSlug}/feedbacks/${slug}`);
export const toggleDriverCommentLikeRequest = ({controllerName = 'decisions', objectSlug, driverSlug, commentSlug}) => api.post(`/${controllerName}/${objectSlug}/drivers/${driverSlug}/feedbacks/${commentSlug}/toggle_like`)

/// D-Sight API
// Analysis API
export const loadDSightAnalysisData = ({controllerName, objectSlug, data = {}}) => api.get(`/dsight/${controllerName}/${objectSlug}/analysis/fetch`, { ...data })
export const checkDSightAnalysisAccess = ({ controllerName, objectSlug }) => api.post(`/dsight/${controllerName}/${objectSlug}/analysis/check_accessibility`)

export const loadAnalysisMetricData = ({controllerName, objectSlug, slug, data = {}}) => api.get(`/dsight/${controllerName}/${objectSlug}/analysis/metrics/${slug}/fetch`, { ...data })

// Metrics API
export const loadCommonGoalData = ({ slug, ...opts }) => api.get(`/dsight/goals/${slug}/fetch`, { ...opts })
export const loadGoalMetricsData = ({ slug, ...opts }) => api.get(`/dsight/goals/${slug}/metrics/fetch_metrics`, { ...opts })
export const loadGoalMetricsDataAccess = ({ id }) => api.post(`/dsight/goals/${id}/metrics/check_accessibility`)
export const loadGoalMetricData = ({ slug, metricSlug, ...opts }) => api.get(`/dsight/goals/${slug}/metrics/${metricSlug}/fetch`, { ...opts })

// Ask Cloverpop API
export const createMessage = (content, goal_id, conversationId='') => {
  return api.post(`/ask_cloverpop/goals/${goal_id}/messages`,
    { content: content, dsight_conversation_id: conversationId })
}
export const loadConversations = (goal_id) => {
  return api.get(`/ask_cloverpop/goals/${goal_id}/conversations`)
}
export const loadConversation = (goal_id, conversationId) => {
  return api.get(`/ask_cloverpop/goals/${goal_id}/conversations/${conversationId}`)
}
export const updateConversation = (goal_id, conversationId, attributes) => {
  return api.patch(`/ask_cloverpop/goals/${goal_id}/conversations/${conversationId}`,
    { conversation: attributes })
}
export const deleteConversation = (goal_id, conversationId) => {
  return api.delete(`/ask_cloverpop/goals/${goal_id}/conversations/${conversationId}`)
}

// User API
export const loadCurrentUserData = () => api.get(`/user/json_data`)
export const validateUserData = (data) => api.post(`/user/check_user`, data)

// Decision Categories API
export const updateDecisionCategories = (slug, data) => api.patch(`/decisions/${slug}/categories`, data);

// Org Categories API
export const loadOrgCategoriesData = (data = {}) => api.get('/decision_categories', { ...data })

// Org Report Properties API
export const loadOrgReportPropertiesData = (data = {}) => api.get('/report_properties', { ...data })

// Org DriverTypes API
export const loadOrgDriverTypesData = (data = {}) => api.get('/driver_types', { ...data })

// Homepage API
export const loadHomePageData = () => api.get(`/homepage/fetch_user_data`)
export const loadTemplatesData = (data = {}) => api.get(`/homepage/load_templates`, { ...data })
export const loadAdminTemplatesData = (data = {}) => api.get(`/homepage/load_admin_templates`, { ...data })
export const loadTemplateSetsData = (data = {}) => api.get(`/homepage/load_template_sets`, { ...data })
export const loadAdminTemplateSetsData = (data = {}) => api.get(`/homepage/load_admin_template_sets`, { ...data })
export const loadDecisionSetsData = (data = {}) => api.get(`/homepage/load_decision_sets`, { ...data })
export const loadUsersData = (data = {}) => api.get(`/homepage/load_users`, { ...data })
export const loadGroupsData = (data = {}) => api.get(`/homepage/load_groups`, { ...data })
export const loadDecisionsData = (data = {}) => api.get(`/homepage/load_decisions`, { ...data })
export const loadAssignedObjects = () => api.get(`/homepage/load_assigned_objects`)
export const loadDsightDataRequest = (data = {}) => api.get(`/homepage/load_dsight_data`, { ...data })
export const loadLearnDataRequest = (data = {}) => api.get(`/homepage/load_learn_data`, { ...data })
export const loadReportInsightsDataRequest = (data = {}) => api.get(`/homepage/load_report_insights_data`, { ...data })
export const loadReportsDataRequest = (data = {}) => api.get(`/homepage/load_reports_data`, { ...data })
export const searchDecisions = (data = {}) => api.get(`/homepage/search_decisions`, { ...data })
export const updateHomepageLastTab = (tab, data = {}) => api.post(`/homepage/${tab}/update_last_tab`, data)
export const switchUserSelectedOrg = (org_slug) => api.get(`/user/switch_org`, { org_slug })
export const signOutRequest = () => api.get(`/users/sign_out`)
export const startDecisionRequest = (data = {}) => api.post(`/homepage/decisions/create_decision_tree`, { description: '', ...data })

// Homepage Templates API
export const createNewTemplateRequest = (data = {}) => api.post(`/templates`, { ...data })
export const getTemplateDashboardDataRequest = (slug) => api.get(`/templates/${slug}/load_dashboard_data`, { dashboard: true });
export const updateTemplateDataRequest = (objectSlug, data) => api.patch(`/templates/${objectSlug}`, data);
export const updateTemplateCategories = (slug, data) => api.patch(`/templates/${slug}/categories`, data)
export const updateTemplateUsers = (slug, data) => api.post(`/templates/${slug}/admissions`, data)
export const deleteTemplateRequest = (slug) => api.delete(`/templates/${slug}`)
export const removeFromTemplateSetRequest = (slug) => api.delete(`/templates/${slug}/remove_from_set`)
export const createTemplateCollaborators = (controllerName = 'templates', slug, data) => api.post(`/${controllerName}/${slug}/admissions/collaborators`, data)
export const removeAdmission = (controllerName = 'templates', objectSlug, slug) => api.delete(`/${controllerName}/${objectSlug}/admissions/${slug}`)

// Reports API
export const loadReportRequest = ({ objectSlug }, data) => api.get(`/reports/${objectSlug}/fetch_data`, data)
export const startReportRequest = (data = {}) => api.post(`/reports`, { ...data })
export const removeReportRequest = (slug) => api.delete(`/reports/${slug}`)

// Homepage Users API
export const updateOrgUserData = (slug, data) => api.patch(`/homepage/users/${slug}`, { ...data });
export const removeUserFromOrg = (slug) => api.delete(`/homepage/users/${slug}`)
export const inviteOrgUsersData = (data) => api.post(`/homepage/org_invites`, { ...data });
export const updatePendingUserData = (slug, data) => api.patch(`/homepage/org_invites/${slug}`, { ...data });
export const resendOrgUserInvite = (slug) => api.patch(`/homepage/org_invites/${slug}/resend`)
export const revokeOrgUserInvite = (slug) => api.delete(`/homepage/org_invites/${slug}`)

// Homepage Groups API
export const createNewGroupData = (data) => api.post(`/homepage/groups`, { ...data })
export const updateGroupData = (slug, data) => api.patch(`/homepage/groups/${slug}`, { ...data })
export const deleteGroupData = (slug) => api.delete(`/homepage/groups/${slug}`)
export const inviteUsersToGroupData = (slug, data) => api.post(`/homepage/groups/${slug}/group_users`, { ...data })
export const updateUserGroupsData = (group_slug, data) => api.patch(`/homepage/groups/${group_slug}/update_user`, { ...data })

// Homepage Profile API
export const updateCurrentUserData = (data) => filesUploadApi.patch(`/users`, data)
export const deleteCurrentUser = () => api.delete(`/users`)

// Homepage Categories API
export const createNewCategoryData = (data = {}) => api.post(`/homepage/categories`, { ...data })
export const updateCategoryData = (slug, data = {}) => api.patch(`/homepage/categories/${slug}`, { ...data })
export const deleteCategoryData = (slug) => api.delete(`/homepage/categories/${slug}`)
export const sortCategoriesData = (data = {}) => api.post(`/homepage/categories/resort`, { ...data })

// Learn API
export const fetchLearnData = (data = {}) => api.post(`/learn/details/fetch_all`, { ...data })
export const fetchLearnTemplateData = (template_id, data = {}) => api.post(`/learn/details/${template_id}/fetch`, { ...data })

// Request Access API
export const createRequestAccess = (slug, data = {}) => api.post(`/decisions/${slug}/request_access`, { ...data })

// SignIn API
export const sendLoginLinkRequest = (email) => api.post('/login_link', { user: { email } })

// Forecast Simulator Scenarios API
export const loadForecastSimulatorScenarios = (data) => api.get(`/forecast_simulator/scenarios`, data);
export const loadForecastSimulatorScenarioData = (scenario_id, data = {}) => api.get(`/forecast_simulator/scenarios/${scenario_id}`, data);
export const updateForecastSimulatorScenarioData = (scenario_id, data = {}) => api.patch(`/forecast_simulator/scenarios/${scenario_id}`, { ...data });
export const removeForecastSimulatorScenarioData = (scenario_id) => api.delete(`/forecast_simulator/scenarios/${scenario_id}`);
export const createForecastSimulatorScenario = (config_id, data) => api.post(`/forecast_simulator/configs/${config_id}/scenarios`, { ...data })

// Forecast Simulator Configs API
export const loadForecastSimulatorConfigs = () => api.get(`/forecast_simulator/configs`);

export default api;

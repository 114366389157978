import React, { useRef } from "react";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import { attachEventEffect } from "../../../../helpers/html_events_helpers";
import { setSetDecisionsSidebarOpen } from "../../../../store/sidebar/actions";

const AddDecision = ({ set, isTemplateSet, treeNode, history, openSideBar, ...opts }) => {
  const ref = useRef();
  const addDecisionParams = treeNode.isRoot ? '' : `?section_slug=${treeNode.slug}`

  const onAddSection = (e) => {
    e.preventDefault();

    if(isTemplateSet) {
      history.push(`/template_sets/${set.slug}/add_template${addDecisionParams}`);
    } else {
      history.push(`/decision_sets/${set.slug}/add_decision${addDecisionParams}`);
    }
    openSideBar(false);
  }
  attachEventEffect(ref, onAddSection)

  return <>
    <Button className="btn btn-secondary btn-sm btn-sm-round me-1" ref={ref}>
      <i className="fas fa-plus fa-lg rounded-0" />
    </Button>
  </>
}
const mapStateToProps = ({ modal, decision_set, template_set }, { isTemplateSet }) => ({
  modal, set: isTemplateSet ? template_set : decision_set
});
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (flag) => {
    dispatch(setSetDecisionsSidebarOpen(flag));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(AddDecision);

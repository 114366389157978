import React from 'react';
import { connect } from "react-redux";
import { setRatingsAndWeightsOpen } from "../../../store/sidebar/actions";
import BaseHamburgerBtn from "../../../common/BaseHamburgerBtn";

const RateCompareHamburgerButton = ({ openRatingsAndWeights, title = 'Show ratings & weights', hidden = false }) =>
  !hidden && <BaseHamburgerBtn onSelect={openRatingsAndWeights} title={title} />

const mapDispatchToProps = (dispatch) => ({
  openRatingsAndWeights: () => dispatch(setRatingsAndWeightsOpen(true))
});
export default connect(null, mapDispatchToProps)(RateCompareHamburgerButton);


import { useHistory } from "react-router-dom";
import { deleteWarning } from "../../helpers/alert_helpers";
import { redirectToHomeCallback } from "../../helpers/decision_helpers";
import { isBlank, successActions } from "../../helpers/common";
import { forceReloadHomepageDecisions, reloadTemplatesData } from "../../store/homepage/actions";
import { deleteDecision } from "../../store/tree/actions";
import {
  copyChoices,
  resetSidebars,
  setDecisionSidebarOpen,
  setRecommendationInputSidebarOpen,
  setTreeSidebarOpen
} from "../../store/sidebar/actions";
import { resetPlaybookNotes } from "../../store/playbook_notes/actions";
import { createNewTemplate } from "../../store/template/actions";
import { isPresent } from "../../../ms_teams/helpers/common";
import {closeModal, openModal} from "../../store/modals/actions";
import { openStartDuplicateDecisionModal } from "../modals/StartDuplicateDecisionModal";
import {openEditDecisionEntryModal} from "../modals/DecisionEntryModal";
import { resetTemplateSetData } from "../../store/template_set/common_actions";
import {TREE_MODES} from "../../helpers/drivers_helpers";
import Decision from "../../models/decision";
import Recommendation from "../../models/recommendation";

const startDecisionFromDuplicate = (decision, history, openModal) => () => {
  history.replace({ search: '' });
  openStartDuplicateDecisionModal({ openModal, type: 'StartDuplicateDecisionModal', decision });
}

const openTreeSidebar = (setTreeSidebarOpen) => () => {
  setTreeSidebarOpen(true, TREE_MODES.edit);
}

const openAssignDriversSidebar = (setTreeSidebarOpen) => () => {
  setTreeSidebarOpen(true, TREE_MODES.assign);
}

const onEditDecisionFromDecisionEntryModal = (decision, openModal, copyChoices) => () => {
  const decisionObj = new Decision(decision);
  const recommendation = decision.recommendation;
  const recommendationObject = new Recommendation(recommendation);

  if(isBlank(decisionObj.decisionChoices) && isPresent(recommendationObject.recommendationChoices)) {
    copyChoices((success, updatedDecision) => {
      if(success) openEditDecisionEntryModal({ openModal, type: 'PanelDecisionEntryModal', decision: updatedDecision });
    })
  } else {
    openEditDecisionEntryModal({ openModal, type: 'PanelDecisionEntryModal', decision });
  }
}
const duplicateAsTemplateFromDecision = (createNewTemplate, decision, history, actions) => () => {
  history.replace({ search: '' });
  const templateData = {
    duplicated_decision_slug: decision.slug,
    description: `${decision.description} (copy)`,
    complete: true
  }
  createNewTemplate(templateData, (slug, completed = false) => {
    if (isPresent(slug)) {
      successActions(true, actions);
      setTimeout(() => {
        history.push(`/templates/${slug}${completed ? '' : '/wizard'}`)
      }, 50);
    }
  });
}

export const deleteAndStartActions = ({
                                        decision,
                                        deleteDecision,
                                        createNewTemplate,
                                        forceReloadHomepageDecisions,
                                        resetSidebars, resetPlaybookNotes, resetTemplateSetData, reloadTemplatesData,
                                        openDecisionDetailsSideBar,
                                        setRecommendationInputSidebarOpen,
                                        setTreeSidebarOpen,
                                        openModal,
                                        copyChoices
                                      }) => {
  const resetActions = [
    forceReloadHomepageDecisions, resetSidebars, resetPlaybookNotes, resetTemplateSetData, reloadTemplatesData,
    () => openDecisionDetailsSideBar(false),
    () => setRecommendationInputSidebarOpen(false)
  ]
  const history = useHistory();
  const deleteDecisionHandler = () => {
    deleteWarning(confirmed => {
      if (!confirmed) return;

      deleteDecision((success) => {
        redirectToHomeCallback(success, history);
        successActions(success, resetActions);
      })
    })
  };

  const editDecisionTree = openTreeSidebar(setTreeSidebarOpen);
  const assignDriversTree = openAssignDriversSidebar(setTreeSidebarOpen);
  const startDecision = startDecisionFromDuplicate(decision, history, openModal);
  const startTemplate = duplicateAsTemplateFromDecision(createNewTemplate, decision, history, resetActions);
  const onEditDecision = onEditDecisionFromDecisionEntryModal(decision, openModal, copyChoices);
  return {
    deleteDecisionHandler, startDecision, startTemplate, onEditDecision, editDecisionTree, assignDriversTree
  }
}
export const mapStartDeleteDispatchToProps = (dispatch) => ({
  forceReloadHomepageDecisions: () => dispatch(forceReloadHomepageDecisions()),
  deleteDecision: (callback) => dispatch(deleteDecision(callback)),
  resetSidebars: () => dispatch(resetSidebars()),
  resetTemplateSetData: () => dispatch(resetTemplateSetData()),
  reloadTemplatesData: () => dispatch(reloadTemplatesData()),
  resetPlaybookNotes: () => dispatch(resetPlaybookNotes()),
  openDecisionDetailsSideBar: (flag) => dispatch(setDecisionSidebarOpen(flag)),
  setRecommendationInputSidebarOpen: (flag) => dispatch(setRecommendationInputSidebarOpen(flag)),
  setTreeSidebarOpen: (flag, mode) => dispatch(setTreeSidebarOpen(flag, mode)),
  createNewTemplate: (data, callback) => dispatch(createNewTemplate(data, callback)),
  openModal: (data) => dispatch(openModal(data)),
  closeModal: () => dispatch(closeModal()),
  copyChoices: (callback) => dispatch(copyChoices(callback))
});

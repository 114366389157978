import {isPresent} from "../helpers/common";

export const TEMPLATE_TYPE = 'template';
export const COLLABORATOR_TYPE = 'collaborator';
export const DECISION_TYPE = 'decision';
export class Template {
  constructor(data) {
    this.data = data
  }

  get template_admissions() {
    return this.data.admissions.filter(a => a.share_type === TEMPLATE_TYPE)
  }

  get decision_admissions() {
    return this.data.admissions.filter(a => a.share_type === DECISION_TYPE)
  }

  get collaborator_admissions() {
    return this.data.admissions.filter(a => a.share_type === COLLABORATOR_TYPE).map(a => this._withUserData(a))
  }

  _withUserData(admission) {
    if (admission.share_type !== COLLABORATOR_TYPE) return admission;

    const userData = this.data.users.find(u => u.email === admission.user_email)
    if (isPresent(userData)) admission.user = userData
    return admission;
  }
}

import React, { Fragment, useEffect } from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { loadTemplate } from "../store/template/actions";
import { updateTemplateData } from "../store/template/common_actions";
import { loadOrgDriverTypes } from "../store/org_driver_types/actions";
import EntryPoint, { updateEntryPointData } from "../EntryPoint";
import AlertsSection from "../alerts";
import TemplatePreviewTree from "../tree_view/accordion_tree/TemplatePreviewTree";
import TemplatePreviewHeader, { getTemplateSetParamsFromTemplate, templateSetParams } from "./header";
import TemplateDetailsPanel from "./side_panel/TemplateDetailsPanel";
import TemplateTreePanel from "./side_panel/TemplateTreePanel";
import { loadOrgCategories } from "../store/org_categories/actions";
import { Loader } from "../common/Loader";
import { isPresent } from "../helpers/common";
import { isDSightTemplate } from "../helpers/template_helpers";
import { isTemplateLoaded, loadTemplateCallbacks } from "../template_view";
import PreviewDriverDetailsModal, {
  showPreviewDriverDetailsModal
} from "../tree_view/modals/PreviewDriverDetailsModal";
import PreviewDecisionDetailsModal, {
  showPreviewDecisionDetailsModal
} from "../tree_view/modals/PreviewDecisionDetailsModal";
import { closeModal } from "../store/modals/actions";
import TemplatePlaybookPanel from "../template_view/side_panel/TemplatePlaybookPanel";
import SidebarTreeNavigationPreview from "./navigation";
import DecisionSetModal, { showDecisionSetModal } from "../homepage/modals/DecisionSetModal";
import { loadTemplateSet } from "../store/template_set/actions";
import DecisionOrderPanel from "../template_set_view/side_panel/DecisionOrderPanel";
import DecisionSetPanel from "../tree_view/side_panel/DecisionSetPanel";
import { loadDecisionSet } from "../store/decision_set/actions";
import AddToSetModal, { showAddToSetModal } from "../template_set_view/modals/AddToSetModal";
import StartDecisionModal, { showStartDecisionModal } from "../homepage/modals/StartDecisionModal";
import { checkLoadingEffect, isStoreLoading } from "../helpers/callbacks_helpers";
import StartUnstartedDecisionModal, {
  showStartUnstartedDecisionModal
} from "../decision_set_view/modals/StartUnstartedDecisionModal";
import RatingsAndWeightsPanel from "../tree_view/side_panel/RatingsAndWeightsPanel";

export const checkLoadedTemplateInfo = (match, updateTemplateData, afterCallback = () => null) => {
  if (EntryPoint.instance.objectSlug !== match?.params?.id) {
    updateEntryPointData(match, 'templates', afterCallback)
    updateTemplateData({loaded: false})
  }
}

export const Sidebars = ({ isParentTemplateSet = false, isUnstartedTemplateInSet = false }) =>
  <Fragment>
    <div className="sidebars">
      <RatingsAndWeightsPanel isTemplate={true} preview={true} hideEditDriverWeights={true}/>
      <TemplateDetailsPanel key="decision-details-side-panel" />
      <TemplateTreePanel key="template-tree-panel" preview={true} />
      <TemplatePlaybookPanel addClass={'left-side-panel-position tree-page'} preview={true} />
      {
        isUnstartedTemplateInSet ?
          <DecisionSetPanel preview={true} addClass="left-side-panel-position tree-page" /> :
          isParentTemplateSet ?
            <DecisionOrderPanel addClass="left-side-panel-position tree-page" isTemplate={true} preview={true}/> :
            null
      }
    </div>
  </Fragment>

export const Wrapper = ({
                          children, closeModal,
                          modal, template_set,
                          isDecisionSet, decisionSet,
                          ...opts
                       }) =>
  <Fragment>
    <AlertsSection />
    <TemplatePreviewHeader />
    <div className="d-flex overflow-hidden">
      <SidebarTreeNavigationPreview {...opts} />
      {children}
    </div>
    <Sidebars {...opts} />
    <div className="modals">
      <PreviewDriverDetailsModal shown={showPreviewDriverDetailsModal(modal)} onClose={closeModal}/>
      <PreviewDecisionDetailsModal shown={showPreviewDecisionDetailsModal(modal)} onClose={closeModal}/>
      <DecisionSetModal shown={showDecisionSetModal(modal)} onClose={closeModal}/>
      <StartDecisionModal shown={showStartDecisionModal(modal)} onClose={closeModal} {...{ isDecisionSet, decisionSetSlug: decisionSet }} />
      <AddToSetModal shown={showAddToSetModal(modal, template_set)} onClose={closeModal}/>
      <StartUnstartedDecisionModal shown={showStartUnstartedDecisionModal(modal)} onClose={closeModal}/>
    </div>
  </Fragment>

export const loadPreviewTemplateCallbacks = ({template, loadTemplate, ...opts}) => {
  loadTemplateCallbacks(opts)
  checkLoadingEffect(template, loadTemplate)
}

export const TemplatePreview = ({
                                  template, match, modal, template_set, decision_set,
                                  closeModal, loadTemplateSet, loadDecisionSet,
                                  ...opts
                                }) => {
  loadPreviewTemplateCallbacks({ template, match, ...opts })

  if (isTemplateLoaded(template, match) && isDSightTemplate(template)) {
    const history = useHistory();
    history.push(`/dsight/templates/${template.slug}/preview`);
  }

  const { isParentTemplateSet, parentTemplateSet } = getTemplateSetParamsFromTemplate(template);
  const {
    decisionSet, isUnstartedTemplateInSet, isDecisionSet
  } = templateSetParams()
  const loadTemplateSetForTemplate = () => loadTemplateSet({
    scope: 'template_sets', setSlug: parentTemplateSet
  })

  useEffect(() => {
    if (template_set.loaded && template.loaded) {
      if (isParentTemplateSet && template_set.slug !== parentTemplateSet) {
        loadTemplateSetForTemplate()
      }
    }
  }, [template, template_set])

  checkLoadingEffect(template_set, loadTemplateSetForTemplate, {
    condition: ([template]) => isPresent(template.template_set_slug),
    another_stores: [template]
  })
  checkLoadingEffect(decision_set, () => loadDecisionSet({
    scope: 'decision_sets', slug: decisionSet
  }), {
    condition: () => isUnstartedTemplateInSet
  })

  if (isStoreLoading(template)) {
    return <Wrapper {...{ modal, closeModal, template_set, isParentTemplateSet }} >
      <Loader />
    </Wrapper>;
  } else {
    return <Wrapper {...{ modal, closeModal, template_set, isParentTemplateSet, isUnstartedTemplateInSet, isDecisionSet, decisionSet }}>
      <TemplatePreviewTree />
    </Wrapper>;
  }
}
export const mapStateToProps = ({ template, org_categories, org_driver_types, modal, template_set, decision_set }) => ({
  template, org_categories, org_driver_types, modal, template_set, decision_set
});
export const mapDispatchToProps = (dispatch) => ({
  loadTemplate: () => { dispatch(loadTemplate()) },
  loadTemplateSet: (hash) => dispatch(loadTemplateSet(hash)),
  loadDecisionSet: (hash) => dispatch(loadDecisionSet(hash)),
  updateTemplateData: (data) => { dispatch(updateTemplateData(data)) },
  loadOrgCategories: () => { dispatch(loadOrgCategories()); },
  loadOrgDriverTypes: () => { dispatch(loadOrgDriverTypes()) },
  closeModal: () => { dispatch(closeModal()) }
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplatePreview);


import React from 'react';
import { isBlank } from "../../../../ms_teams/helpers/common";
import { RichTextSection } from "../../../common/RichTextEditor";

const DriverQuestion = ({ driverData, addClass = '' }) =>
  <h2 className={addClass}>
    {driverData.driver.question}
  </h2>

export const DriverNotes = ({ driverNotes }) => {
  if (isBlank(driverNotes)) return null

  return <div className='px-3'>
     <h3>Notes</h3>
     <div className={'text-break text-dark mb-3'}>
       <RichTextSection className="note_paragraph" text={driverNotes} showMoreLess={true}/>
     </div>
   </div>
}

export const QuestionRow = ({ driverData }) =>
  <div className="d-inline-block mb-2">
    <DriverQuestion driverData={driverData} addClass={'m-0'} />
  </div>

import React from 'react';
import Wrapper from '../Wrapper';
import AggregatedData from "../driver/AggregatedData";
import Header from "../template_driver/Header";
import Content from "../template/Content";
import {driverInitData} from "../shared/helpers";
import {Template} from "../../../models/template";
import {assignedTemplateUser} from "./TemplateCard";
import {isPresent} from "../../../helpers/common";

const TemplateDriverCard = ({ driverData, template, tree, indentStep = 1, user, contactsData, openModal }) => {
  const { childrenDrivers, setShowChildren, showChildren } = driverInitData(tree, driverData);
  const templateObj = new Template(template)
  const assignedUser = assignedTemplateUser(templateObj, driverData.driver.assign_to_user);

  const onDriverClick = () => openModal({
    decision: template, drivers: tree.drivers, data_sources: tree.data_sources,
    slug: driverData.driver.slug, type: 'DriverDetailsModal'
  })

  return <>
    <Wrapper indentStep={indentStep} >
      <div className={`d-flex ${isPresent(driverData.driver_sources_slugs) || isPresent(assignedUser) ? '' : 'py-1'}`}>
        <div className="my-auto lh-sm w-100 me-1">
          <Header {...{ driverData, childrenDrivers, setShowChildren, onEditClick: onDriverClick }} />
          <AggregatedData {...{ driverData }} />
        </div>
        <Content {...{ driverData, assignedUser }} />
      </div>
    </Wrapper>
    {showChildren && childrenDrivers.map((child) => {
      return <TemplateDriverCard key={`driver-card-${child.driver.slug}`}
                         {...{
                           driverData: child,
                           indentStep: indentStep + 1,
                           template, tree,
                           user, contactsData, openModal
                         } } />
    })}
  </>
}
export default TemplateDriverCard;

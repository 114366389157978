import { useCallback } from 'react';
import { isPresent, successActions } from "../../helpers/common";
import { isDecisionSetDecision, redirectToHomeCallback, redirectToSetsCallback } from "../../helpers/decision_helpers";
import {
  buildInviteDataBy,
  isHistoricalDecision,
  isHistoricalFlow,
  isTreeBuilder, isTreePreview,
  isWizardV2, isWizardV3, stepKeyWithInvites, WIZARD_STEPS,
  wizardStepDataBy
} from "../../helpers/wizard_helpers";
import { isTemplateSetTemplate } from "../../helpers/template_helpers";
import { buildDrivers, saveDriversCallback } from "../steps_wizard/steps/helpers/tree_builder_step";
import {setTreeSidebarOpen} from "../../store/sidebar/actions";

export const stepTreeData = (wizard, contactsData, decision) => {
  const framingStepData = wizardStepDataBy(wizard, WIZARD_STEPS.tree_framing.key);
  const collaboratorsStepData = wizardStepDataBy(wizard, stepKeyWithInvites(wizard));
  const collaborators = collaboratorsStepData.collaborators || collaboratorsStepData.invites || [];
  const invites = collaborators?.map(email => buildInviteDataBy(email, contactsData.contacts)) || [];
  const users = [
    ...invites.filter(invite => isPresent(invite.user)).map(invite => invite.user),
    decision.user
  ];
  return { users, invites, assigned_collaborator_email: framingStepData.assign_to };
};

export const previewTreeCallback = ({
                                      wizard, tree, decision, stepRef,
                                      updateTreeData, previewWizardTree,
                                      contactsData, isTemplate = false,
                                      updateDecisionData = () => null
                                    }) => () => {
  const additional_data = { finish_later: true }
  if (isWizardV3(wizard)) additional_data.preview = true
  stepRef?.current?.submitStep(additional_data, (success, _, wizardData = wizard) => {
    if (success) {
      const drivers = buildDrivers({ tree, wizard: wizardData, fetchFromTree: false });
      const saveDrivers = saveDriversCallback(updateTreeData, drivers);

      saveDrivers();
      if (!isTemplate) updateDecisionData(stepTreeData(wizardData, contactsData, decision));
      if (!isWizardV3(wizard)) previewWizardTree();
    }
  }, isTemplate);
};

export const cancelPreviewTreeCallback = ({
                                            wizard, tree, decision, stepRef,
                                            updateTreeData, resetSidebars, buildWizardTree, resetTreeDrivers,
                                            contactsData, isTemplate = false,
                                            updateDecisionData = () => null
                                          }) => () => {
  if (isWizardV3(wizard)) {
    stepRef?.current?.submitStep({ finish_later: true, preview: false }, (success, _, wizardData = wizard) => {
      if (success) {
        successActions(true, [resetSidebars, resetTreeDrivers])
        const drivers = buildDrivers({ tree, wizard: wizardData, fetchFromTree: false });
        const saveDrivers = saveDriversCallback(updateTreeData, drivers);

        saveDrivers();
        if (!isTemplate) {
          updateDecisionData(stepTreeData(wizardData, contactsData, decision));
        }
      }
    }, isTemplate);
  } else {
    successActions(true, [resetSidebars, buildWizardTree, resetTreeDrivers])
  }
}

export const nextStepTreeAction = ({ wizard, stepRef }) => {
  if (isWizardV3(wizard)) stepRef?.current?.submitStep();
}

export const wizardSetCallbacks = ({
                                     decision, template = null, isTemplate,
                                     resetTemplateSet = () => null,
                                     resetDecisionSet = () => null
                                   }) => {
  const isSetObject = useCallback(() => isTemplate ? isTemplateSetTemplate(template) : isDecisionSetDecision(decision), [template, decision]);
  const resetSetCallback = useCallback(() =>
      isSetObject() ?
        (isTemplate ? resetTemplateSet() : resetDecisionSet()) :
        () => {},
    [template, decision]
  );
  const setObjectSlug = useCallback(() => isTemplate ? template.template_set_slug : decision.decision_set_slug, [template, decision]);
  const objectSlug = useCallback(() => isTemplate ? template.slug : decision.slug, [template, decision]);

  return {
    isSetObject, resetSetCallback, setObjectSlug, objectSlug
  };
};

export const treeSidebarOpenCallback = (setTreeSidebarOpen) => () => {
  setTreeSidebarOpen(true);
}

export const goToHomepageCallback = (actions, history) => () => {
  successActions(true, actions);
  redirectToHomeCallback(true, history);
};
export const goToSetsCallback = (actions, history, slug, isTemplate) => () => {
  successActions(true, actions);
  redirectToSetsCallback(true, history, slug, isTemplate);
};
export const prevStepCallback = (isTemplate, resetSidebars, prevTemplateWizardStep, prevWizardStep) => () => {
  resetSidebars();
  isTemplate ?
    prevTemplateWizardStep() :
    prevWizardStep();
};
export const showPlaybookButton = (isTemplate, wizard, decision) => {
  if (isTreePreview(wizard)) return false;
  if (isTemplate) return true;

  return decision.playbook && (
    isTreeBuilder(wizard) || isHistoricalDecision(wizard)
  );
};
export const hidePreviewBtn = (wizard) => {
  if (isTreePreview(wizard) || isHistoricalFlow(wizard)) return true;
  if (isWizardV2(wizard)) return false;
  if (isWizardV3(wizard)) return false;

  return !isTreeBuilder(wizard);
};

export const hideStartDecisionBtn = (wizard) => {
  if (!isWizardV3(wizard)) return true;

  return !isTreePreview(wizard);
};
export const hideNextStepBtn = (wizard) => {
  if (!isWizardV3(wizard)) return true;
  if (isHistoricalFlow(wizard)) return true;

  return isTreePreview(wizard);
};

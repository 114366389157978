import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { equalsArraysIgnoreOrder, isBlank, isPresent } from '../../../helpers/common';
import { connect } from "react-redux";
import { resetSearch, searchDashboardDecisions } from '../../../store/homepage/actions';
import { Accordion } from 'react-bootstrap'
import { CATEGORIES } from '../../../models/decision';
import {
  allContacts, DEFAULT_STATE, OWNERS_FILTER_SEPARATOR, STATE_FILTER_SEPARATOR
} from '../../../helpers/filter_helpers';
import DSightOnlyFilterCard from "./DSightOnlyFilterCard";
import ResetFiltersCard from "./ResetFiltersCard";
import DecisionStatusFilter from "./DecisionStatusFilter";
import OwnerFilterCard from "./OwnerFilterCard";
import SharedWithFilterCard from "./SharedWithFilterCard";
import { useParams } from "react-router-dom";

export const Menu = ({home, current_org, contactsData, user, searchDashboardDecisions, saveMenu, onSave}) => {
  const [submit, setSubmit] = useState(false);
  const [orgUsersSelection, setOrgUsersSelection] = useState(allContacts(contactsData, user).filter((u) => home.owners.includes(u.email)));
  const sharedWithAdmission = home.shared_with_admissions.map((admission) => JSON.parse(admission))
  const [sharedWithSelection, setSharedWithSelection] = useState(sharedWithAdmission);
  const [allStatus, setAllStatus] = useState( home.state === DEFAULT_STATE );
  const [dSightOnly, setDSightOnly] = useState(isPresent(home.d_sight_only));
  const [decidedStatus, setDecidedStatus] = useState(home.state.includes(CATEGORIES.recorded));
  const [inFlightStatus, setInFlightStatus] = useState(home.state.includes(CATEGORIES.created));
  const [resultsStatus, setResultsStatus] = useState(home.state.includes(CATEGORIES.results));
  const [openedSection, setOpenedSection]= useState('');
  const { id } = useParams();
  const decisionSetPresent = !!id;

  useEffect(() => {
    if(saveMenu) {
      save();
      onSave();
    }
  }, [saveMenu]);

  useEffect(() => {
    if(!decidedStatus && !inFlightStatus && !resultsStatus) setDefaultValues();
    if(decidedStatus && inFlightStatus && resultsStatus) setDefaultValues();
    if(allStatus) setDefaultValues();
  }, [allStatus, decidedStatus, inFlightStatus, resultsStatus]);

  const setDefaultValues = useCallback(() => {
    setAllStatus(true);
    setDecidedStatus(false);
    setInFlightStatus(false);
    setResultsStatus(false);
  }, []);

  const filtersChanged = useCallback(() => {
    return home.state !== buildStateFilterValues() || home.owners !== buildOwnersFilterValues ||
    !equalsArraysIgnoreOrder(sharedWithAdmission, sharedWithSelection) || home.d_sight_only !== dSightOnly;
  }, [decidedStatus, inFlightStatus, resultsStatus, allStatus, sharedWithSelection, orgUsersSelection, dSightOnly]);

  const buildStateFilterValues = useCallback(() => {
    let result = [];
    if(decidedStatus) result.push(CATEGORIES.recorded);
    if(inFlightStatus) result.push(CATEGORIES.created);
    if(resultsStatus) result.push(CATEGORIES.results);

    return isBlank(result) ? DEFAULT_STATE : result.join(STATE_FILTER_SEPARATOR);
  }, [decidedStatus, inFlightStatus, resultsStatus, allStatus]);

  const buildOwnersFilterValues = useMemo(() => orgUsersSelection.map(u => u.email).join(OWNERS_FILTER_SEPARATOR), [orgUsersSelection]);

  const sharedWithSelectionToURL = useMemo(() => {
    return sharedWithSelection.map((obj) => JSON.stringify(obj));
  }, [sharedWithSelection]);


  const save = useCallback(() => {
    if(filtersChanged()) {
      setSubmit(true);
      searchDashboardDecisions({
        d_sight_only: dSightOnly,
        state: buildStateFilterValues(),
        owners: buildOwnersFilterValues,
        shared_with_admissions: sharedWithSelectionToURL,
        current_page: 1,
        ...(decisionSetPresent && { decision_set_slug: id })
      });
      setSubmit(false);
    }
    onSave();
  }, [orgUsersSelection, allStatus, decidedStatus, inFlightStatus, resultsStatus, dSightOnly,
    sharedWithSelection]);

  const defaultFilters = useCallback(() =>
      allStatus && isBlank(orgUsersSelection) && isBlank(sharedWithSelection) &&
    !dSightOnly,[allStatus, orgUsersSelection, dSightOnly]);

  const reset = useCallback(() => {
    if(defaultFilters()) return;

    setDefaultValues();
    setOrgUsersSelection([]);
    setSharedWithSelection([]);
    setDSightOnly(false)
  }, [defaultFilters()]);

  const handleStatusChange = (event) => {
    switch(event.target.name) {
      case 'all':
        setDefaultValues();
        break;
      case 'decided':
        setAllStatus(false);
        setDecidedStatus(event.target.checked);
        break;
      case 'inFlight':
        setAllStatus(false);
        setInFlightStatus(event.target.checked);
        break;
      case 'results':
        setAllStatus(false);
        setResultsStatus(event.target.checked);
        break;
      default:
        break;
    }
  };

  const nonUsers = useMemo(() => {
    return contactsData.contacts.filter((contact) => isBlank(contact.full_name))
  }, [contactsData.contacts])

  return <>
    <ResetFiltersCard {...{ save, reset, defaultFilters }} />
    <Accordion alwaysOpen>
      <DecisionStatusFilter {...{
        allStatus,inFlightStatus, decidedStatus,
        resultsStatus, handleStatusChange }} />
      <OwnerFilterCard {...{
        openedSection, setOpenedSection,
        orgUsersSelection, setOrgUsersSelection,
        submit, nonUsers
      }} />
      <SharedWithFilterCard {...{
        sharedWithSelection, setSharedWithSelection, openedSection, setOpenedSection,
        nonUsers, submit
      }} />
      {current_org.dsight ? <DSightOnlyFilterCard dSightOnly={dSightOnly} setDSightOnly={setDSightOnly} /> : null}
    </Accordion>
  </>
}

const mapStateToProps = ({ home, current_org, contacts, current_user }) => ({
  home, current_org, contactsData: contacts, user: current_user
});
export default connect(mapStateToProps, { searchDashboardDecisions, resetSearch }, null, { forwardRef: true })(Menu);

import React, { useCallback, useEffect, useState } from 'react';
import { connect } from "react-redux";
import NAElement from "../../../tree_view/side_panel/sections/NAElement";
import DecisionCategoriesModal from "../../modals/DecisionCategoriesModal";
import { CategoryBlockRow } from "../../../decision_categories/CategoryBlockRow";
import { decisionSelectedCategories } from "../../../models/decision";
import { CategorizationPBSection } from "../../../template_view/side_panel/sections/PlaybookNotesSection";
import { EditCategorySection, HeaderCategoriesElement } from '../../../tree_view/side_panel/sections/CategoriesSection'
import { stepKeyWithCategories, wizardStepDataBy } from "../../../helpers/wizard_helpers";
import { getInitialCategoriesData } from "../../steps_wizard/steps/TreeDecisionCategorizationStep";

export const CategoriesSection = ({ wizard, playbook_notes, org_categories }) => {
  const stepData = wizardStepDataBy(wizard, stepKeyWithCategories(wizard))
  const [showEditCategoriesModal, setShowEditCategoriesModal] = useState(false)
  const [categoriesData, setCategoriesData] = useState(getInitialCategoriesData(org_categories, stepData))

  useEffect(() => {
    if (!org_categories.loaded) return;

    setCategoriesData(getInitialCategoriesData(org_categories, stepData));
  }, [org_categories.loaded, wizard])

  const openEditModal = useCallback(() => {
    setShowEditCategoriesModal(true)
  }, [])

  const selectedCategories = decisionSelectedCategories(org_categories, stepData.selected_categories)

  return <div className="p-3 border-bottom">
    <HeaderCategoriesElement />
    <CategorizationPBSection playbook_notes={playbook_notes} sidePanelStyle={true} showNA={false} />
    <div className="d-flex">
      <div className="d-table me-auto">
        <div hidden={selectedCategories.length > 0}>
          <NAElement />
        </div>
        {selectedCategories.map(category =>
          <CategoryBlockRow category={category} selectedCategories={categoriesData} key={`category-block-row-${category.slug}`} />
        )}
      </div>
    </div>
    <EditCategorySection openEditModal={openEditModal} />
    <div className="modals">
      <DecisionCategoriesModal shown={showEditCategoriesModal} onClose={() => setShowEditCategoriesModal(false)} />
    </div>
  </div>
}
const mapStateToProps = ({ playbook_notes, current_user, org_categories, wizard }) => ({
  playbook_notes, user: current_user, org_categories, wizard
});
export default connect(mapStateToProps)(CategoriesSection);

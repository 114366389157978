import React, { useCallback, useMemo } from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { isBlank } from "../../helpers/common";
import Context from "./Context";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Decision from "../../models/decision";
import HamburgerDropdown, { redirectToHomepageCallback, resetDispatch } from './HamburgerDropdown'
import { isPresent } from "../../../ms_teams/helpers/common";
import OrgLogo from "./OrgLogo";
import RenderDecisionInputSidebar from "./buttons/RenderDecisionInputSidebar";
import RenderRecommendationInputSidebar from "./buttons/RenderRecommendationInputSidebar";
import RenderShareDropdown from "./buttons/RenderShareDropdown";
import RenderFollowupResults from "./buttons/RenderFollowupResults";
import RenderRateBuyInButton from "./buttons/RenderRateBuyInButton";

const TreeHeader = ({ tree, current_org, decision, current_user, ...opts }) => {
  const { error, drivers } = tree;
  if (error || isBlank(decision)) return <nav className="navbar"/>;

  const decisionObject = useMemo(() => new Decision(decision, drivers), [decision, tree]);
  const history = useHistory();
  const processBackClick = useCallback(redirectToHomepageCallback({
    current_user, history, ...opts
  }), [current_user]);

  return <header className="decision-tree-header">
    <nav className="navbar navbar-expand bd-navbar px-3">
      <div className="d-flex align-items-center active">
        <div className="logo-dropdown pointer d-none d-lg-flex me-2">
          <OrgLogo current_org={current_org} processBackClick={processBackClick} />
        </div>
        <div className="w-100">
          <h5><Context /></h5>
        </div>
      </div>
      <div className="navbar-nav ms-auto">
        <div className='d-lg-none'>
          <HamburgerDropdown showDecisionSet={isPresent(decision.decision_set_slug)} />
        </div>
        <ul className="navbar-nav ms-auto d-none d-lg-flex align-items-center">
          <li className="nav-item ms-2">
            <RenderRecommendationInputSidebar />
            <RenderDecisionInputSidebar />
            <RenderRateBuyInButton />
            <RenderFollowupResults />
          </li>
          <RenderShareDropdown />
        </ul>
      </div>
    </nav>
    <ProgressBar now={decisionObject.answeringProgress} />
  </header>;
}

const mapStateToProps = ({ tree, current_org, current_user, decision }) => ({
  tree, current_org, current_user, decision
});
export default connect(mapStateToProps, resetDispatch)(TreeHeader);

import {isBlank} from "../../helpers/common";
import {paragraphTag} from "./common";
import {PRIMARY_COLOR} from "../styles/common";

const MAX_INDENT_LEVEL = 9
const BASE_INDENT_SIZE = 20
const LOWER_ALPHA_CLASSES = ['ql-indent-1', 'ql-indent-4', 'ql-indent-7'];
const LOWER_ROMAN_CLASSES = ['ql-indent-2', 'ql-indent-5', 'ql-indent-8'];
const DECIMAL_CLASSES = ['ql-indent-3', 'ql-indent-6', 'ql-indent-9'];
const ROMAN_MATCHES = {
  m: 1000, cm: 900, d: 500, cd: 400,
  c: 100, xc: 90, l: 50, xl: 40,
  x: 10, ix: 9, v: 5, iv: 4, i: 1
}

export function modifyRichText(html) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(paragraphTag(html), 'text/html');
  const orderedLists = doc.querySelectorAll('ol');

  if(isBlank(orderedLists)) return doc.body.innerHTML

  orderedLists.forEach((ol) => {
    const listItems = ol.querySelectorAll('li');
    let letter = 'a';
    let indexRoman = 1;
    let indexDecimal = 1;

    listItems.forEach((li) => {
      const klass = li.classList.value;
      if (LOWER_ALPHA_CLASSES.includes(klass)) {
        indexRoman = 1;
        indexDecimal = 1;
        li.replaceWith(createListItem(letter, li.textContent, klass));
        letter = getNextLetter(letter);
      } else if (LOWER_ROMAN_CLASSES.includes(klass)) {
        letter = 'a';
        indexDecimal = 1;
        li.replaceWith(createListItem(romanize(indexRoman), li.textContent, klass));
        indexRoman += 1;
      } else if (DECIMAL_CLASSES.includes(klass)) {
        letter = 'a';
        indexRoman = 1;
        li.replaceWith(createListItem(indexDecimal, li.textContent, klass));
        indexDecimal += 1;
      } else {
        letter = 'a';
        indexRoman = 1;
        indexDecimal = 1;
      }
    });
  });

  return doc.body.innerHTML;
}

function createListItem(index, content, klass) {
  const p = document.createElement('p');
  p.textContent = `${index}. ${content}`;
  p.classList.add(klass);
  return p;
}

function getNextLetter(letter) {
  return String.fromCharCode(letter.charCodeAt(0) + 1);
}

const romanize = (num) => {
  let lookup = ROMAN_MATCHES, roman = '', i;
  for ( i in lookup ) {
    while ( num >= lookup[i] ) {
      roman += i;
      num -= lookup[i];
    }
  }
  return roman;
}

export const quillIndentStyles = () => {
  const styles = {
    p: {
      margin: 0,
    },
    ol: {
      margin: 0
    },
    ul: {
      margin: 0
    },
    li: {
      margin: 0
    },
    a: {
      color: PRIMARY_COLOR,
      fontSize: 10.5,
      textDecoration: 'none'
    }
  };

  for (let i = 1; i <= MAX_INDENT_LEVEL; i++) {
    styles[`.ql-indent-${i}`] = {
      paddingLeft: BASE_INDENT_SIZE * (i + 1)
    };
  }
  return styles;
};
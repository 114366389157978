import React, {useMemo, useState} from 'react';
import { buildNewDriver, findDriverIn, saveDriversCallback } from "../steps_wizard/steps/helpers/tree_builder_step";
import EditDriverDetailsModal, { modalDriverData } from "../../tree_editor/modals/DriverDetailsModal";
import HistoricalDriverDetailsModal from "../../tree_editor/modals/HistoricalDriverDetailsModal";
import { isBlank, isPresent } from "../../helpers/common";
import { DEFAULT_RATING_SCALE } from "../../models/driver";
import * as moment from "moment";
import { resetDecisionSetData } from "../../store/decision_set/common_actions";

const sortByLevelOrder = (a, b) => {
  const aLevelOrder = a.driver.level_order || Infinity;
  const bLevelOrder = b.driver.level_order || Infinity;
  return (aLevelOrder < bLevelOrder) ? -1 : 1
}
const reArrangeDriversByLevelOrder = (parentDriverData, levelOrder, newLevelOrder) => {
  if(levelOrder === newLevelOrder || newLevelOrder < 1 || newLevelOrder > parentDriverData.children.length) return false;
  const driverData = parentDriverData.children.find((driverData) => driverData.driver.level_order === newLevelOrder);
  driverData.driver.level_order = levelOrder;
  return true;
}
const updateDriverDataCallback = (drivers, setDrivers) => (slug, inputValue, assignTo, notes = '', explanation = '', driverTypeSlug = '', levelOrder = 1, answer = '', ratingScale = DEFAULT_RATING_SCALE.value, ratingLabels = '', dataSourcesSlugs = [], dueDate = null) => {
  const newDrivers = [...drivers]
  const [driverData, parentDriversData] = findDriverIn(slug, { children: newDrivers })
  if (isPresent(driverData)) {
    reArrangeDriversByLevelOrder(parentDriversData, driverData.driver.level_order, levelOrder)
    driverData.driver.question = inputValue;
    driverData.driver.assign_to_user = assignTo;
    driverData.driver.driver_type_slug = driverTypeSlug;
    driverData.driver.notes = notes;
    driverData.driver.explanation = explanation;
    driverData.driver.level_order = levelOrder;
    driverData.driver.answer = answer;
    driverData.driver.rating_scale = ratingScale;
    driverData.driver.rating_labels =  ratingLabels;
    driverData.driver.due_date = dueDate;
    driverData.driver_sources_slugs = dataSourcesSlugs;
    if (isBlank(driverData.children)) driverData.children.push(buildNewDriver(driverData))

    if (isPresent(parentDriversData)) {
      const { children } = parentDriversData
      if (isPresent(children) && isPresent(children[children.length - 1]?.driver?.question)) {
        children.push(buildNewDriver(parentDriversData, children.length+1))
      }
      children.sort(sortByLevelOrder)
    }
    setDrivers(newDrivers);
  }
  return newDrivers;
}

const onBuilderFormSubmitCallback = (saveDrivers, updateDriverData, updateDecisionData, setDecisionDescription) => (slug, inputValue, assignTo, notes, explanation, driverTypeSlug, levelOrder, isDecision, answer, ratingScale, ratingLabels, dataSourcesSlugs, dueDate) => {
  if (isDecision) {
    setDecisionDescription(inputValue)
    updateDecisionData({ description: inputValue })
  } else {
    return updateDriverData(slug, inputValue, assignTo, notes, explanation, driverTypeSlug, levelOrder, answer, ratingScale, ratingLabels, dataSourcesSlugs, dueDate)
  }
}

export default ({
                  tree, orgDriverTypes = {}, collaborators = [], title, isTemplate, submitStep, submitDrivers,
                  modal, driverData, modalDrivers, isHistoricalDecision = false,
                  onClose, updateTreeData
                }) => {
  const availableDriverTypes = useMemo(() => orgDriverTypes.available_types, [orgDriverTypes]);

  const [drivers, setDrivers] = useState(modalDrivers)
  const [inputValue, setInputValue] = useState(driverData.driver.question || '')
  const [answer, setAnswer] = useState(driverData.driver.answer || '')
  const [notes, setNotes] = useState(driverData.driver.notes || '')
  const [explanation, setExplanation] = useState(driverData.driver.explanation || '')
  const [ratingScale, setRatingScale] = useState(driverData.driver.rating_scale || DEFAULT_RATING_SCALE.value)
  const [ratingLabels, setRatingLabels] = useState(driverData.driver.rating_labels || {})
  const initDate = isPresent(driverData?.driver.due_date) ? moment(driverData?.driver.due_date).format('DD MMM, yyyy') : null;
  const [dueDate, setDueDate] = useState(initDate);
  const defaultHistoricalDriverType = availableDriverTypes?.find((driverType) => driverType.default_historical_driver_type);
  const showDefaultDriverType = isBlank(driverData.driver.driver_type_slug) && isPresent(defaultHistoricalDriverType)
    && isHistoricalDecision
  const [driverTypeSlug, setDriverTypeSlug] = useState(showDefaultDriverType ? defaultHistoricalDriverType.slug : (driverData.driver.driver_type_slug || ''));
  const [assignedToUser, setAssignedToUser] = useState(driverData.driver.assign_to_user);
  const levelOrder = driverData.driver.level_order || 1
  const dataSourcesSlugs = driverData.driver_sources_slugs || [];

  const saveDrivers = saveDriversCallback(updateTreeData, drivers)
  const updateDriverData = updateDriverDataCallback(drivers, setDrivers)
  const onFormSubmit = onBuilderFormSubmitCallback(saveDrivers, updateDriverData)

  const onSubmitDrivers = (updatedDrivers) => {
    submitDrivers(updatedDrivers)
    if(tree.loaded) updateTreeData({drivers: updatedDrivers})
  }

  const onSubmitModal = () => {
    const updatedDrivers = onFormSubmit(modal.slug, inputValue, assignedToUser,notes, explanation, driverTypeSlug, levelOrder, false, answer, ratingScale, ratingLabels, dataSourcesSlugs, dueDate)
    submitStep({ drivers: updatedDrivers, finish_later: true }, success => {
      if(success && tree.loaded) updateTreeData({drivers: updatedDrivers})
    })
    onClose()
  }
  const onChangeDriverType = ({ value }) => setDriverTypeSlug(value);
  const options = {
    question: inputValue, setQuestionValue: setInputValue, driverData,
    notes, setNotes, driverTypeSlug, onChangeDriverType, answer, setAnswer,
    onClose, onSubmitModal, title, explanation, setExplanation, setDriverTypeSlug, onSubmitDrivers,
    ratingScale, setRatingScale, ratingLabels, setRatingLabels, dueDate, setDueDate,
    show: true
  }

  if(isHistoricalDecision) {
    return <HistoricalDriverDetailsModal {...options}/>
  } else {
    return <EditDriverDetailsModal
      {...options}
      {...{ collaborators, isTemplate, isHistoricalDecision, assignedToUser, setAssignedToUser, setDriverTypeSlug }}
      isWizard={true}
    />
  }
}

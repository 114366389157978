import { clearHtmlTags, isBlank, toSentence } from "../helpers/common";
import { notifySharedWarning } from "../helpers/alert_helpers";

const ORG_TYPE = 'Org';
const SET_TYPE = 'DecisionSet';

export const itemIdentifier = (item, idField = 'admittable_id') => `${item.admittable_type}-${item[idField]}`

export const checkNotifyAllSharedWarning = (displayWarning, sharedList, action) => {
  if (displayWarning) {
    notifySharedWarning(sharedList, notify_shared => {
      action({ notify_shared })
    })
  } else {
    action({ notify_shared: false })
  }
}

export default class ShareData {
  constructor(share_data) {
    this.share_data = share_data
  }

  get isShared() {
    return this.share_data.sharable;
  }

  get availableItems() {
    return this.share_data.available_items;
  }

  get availableDecisionShareItems() {
    return this.share_data.available_decision_shared_items;
  }

  get selectedItems() {
    return this.share_data.values;
  }

  get shareList() {
    return toSentence(
      this.selectedItems.filter(row => row.admittable_type !== ORG_TYPE).map(row =>
        clearHtmlTags(row.share_label) || row.label
      ),
      { last: ', and' }
    )
  }

  get isSharedWIthNonOrg() {
    return this.selectedItems.some(row => row.admittable_type !== ORG_TYPE)
  }

  get entireOrg() {
    return this.availableItems.find(row => row.admittable_type === ORG_TYPE)
  }

  get entireDecisionSet() {
    return this.availableItems.find(row => row.admittable_type === SET_TYPE)
  }

  get entireOrgLabel() {
    return this.entireOrg?.label?.replace('All', 'all')
  }

  get couldShareOrg() {
    if (isBlank(this.entireOrg)) return false;

    return !this.selectedItems.some(row => row.admittable_type === ORG_TYPE);
  }

  get couldShareSet() {
    if (isBlank(this.entireDecisionSet)) return false;

    return !this.selectedItems.some(row => row.admittable_type === SET_TYPE);
  }
}

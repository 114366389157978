import React, {Fragment, useState} from 'react';
import { connect } from "react-redux";
import {isBlank, isPresent, qSortArray, sortSourceByType} from "../../../helpers/common";
import {
  attachReport,
  detachReport
} from "../../../store/tree/actions";
import { addSourceToDecision } from "../../../store/decision_set/actions";
import { hasParticipantAdmission, isVisitor } from "../../../helpers/user_helpers";
import NAElement from "./NAElement";
import {decisionDataSources, linkedReportDataSources} from "../../../models/decision";
import DataSource, {
  DecisionOrDSightDataSourcesTypes, DecisionOrTemplateOrDSightDataSourcesTypes,
  LinkedReportTemplateDataSourcesType,
  DSightSourcesTypes,
  Type, DecisionSetSourcesTypes, SourceableTypes, IMAGE_FILE_TYPES
} from "../../../models/data_source";
import { DataSourcesPBSection } from "../../../template_view/side_panel/sections/PlaybookNotesSection";
import {
  AddSourceRows,
  DataSourceRow,
  UploadingSourceRow,
} from "../../../template_view/side_panel/sections/DataSourcesSection";
import { generateBatchDataSourceUploadState } from "../../../helpers/uploads_callbacks";
import Select from "react-select";
import { BorderedFilterStyle } from "../../../tree_wizard/styles/choice_entry_styles";
import SourceItem from "../../data_sources/Item";
import Image from "react-bootstrap/Image";
import {
  createSource,
  destroySource,
  replaceSource,
  saveUploadingSources,
  updateSource
} from "../../../store/sidebar/actions";
import AccordionWrapper from "../../../common/AccordionWrapper";

const HeaderElement = () => <h3 className="mb-0">Data sources</h3>

const LocalAddSourceRowsRecommendation = ({ user, fromRecommendationPanel, ...opts }) => {
  if(isVisitor(user) || hasParticipantAdmission(user)) return null;

  return <AddSourceRows {...opts} fromRecommendationPanel={fromRecommendationPanel} isTemplate={false} />
};

const LocalAddSourceRowsDecision = ({ user, fromDecisionPanel, ...opts }) => {
  if(isVisitor(user) || hasParticipantAdmission(user)) return null;

  return <AddSourceRows {...opts} fromDecisionPanel={fromDecisionPanel} isTemplate={false} />
};

export const sourcesData = (current_org, finalRelatedDataSources) => {
  const allLinkedReports = linkedReportDataSources(finalRelatedDataSources);
  const allLinkedReportsSlugs = allLinkedReports.map((source) => source.report_slug)
  const allLinkedReportsOnDecisionSlugs = allLinkedReports.filter((source) => !source.hide_in_decision_panel).map((source) => source.report_slug)
  const allLinkedReportsOnRecommendationSlugs = allLinkedReports.filter((source) => !source.hide_in_recommendation_panel).map((source) => source.report_slug)
  const orgReportsForDetailsPanel = current_org.reports.filter((r) => !allLinkedReportsSlugs.includes(r.slug))
  const orgReportsForDecisionPanel = current_org.reports.filter((r) => !allLinkedReportsOnDecisionSlugs.includes(r.slug))
  const orgReportsForRecommendationPanel = current_org.reports.filter((r) => !allLinkedReportsOnRecommendationSlugs.includes(r.slug))

  return {
    orgReportsForDecisionPanel: orgReportsForDecisionPanel,
    orgReportsForRecommendationPanel: orgReportsForRecommendationPanel,
    orgReportsForDetailsPanel: orgReportsForDetailsPanel,
    decision_sources: finalRelatedDataSources.filter((source) => source.hide_in_decision_panel && source.type !== Type.LINKED_REPORT_DATA_SOURCE),
    recommendation_sources: finalRelatedDataSources.filter((source) => source.hide_in_recommendation_panel && source.type !== Type.LINKED_REPORT_DATA_SOURCE)
  }
}

const DataSourcesList = ({
                           data_sources, user, fromDecisionPanel,
                           isWizard = false,
                           ...opts
                         }) => {
  return data_sources.map(data_source =>
    <DataSourceRow data_source={data_source}
                   isWizard={isWizard}
                   isTemplate={false}
                   fromDecisionPanel={fromDecisionPanel}
                   hideMenu={isVisitor(user) || hasParticipantAdmission(user) || new DataSource(data_source).isDSight}
                   key={`decision-details-data-source-${data_source.slug}-${data_source.key}`}
                   {...opts}
    />
  )
};

const UploadingSourcesList = ({
                           uploading_sources, user,
                           ...opts
                         }) => {
  return uploading_sources.map((sourceData, i) =>
    <UploadingSourceRow uploading_source={sourceData.uploadingSource}
                        isTemplate={false}
                        key={`decision-details-uploading-source-${sourceData.uploadingSource.slug}-${i}`}
                        {...opts}
    />
  )
};

export const FinalDataSourcesRow = ({ data_sources }) =>
  isBlank(data_sources) ? null :
    <div className="px-3 my-2">
      <h3>Data sources</h3>
      <div className="d-flex flex-column" hidden={isBlank(data_sources)}>
        {sortSourceByType(data_sources, IMAGE_FILE_TYPES).map(data_source => {
          const sourceObj = new DataSource(data_source);
          return sourceObj.isImage ?
            <Image className='w-100 my-2 border'
                   key={`decision-details-data-source-${data_source.slug}-${data_source.key}`}
                   src={data_source.url} alt={data_source.title} title={data_source.title} /> :
            <SourceItem source={data_source} hideMenu={true}
                        key={`decision-details-data-source-${data_source.slug}-${data_source.key}`} />
        })}
      </div>
    </div>

export const SelectExistingSourceRow = ({ selectedSource, onSelectSource, onSelectReport, orgReports = [],
                                          onSelectSetSource,
                                          dataSources = [], decisionSetSources = [] }) => {
  const combinedSources =
    [
      ...dataSources.map(h => ({ ...h, sourceable_type: SourceableTypes.DECISION })),
      ...decisionSetSources.map(h => ({ ...h, sourceable_type: SourceableTypes.DECISION_SET })),
      ...orgReports.map(h => ({ ...h, sourceable_type: SourceableTypes.REPORT }))
    ]

  if (isBlank(combinedSources)) return <></>

  const list = qSortArray(combinedSources, false, d => d.created_at)
  const decisionOrSetOption = (option) => [SourceableTypes.DECISION, SourceableTypes.DECISION_SET].includes(option.sourceable_type)
  const onChange = (option) => {
    switch (option.sourceable_type) {
      case SourceableTypes.DECISION_SET:
        onSelectSetSource(option)
        break;
      case SourceableTypes.DECISION:
        onSelectSource(option)
        break;
      case SourceableTypes.REPORT:
        onSelectReport(option)
        break;
    }
  }

  return <div className="existing-source-dropdown">
    <Select
      value={selectedSource}
      onChange={onChange}
      components={{IndicatorSeparator: () => null}}
      styles={BorderedFilterStyle}
      placeholder={`${isBlank(dataSources) && isBlank(decisionSetSources) ? 'Add a report' : 'Add an existing data source or report'}`}
      options={list.map(source => ({
        value: source.slug,
        label: <span style={{wordBreak: 'break-word'}}>
          {
            decisionOrSetOption(source) ? source.title || source.link_url : source.name
          }
        </span>,
        sourceable_type: source.sourceable_type })
      )}/>
  </div>
}

const SelectReportSourceRow = ({ selectedSource, onSelectReport, orgReports }) =>
  <div className="existing-report-source-dropdown">
    <Select
      value={selectedSource}
      onChange={onSelectReport}
      components={{ IndicatorSeparator:() => null }}
      styles={BorderedFilterStyle}
      placeholder="Add a report"
      options={orgReports.map(report => ({
        value: report.slug,
        label: <span style={{wordBreak: 'break-word'}}>
          {report.name}
        </span>})
      )} />
  </div>

export const AddReportSelector = ({ user, fromDecisionPanel, fromRecommendationPanel, orgReportsForDetailsPanel, selectedSource, onSelectReport }) => {
  if(isVisitor(user) || hasParticipantAdmission(user)) return null;

  return <div className="mt-2" hidden={fromDecisionPanel || fromRecommendationPanel || isBlank(orgReportsForDetailsPanel)}>
    <div className="new-source-entries">
      <SelectReportSourceRow selectedSource={selectedSource} onSelectReport={onSelectReport}
                             orgReports={orgReportsForDetailsPanel}/>
    </div>
  </div>
}

export const DataSourcesSection = ({ current_org,
                                     decision, playbook_notes, data_sources, user, fromRecommendationPanel = false,
                                     createSource, uploading_sources, fromDecisionPanel = false, decision_sources,
                                     decision_set_sources,
                                     saveUploadingSources, addClass, isTemplate = false, recommendation_sources,
                                     isWizard, updateSource, driver = { slug: null }, attachReport,
                                     orgReportsForDecisionPanel, orgReportsForRecommendationPanel, orgReportsForDetailsPanel,
                                     addSourceToDecision, isDecision = false,
                                     ...opts
                                  }) => {
  if ((isVisitor(user) || hasParticipantAdmission(user)) && isBlank(data_sources))
    return <div className={`${addClass}`}>
      <HeaderElement />
      <NAElement />
    </div>

  const submitLink = (value, callback) => { createSource({ link_url: value }, {}, callback, isWizard); };
  const submitLinkInDecisionPanel = (value, callback) => { createSource({ link_url: value, hide_in_decision_panel: false }, {}, callback, isWizard); };
  const submitLinkInRecommendationPanel = (value, callback) => { createSource({ link_url: value, hide_in_recommendation_panel: false }, {}, callback, isWizard); };
  const [selectedSource, setSelectedSource] = useState('');
  const uploadOptions = generateBatchDataSourceUploadState(data_sources, uploading_sources, saveUploadingSources, user)

  const onSelectDecisionSource = (option) => {
    setSelectedSource(option.value);
    updateSource(option.value, { hide_in_decision_panel: false, driver_slug: driver.slug }, (isTemplate || isWizard));
  };
  const onSelectSetSource = (option) => {
    setSelectedSource(option.value);

    const attributes = { data_source: { decision_slug: decision.slug } }
    if(fromDecisionPanel) attributes.data_source.hide_in_decision_panel = false;
    if(fromRecommendationPanel) attributes.data_source.hide_in_recommendation_panel = false;
    addSourceToDecision(option.value, decision.decision_set_slug, attributes, (isTemplate || isWizard));
  };
  const onSelectReport = (option) => {
    setSelectedSource(option.value);
    attachReport(option.value, {}, (isTemplate || isWizard));
  };
  const onSelectRecommendationReport = (option) => {
    setSelectedSource(option.value);
    attachReport(option.value, { hide_in_recommendation_panel: false }, (isTemplate || isWizard));
  };
  const onSelectDecisionReport = (option) => {
    setSelectedSource(option.value);
    attachReport(option.value, { hide_in_decision_panel: false }, (isTemplate || isWizard));
  };
  const onSelectRecommendationSource = (option) => {
    setSelectedSource(option.value);
    updateSource(option.value, { hide_in_recommendation_panel: false, driver_slug: driver.slug }, (isTemplate || isWizard));
  };

  const recommendationAccordion = {
    header: <HeaderElement />,
    body: <Fragment>
      <div className="d-flex flex-column" hidden={isBlank(data_sources)}>
        <DataSourcesList data_sources={data_sources}
                         user={user}
                         isWizard={isWizard}
                         fromRecommendationPanel={fromRecommendationPanel}
                         updateSource={updateSource}
                         {...uploadOptions}
                         {...opts} />
        <UploadingSourcesList uploading_sources={uploading_sources} user={user} {...uploadOptions} {...opts} />
      </div>
      <LocalAddSourceRowsRecommendation user={user}
                                        submitLink={fromRecommendationPanel ? submitLinkInRecommendationPanel : submitLink}
                                        isWizard={isWizard}
                                        fromRecommendationPanel={fromRecommendationPanel}
                                        createSource={createSource}
                                        {...uploadOptions} />
      <div className="mt-2" hidden={isBlank(recommendation_sources) && isBlank(decision_set_sources) && isBlank(orgReportsForRecommendationPanel)}>
        <div className="new-source-entries">
          <SelectExistingSourceRow selectedSource={selectedSource}
                                   onSelectSource={onSelectRecommendationSource}
                                   onSelectReport={onSelectRecommendationReport}
                                   onSelectSetSource={onSelectSetSource}
                                   dataSources={recommendation_sources}
                                   decisionSetSources={decision_set_sources}
                                   orgReports={orgReportsForRecommendationPanel}
          />
        </div>
      </div>
    </Fragment>,
    defaultExpand: data_sources.some((source) => !source.hide_in_recommendation_panel)
  };

  if(fromRecommendationPanel) {
    return <AccordionWrapper accordionObject={recommendationAccordion} className={`${addClass}`} />
  }

  const decisionAccordion = {
    header: <HeaderElement />,
    body: <Fragment>
      <DataSourcesPBSection playbook_notes={playbook_notes}
                            sidePanelStyle={true}
                            showNA={!data_sources?.length}
                            hidden={fromDecisionPanel}
                            isDecision={isDecision}
                            {...opts}/>
      <div className="d-flex flex-column mt-2" hidden={isBlank(data_sources)}>
        <DataSourcesList data_sources={data_sources}
                         user={user}
                         isWizard={isWizard}
                         fromDecisionPanel={fromDecisionPanel}
                         updateSource={updateSource}
                         {...uploadOptions}
                         {...opts} />
        <UploadingSourcesList uploading_sources={uploading_sources} user={user} {...uploadOptions} {...opts} />
      </div>
      <LocalAddSourceRowsDecision user={user}
                                  submitLink={fromDecisionPanel ? submitLinkInDecisionPanel : submitLink}
                                  isWizard={isWizard}
                                  fromDecisionPanel={fromDecisionPanel}
                                  createSource={createSource}
                                  {...uploadOptions}
                                  {...opts} />
      {
        (isPresent(decision.decision_set_slug) && isPresent(decision_set_sources)) || fromDecisionPanel ?
          <div className="mt-2" hidden={isBlank(decision_sources) && isBlank(decision_set_sources) && isBlank(orgReportsForDecisionPanel)}>
            <div className="new-source-entries">
              <SelectExistingSourceRow selectedSource={selectedSource}
                                       onSelectSource={onSelectDecisionSource}
                                       onSelectReport={onSelectDecisionReport}
                                       onSelectSetSource={onSelectSetSource}
                                       dataSources={decision_sources}
                                       decisionSetSources={decision_set_sources}
                                       orgReports={orgReportsForDecisionPanel}

              />
            </div>
          </div> :
          current_org.show_report_insights ?
            <AddReportSelector user={user}
                               fromDecisionPanel={fromDecisionPanel}
                               fromRecommendationPanel={fromRecommendationPanel}
                               orgReportsForDetailsPanel={orgReportsForDetailsPanel}
                               selectedSource={selectedSource}
                               onSelectReport={onSelectReport} /> : null
      }
    </Fragment>,
    defaultExpand: fromDecisionPanel ? data_sources.some((source) => !source.hide_in_decision_panel) : isPresent(data_sources)
  };

  if (isDecision) {
    return <div className={`${addClass}`}>
      <HeaderElement />
      {decisionAccordion.body}
    </div>
  }

  return  <AccordionWrapper accordionObject={decisionAccordion} className={`${addClass}`} />
};
const mapStateToProps = ({ decision, current_org, playbook_notes, tree, current_user, isWizard={isWizard} }) => {
  const sourcesScopes = isWizard ?
    DecisionOrTemplateOrDSightDataSourcesTypes :
    DecisionOrDSightDataSourcesTypes
  const available_tree_data_sources = isBlank(tree.data_sources) ? [] : tree.data_sources.filter((source) => !LinkedReportTemplateDataSourcesType.includes(source.type))
  const available_set_sources = available_tree_data_sources.filter((source) => DecisionSetSourcesTypes.includes(source.type))
  const sortedRelatedDataSources = decisionDataSources(available_tree_data_sources || [], tree.drivers, sourcesScopes)
  const finalRelatedDataSources = sortedRelatedDataSources.filter((source) => !DSightSourcesTypes.includes(source.type))
  return {
    current_org: current_org,
    data_sources: sortedRelatedDataSources,
    decision_set_sources: available_set_sources,
    uploading_sources: tree.uploading_sources,
    user: current_user,
    decision, playbook_notes,
    ...sourcesData(current_org, finalRelatedDataSources)
  }
};
export default connect(mapStateToProps, {
  createSource, updateSource, destroySource, saveUploadingSources, replaceSource,
  attachReport, detachReport,
  addSourceToDecision
})(DataSourcesSection);

import {pageHeader} from "./sections/PageHeader";
import {pageFooter} from "./sections/PageFooter";
import {pageProperties} from "../index";
import {keyDriverQuestionBlock} from "./sections/AdditionalSections";
import Driver from "../../models/driver";
import {isBlank, isPresent, qSortArray, sortSourceByType} from "../../helpers/common";
import {baseBlockSection, baseHeadParagraph, baseRichBlock} from "./sections/wrappers";
import {dSightInsightsBlock} from "./sections/DSightBlocks";
import * as moment from "moment";
import {HeadingLevel} from "docx";
import {MENTIONED_REGEXP} from "../../helpers/discussion_helpers";
import {PDF_IMAGE_FILE_TYPES} from "../../models/data_source";
import {dataSourcesSection} from "./sections/DataSources";
import {enteredBySection} from "./sections/commonBlocks";
import { userName } from "../../helpers/user_helpers";

export const dSightDriverBlock = (driver, hideBlock) => {
  const driverObj = new Driver(driver);
  const insightsData = driverObj.insightsData
  if (isBlank(insightsData) || hideBlock) return [];

  return [
    baseHeadParagraph({text: 'D-Sight analysis'}),
    ...dSightInsightsBlock(insightsData)
  ]
}

export const driverTypeBlock = (decision, driver, org_driver_types) => {
  if (isBlank(driver.answered_at) || isBlank(driver.answered_by_user)) return {};

  const driverObj = new Driver(driver, [], org_driver_types);
  const driverType = driverObj.driverType;
  const answeredByUser = decision.users.find(u => u.email === driver.answered_by_user);
  const prefix = `${isBlank(driver.driver_type_slug) ? 'Entered' : `${driverType.name} entered`}`

  return enteredBySection({user: answeredByUser, time: driver.answered_at, prefix})
}

export const driverComments = (driver, decision) => {
  if (isBlank(driver.comments)) return [];

  const driverComments = []
  driver.comments.forEach(comment => {
    const commentedUser = decision.users.find(u => u.email === comment.user_email);
    const description = comment.description.replace(MENTIONED_REGEXP, '$1');
    const text = `<strong>${userName(commentedUser, comment.user_email)}:</strong> ${description}`

    return driverComments.push(
      ...baseRichBlock({text})
    )
  })

  return [
    baseHeadParagraph ({text: 'Comments'}),
    ...driverComments
  ]
}

export const driverDataSources = (driver, driver_sources_slugs, data_sources = []) => {
  const driverSourcesSlugs = driver_sources_slugs || [];
  const driverDataSources = data_sources.filter((source) => driverSourcesSlugs.includes(source.slug));
  if (isBlank(driverDataSources)) return [];

  const sortedDriverDataSources = qSortArray(driverDataSources, true,(o) => moment(o.created_at));
  const sortedDataSources = sortSourceByType(sortedDriverDataSources, PDF_IMAGE_FILE_TYPES)

  return dataSourcesSection(sortedDataSources)
}

export const DriverSubPage = (decision, driver, org_driver_types, driver_sources_slugs, data_sources, index) => {
  const keyDriverBody = []
  const driverObj = new Driver(driver);
  const hideBlock = !!(driverObj.isAnswered);

  keyDriverQuestionBlock({driver, keyDriverBody, heading: HeadingLevel.HEADING_5, id: `driver-${driver.slug}`, instance: `driver-summary-${index}`})

  return [
    ...keyDriverBody,
    ...baseBlockSection({header: 'Details', text: driver.explanation, instance: `driver-details-${index}`}),
    ...baseBlockSection({header: 'Confidence', text: isPresent(driver.confidence) ? `${driver.confidence} out of 5` : ''}),
    driverTypeBlock(decision, driver, org_driver_types),
    ...dSightDriverBlock(driver, hideBlock),
    ...driverComments(driver, decision),
    ...driverDataSources(driver, driver_sources_slugs, data_sources)
  ]
}

export const KeyDriverPage = (format, decision, data_sources, driverData, extraImages, org_driver_types, index) => {
  const {driver, driver_sources_slugs} = driverData

  return {
    ...pageProperties({format}),
    ...pageHeader({head: 'Drivers', description: driver.question, _, anchor: 'anchorForDriversPage', id: '', skipFirstPage: true}),
    ...pageFooter(extraImages),
    children: [
      ...DriverSubPage(decision, driver, org_driver_types, driver_sources_slugs, data_sources, index)
    ]
  }
}

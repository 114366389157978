import React from 'react';
import { RichTextEditor } from "../../../common/RichTextEditor";

export default ({ isTemplate, titleNotes, setTitleNotes }) =>
  <div className="w-100 mb-3" hidden={!isTemplate}>
    <h3>Title notes</h3>
    <RichTextEditor quillId={'Title notes'} value={titleNotes} setValue={setTitleNotes}
                    placeholder="Enter any comments or instructions for the decision question"
    />
  </div>

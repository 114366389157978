import React, {useState, useEffect} from 'react';
import { connect } from "react-redux";
import { DriverAssignment, DriverPlaybook, StatusAndQuestion } from "../../tree_view/nodes/ZTreeDriverNode";
import { loadContacts } from "../../../store/contacts/actions";
import { fetchContacts } from "../../../tree_wizard/steps_wizard/steps/helpers/tree_builder_step";
import { isBlank } from "../../../helpers/common";

const ZTreeDriverNode = ({ key, tree, treeNode, collaborators, contacts, wizard }) => {
  const [allCollaborators, setAllCollaborators] = useState(collaborators);
  useEffect(() => {
    if (contacts.loaded && isBlank(collaborators)) setAllCollaborators(fetchContacts(wizard, contacts, true))
  }, [contacts, wizard, collaborators, treeNode]);

  return <div className="d-flex align-items-center h-inherit justify-content-between" key={key}>
    <StatusAndQuestion treeNode={treeNode} classes={`selectable-area ${tree.copied_ztree_node.slug === treeNode.slug ? 'copied' : ''} text-truncate`} />
    <div className="d-flex align-items-center">
      <DriverPlaybook treeNode={treeNode} />
      <DriverAssignment {...{treeNode}} collaborators={allCollaborators} classNames="mb-0" />
    </div>
  </div>
}

const mapDispatchToProps = (dispatch) => ({
  loadContacts: (callback) => dispatch(loadContacts(callback))
});
const mapStateToProps = ({ tree, decision, template, contacts, wizard },  { isTemplate }) => ({
  tree, decision: isTemplate ? template : decision, contacts, wizard
});
export default connect(mapStateToProps, mapDispatchToProps)(ZTreeDriverNode);

import React from 'react';
import UserAvatarImage from "../../../../../common/UserAvatarImage";
import { connect } from "react-redux";

const UserMessage = ({
  children,
  // redux
  current_user
}) => {
  return (
    <div className="d-flex align-items-center mb-2">
      <UserAvatarImage user={current_user}/>
      <div className="ms-2 w-100 p-2 bg-secondary rounded border-top-start-90">
        { children}
      </div>
    </div>
  );
};
const mapStateToProps = ({ current_user }) => ({ current_user });
export default connect(mapStateToProps, {})(UserMessage);

import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { agGridInit } from "../helpers/ag_grid_helpers";
import ActionsRow from "./components/ActionsRow";
import { allContacts } from "../../helpers/filter_helpers";
import { updateScenario } from "../../store/forecast_simulator_scenario/actions";
import {setLargeScalePanelOpen} from "../../store/sidebar/actions";

const ScenarioForecastData = ({ forecast_simulator_scenario, contactsData, current_user, gridRef, updateScenario, setLargeScalePanelOpen }) => {
  const [runModelActive, setRunModelActive] = useState(false);
  const [updateCells, setUpdateCells] = useState(false);
  const { ...agGridOpts } = agGridInit(forecast_simulator_scenario, setRunModelActive, updateCells, setUpdateCells, gridRef, updateScenario, setLargeScalePanelOpen)
  const allContactsData = useMemo(() => allContacts(contactsData, current_user), [contactsData.contacts, current_user]);
  const userEmail = forecast_simulator_scenario.scenario.data.attributes.user_email;
  const scenarioUser = allContactsData.find(i => i.email === userEmail) || { email: userEmail, value: userEmail  }

  const onRunModel = () => {
    setUpdateCells(true);
    setRunModelActive(false);
  }

  return <>
    <ActionsRow {...{ forecast_simulator_scenario, user: scenarioUser, runModelActive, onRunModel }} />
    <div className="row">
      <div className="col ag-theme-quartz ag-quartz-styles">
        <AgGridReact {...{...agGridOpts}} ref={gridRef} />
      </div>
    </div>
  </>
}

const mapStateToProps = ({forecast_simulator_scenario, contacts, current_user}) => ({
  forecast_simulator_scenario, contactsData: contacts, current_user
});
const mapDispatchToProps = (dispatch) => ({
  updateScenario: (scenario_id, updateData, callback, showLoading) =>
    dispatch(updateScenario(scenario_id, updateData, callback, showLoading)),
  setLargeScalePanelOpen: (open) => dispatch(setLargeScalePanelOpen(open))
});
export default connect(mapStateToProps, mapDispatchToProps)(ScenarioForecastData);

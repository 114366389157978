import React from 'react';
import DetailsItem from "../../tree_view/navigation/DetailsItem";
import PlaybookTemplateItem from "./PlaybookTemplateItem";
import DecisionSetItem from "../../template_set_view/navigation/DecisionSetItem";
import RatingsAndWeightsItem from "../../tree_view/navigation/RatingsAndWeightsItem";

const NavigationItems = ({ isParentTemplateSet = false}) =>
  <div className="sidebar-top">
    <ul className="list-unstyled m-0 p-3">
      <RatingsAndWeightsItem isTemplate={true} />
      <DetailsItem />
      <PlaybookTemplateItem />
      { isParentTemplateSet ? <DecisionSetItem /> : null }
    </ul>
  </div>

export const SidebarTreeNavigationPreview = (opts) =>
  <div id="sidebar-navigation" className="tree-sidebar bg-light-gray d-none d-lg-block">
    <NavigationItems {...opts} />
  </div>

export default SidebarTreeNavigationPreview;

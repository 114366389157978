import { fetchAggregatedValuesFor, ForecastScenarioRow, TableRow } from "./ForecastScenarioRow";
import { isBlank, isPresent, qSortArray } from "../../helpers/common";
import * as moment from "moment";
import {isFuturePeriod} from "../../forecast_simulator_scenario/helpers/ag_grid_helpers";

export const FACTS_HEADER = 'Facts'
export const YEAR_HEADER_KEY = 'y'
export const TIME_SCALE_HEADERS = {
  'y': [],
  'q': [],
  'm': []
}
export const TIME_SCALES = Object.keys(TIME_SCALE_HEADERS);
export const ROW_DRIVER_ID_KEY = 'rowId'

export const DISPLAY_NAME_LIMIT = 50;

const formatWrapper = (value, { metric = '', decimal = 2 }) => {
  const numericValue = typeof value === 'number' ? value : parseFloat(value);
  const roundedValue = parseFloat(numericValue.toFixed(decimal));
  switch (metric) {
    case '$':
      return `$${roundedValue}`
    default:
      return `${roundedValue}${metric}`
  }
}

export class ForecastScenario {
  constructor(scenario, config, timeScale) {
    this.id = scenario.data.id;
    this.attributes = scenario.data.attributes;
    this.included = scenario.included;
    this.config = config;
    this.viewOptions = scenario.view_options;
    this.timeScale = scenario.view_options.timeScale || TIME_SCALES[0];
    this.updateData = scenario.update_data;
  }

  setTimeScale(timeScale) {
    if (this.timeScale !== timeScale) {
      this._viewHeaders = null;
      this._rows = null;
    }
    this.timeScale = timeScale;
  }

  get _groupedRows() {
    return this.rows.reduce((acc, row) => {
      if (isBlank(acc[row.cmusGroupKey])) acc[row.cmusGroupKey] = [];
      acc[row.cmusGroupKey].push(row);
      return acc;
    }, {});
  }

  _cellDataByRowDriverId(id) {
    return this.updateData?.edited_cells?.find(cell => cell.id === id)
  }

  get preparedAggregatedRows() {
    return Object.values(this._groupedRows).map(rows => {
      if (rows.length === 1) return rows[0];

      const aggregatedRowData = {}
      this.config?.cmuHeaders.forEach(column => aggregatedRowData[column.displayName] = rows[0].fetchCmu(column))
      const driver = rows[0].selectedDriver
      aggregatedRowData[FACTS_HEADER] = driver.displayName;
      aggregatedRowData[ROW_DRIVER_ID_KEY] = rows[0].rowDriverId;
      if (isPresent(driver.driverRules)) {
        this.periodHeaders.forEach(period => {
          const { value, format} = fetchAggregatedValuesFor(rows, driver, period, this.config)
          if (value !== false) {
            const editedCell = this._cellDataByRowDriverId(`${rows[0].rowDriverId}_${period.id}`)
            if(editedCell?.value) {
              aggregatedRowData[period.name] = editedCell.value;
            } else {
              aggregatedRowData[period.name] = format ? formatWrapper(value, driver.driverRules) : value
            }
          }
        })
      }
      return new TableRow(aggregatedRowData)
    }).map(aggregatedRow =>
      this.viewHeaders.reduce((acc, header) => {
        acc[header] = aggregatedRow.fetchData(header);
        return acc;
      }, {})
    )
  }

  get groupFields() {
    return [
      ...this.config?.cmuHeaders?.map(column => column.displayName),
      FACTS_HEADER
    ]
  }

  get allFuturePeriodsByTimeScale() {
    return this.config?.getTimeScaleBy(this.timeScale)?.timePeriods
      .filter(period => isFuturePeriod(this.timeScale, period.name));
  }

  get periodHeaders() {
    try {
      const fromDate = isPresent(this.viewOptions.from) ? moment(this.viewOptions.from) : false
      const toDate = isPresent(this.viewOptions.to) ? moment(this.viewOptions.to) : false

      return this.config?.getTimeScaleBy(this.timeScale)?.timePeriods?.filter(period => {
        if (fromDate && fromDate > period.startDate) return false;
        if (toDate && toDate < period.endDate) return false;

        return true;
      }) || []
    } catch (e) {
      console.error("Error while parsing period headers", e);
      return []
    }
  }

  getPeriodIdByPeriodName(name) {
    return this.periodHeaders.find(period => period.name === name).id;
  }

  get periodHeadersNames() {
    return this.periodHeaders.map(period => period.name)
  }

  get viewHeaders() {
    if (isBlank(this._viewHeaders)) {
      this._viewHeaders = [
        ROW_DRIVER_ID_KEY,
        ...this.config?.cmuHeaders.map(column => column.displayName),
        FACTS_HEADER,
        ...this.periodHeadersNames
      ]
    }
    return this._viewHeaders;
  }

  get rows() {
    if (isBlank(this._rows)) {
      this._rows = qSortArray(
        this.included.flatMap(hash =>
          this.config.viewDriversBy(this.timeScale).map(column => new ForecastScenarioRow(hash, this, column))
        ), true, row => row.timePeriod.startDate
      )
    }
    return this._rows;
  }
}

import { create } from 'zustand'
import { loadConversations } from "../../../../../utils/Api";

const DEFAULT_SAVED_CHATS = {
  conversations: []
};

export const useSavedChatsStore = create(
  () => (DEFAULT_SAVED_CHATS)
);

export const setSavedChats = (conversations) => {
  useSavedChatsStore.setState({ conversations });
}

export const querySavedChats = async (goal_id) => {
  const response = await loadConversations(goal_id);
  return response;
}
import React, {useState, useMemo, useEffect} from "react";
import DropdownWithSearch from "../../common/DropdownWithSearch"
import { isBlank, isPresent, qSortArray } from "../../helpers/common";
import { SmallLoader } from "../../common/Loader";

const BenchmarkScenario = ({
                             forecast_simulator_scenario, scenarioIsLoaded,
                             loadForecastSimulatorDBenchmarkScenario
                           }) => {
  const allOptions = useMemo(() => [
    ...qSortArray(
      forecast_simulator_scenario.config_scenarios.filter(hash => hash.id !== forecast_simulator_scenario.scenario_id), true,
      (hash) => [hash.default_scenario ? 0 : 1, hash.display_name]
    )
  ], [forecast_simulator_scenario.config_scenarios])
  const [selectedOption, setSelectedOption] = useState(forecast_simulator_scenario.benchmark_id);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredScenarios, setFilteredScenarios] = useState(allOptions)
  useEffect(() => setSelectedOption(allOptions[0]?.id), [allOptions]);
  useEffect(() => {
    if (isPresent(selectedOption) && selectedOption !== forecast_simulator_scenario.benchmark_id) {
      loadForecastSimulatorDBenchmarkScenario(selectedOption)
    }
  }, [selectedOption, forecast_simulator_scenario.benchmark_id]);
  useEffect(() => {
    setFilteredScenarios(
      allOptions.filter(hash =>
        isBlank(searchQuery.trim()) || hash.display_name.toLowerCase().includes(searchQuery.trim().toLowerCase())
      )
    )
  }, [searchQuery, allOptions]);

  return <div className="text-start me-2">
    <div className="fs-6 lh-1 mb-1">Benchmark scenario</div>
    {
      scenarioIsLoaded ?
        <div className="fs-5 lh-lg" style={{ marginBottom: '-6px' }}>
          <DropdownWithSearch {...{
            selectedOption: allOptions.find(hash => hash.id === selectedOption)?.display_name,
            setSelectedOption, searchQuery, setSearchQuery,
            options: filteredScenarios
          }}/>
        </div> :
        <SmallLoader />
    }
  </div>
}
export default BenchmarkScenario;

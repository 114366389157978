import React from "react";
import { SourceableTypes } from "../../models/data_source";
import { isBlank, isPresent, qSortArray} from "../../helpers/common";
import Select from "react-select";
import { BorderedFilterStyle } from "../styles/choice_entry_styles";

export default ({ selectedSource, onSelectSource, onSelectReport, orgReports = [],
                  onSelectSetSource,
                  decisionSetSources = [], selectableDriverDataSources = [],
                  isDriverModal = false, isHistoricalDecision = false }) => {
  const wizardSourcesList = isDriverModal ? selectableDriverDataSources.map(h => ({ ...h, sourceable_type: SourceableTypes.WIZARD })) : [];
  const combinedSources =
    [
      ...wizardSourcesList,
      ...decisionSetSources.map(h => ({ ...h, sourceable_type: SourceableTypes.DECISION_SET })),
      ...orgReports.map(h => ({ ...h, sourceable_type: SourceableTypes.REPORT }))
    ]

  if (isBlank(combinedSources)) return <></>

  const list = qSortArray(combinedSources, false, d => d.created_at)
  const wizardOrSetOption = (option) => [SourceableTypes.WIZARD, SourceableTypes.DECISION_SET].includes(option.sourceable_type)
  const onChange = (option) => {
    switch (option.sourceable_type) {
      case SourceableTypes.DECISION_SET:
        onSelectSetSource(option)
        break;
      case SourceableTypes.WIZARD:
        onSelectSource(option)
        break;
      case SourceableTypes.REPORT:
        onSelectReport(option)
        break;
    }
  }

  return <div className="existing-source-dropdown">
    <Select
      value={selectedSource}
      onChange={onChange}
      components={{IndicatorSeparator: () => null}}
      styles={BorderedFilterStyle}
      placeholder={`${isDriverModal ? 'Add an existing data source or report' : 'Add a report'}`}
      options={list.map(source => ({
        value: source.slug,
        label: <span style={{wordBreak: 'break-word'}}>
          {
            wizardOrSetOption(source) ? source.title || source.link_url : source.name
          }
        </span>,
        sourceable_type: source.sourceable_type})
      )}/>
  </div>
}
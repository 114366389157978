import React from 'react';
import DetailsItem from "./DetailsItem";
import PlaybookItem from "./PlaybookItem";
import CommentsItem from "./CommentsItem";
import HelpItem from "./HelpItem";
import DecisionSetItem from "../../template_set_view/navigation/DecisionSetItem";
import RatingsAndWeightsItem from "./RatingsAndWeightsItem";

const NavigationItems = ({ showDecisionSet = false }) =>
  <>
    <div className="sidebar-top">
      <ul className="list-unstyled m-0 p-3">
        <RatingsAndWeightsItem />
        <DetailsItem />
        <PlaybookItem />
        <CommentsItem />
        {
          showDecisionSet ?
            <DecisionSetItem /> :
            null
        }
      </ul>
    </div>
    <div className="sidebar-bottom">
      <ul className="list-unstyled m-0 p-3">
        <HelpItem />
      </ul>
    </div>
  </>

export const SidebarTreeNavigation = (opts = {}) =>
  <div id="sidebar-navigation" className="tree-sidebar bg-light-gray d-none d-lg-block">
    <NavigationItems {...opts} />
  </div>

export default SidebarTreeNavigation;

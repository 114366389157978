import React from 'react';
import { connect } from "react-redux";
import {
  updateRecommendationChoicesDraft,
  setRecommendationInputSidebarOpen,
  updateRecommendationFinalChoices
} from "../../../store/sidebar/actions";
import DoneButton from '../../../common/DoneButton';
import { isMobile } from "react-device-detect";
import Recommendation from "../../../models/recommendation";
import { isPresent } from "../../../helpers/common";
import ShareData, { checkNotifyAllSharedWarning } from "../../../models/share_data";
import { setDoneClicked } from "../../../helpers/sidebar_helpers";

const Footer = ({
                  decision, share_data,
                  setRecommendationInputSidebarOpen, updateRecommendationFinalChoices, updateRecommendationChoicesDraft
                }) => {
  const shareData = new ShareData(share_data)
  const recommendationObj = new Recommendation(decision?.recommendation);
  const openEndedChoice = recommendationObj.openEndedChoice[0];
  const needChoiceSubmission = () => isPresent(openEndedChoice) && !recommendationObj.isEnteredRecommendation
    && recommendationObj.isOpenEnded;

  const submitChoices = (checkShared = false, callback) =>
    checkNotifyAllSharedWarning(
      checkShared && isPresent(openEndedChoice.description),
      shareData.shareList, (hash = {}) => {
        updateRecommendationFinalChoices(openEndedChoice.slug, {
          ...hash,
          final_decision: true,
          reset_final: false,
          ignore_line_breaks: true,
          draft: false
        })
        callback()
      }
    )

  const updateChoicesDraft = (checkShared = false, callback) =>
    checkNotifyAllSharedWarning(
      checkShared && recommendationObj.recommendationChoices.some(choice => choice.final_decision),
      shareData.shareList, (hash = {}) => {
        if (!recommendationObj.isOpenEnded) {
          updateRecommendationChoicesDraft({...hash, draft: false})
        }
        callback()
      }
    )

  const onClose = () => {
    setDoneClicked('recommendation-input');
    const updateAction = needChoiceSubmission() ? submitChoices : updateChoicesDraft
    updateAction(!recommendationObj.isEnteredRecommendation && shareData.isSharedWIthNonOrg, () => {
      setRecommendationInputSidebarOpen(false)
    });
  }
  return <div className={`${isMobile ? 'mb-5' : 'side-panel bottom'} py-3 px-3`} style={{zIndex: 0}}>
    <DoneButton onClose={onClose} />
  </div>
}
const mapStateToProps = ({ sidebar, share_data }) => ({ decision: sidebar.decision, share_data });
export default connect(mapStateToProps, {
  setRecommendationInputSidebarOpen,
  updateRecommendationFinalChoices,
  updateRecommendationChoicesDraft
})(Footer);

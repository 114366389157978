import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { isBlank } from '../../helpers/common';
import { isEnter } from "../../helpers/keys_helpers";

export default ({ onClick }) => {
  const [inputValue, setInputValue] = useState('');
  const [showInputField, setShowInputField] = useState(false);
  const addLinkButtonContent = 'Add link';
  const addSharedLinkButtonContent = 'Add a shared link';

  const onChangeInput = (e) => {
    setInputValue(e.target.value);
  };

  const callback = (success) => {
    if (success) {
      setInputValue('');
    }
  };

  const onSave = (inputValue) => {
    setShowInputField(!showInputField)
    onClick(inputValue, callback)
  };

  const checkInput = () => {
    if(isBlank(inputValue)) {
      return false;
    } else {
      changeInputHandler(inputValue)
    }
  };

  const changeInputHandler = (inputValue) => {
    const URL_LIST = [
      'https://',
      'http://'
    ];

    if(URL_LIST.some(URL_LIST => inputValue.includes(URL_LIST))) {
      onSave(inputValue)
    } else {
      inputValue = inputValue.replace (/^/,'https://');
      onSave(inputValue)
    }
  };

  const onEnterPress = (e) => {
    if (isEnter(e.keyCode)) {
      e.preventDefault();
      const value = e.target.value;
      if (value) {
        checkInput()
      }
    }
  };

  return showInputField ?
    <div className="d-flex w-100">
      <input id="add-link-input"
             className="form-control"
             value={inputValue}
             placeholder={'Paste the link here'}
             onChange={onChangeInput}
             onKeyDown={onEnterPress}
             autoFocus={true} />
      <Button onClick={checkInput} className={`ms-2 text-nowrap ${isBlank(inputValue) ? 'disabled' : '' }`}>
        {addLinkButtonContent}
      </Button>
    </div> :
    <Button onClick={() => setShowInputField(!showInputField)} className="w-100 btn-secondary">
      {addSharedLinkButtonContent}
    </Button>
}

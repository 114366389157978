import { stepWithChoices, stepWithDrivers, wizardStepDataBy } from "../../../helpers/wizard_helpers";
import Decision from "../../../models/decision";

export const ratingAndWeightChoicesData = (decision, wizard) => {
  const choicesStepData = wizardStepDataBy(wizard, stepWithChoices(decision));
  const driversStep = stepWithDrivers(wizard);
  const { drivers } = wizardStepDataBy(wizard, driversStep);

  const choices = choicesStepData.choices.map((description, slug) => ({ description, slug }));
  const decisionObj = new Decision(decision, drivers);
  decisionObj.choicesOrRecommendations = (_) => choices;
  const keyDrivers =  decisionObj.keyDrivers;

  return {
    keyDrivers, choices, decisionObj,
    driversStep, drivers
  };
};

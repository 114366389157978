import Alerts from "../../js/alerts";
import { pluralize } from "./common";

export const deleteWarning = (callback, { isTemplate = false, custom_description = null } = {}) => {
  Alerts.warning({
    title: `Are you sure you would like to ${custom_description || `delete this ${isTemplate ? 'template' : 'decision'}`}?`,
  }).then(callback, () => {
    callback(false);
  })
}

export const deleteApprovalInviteWarning = (callback) => {
  Alerts.warning({
    title: "Are you sure you want to remove this approval request?",
  }).then(callback, () => {
    callback(false);
  })
}

export const deleteReportWarning = (callback) => {
  Alerts.warning({
    title: `Are you sure you would like to delete this report?`,
    text: "It will be removed from all decisions where it is used. This cannot be undone."
  }).then(callback, () => {
    callback(false);
  })
}

export const deleteReportSourceWarning = (callback) => {
  Alerts.warning({
    title: `Are you sure you would like to remove this?`
  }).then(callback, () => {
    callback(false);
  })
}

export const reportInEditModeWarning = () => {
  Alerts.warning({
    title: `Looks like somebody is editing this report. Please try later.`,
    showCancelButton: false,
    confirmButtonText: "OK"
  })
}

export const deleteDriverWarning = (callback) =>
  Alerts.warning({
    title: `Are you sure you would like to delete this driver?`,
    text: "All sub-drivers will also be deleted. This can not be undone.",
  }).then(callback, () => {})

export const deleteDecisionFromSetWarning = (callback, showText = true) =>
  Alerts.warning({
    title: `Are you sure you would like to remove this decision from the decision flow?`,
    text: showText && "This will not delete the decision but it might change who has permission to view it. You can add it back to the decision flow if it is shared with you.",
  }).then(callback, () => {})

export const mentioneeWarning = (mentioned, callback) => {
  if (mentioned.length > 0) {
    Alerts.warning({
      title: "Would like to add the mentionee to the decision?",
    }).then(callback, () => callback(false))
  } else {
    callback()
  }
}

export const alreadyStartedTemplateAlert = () =>
  Alerts.warningWithOk({
    text: "This template has been already started by another user."
  })

export const userSelfRemovingAdminRoleAlert = () =>
  Alerts.warning({
    title: "Are you sure you want to remove yourself as admin?",
    text: "You will no longer be able to manage Users."
  })

export const userRemoveAlert = (user) =>
  Alerts.warning({
    title: `Are you sure you would like to delete ${user.full_name}?`,
    text: "Note: Their decisions and weigh-ins will not be deleted."
  })

export const userMakeGuestAlert = (user) =>
  Alerts.warning({
    title: `Are you sure you would like to make ${user.full_name} a guest?`
  })

export const guestMakeUserAlert = (user, newRole= 'User') =>
  Alerts.warning({
    title: `Upgrade ${user.full_name} from guest to ${newRole}?`
  })

export const guestMakeUserSSOAlert = (user, newRole= 'User') =>
  Alerts.warning({
    title: "Require log in with SSO?",
    text: `You are upgrading ${user.first_name} from guest to ${newRole}. Do you want them to log in with SSO?`
  })

export const bypassSSOAlert = (user) =>
  Alerts.warning({
    title: `Allow ${user.full_name} to bypass SSO on login?`
  })

export const requireSSOAlert = (user) =>
  Alerts.warning({
    title: `Require ${user.full_name} to use SSO to log in?`
  })

export const userResendInviteSuccess = (user) =>
  Alerts.success({
    title: "Resend Invite",
    text: `An invitation will be resent to ${user.email}.`
  })

export const removeExistedUserFromGroups = (user) =>
  Alerts.warning({
    title: `Are you sure you would like to remove ${user.full_name || user.email} from this group?`,
    text: "They will no longer be able to view any decisions shared with this group or manage group membership."
  })

export const removeLastUserWarning = () =>
  Alerts.warning({
    title: " Are you sure you would like to remove this user?",
    text: "Removing the last user in the group will delete this group. All decisions shared with this group will no longer be visible to the group. This cannot be undone."
  })

export const removeUserSelfFromGroup = () =>
  Alerts.warning({
    title: "Are you sure you would like to remove yourself from this group?",
    text: "You will no longer be able to edit this group or view any decisions shared with this group."
  })

export const removeGroupWarning = () =>
  Alerts.warning({
    title: "Are you sure you would like to delete this group?",
    text: "All decisions shared with this group will no longer be visible to the group. This cannot be undone."
  })

export const removeCategoryWarning = () =>
  Alerts.warning({
    title: "Are you sure you would like to delete this category?",
    text: "This category will be removed from all decisions. This cannot be undone."
  })

export const addGuestToGroupWarning = (guests) => {
  const namesToDisplay = guests.slice(0, 3)
  const guestInNumbers = guests.length - namesToDisplay.length
  return Alerts.warning({
    text: `You are adding the following ${pluralize(guests.length, 'guest')} to this group: ${guests.join(', ')}${guestInNumbers > 0 ? pluralize(guestInNumbers, 'other') : ''}. They will have access to ALL decisions shared with that group. Are you sure?`,
    confirmButtonText: "Add Users"
  })
}

export const moveDecisionToSet = (message, callback) => {
  return Alerts.warning({
    title: "Change decision flow?",
    text: message
  }).then(callback, () => {
    callback(false);
  });
};

export const deleteConversationWarning = (message, callback) => {
  return Alerts.warning({
    title: "Are you sure you would like to delete this chat?"
  }).then(callback, () => {
    callback(false);
  });
};

export const notifySharedWarning = (sharedList, callback) =>
  Alerts.warning({
    title: "Notify everyone?",
    text: `This decision is shared with ${sharedList}. Would you like to send them the update notification sent to decision collaborators?`,
    confirmButtonText: "No",
    cancelButtonText: "Yes"
  }).then(confirmed => {
    callback(!confirmed)
  }, () => {
    callback(true);
  })

import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import {updateDriver} from "../../store/modals/actions";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../common/CloseIcon";
import { ModalDoneFooter } from "../../common/modals";
import DecisionAssignToBlock from "../../tree_view/modals/helpers/DecisionAssignToBlock";
import NotesRow from "./helpers/NotesRow";
import * as moment from "moment/moment";
import DriverDueDate from "../../tree_view/modals/helpers/DriverDueDate";
import { isBlank, isPresent } from "../../helpers/common";
import { driversToArray } from "../../helpers/drivers_helpers";

export const showDriverAssign = (modal) => modal.type === "DriverAssignModal" && modal.shown;

const DriverAssignModal = ({driverData, show, onClose, updateDriver, key = ''}) => {
  if (isBlank(driverData)) return null

  const { slug } = driverData.driver;
  const prevNotes = driverData.driver.notes || '';
  const [notes, setNotes] = useState(prevNotes)
  const prevAssignedToUser = driverData?.driver?.assign_to_user || '';
  const [assignedToUser, setAssignedToUser] = useState(prevAssignedToUser);
  const initDate = isPresent(driverData.driver.due_date) ? moment(driverData.driver.due_date).format('DD MMM, yyyy') : null;
  const [dueDate, setDueDate] = useState(initDate);
  const [submitState, setSubmitState] = useState(false)

  const onChangeDueDate = (date) => {setDueDate(moment(date).format('DD MMM, yyyy'))};
  const onCancelDueDate = () => {setDueDate(null)};

  const onDone = () => {
    setSubmitState(true)
    onClose()
  }

  useEffect(() => {
    if (initDate !== dueDate || prevNotes !== notes || prevAssignedToUser !== assignedToUser) {
      submitState && updateDriver({slug,
        due_date: dueDate,
        assign_to_user: assignedToUser,
        notes
      });
    }
    setSubmitState(false)
  }, [submitState])

  return <Modal key={key} size="lg" backdrop="static" show={show} onHide={onClose}>
    <Modal.Body>
      <CloseIcon onClose={onClose} />
      <h2>{'Assign driver'}</h2>
      <form>
        <div className="row">
          <div className='col-sm-6'>
            <DecisionAssignToBlock {...{assignedToUser, setAssignedToUser }} />
          </div>
          <div className='col-sm-6'>
            <DriverDueDate dueDate={dueDate} onChangeDueDate={onChangeDueDate} onCancelDueDate={onCancelDueDate} />
          </div>
        </div>
        <NotesRow {...{ notes, setNotes }} addClass='mb-0' />
      </form>
    </Modal.Body>
    <ModalDoneFooter onClose={onDone} />
  </Modal>
};

const mapStateToProps = ({ modal }) => {
  const driverData = driversToArray(modal.drivers).find((driverData) => driverData.driver.slug === modal.slug)
  return { driverData }
};
export default connect(mapStateToProps, {updateDriver})(DriverAssignModal);

import React from 'react';
import Wrapper from '../Wrapper';
import TemplateDriverInfo from "../template/TemplateDriverInfo";
import AggregatedData from "../driver/AggregatedData";
import Header from "../template_driver/Header";
import Content from "../template/Content";
import { displayDSightDriverPreviewData, displayDriverPreviewData } from "../TemplatePreviewTree";
import { openDSightDriverModal } from "../../../dsight/template_view/modals/DSightDriverModal";
import { driverInitData } from "../shared/helpers";

const DSightTemplatePreviewDriverCard = ({ driverData, template, tree, indentStep = 1, user, contactsData, openModal }) => {
  const { childrenDrivers, setShowChildren, showChildren } = driverInitData(tree, driverData);

  const clickable = () => displayDriverPreviewData(driverData) || displayDSightDriverPreviewData(driverData)

  const onDriverClick = () => {
    if(clickable()) {
      openDSightDriverModal(template, driverData.driver.slug, tree, openModal)
    }
  }

  return <>
    <Wrapper indentStep={indentStep} >
      <div className={`d-flex`}>
        <div className="my-auto lh-sm w-100 me-1">
          <Header {...{ driverData, childrenDrivers, setShowChildren, onEditClick: onDriverClick, clickable: clickable() }} />
          <TemplateDriverInfo {...{ driverData }} />
          <AggregatedData {...{ driverData }} />
        </div>
        <Content {...{ driverData, assignedUser: {} }} />
      </div>
    </Wrapper>
    {showChildren && childrenDrivers.map((child) =>
      <DSightTemplatePreviewDriverCard key={`dsight-preview-driver-card-${child.driver.slug}`}
                                       {...{
                                         driverData: child,
                                         indentStep: indentStep + 1,
                                         template, tree, user, contactsData, openModal
                                       } } />
    )}
  </>
}
export default DSightTemplatePreviewDriverCard;

import React, { useEffect } from 'react';
import Select from 'react-select';
import { BorderedFilterStyle } from "../../../tree_wizard/styles/choice_entry_styles";

const ScenarioSelector = ({ scenario, setScenario, options }) => {
  return <div className="mb-0">
    <h3>Benchmark scenario</h3>
    <Select
      defaultValue={scenario}
      onChange={setScenario}
      components={{ IndicatorSeparator: () => null }}
      styles={BorderedFilterStyle}
      placeholder="Make a selection"
      options={options} />
  </div>
};
export default ScenarioSelector;

import { isBlank, isPresent } from "../../helpers/common";
import { ForecastBaseModel } from "./Base";

const TYPES = {
  cmu: 'cmu',
  output: 'output',
  driver: 'driver'
}
export const forecastColumnFactory = (data) => {
  switch (data.attributes.column_type) {
    case TYPES.cmu:
      return new ForecastCmuColumn(data);
    case TYPES.output:
      return new ForecastOutputColumn(data);
    case TYPES.driver:
      return new ForecastDriverColumn(data);
    default:
      return new ForecastColumn(data);
  }
}

export class ForecastColumn extends ForecastBaseModel {

  get displayName() {
    return this.attributes.name;
  }

  get name() {
    return this.attributes.name;
  }

  get sortOrder() {
    return parseInt(this.attributes.sort_order);
  }

  get coefficientColumn() {
    return null;
  }

  get driverRules() {
    return this.attributes.data_view_rules;
  }

  visibleFor(_timeScale) {
    return false
  }

  fetchValue(row) {
    const driverValues = row.fetchDriverData(this)
    return parseFloat(driverValues?.base)
  }
}

export class ForecastCmuColumn extends ForecastColumn {
  get displayName() {
    return this.driverRules?.display_name;
  }

  visibleFor(_timeScale) {
    return true;
  }

  fillValues(cmuValues) {
    this.values = cmuValues.filter(({ attributes }) => parseInt(attributes.forecast_data_column_id) === this.id).reduce((res, hash) => {
      res[hash.id] = hash.attributes.value;
      return res;
    }, {})
  }
}
export class ForecastOutputColumn extends ForecastColumn {
  get displayName() {
    return this.driverRules?.display_name;
  }

  fetchValue(row) {
    const driverValues = row.fetchDriverData(this)
    if (driverValues?.forecast) return parseFloat(driverValues?.forecast)

    return parseFloat(driverValues?.base)
  }
}

export class ForecastDriverColumn extends ForecastColumn {

  get isViewInTable() {
    return isPresent(this.driverRules);
  }

  get coefficientColumn() {
    if (isBlank(this.attributes.coefficient_view_rules)) return null;

    const { id, attributes } = this;
    return new ForecastCoefficientColumn({ id, attributes });
  }

  visibleFor(timeScale) {
    return isPresent(this.driverRules?.visible[timeScale]);
  }

  editableFor(timeScale) {
    return isPresent(this.driverRules?.editable[timeScale]);
  }

  get displayName() {
    return this.driverRules?.display_name;
  }
}

export class ForecastCoefficientColumn extends ForecastColumn {
  get sortOrder() {
    return parseInt(this.driverRules?.id);
  }

  get displayName() {
    return this.driverRules?.display_name;
  }

  get driverRules() {
    return this.attributes.coefficient_view_rules;
  }

  fetchValue(row) {
    const driverValues = row.fetchDriverData(this)

    return parseFloat(driverValues?.coefficient)
  }
}

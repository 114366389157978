import React from 'react';
import { connect } from "react-redux";
import SlidingPanel from "react-sliding-side-panel";
import { setSetDecisionsSidebarOpen } from "../../store/sidebar/actions";
import PanelTreeEditor from "../../tree_editor/set_decisions/PanelTreeEditor";
import {isMobile} from "react-device-detect";
import PanelHeader from "../../tree_view/side_panel/PanelHeader";

const DecisionSetDecisionsPanel = ({ addClass, sidebar, openSideBar,
                                     headerName = 'Add or remove decisions', ...opts }) => {
  const onClose = () => openSideBar(false)

  return <SlidingPanel
    type="left"
    isOpen={sidebar.setDecisionsSidebar}
    panelContainerClassName={`sidebar-panel-wrapper set-decisions-panel-wrapper details-panel-width template-panel ${addClass} ${isMobile ? 'pb-5 mb-5' : ''}`}
    panelClassName={`sidebar-panel-wrapper-content set-decisions-panel-content`}
    size={50}>
    <PanelHeader headerName={headerName} openSideBar={openSideBar} preview={true} />
    <PanelTreeEditor onClose={onClose} {...opts} />
  </SlidingPanel>
}

const mapStateToProps = ({ sidebar }) => ({ sidebar });
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => {
    dispatch(setSetDecisionsSidebarOpen(options));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(DecisionSetDecisionsPanel);

import React from 'react';
import { isPresent } from "../helpers/common";
import NAElement from "../tree_view/side_panel/sections/NAElement";

const selectedOptions = (selectedCategories, category) =>
  selectedCategories.map(id => category.options.find(h => h.id === id)).filter(h => isPresent(h))

export const CategoryBlockRow = ({category, selectedCategories}) => {
  const options = selectedOptions(selectedCategories, category)

  return <div className="d-table-row">
    <div className="d-block d-lg-table-cell pt-1 text-muted white-space-nowrap">
      <span>{category.name}</span>
    </div>
    <div className="d-block d-lg-table-cell ps-2 pt-1">
      {
        isPresent(options) ?
          options.map(option => option.description).join(', ') :
          <NAElement />
      }
    </div>
  </div>
}


export const ChoiceEntryStyle = {
  valueContainer: (provided) => ({
    ...provided,
    padding: '8px 12px'
  }),
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 48,
    borderRadius: 12,
    borderColor: state.isFocused ? '#ced4da' : '#ced4da',
    boxShadow: state.isFocused ? 'none' : 'none',
    //borderColor: state.isFocused ? 'rgba(4, 147, 196, 0.6)' : '#ced4da',
    //boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(4, 147, 196, 0.25)' : 'none',
    "&:hover": {
      borderColor: null,
    },
  }),
  placeholder: () => ({
    color: '#9AA9B8'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#2487BF'
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isFocused ? '#F0F6FC' : null,
    color: state.isFocused ? 'black' : null
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#F0F6FC',
    fontSize: '1rem',
    borderRadius: '0.25rem',
    color: '#1F2F3F'
  }),
  multiValueRemove: (css) => ({
    ...css,
    color: "#1F2F3F",
    ":hover": { color: "#F0584D", background: "inherit" }
  }),
};

export const BorderedFilterStyle = {
  ...ChoiceEntryStyle,
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected ? '#2487BF' : 'white',
    color: (state.data?.color || (state.isSelected ? 'white' : 'black')),
    boxShadow: 'none',
    padding: '8px 18px',
    "&:hover, &:active, &:focus": {
      borderColor: '#2487BF',
      backgroundColor: '#2487BF',
      color: 'white'
    }
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: "10px",
    paddingTop: "4px",
    paddingBottom: "4px",
    zIndex: 2
  }),
}

export const NoBorderFilterStyle = {
  ...BorderedFilterStyle,
  control: (provided, state) => ({
    ...provided,
    border: 0,
    minHeight: 48,
    borderRadius: 10,
    borderColor: state.isFocused ? 'rgba(4, 147, 196, 0.6)' : '#ced4da',
    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(4, 147, 196, 0.25)' : 'none',
    "&:hover": {
      borderColor: null,
    },
  }),
};

export const AssignToFilterStyles = {
  ...BorderedFilterStyle,
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 12px'
  }),
  control: (provided, state) => ({
    ...provided,
    height: 50,
    width: state.selectProps.width,
    borderRadius: 10,
    borderColor: state.isFocused ? 'rgba(4, 147, 196, 0.6)' : '#ced4da',
    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(4, 147, 196, 0.25)' : 'none',
    "&:hover": {
      borderColor: null,
    },
  }),
};

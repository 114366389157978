import React, { useMemo } from "react";
import Wrapper from "../Wrapper";
import DecisionStatus from "../decision/DecisionStatus";
import Description from "../decision/Description";
import AssignedUser from "../shared/AssignedUser";
import DecisionInfo from "../decision/DecisionInfo";
import { assignedDecisionUser } from "./DecisionCard";

export default ({ decision, user, decisionObject, collaborators, onDecisionClick }) => {
  const assignedUser = assignedDecisionUser(collaborators, {}, decisionObject);

  return <Wrapper>
    <div className="d-flex">
      <div className="my-auto lh-sm w-100 me-1">
        <Description {...{ user, description: decision.description, onClick: onDecisionClick } } />
        <DecisionInfo {...{ user, decision, decisionObject }} />
      </div>
      <AssignedUser bubbleIcon={`decision-assignment-${decision.slug}`} assignedUser={assignedUser} />
      <DecisionStatus {...{ decisionObject } } />
    </div>
  </Wrapper>
}

import React from 'react';
import { connect } from "react-redux";
import { setDecisionInputSidebarOpen, transferDecisionFromSidebar } from "../../store/sidebar/actions";
import SlidingPanel from "react-sliding-side-panel";
import Header from "./sections/Header";
import Footer from "./decision_input/Footer";
import DecisionInputBody from "./decision_input/DecisionInputBody";
import RateSupportSwitcher from "./decision_input/RateSupportSwitcher";
import RationaleInput from "./decision_input/RationaleInput";
import DecisionDateInput from "./decision_input/DecisionDateInput";
import DataSourcesSection from "./sections/DataSourcesSection";
import { isMobile } from "react-device-detect";
import { saveSideBarData } from "../../store/sidebar/common_actions";
import AssignedDeciderInput from "./decision_input/AssignedDeciderInput";
import MoreActionsFinalSection from "./decision_input/MoreActionsFinalSection";
import NextStepsInput from "./decision_input/NextStepsInput";
import AssignedToSection from "./decision_input/AssignedToSection";
import DuplicateRecommendation from "./decision_input/DuplicateRecommendation";
import { Loader } from "../../common/Loader";
import ExpectedResultsInput from "./decision_input/ExpectedResultsInput";
import ExpectedOpportunityInput from "./decision_input/ExpectedOpportunityInput";
import ExpectedInvestmentInput from "./decision_input/ExpectedInvestmentInput";
import OtherConsideredChoicesInput from "./decision_input/OtherConsideredChoicesInput";

const DecisionInputRightPanel = ({
                                   sidebar,
                                   openSideBar, transferDecisionFromSidebar, saveSideBarData,
                                   hideAssignee = false, isDsight = false
                                 }) =>
  <SlidingPanel
    type="right"
    isOpen={sidebar.decisionInputSidebar}
    panelContainerClassName={`right-sidebar-panel-wrapper decision-input-panel-wrapper decision-panel-width`}
    panelClassName={`right-sidebar-panel-wrapper-content decision-input-panel-content ${isMobile ? '' : 'd-flex flex-column'}`}
    onClose={transferDecisionFromSidebar}
    onClosed={() => {
      if (!sidebar.recommendationInputSidebar) {
        saveSideBarData({decision: null, isOpened: false })
      } else {
        saveSideBarData({ isOpened: false })
      }
    }}
    onOpened={() => saveSideBarData({ isOpened: true })}
  >
    { sidebar.loading && <Loader /> }
    {
      !sidebar.loading && sidebar.decision && <>
        <Header decision={sidebar.decision} headerText={"Enter what was decided"} openSideBar={() => openSideBar(false)} >
          <MoreActionsFinalSection {...{isDsight}}>
            <DuplicateRecommendation />
          </MoreActionsFinalSection>
        </Header>
        <AssignedToSection />
        {
          isDsight ?
            <DuplicateRecommendation {...{isDsight}}/> :
            <></>
        }
        <DecisionInputBody />
        <RationaleInput />
        <RateSupportSwitcher />
        <DecisionDateInput />
        { hideAssignee ? null : <AssignedDeciderInput /> }
        <OtherConsideredChoicesInput />
        <NextStepsInput />
        <ExpectedResultsInput />
        <ExpectedOpportunityInput />
        <ExpectedInvestmentInput />
        <DataSourcesSection fromDecisionPanel={true} addClass="px-3" />
        <Footer />
      </>
    }
  </SlidingPanel>

const mapStateToProps = ({ sidebar }) => ({ sidebar });
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => { dispatch(setDecisionInputSidebarOpen(options)) },
  transferDecisionFromSidebar: () => { dispatch(transferDecisionFromSidebar()) },
  saveSideBarData: (data = {}) => { dispatch(saveSideBarData(data)) }
});
export default connect(mapStateToProps, mapDispatchToProps)(DecisionInputRightPanel);

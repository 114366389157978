import React from "react";
import { TABS } from "./index";
import ScenarioForecastData from "./tabs/ScenarioForecastData";
import ScenarioForecastCharts from "./tabs/ScenarioForecastCharts";
import ScenarioForecastDecomposition from "./tabs/ScenarioForecastDecomposition";

const TabContent = ({ activeTab, gridRef }) => {
  if(activeTab === TABS.table) return <ScenarioForecastData activeTab={activeTab} gridRef={gridRef} />
  if(activeTab === TABS.charts) return <ScenarioForecastCharts activeTab={activeTab} />
  if(activeTab === TABS.decomposition) return <ScenarioForecastDecomposition activeTab={activeTab} />
}

const TabManager = ({ activeTab, gridRef }) => {
  return <div className="tab-content">
    <TabContent activeTab={activeTab} gridRef={gridRef} />
  </div>
}
export default TabManager;
import React from "react";
import { TAB_TITLES, TABS } from "../index";

const Tabs = ({ activeTab, setActiveTab }) => {
  const onChangeTab = (tab) => setActiveTab(tab);
  return <>
    <ul className="nav nav-tabs">
      <li className="nav-item me-1">
        <a className={`nav-link d-flex align-items-center justify-content-center ${activeTab === TABS.table ? 'active' : ''}`} href="#"
           onClick={() => onChangeTab(TABS.table)}>{TAB_TITLES.table}</a>
      </li>
      <li className="nav-item me-1">
        <a className={`nav-link d-flex align-items-center justify-content-center ${activeTab === TABS.charts ? 'active' : ''}`} href="#"
           onClick={() => onChangeTab(TABS.charts)}>{TAB_TITLES.charts}</a>
      </li>
      <li className="nav-item">
        <a className={`nav-link d-flex align-items-center justify-content-center ${activeTab === TABS.decomposition ? 'active' : ''}`} href="#"
           onClick={() => onChangeTab(TABS.decomposition)}>{TAB_TITLES.decomposition}</a>
      </li>
    </ul>
  </>
}
export default Tabs;

import React from 'react';
import { connect } from "react-redux";
import BaseHamburgerBtn from "../../../common/BaseHamburgerBtn";
import { IconDropdown } from "../../../common/dropdowns";
export const ScenarioActions = ({ btnId }) => {

  const iconDropdownEvents = {
    applyLargeScaleInputHandler: 'applyLargeScaleInputHandler',
    resetInputsHandler: 'resetInputsHandler',
    importValuesHandler: 'importValuesHandler'
  }

  const iconDropdownSelect = (eventKey) => {
    switch (eventKey) {
      case iconDropdownEvents.applyLargeScaleInputHandler:
        return () => {}
      case iconDropdownEvents.resetInputsHandler:
        return () => {}
      case iconDropdownEvents.importValuesHandler:
        return () => {}
    }
  }

  return <IconDropdown id={btnId} bsPrefix="h5 m-0 btn-link pt-2"
                       className="btn btn-secondary btn-lg-round d-flex p-0 float-end"
                       onSelect={iconDropdownSelect}>
    <BaseHamburgerBtn eventKey={iconDropdownEvents.applyLargeScaleInputHandler} title="Apply large-scale input"/>
    <BaseHamburgerBtn eventKey={iconDropdownEvents.resetInputsHandler} title="Reset inputs to defaults"/>
    <BaseHamburgerBtn eventKey={iconDropdownEvents.importValuesHandler} title="Import values"/>
  </IconDropdown>;
}
const mapStateToProps = ({}) => ({});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ScenarioActions);

import React from "react";
import DueBy from "./../shared/DueBy";
import { isPresent } from "../../../helpers/common";
import { RichTextSection } from "../../../common/RichTextEditor";
export const AssignedToYou = () => <span className="text-danger">Assigned to you</span>
export const Answer = ({ driverData }) => <RichTextSection text={driverData.driver.answer}/>
export const BulletSplitter = () => <span className="text-muted">&nbsp;•&nbsp;</span>

const DriverRateChoicesSection = ({ driverObject }) => {
  const allDriverChoicesRatings = driverObject.allDriverChoicesRatings || []

  const Ratings = ({}) => {
    const elements = [!driverObject.isSubDriver && [`${driverObject.weightPercentage}`, '% weight'],
      !driverObject.isSubDriver && [`${allDriverChoicesRatings.filter(o => !o.isNA).length} of ${allDriverChoicesRatings.length}`, ' rated choices'],
      [`${allDriverChoicesRatings.filter(o => o.hasEvaluation).length} of ${allDriverChoicesRatings.length}`, ' choice evaluations']
    ]
    return <>
      {elements.filter(isPresent).map((data, index) => {
        return <span key={`${driverObject.driver.slug}-rating-${index}`}>
        <span className="fw-bolder">{data[0]}</span>
          {data[1]}
          {index < elements.length - 1 && ' • '}
      </span>
      })}
    </>
  }


  return <div className="d-flex">
    <span className="text-muted"><Ratings /></span>
  </div>
}
const CompletedDriverInfo = ({ driverData, driverObject, decisionObject }) => {
  if(!decisionObject.isRateAndCompareDriverChoicesHidden && driverObject.withEnteredChoicesRating) {
    return <div>
      <DriverRateChoicesSection {...{driverObject}}/>
    </div>
  }

  return <Answer driverData={driverData}/>;
}
export default ({ driverData, driverObject, decisionObject, user, hidden = false }) => {
  if(hidden) return null;
  if(driverObject.isCompleted) return <CompletedDriverInfo {...{ driverData, driverObject, decisionObject } } />

  const isAssignedToUser = isPresent(user.email) && user.email === driverData.driver.assign_to_user;
  const hasDueDate = isPresent(driverData.driver.due_date);
  if(!isAssignedToUser && !hasDueDate) return null;

  const needBullet = isAssignedToUser && hasDueDate;
  return <div className="d-flex">
    { isAssignedToUser && <AssignedToYou /> }
    { needBullet && <BulletSplitter /> }
    <DueBy {... { object: driverData.driver, isOverdue: driverObject.isOverdue }}/>
  </div>
}

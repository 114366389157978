import React, {useMemo, useRef, useState} from 'react';
import {connect} from "react-redux";
import {isBlank, isPresent, sortSourceByType} from "../../helpers/common";
import SourceItem from "../data_sources/Item";
import * as moment from "moment";
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import Modal from "react-bootstrap/Modal";
import CommentsBlock from "../driver_entry/CommentsBlock";
import ConfidenceBubble from "../driver_entry/ConfidenceBubble";
import {CONFIDENCE_LEVELS} from "../driver_entry/ConfidenceLevel";
import Driver, {DEFAULT_DRIVER_TYPE_NAME} from "../../models/driver";
import {Link} from "react-router-dom";
import { isVisitor, userName } from "../../helpers/user_helpers";
import Analysis from "../../models/d_sight/Analysis";
import {mapModalDriverWithSourcesToProps} from "./PreviewDriverDetailsModal";
import DoneButton from "../../common/DoneButton";
import {ModalDoneFooter} from "../../common/modals";
import {RichTextSection} from "../../common/RichTextEditor";
import CloseIcon from "../../common/CloseIcon";
import Decision from "../../models/decision";
import {calculateRatingScore, RATING_SCALES_DATA} from "../../helpers/drivers_helpers";
import DataSource, {IMAGE_FILE_TYPES} from "../../models/data_source";
import { MAX_SCALE, MIN_SCALE } from "../../models/DriverChoiceRating";
import UserAvatarImage from "../../common/UserAvatarImage";
import AccordionWrapper from "../../common/AccordionWrapper";

export const DSightAnalysisHeaderLink = ({
                                           linkTo, headerClass = '', containerClass = '',
                                           renderCloseIcon = () => null
                                         }) =>
  <div className={`d-flex ${containerClass}`}>
    <div className={`${headerClass} m-0`}>
      <Link to={linkTo} target={`_blank`} className={'text-decoration-none color-inherit'}>D-Sight analysis</Link>
    </div>
    <div>
      <Link to={linkTo} target={`_blank`}>
        <div className="d-inline-block icon px-2">
          <i className={'fas fa-external-link-alt text-primary fa-lg'} />
        </div>
      </Link>
    </div>
    <div className="ms-auto">
      {renderCloseIcon()}
    </div>
  </div>

export const DSightAnalysisAnswerBlock = ({insightsData}) =>
  isBlank(insightsData.answerInsight) ? null :
    <div className="d-flex node-spacer-container mb-2">{insightsData.answer}</div>

export const DSightAnalysisInsightBlock = ({ insight }) =>
  <div className="d-flex mt-3">
    <div className='align-text-top fa-layers fa-3x dsight-datasource-block'>
      <div className='dsight-datasource bg-primary mb-auto fa-layers-square'>
        <i className="dsights-icon--light h-17 d-initial"/>
      </div>
    </div>
    <div className="lh-sm my-auto ms-2">{insight.insight}</div>
  </div>

export const DSightAnalysisInsightsBlock = ({
                                              insightsData,
                                              keyPreffix, decision, driverObj
                                           }) =>
  isBlank(insightsData.orderedInsights()) ? null :
    insightsData.orderedInsights().map(insight =>
      <DSightAnalysisInsightBlock key={`${keyPreffix}-${decision.slug}-${driverObj?.driver?.slug}-insight-${insight.id}`} insight={insight} />
    )

const DSightAnalysisKpisBlock = ({
                                   driverObj, decision
                                 }) => {
  const dSightKpis = driverObj.dSightKpis;
  if (isBlank(dSightKpis)) return null;

  const [open, setOpen] = useState(false)

  return <div>
    <Collapse in={open}>
      <div id="collapsed-kpis">
        {
          dSightKpis.map(kpi =>
            <div key={`contributing-kpi-${kpi.id}`}>
              <div className="mt-1">
                <div className="d-flex">
                  <h3 className="m-0">
                    <Link to={`/dsight/decisions/${decision.slug}/analysis/${driverObj.dsightChainId}/kpi/${kpi.id}`} target={`_blank`}
                          className={'text-decoration-none color-inherit'}>
                      {kpi.name}
                    </Link>
                  </h3>
                </div>
              </div>
              <DSightAnalysisInsightsBlock driverObj={driverObj} decision={decision} insightsData={kpi.insightData} />
            </div>
          )
        }
      </div>
    </Collapse>
    <Button onClick={() => setOpen(!open)} aria-controls="collapsed-kpis" aria-expanded={open} className="w-100 mt-2 btn-secondary">
      { open ? 'Hide' : 'Show' } contributing KPIs
    </Button>
  </div>
}

export const DSightAnalysis = ({ headerClass = 'h3',
                                 driverKpis = false,
                                 decision, addClass ='',
                                 driverObj = {},
                                 showDecisionDescription = false,
                                 keyPreffix = '',
                                 renderCloseIcon = () => {}
}) => {
  const insightsData = isPresent(driverObj) ? driverObj.insightsData : new Analysis(decision.d_sight).insightData
  if(isBlank(insightsData)) return null;

  const linkTo = isBlank(driverObj) ? `/dsight/decisions/${decision.slug}/analysis` : `/dsight/decisions/${decision.slug}/analysis/${driverObj.dsightChainId}`

  return <div className={`mb-3 ${addClass}`}>
    <DSightAnalysisHeaderLink linkTo={linkTo} headerClass={headerClass} renderCloseIcon={renderCloseIcon} />
    {showDecisionDescription ? <div className="h5 my-2">{decision.description}</div> : null}
    <DSightAnalysisAnswerBlock insightsData={insightsData} />
    <DSightAnalysisInsightsBlock insightsData={insightsData} keyPreffix={keyPreffix} decision={decision} driverObj={driverObj} />
    { driverKpis ? <DSightAnalysisKpisBlock decision={decision} driverObj={driverObj} /> : null }
  </div>
}

export const DriverTypeRow = ({ driver, user, onEdit }) =>
  <>
    <div className="float-end">
      <EditDriverIcon user={user} driver={driver} onEdit={onEdit} margin={'me-2'} />
    </div>
    <h2>{driver.driver_type_name || DEFAULT_DRIVER_TYPE_NAME}</h2>
  </>

export const QuestionRow = ({ driver, user, onEdit }) =>
  <div>
    <div className="float-end">
      <EditDriverIcon user={user} driver={driver} onEdit={onEdit} />
    </div>
    <h2>{driver.question}</h2>
  </div>

export const AnswerRow = ({ driver }) =>
  <div hidden={isBlank(driver.answer)}>
    <RichTextSection text={driver.answer}/>
  </div>

export const CustomRating = ({driveChoicesRating, startScale, endScale, isYesOrNo}) => {
  const isHundredScale = startScale === MIN_SCALE && endScale === MAX_SCALE && !isYesOrNo

  return <span className="d-flex-inline">
    {
      isYesOrNo ?
        <span className="fw-bolder text-nowrap">{driveChoicesRating.rating === MAX_SCALE ? 'Yes' : 'No'}</span>:
        <span className="fw-bolder text-nowrap">{driveChoicesRating.rating}</span>
    }
    {
      !isHundredScale && <span className="text-muted text-nowrap">&nbsp;
        ({calculateRatingScore(driveChoicesRating.rating, startScale, endScale)})
      </span>
    }
  </span>
}

export const RatedChoices = ({ driverObj, startScale, endScale, isYesOrNo }) => {
  return <>
    {
      driverObj.sortedDriveChoicesRatings.filter(r => r.isAnswered).map((driveChoicesRating, index) =>
        <div key={`driver-choice-rating-${index}`} className="mb-3">
          <div className="d-flex justify-content-between mb-2">
            <div className={`text-ellipsis fw-bolder ${driverObj.isSubDriver ? '' : 'me-2'}`}>
              {driveChoicesRating.choiceDescription}
            </div>
            {
              driverObj.isSubDriver ? <></> :
                <div>
                  {driveChoicesRating.isNA ?
                    <span>&#8212;</span> :
                    <>
                      {
                        driverObj.withRatingScale ?
                          <CustomRating driveChoicesRating={driveChoicesRating} startScale={startScale} endScale={endScale} isYesOrNo={isYesOrNo} />:
                          <span className="fw-bolder">{driveChoicesRating.rating}</span>
                      }
                    </>
                  }
                </div>
            }
          </div>
          <div hidden={isBlank(driveChoicesRating.evaluation)}>
            <RichTextSection text={driveChoicesRating.evaluation}/>
          </div>
        </div>
      )
    }
  </>
}

export const ExplanationRow = ({ driver }) =>
  <div hidden={isBlank(driver.explanation)}>
    <h3>Details</h3>
    <div className="mb-3">
      <RichTextSection text={driver.explanation}/>
    </div>
  </div>

export const Actions = ({ onDone }) =>
  <React.Fragment>
    <DoneButton onClose={onDone} />
  </React.Fragment>

export const EditDriverIcon = ({ user, driver, onEdit, margin = '' }) =>
  isVisitor(user) ? null :
    <div className={`ms-auto ${margin}`}>
      <Button onClick={() => onEdit(driver.slug)} bsPrefix="btn btn-sm btn-secondary btn-sm-round py-1">
        <i className="fas fa-pencil-alt fa-lg w-100" />
      </Button>
    </div>

export const ConfidenceRow = ({ driver, applyAccordion = true }) => {
  if(isBlank(driver.confidence)) return null;

  const confidenceHash = CONFIDENCE_LEVELS.find(hash => hash.value === driver.confidence.toString())
  if(isBlank(confidenceHash)) return null;

  const HeaderElement = ({classNames}) => <h3 className={classNames}>Confidence level</h3>

  const accordionObject = {
    header: <HeaderElement classNames={'mb-0'}/>,
    body: <div className="driver-confidence-bubble">
      <div className="radio-container">
        <ConfidenceBubble confidence={driver.confidence.toString()} disabled={true} {...confidenceHash} />
      </div>
    </div>,
    defaultExpand: isPresent(driver.confidence)
  };

  if(applyAccordion)
    return <AccordionWrapper accordionObject={accordionObject} />

  return <>
    <HeaderElement />
    {accordionObject.body}
  </>
};

export const DataSourcesRow = ({ driver, driverDataSources, applyAccordion = true }) => {
  if (isBlank(driverDataSources)) return null;

  const body = <div className="d-flex flex-column">
    {sortSourceByType(driverDataSources, IMAGE_FILE_TYPES).map(source => {
      const sourceObj = new DataSource(source);
      return sourceObj.isImage ?
        <Image className='w-100 my-2 border'
               key={`driver-${source.slug}-key-${source.key}`}
               src={source.url}
               alt={source.title}
               title={source.title} /> :
        <SourceItem key={`driver-modal-${source.slug}-key-${source.key}`}
                    driver={driver} source={source}
                    hideMenu={true} />
    })}
  </div>

  const accordionObject = {
    header: <h3 className="mb-0">Data sources</h3>,
    body: body,
    defaultExpand: isPresent(driverDataSources)
  };

  if(applyAccordion) {
    return <AccordionWrapper accordionObject={accordionObject} className={'my-3'} />
  } else {
    return <><h3 className="mt-3">Data sources</h3>{body}</>;
  }
}

export const AnsweredByRow = ({ driver, answeredByUser, insightsData, avatarSize = 'md' }) => {
  if(isBlank(answeredByUser)) return null;

  const prefix = isPresent(driver.driver_type_name) ? `${driver.driver_type_name} entered by` : 'Entered by'

  return <div className={`d-flex align-items-center mt-3 ${isPresent(insightsData) ? 'mb-3' : ''}`}>
    <UserAvatarImage user={answeredByUser} size={avatarSize} isPendingUser={isBlank(answeredByUser.full_name)} />
    <div className="text-muted ms-2">
      {prefix} {userName(answeredByUser) || answeredByUser} {moment(driver.answered_at).format('DD MMM, yyyy')}
    </div>
  </div>
};

const DriverShowModal = ({
                           decision, driver, drivers, show,
                           key = '', trigger = null,
                           driverDataSources, user,
                           onEdit, onClose, onCloseWithSave, answeredByUser,
                           default_user_avatar_url
                         }) => {
  const innerRef = useRef(null)
  const decisionObj = new Decision(decision, drivers)
  const driverObj = useMemo(() => new Driver(driver, [], {}, decisionObj), [driver, decisionObj]);
  const startScale = RATING_SCALES_DATA[driverObj.driver?.rating_scale]?.start_scale || MIN_SCALE
  const endScale = RATING_SCALES_DATA[driverObj.driver?.rating_scale]?.end_scale || MAX_SCALE
  const isYesOrNo = RATING_SCALES_DATA[driverObj.driver?.rating_scale]?.yes_or_no;

  const close = (needToTransferData = true) => {
    innerRef.current?.onClose()
    needToTransferData ? onCloseWithSave() : onClose()
  }
  const onDone = () => {
    if (isPresent(innerRef.current)) {
      innerRef.current?.onDone(close)
    } else {
      close(false);
    }
  }

  return <Modal key={key} size="lg" backdrop="static" show={show} onHide={close}>
    <Modal.Body>
      <form>
        <div>
          <CloseIcon onClose={close} />
          <DriverTypeRow driver={driver} user={user} onEdit={onEdit} />
        </div>
        <div className="mb-3">
          <div className="h3 text-ellipsis">{driver.question}</div>
          { decisionObj.isRateAndCompareDriverChoicesHidden ? <AnswerRow driver={driver} /> : null }
        </div>
        { decisionObj.isRateAndCompareDriverChoicesHidden ? null : <RatedChoices driverObj={driverObj} startScale={startScale} endScale={endScale} isYesOrNo={isYesOrNo} /> }
        <div className="mb-3">
          <ExplanationRow driver={driver} />
        </div>
        <ConfidenceRow driver={driver} />
        <DataSourcesRow driverDataSources={driverDataSources} driver={driver} />
        <CommentsBlock {...{ ref: innerRef, trigger }} />
        <AnsweredByRow driver={driver} answeredByUser={answeredByUser} default_user_avatar_url={default_user_avatar_url} insightsData={driverObj.insightsData} />
        <DSightAnalysis decision={decision} driverObj={driverObj} keyPreffix={'driver-show-modal'} />
      </form>
    </Modal.Body>
    <ModalDoneFooter onClose={onDone} />
  </Modal>
};

const mapStateToProps = ({ modal, decision, tree, current_user }) => {
  const { driver, driverDataSources } = mapModalDriverWithSourcesToProps({ modal })
  const users  = decision.users || [];
  return {
    driver, driverDataSources,
    decision: modal.decision || {},
    drivers: tree.drivers,
    answeredByUser: users.find((user) => user.email === driver.answered_by_user),
    default_user_avatar_url: tree.default_user_avatar_url,
    user: current_user,
    trigger: modal.trigger
  }
};
export default connect(mapStateToProps)(DriverShowModal);

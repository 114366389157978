import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { updateRecordDecision } from "../../../store/sidebar/actions";
import {isBlank} from "../../../helpers/common";
import { RichTextEditor } from "../../../common/RichTextEditor";

const RationaleInput = ({ decision, updateRecordDecision }) => {
  if (isBlank(decision)) return null;

  const [reasons, setReasons] = useState(decision.final_decision_reasons);
  const onChangeReasons = (_e) => { updateRecordDecision({ final_decision_reasons: reasons }); };

  useEffect(() => {
    if (reasons !== decision.final_decision_reasons) {
      setReasons(decision.final_decision_reasons)
    }
  }, [decision.final_decision_reasons])

  return <div className="mb-3 px-3" onBlur={onChangeReasons}>
    <h3>Rationale</h3>
    <RichTextEditor quillId={'Rationale'} value={reasons} setValue={setReasons}
                    placeholder="Enter a detailed explanation"
    />
  </div>
}
const mapStateToProps = (state) => ({ decision: state.sidebar.decision });
export default connect(mapStateToProps, { updateRecordDecision })(RationaleInput);

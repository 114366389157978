import React, { useRef, useState } from 'react';
import { connect } from "react-redux";
import SlidingPanel from "react-sliding-side-panel";
import Button from 'react-bootstrap/Button';
import { isPresent } from "../../helpers/common";
import { setPlaybookSidebarOpen } from "../../store/sidebar/actions";
import PlaybookNotesSection from "./sections/PlaybookNotesSection";
import PlaybookForm from "./sections/PlaybookForm";
import PlaybookDataSourcesSection, { sortedPlaybookDataSources } from "./sections/PlaybookDataSourcesSection";
import { isPlaybookNotesPresent } from "../../helpers/playbook_helpers";
import PanelHeader from "../../tree_view/side_panel/PanelHeader";
import { isMobile } from "react-device-detect";

export const PlaybookPanelWrapper = ({
                                       sidebar, preview = false,
                                       children, position = 'left',
                                       openSideBar, addClass,
                                       inputRef,
                                       edit, setEdit
                                    }) => {
  const onSubmit = () => {
    if (inputRef.current?.submit) inputRef.current?.submit()
    setEdit(false)
  }

  return <SlidingPanel
    type={position}
    isOpen={sidebar.playbookSidebar}
    panelContainerClassName={`sidebar-panel-wrapper decision-details-panel-wrapper playbook-panel ${addClass} ${isMobile ? 'pb-5 mb-5' : ''}`}
    panelClassName={`sidebar-panel-wrapper-content decision-details-panel-content`}
    onClose={onSubmit}
    size={52}
  >
    <PanelHeader headerName={'Playbook'} openSideBar={openSideBar} preview={preview} edit={edit} setEdit={setEdit} />
    { children }
    <div className={`${isMobile ? 'mb-5' : 'side-panel bottom'} p-3`} hidden={!edit}>
      <Button className="w-100 m-0" onClick={onSubmit}>Done</Button>
    </div>
  </SlidingPanel>
}

export const PlaybookHeader = ({ tree, edit, children }) =>
  <div className={`px-3 pb-3 pt-0 ${isPresent(sortedPlaybookDataSources(tree)) || edit ? 'border-bottom' : ''}`}>
    { children }
  </div>

export const TemplatePlaybookPanel = ({ tree, preview, sidebar, template, openSideBar, addClass, position }) => {
  if (preview && !isPlaybookNotesPresent(template)) return null;
  const inputRef = useRef(null)
  const [edit, setEdit] = useState(false)

  return <PlaybookPanelWrapper sidebar={sidebar} preview={preview} addClass={addClass} position={position} openSideBar={openSideBar} inputRef={inputRef} edit={edit} setEdit={setEdit}>
    <PlaybookHeader edit={edit} tree={tree}>
      { edit ? <PlaybookForm ref={inputRef} /> : <PlaybookNotesSection /> }
    </PlaybookHeader>
    <PlaybookDataSourcesSection edit={edit} isTemplate={true} isPlaybook={true} />
  </PlaybookPanelWrapper>
}
const mapStateToProps = ({ tree, sidebar, template }) => ({ tree, sidebar, template });
export const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => { dispatch(setPlaybookSidebarOpen(options)); }
});
export default connect(mapStateToProps, mapDispatchToProps)(TemplatePlaybookPanel);

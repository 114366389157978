import React, {useState} from "react";
import {isBlank} from "../helpers/common";
import {Accordion} from "react-bootstrap";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

const CustomToggle = ({ children, eventKey = '0', rotate, setRotate }) =>
  <div onClick={useAccordionButton(eventKey, () => setRotate(!rotate))}>
    {children}
  </div>

export default ({ accordionObject, className = '', bodyClass = 'mt-2' }) => {
  const {header, body, defaultExpand} = accordionObject
  const [rotate, setRotate] = useState(defaultExpand)
  if(isBlank(header) || isBlank(body)) return null;

  return <Accordion className={className} defaultActiveKey={defaultExpand ? '0' : '1'}>
    <CustomToggle {...{ rotate, setRotate }}>
      <div className='pointer'>
        <div className="d-inline-flex align-items-start">
          {header}
          <span className={`dropdown-toggle accordion-arrow pb-1 ms-1 h-0 ${rotate ? 'rotate-after-180 rotated' : ''}`} />
        </div>
      </div>
    </CustomToggle>
    <Accordion.Collapse eventKey="0">
      <div className={bodyClass}>
        {body}
      </div>
    </Accordion.Collapse>
  </Accordion>
}

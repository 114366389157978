import React from "react";
import DraftTitle from "../../../common/DraftTitle";
import { isBlank } from "../../../helpers/common";
import { TooltipWrapper } from "../../../bubble_tooltip/BubbleTooltip";
import {DriverStatus} from "../../tree_view/nodes/ZTreeDriverNode";
import SequenceTitle from "../../../common/SequenceTitle";
import UserAvatarImage from "../../../common/UserAvatarImage";
import {isPendingContact} from "../../../helpers/decision_helpers";
import { userName } from "../../../helpers/user_helpers";

export const DecisionDescription = ({ treeNode, assignDecisions = false }) =>
  <span className="selectable-area decision-order text-truncate">
    <DriverStatus treeNode={treeNode} />
    <SequenceTitle treeNode={treeNode}  />
    <DraftTitle draft={treeNode.draft}/>
    <span id={`${treeNode.tId}_span`} className={`node_name ${assignDecisions && (treeNode.isDSight || treeNode.answered) ? 'text-muted' : ''}`}>
      {treeNode.name}
    </span>
  </span>

export const DecisionAssignment = ({ treeNode, set }) => {
  const allUsers = [...(treeNode.users ?? []), ...(set.users ?? [])]
  if(isBlank(allUsers) || !treeNode.assigned || treeNode.answered) return null;

  const assignedTo = allUsers.find((user) => treeNode.assignedToUser === user.email) || {}
  return <TooltipWrapper bubbleIcon={`decision-assignment-${treeNode.slug}`} tooltipText={`Assigned to ${userName(assignedTo) || treeNode.assignedToUser}`}>
    <span className="ms-auto">
      <UserAvatarImage user={assignedTo} isPendingUser={isPendingContact(assignedTo)} hideTitle={true} />
    </span>
  </TooltipWrapper>
}

const ZTreeDecisionNode = ({ treeNode, set, assignDecisions = false }) =>
  <div className="d-flex align-items-center h-inherit justify-content-between">
    <DecisionDescription treeNode={treeNode} assignDecisions={assignDecisions} />
    <DecisionAssignment treeNode={treeNode} set={set} />
  </div>

export default ZTreeDecisionNode;

import React from "react";
import { connect } from "react-redux";
import { isBlank, pluralize } from "../../../helpers/common";
import { TooltipWrapper } from "../../../bubble_tooltip/BubbleTooltip";
import UserAvatarImage from "../../../common/UserAvatarImage";
import {isPendingContact} from "../../../helpers/decision_helpers";
import { userName } from "../../../helpers/user_helpers";

export const DriverQuestion = ({ treeNode, disableQuestion }) => {
  return <span id={`${treeNode.tId}_span`} className={`node_name ${disableQuestion ? 'text-muted' : ''}`}>{treeNode.question}</span>
}

export const DriverStatus = ({ treeNode }) => {
  const iconClass = treeNode.inFlight ? 'in-flight' : (treeNode.answered ? 'answered' : 'non-answered')
  return <span className={`button ${iconClass}`} />
}

export const DriverAssignment = ({ treeNode, collaborators }) => {
  if(isBlank(collaborators) || !treeNode.assigned) return null;
  const assignedTo = collaborators.find((user) => treeNode.assignedToUser === user.email) || {}

  return <TooltipWrapper bubbleIcon={`driver-assignment-${treeNode.slug}`} tooltipText={`Assigned to ${userName(assignedTo)}`} isDriver={true}>
    <div className="ms-auto">
      <UserAvatarImage user={assignedTo} isPendingUser={isPendingContact(assignedTo) || isBlank(assignedTo)} hideTitle={true} />
    </div>
  </TooltipWrapper>
}

export const DriverPlaybook = ({ treeNode }) => {
  if(!treeNode.has_notes || treeNode.isHistoricalDecision ) return null;

  return <TooltipWrapper bubbleIcon="fa-book-open" tooltipText="Playbook notes">
    <span className="playbook-icon ms-auto me-1">
      <i className='fa fa-solid fa-book-open align-middle text-primary fa-3x rounded-0' />
    </span>
  </TooltipWrapper>
}

export const StatusAndQuestion = ({ treeNode, classes, disableQuestion }) =>
  <span className={classes}>
    <DriverStatus treeNode={treeNode} />
    <DriverQuestion treeNode={treeNode} disableQuestion={disableQuestion} />
  </span>

const DriverComments = ({ treeNode }) => {
  if(!treeNode.commented) return null;

  return <TooltipWrapper bubbleIcon="fa-comment" tooltipText={`${pluralize(treeNode.comments_size, ' comment')}`}>
      <span className="comment-icon ms-auto me-1">
       <i className='fa fa-solid fa-comment text-primary fa-2x rounded-0' />
      </span>
  </TooltipWrapper>
}

const ZTreeDriverNode = ({ key, tree, treeNode, collaborators, disableQuestion = false, decision }) =>
  <div className="d-flex align-items-center h-inherit justify-content-between" key={key}>
    <StatusAndQuestion treeNode={treeNode} disableQuestion={disableQuestion} classes={`selectable-area ${tree.copied_ztree_node.slug === treeNode.slug ? 'copied' : ''} text-truncate`} />
    <div className="d-flex align-items-center">
      <DriverComments treeNode={treeNode} />
      <DriverPlaybook treeNode={treeNode} />
      <DriverAssignment treeNode={treeNode} collaborators={collaborators} classNames="mb-0"/>
    </div>
  </div>

const mapStateToProps = ({ tree, decision, template },  { isTemplate }) => ({
  tree, decision: isTemplate ? template : decision
});
export default connect(mapStateToProps)(ZTreeDriverNode);

import React, { useCallback } from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  resetSidebars,
  setSetSectionsSidebarOpen,
  setSetDecisionsSidebarOpen,
  setAssignDecisionsSidebarOpen
} from "../../store/sidebar/actions";
import HamburgerDropdown, { DeleteSetItem } from './HamburgerDropdown'
import { redirectToHomeCallback } from "../../helpers/decision_helpers";
import { successActions } from "../../helpers/common";
import { IconDropdown } from "../../common/dropdowns";
import { BaseDropdownBtn } from "../../common/BaseHamburgerBtn";
import { openModal } from "../../store/modals/actions";
import { openModalCallback } from "../modals/SetNameModal";
import { resetSearchAndFilters } from "../../store/homepage/actions";
import OrgLogo from "../../tree_view/header/OrgLogo";
import { openTemplateSetUsersEntry } from "../modals/TemplateSetUsersModal";
import ProgressMeter from "../../decision_set_view/ProgressMeter";
import AddDecisionView from "../../decision_set_search_view/header/AddDecisionView"

const TemplateFlowView = ({
                            set, homeTab, setTitle,
                            isManager = true, isTemplateSet= false,
                            current_org,
                            resetSet, removeSet,
                            resetSidebars, openModal, reloadRecords, resetSearchAndFilters, setSetSectionsSidebarOpen,
                            setSetDecisionsSidebarOpen, setAssignDecisionsSidebarOpen,
                            ...opts
                          }) => {
  const history = useHistory();
  const processBackClick = useCallback(() => {
    successActions(true, [resetSet, resetSearchAndFilters, resetSidebars, reloadRecords]);
    redirectToHomeCallback(true, history);
  }, []);

  return <>
    <div className="d-flex align-items-center active"
         style={{maxWidth: 'calc(100% - 343px)'}}>
      <div className="logo-dropdown pointer d-none d-lg-flex me-2">
        <OrgLogo current_org={current_org} processBackClick={processBackClick} />
      </div>
      <h5>Decision flow templates</h5>
    </div>
    <div className="navbar-nav ms-auto">
      <div className='d-lg-none'>
        <HamburgerDropdown {...{ ...opts, set, isTemplateSet, setTitle, homeTab, resetSet, removeSet, isManager, reloadRecords }} />
      </div>
      {
        isManager &&
          <ul className="navbar-nav ms-auto d-none d-lg-flex align-items-center">
            <IconDropdown bsPrefix=" h5 m-0 btn-link pt-2" className="btn btn-secondary btn-lg-round d-none d-lg-flex p-0">
              <BaseDropdownBtn eventKey={set.slug} onSelect={setSetDecisionsSidebarOpen} title={`Add or remove ${setTitle}s`} />
              <BaseDropdownBtn eventKey={set.slug} onSelect={setAssignDecisionsSidebarOpen} title={`${isTemplateSet ? 'Assign templates' : 'Assign unstarted decisions'}`} />
              <BaseDropdownBtn hidden={!isTemplateSet} eventKey={set.slug} onSelect={openTemplateSetUsersEntry(openModal)} title={`Edit decision flow users`} />
              <BaseDropdownBtn eventKey={set.slug} onSelect={openModalCallback(openModal, set)} title={`Edit decision flow name`} />
              <BaseDropdownBtn eventKey={set.slug} onSelect={setSetSectionsSidebarOpen} title={`Add or remove sections`} />
              <DeleteSetItem {...{ set, setTitle, homeTab, reloadRecords, resetSidebars, resetSet, removeSet }} />
            </IconDropdown>
          </ul>
      }
    </div>
  </>
};
const SetHeader = ({ isAddPage= false, ...opts }) =>
  <header className="decision-tree-header">
    <nav className="navbar navbar-expand bd-navbar px-3">
      {
        (
          isAddPage && <AddDecisionView {...{ ...opts, isDecisionSet: false }} />
        ) || <TemplateFlowView {...opts} />
      }
    </nav>
    <ProgressMeter {...opts} />
  </header>

const mapStateToProps = ({ current_org, sidebar }) => ({ current_org, sidebar });

const mapDispatchToProps = (dispatch) => ({
  openModal: (data) => dispatch(openModal(data)),
  resetSidebars: () => dispatch(resetSidebars()),
  setSetSectionsSidebarOpen: () => dispatch(setSetSectionsSidebarOpen(true)),
  setSetDecisionsSidebarOpen: () => dispatch(setSetDecisionsSidebarOpen(true)),
  resetSearchAndFilters: () => dispatch(resetSearchAndFilters()),
  setAssignDecisionsSidebarOpen: () => dispatch(setAssignDecisionsSidebarOpen(true))
});
export default connect(mapStateToProps, mapDispatchToProps)(SetHeader);

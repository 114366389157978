import React, {useMemo, useState} from "react";
import {filterHashEmptyValues} from "../helpers/drivers_helpers";
import DoneButton from "../common/DoneButton";
import {isBlank, isPresent} from "../helpers/common";
import SummaryInput from "./inputs/SummaryInput";
import DetailsInput from "./inputs/DetailsInput";
import ConfidenceLevelInput from "./inputs/ConfidenceLevelInput";
import DriverSourceSection from "./inputs/DriverSourceSection";
import {SuccessSection} from "./QuickDriversPage";
import {RichTextSection} from "../common/RichTextEditor";
import RateDriverChoicesInput from "./inputs/RateDriverChoicesInput";
import Decision from "../models/decision";

const Notes = ({ driver }) => {
  const {notes} = driver

  return isPresent(notes) && <div className='my-3 border-top px-3'>
    <h3 className='mt-3'>Notes</h3>
    <RichTextSection text={notes} showMoreLess={true} />
  </div>
}

export const DriverSection = ({driver, updateQuickDriver, data, setData, assign_user_email, decision}) => {
  const current_user = { email: assign_user_email }
  const [driverData, setDriverData] = useState({ slug: driver.driver.slug });
  const [submitData, setSubmitData] = useState(false);
  const decisionObject = new Decision(data.decision)
  const filteredChoices = driverData?.driver_choices?.filter(choice => isPresent(choice.evaluation) || isPresent(choice.rating))

  const onChangeDriver = (value, name) => {
    setDriverData(Object.assign({}, driverData, {[name]: value}))
  }

  const onClose = () => {
    const callback = (success, drivers) => {
      if (success) {
        setData(Object.assign({}, data, {drivers}))
        setSubmitData(true)
      }
    }
    const filteredDriverData = filterHashEmptyValues(driverData)
    const draft = isBlank(filteredDriverData.answer) && isBlank(filteredChoices)
    updateQuickDriver({...filteredDriverData, draft, answered_by_email: current_user.email}, callback);
  }

  const disableDone = () => {
    if (!decisionObject.isRateAndCompareDriverChoicesHidden) return isBlank(filteredChoices)

    return isBlank(driverData.answer)
  }

  if (submitData) return <SuccessSection />

  return <div className="mx-auto quick-driver-container scroll-margin-tab bg-white py-3 m-3 rounded" id={`driver-card-${driver.driver.slug}`}>
    <SummaryInput driver={driver} driverData={driverData} decision={data.decision} onChangeDriver={onChangeDriver} />
    <RateDriverChoicesInput decision={data.decision} drivers={data.drivers} driver={driver} driverData={driverData} setDriverData={setDriverData} />
    <DetailsInput driver={driverData} onChangeDriver={onChangeDriver} />
    <ConfidenceLevelInput driver={driverData} onChangeDriver={onChangeDriver} />
    <DriverSourceSection {...{driver, current_user, setData}} />
    <Notes driver={driver.driver} />
    <div className='px-3 pt-3'>
      <DoneButton disabled={disableDone()} onClose={onClose} />
    </div>
  </div>
}

export default DriverSection

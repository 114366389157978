import { collectDecisionDriversComments } from "../decision/actions";
import { loadTreeSuccess, updateTreeData } from "./common_actions";
import { updateTemplateData } from "../template/common_actions";
import { deepEqual, isDecisionChanged, updateDecisionDataInStores } from "../../helpers/store_helpers";
import { updateDecisionData } from "../decision/common_actions";
import { saveSideBarData } from "../sidebar/common_actions";
import { isBlank, isPresent } from "../../helpers/common";
import { resetTemplateSetData } from "../template_set/common_actions";

export const transferDriverFromModal = (data) => (dispatch, getState) => {
  const { data_sources, drivers } = data;
  collectDecisionDriversComments(dispatch, getState, drivers)
  if (isDriversOrDataSourcesChanged(getState, drivers, data_sources)) {
    dispatch(updateTreeData({ data_sources, drivers }));
  }
}
export const transferTemplateFromModal = (data) => (dispatch, getState) => {
  const { template } = data;
  if (isTemplateChanged(getState, template)) {
    dispatch(updateTemplateData({ ...template }))
    if (isPresent(template.template_set_slug)) {
      dispatch(resetTemplateSetData());
    }
  }
}
export const transferSourcesAndDriversFromModal = (data) => {
  return (dispatch, getState) => {
    const { data_sources, drivers } = data;
    collectDecisionDriversComments(dispatch, getState, drivers)
    dispatch(updateTreeData({ data_sources, drivers }));
  }
}
export const transferDriversFromModal = (data) => (dispatch, getState) => {
  const { drivers } = data;
  collectDecisionDriversComments(dispatch, getState, drivers)
  if (isDriversChanged(getState, drivers)) {
    dispatch(updateTreeData({ drivers }));
  }
}
export const transferDecisionAndDriversAndSourcesFromModal = (data) => {
  return (dispatch, getState) => {
    const { decision, drivers, data_sources } = data;
    if (isDriversOrDataSourcesChanged(getState, drivers, data_sources)) {
      dispatch(updateTreeData({ data_sources, drivers }));
    }
    if (isDecisionChanged(getState, decision)) {
      dispatch(updateDecisionData({ ...decision }))
    }
    updateSidebarDriversDataSourcesData(dispatch, getState, drivers, data_sources)
    collectDecisionDriversComments(dispatch, getState, drivers)
    setTimeout(() => {
      dispatch(loadTreeSuccess({ changesSaved: false }));
    }, 1000)
  }
}
export const transferDecisionFromModal = (data, transferToSidebar = false, transferToDecisionSet = false) => (dispatch, getState) => {
  const { decision } = data;
  if (isBlank(decision)) return null;

  updateDecisionDataInStores(dispatch, getState, { ...decision }, {
    updateSidebar: transferToSidebar,
    updateDecisionSet: transferToDecisionSet || isPresent(decision.decision_set_slug)
  })
}

export const updateSidebarDriversDataSourcesData = (dispatch, getState, driversData, dataSourcesData) => {
  if (getState().sidebar.driverInputSidebar) {
    dispatch(saveSideBarData({ drivers: driversData, data_sources: dataSourcesData, leftError: '' }));
  } else {
    dispatch(transferDriversFromModal({ drivers: driversData, data_sources: dataSourcesData }))
  }
}

const isDriversOrDataSourcesChanged = (getState, drivers, data_sources) =>
  isDriversChanged(getState, drivers) || isDataSourcesChanged(getState, data_sources)

const isTemplateChanged = (getState, newTemplateData) => !deepEqual(getState().template, newTemplateData)

const isDriversChanged = (getState, drivers) => !deepEqual(drivers, getState().tree.drivers)
const isDataSourcesChanged = (getState, data_sources) => !deepEqual(data_sources, getState().tree.data_sources)

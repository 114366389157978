const firefoxCopyToClipboard = (value) => {
  return navigator.clipboard.writeText(value);
};

export default (value) => {
  if (navigator.clipboard.writeText) return firefoxCopyToClipboard(value);

  const dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = value;

  if (navigator.userAgent.match(/ipad|iphone/i)) {
    const range = document.createRange();
    range.selectNodeContents(dummy);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    dummy.setSelectionRange(0, 999999);
  } else {
    dummy.select();
  }
  document.execCommand('copy');
  document.body.removeChild(dummy);
}

import React, {useRef} from "react";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import { attachEventEffect } from "../../../../helpers/html_events_helpers";
import { removeDecisionSetSection } from "../../../../store/decision_set/actions";
import { removeTemplateSetSection } from "../../../../store/template_set/actions";

const RemoveSection = ({
                         treeNode, isTemplateSet = false,
                         removeDecisionSetSection, removeTemplateSetSection
                      }) => {
  const removeSection = isTemplateSet ? removeTemplateSetSection : removeDecisionSetSection
  const onRemove = () => removeSection(treeNode.slug)
  const ref = useRef();
  attachEventEffect(ref, onRemove)

  return <Button className="btn btn-light btn-sm btn-sm-round bg-light-gray text-danger ms-1" ref={ref}>
    <i className="fa fa-solid fa-times bg-light-gray text-danger fa-2x w-100"/>
  </Button>
}
export default connect(null, { removeDecisionSetSection, removeTemplateSetSection })(RemoveSection);

import React from 'react';

export const Spinner = () =>
  <div className="spinner-border" role="status">
    <span className="sr-only">Loading...</span>
  </div>

export const SmallLoader = () =>
  <div className="fs-5 lh-lg d-flex justify-content-center small-spinner text-primary w-100">
    <Spinner />
  </div>

export const Loader = () =>
  <div className="d-flex justify-content-center mt-3 text-primary w-100">
    <Spinner />
  </div>

export const LoaderInAbsolute = () =>
  <div className="position-absolute d-flex justify-content-center align-items-center text-primary h-100 w-100">
    <Spinner />
  </div>

import React from 'react';
import DetailsItem from "../../tree_view/navigation/DetailsItem";
import HelpTemplateItem from "./HelpTemplateItem";
import DecisionSetItem from "./DecisionSetItem"

const NavigationItems = ({ preview = false, ...opts }) =>
  <>
    <div className="sidebar-top">
      <ul className="list-unstyled m-0 p-3">
        <DetailsItem />
        <DecisionSetItem {...opts} />
      </ul>
    </div>
    { preview ? null :
      <div className="sidebar-bottom">
        <ul className="list-unstyled m-0 p-3">
          <HelpTemplateItem/>
        </ul>
      </div>
    }
  </>

export const SidebarTreeNavigationTemplateSet = ({ ...opts }) =>
  <div id="sidebar-navigation" className="tree-sidebar bg-light-gray d-none d-lg-block">
    <NavigationItems {...opts} />
  </div>

export default SidebarTreeNavigationTemplateSet;

import React, { useRef } from "react";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import { openModal, transferDataAndCloseModal } from "../../../../store/modals/actions";
import { attachEventEffect } from "../../../../helpers/html_events_helpers";


const EditSection = ({ set, isTemplateSet, treeNode, openModal, ...opts }) => {
  const ref = useRef();
  const onEditSection = (e) => {
    e.preventDefault();
    openModal({ type: 'SetSectionsPanelEditSectionModal', slug: treeNode.slug, set_section: { ...treeNode }})
  }
  attachEventEffect(ref, onEditSection)

  return <>
    <Button className="btn btn-secondary btn-sm btn-sm-round ms-1" ref={ref}>
      <i className="fas fa-pencil-alt fa-lg w-100" />
    </Button>
  </>
}
const mapStateToProps = ({ modal, decision_set, template_set }, { isTemplateSet }) => ({
  modal, set: isTemplateSet ? template_set : decision_set
});
export default connect(mapStateToProps, { openModal, transferDataAndCloseModal })(EditSection);

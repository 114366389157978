import React from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import {
  resetSidebars,
  setSetSectionsSidebarOpen,
  setSetDecisionsSidebarOpen,
  setAssignDecisionsSidebarOpen, setSetSummarySidebarOpen
} from "../../../store/sidebar/actions";
import { openModal } from "../../../store/modals/actions";
import { openModalCallback } from "../../modals/SetNameModal";
import { openTemplateSetUsersEntry } from "../../modals/TemplateSetUsersModal";
import { deleteSetCallback } from "../../helpers";
import { isSummaryPending } from "../../../helpers/set_helpers";

export const SetMoreActionsSection = ({
                                        set, setTitle, homeTab, preview = false, isManager = true,
                                        removeSet, resetActions, isTemplateSet = false,
                                        resetSidebars, openModal, setSetSectionsSidebarOpen, setSetDecisionsSidebarOpen,
                                        setAssignDecisionsSidebarOpen, setSetSummarySidebarOpen
                                      }) => {
  if (preview || !isManager) return null;

  const history = useHistory();
  const deleteTemplateHandler = deleteSetCallback(removeSet,
    [...resetActions, resetSidebars],
    { history, homeTab, setTitle }
  )

  return <React.Fragment>
    <div className="p-3 border-bottom">
      <h3>More actions</h3>
      <Button onClick={setSetDecisionsSidebarOpen} className="btn btn-secondary w-100 mb-2">
        Add or remove {setTitle}s
      </Button>
      {
        isTemplateSet ?
          <>
            <Button onClick={setAssignDecisionsSidebarOpen} className="btn btn-secondary w-100 mb-2">
              Assign templates
            </Button>
            <Button onClick={openTemplateSetUsersEntry(openModal)} className="btn btn-secondary w-100 mb-2">
              Edit decision flow users
            </Button>
          </>
          : <Button onClick={setAssignDecisionsSidebarOpen} className="btn btn-secondary w-100 mb-2">
            Assign unstarted decisions
          </Button>
      }
      <Button onClick={openModalCallback(openModal, set)} className="btn btn-secondary w-100 mb-2">
        Edit decision flow name
      </Button>
      <Button onClick={setSetSectionsSidebarOpen} className="btn btn-secondary w-100 mb-2">
        Add or remove sections
      </Button>
      {
        !isTemplateSet &&
          isManager && isSummaryPending(set) &&
          <Button onClick={setSetSummarySidebarOpen} className="btn btn-secondary w-100 mb-2">
            Create a flow summary
          </Button>
      }
      <Button onClick={deleteTemplateHandler} className="btn-light text-danger w-100 mb-0">
        Ungroup decision flow
      </Button>
    </div>
  </React.Fragment>
}
const mapStateToProps = ({}) => ({});
const mapDispatchToProps = (dispatch) => ({
  resetSidebars: () => dispatch(resetSidebars()),
  openModal: (data) => dispatch(openModal(data)),
  setSetSummarySidebarOpen: () => dispatch(setSetSummarySidebarOpen(true)),
  setSetSectionsSidebarOpen: () => dispatch(setSetSectionsSidebarOpen(true)),
  setSetDecisionsSidebarOpen: () => dispatch(setSetDecisionsSidebarOpen(true)),
  setAssignDecisionsSidebarOpen: () => dispatch(setAssignDecisionsSidebarOpen(true))
});
export default connect(mapStateToProps, mapDispatchToProps)(SetMoreActionsSection);

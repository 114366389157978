import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { updateDecisionAssignedRecommender } from "../../../store/sidebar/actions";
import {isBlank, isPresent} from "../../../helpers/common";
import DecisionAssignToBlock from "../../modals/helpers/DecisionAssignToBlock";
import {assignAssignedUserCallback} from "../decision_input/AssignedDeciderInput";
import Decision from "../../../models/decision";

const AssignedRecommenderInput = ({ decision, updateDecisionAssignedRecommender, current_user }) => {
  const recommendation = decision?.recommendation
  const [assignedToUser, setAssignedToUser] = useState(recommendation?.assigned_recommender_email)
  const decisionObj = decision && new Decision(decision);

  const isFinalChoices = recommendation?.recommendation_choices?.some(choice => choice.final_decision)
  assignAssignedUserCallback({
    decisionObj, isFinalChoices, current_user,
    assignedToUser, setAssignedToUser,
    updateAssignedAction: updateDecisionAssignedRecommender
  })

  if (isBlank(decision) || isBlank(recommendation)) return null;

  return <div className="w-100 px-3">
    <DecisionAssignToBlock {...{
      assignedToUser, setAssignedToUser,
      updateAction: (email) => updateDecisionAssignedRecommender(email), title: 'Recommender', className: 'mb-3'
    }} />
  </div>
}
const mapStateToProps = ({ sidebar, current_user  }) => ({ decision: sidebar.decision, current_user  });
export default connect(mapStateToProps, { updateDecisionAssignedRecommender })(AssignedRecommenderInput);

import React from 'react';
import { connect } from "react-redux";
import {isBlank, isPresent} from "../../../../helpers/common";
import { RichTextEditor } from "../../../../common/RichTextEditor";
import AccordionWrapper from "../../../../common/AccordionWrapper";

const ExpectedResultsInput = ({ object, decision, expectedResults, setExpectedResults }) => {
  if (isBlank(decision)) return null;

  const accordionObject = {
    header: <h3 className="mb-0">Expected results</h3>,
    body: <RichTextEditor quillId={'Expected Results'} value={expectedResults} setValue={setExpectedResults}
                          placeholder="Describe what is expected to happen" />,
    defaultExpand: isPresent(object.expected_results)
  };

  return  <AccordionWrapper accordionObject={accordionObject} className='mb-3' />
}
const mapStateToProps = (state) => ({ decision: state.sidebar.decision });
export default connect(mapStateToProps)(ExpectedResultsInput);

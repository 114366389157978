import React, {useState, useEffect, Fragment} from 'react';
import { connect } from "react-redux";
import {isBlank, isPresent, qSortArray} from "../../helpers/common";
import { isVisitor } from "../../helpers/user_helpers";
import CommentRow from "./CommentRow";
import AddCommentForm from "./AddCommentForm";
import { mapModalSidebarDriverToProps } from "./EditCommentRow";
import { ADD_COMMENT_ACTION } from "../side_panel/discussion/MessageRow";
import { NEW_COMMENT_KEY } from "./comments_helpers";
import AccordionWrapper from "../../common/AccordionWrapper";

const CommentsBlock = ({
                         innerRef,
                         isDriverPanel = false, trigger = null,
                         driver, users, user,
                         avatarSize = 'sm', applyAccordion = true, classNames = ''
                       }) => {
  const comments =  driver?.comments?.filter(c => isBlank(c.feedback_reply_id))?.map(c => ({ ...c, user: users.find(u => u.email === c.user_email) })) || []
  if(isVisitor(user) && isBlank(comments)) return null;

  const [displayAddCommentForm, setDisplayAddCommentForm] = useState(false)
  const [lastOpenedField, setLastOpenedField] = useState(false)
  const sortedComments = qSortArray(comments, true, s => s.created_at)

  useEffect(() => {
    if (trigger === ADD_COMMENT_ACTION) onAddCommentClick()
  }, [trigger])
  const onAddCommentClick = () => {
    setDisplayAddCommentForm(true)
    setLastOpenedField(NEW_COMMENT_KEY)
  }

  const body = <Fragment>
    <div className="comments chat">
      {
        sortedComments.map(comment =>
          <CommentRow {...{ ref: innerRef, comment, lastOpenedField, setLastOpenedField, avatarSize, driver }}
                      key={`driver-${driver.slug}-comment-${comment.id}`} />
        )
      }
    </div>
    <AddCommentForm {...{
      ref: innerRef,
      displayAddCommentForm, setDisplayAddCommentForm, onAddCommentClick,
      lastOpenedField, setLastOpenedField, driver, noComments: isBlank(sortedComments)
    }} />
  </Fragment>

  const accordionObject = {
    header: <h3 className="mb-0">Comments</h3>,
    body: body,
    defaultExpand: isPresent(sortedComments)
  };

  if(applyAccordion) {
    return <div className={`${isDriverPanel ? 'mb-3' : 'mt-3'}`}>
      <AccordionWrapper accordionObject={accordionObject} className={`${isDriverPanel ? 'px-3' : ''}`} />
    </div>
  } else {
    return <div className={classNames}><h3>Comments</h3>{body}</div>;
  }
}
const mapStateToProps = ({ decision, current_user, ...opts }, { driver = null }) => ({
  ...mapModalSidebarDriverToProps({ ...opts, driver }),
  users: decision.users || [],
  user: current_user
});
const wrapper = React.forwardRef((props, ref) => <CommentsBlock {...props} innerRef={ref} />)
export default connect(mapStateToProps, null, null, { forwardRef: true })(wrapper);

import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import { updateRecordDecision } from "../../../store/sidebar/actions";
import {isBlank, isPresent} from "../../../helpers/common";
import { RichTextEditor } from "../../../common/RichTextEditor";
import AccordionWrapper from "../../../common/AccordionWrapper";

const NextStepsInput = ({ decision, updateRecordDecision }) => {
  if (isBlank(decision)) return null;

  const description = decision.next_steps_description
  const [nextStepsDescription, setNextStepsDescription] = useState(description || '');

  useEffect(() => {description && setNextStepsDescription(description)}, [description])

  const onChangeNextStepsDescription = (_e) => { updateRecordDecision({ next_steps_description: nextStepsDescription }) };

  const accordionObject = {
    header: <h3 className="mb-0">Next steps</h3>,
    body: <RichTextEditor quillId={'NextStepsInput'} value={nextStepsDescription} setValue={setNextStepsDescription}
                          placeholder="Enter any actions to take" />,
    defaultExpand: isPresent(description)
  };

  return <div onBlur={onChangeNextStepsDescription}>
    <AccordionWrapper accordionObject={accordionObject} className='px-3 mb-3' />
  </div>
}
const mapStateToProps = (state) => ({ decision: state.sidebar.decision });
export default connect(mapStateToProps, { updateRecordDecision })(NextStepsInput);

import {
  loadForecastSimulatorScenarioData,
  removeForecastSimulatorScenarioData,
  updateForecastSimulatorScenarioData
} from "../../utils/Api";
import { failedResponseHandler, isResponseFailed } from "../../helpers/store_helpers";
import { LOAD_SCENARIO_FAILED, UPDATE_SCENARIO_DATA } from "./types";
import { isBlank, isPresent } from "../../helpers/common";

export const loadForecastSimulatorScenario = (scenario_id) => (dispatch, getState) => {
  const only_scenario = isPresent(getState().forecast_simulator_scenario.config)
  loadForecastSimulatorScenarioData(scenario_id, { only_scenario }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadFailure });

    const { data } = response;
    const benchmark_id = getState().forecast_simulator_scenario.benchmark_id === scenario_id ? null : getState().forecast_simulator_scenario.benchmark_id
    if (only_scenario) {
      const { scenario } = data;
      dispatch(updateScenarioData({ scenario, scenario_id, benchmark_id, loaded: true }));
    } else {
      const { scenario, config, config_scenarios } = data;
      dispatch(updateScenarioData({ scenario, config, config_scenarios, benchmark_id, scenario_id, loaded: true }));
    }
  })
}

export const loadForecastSimulatorDBenchmarkScenario = (benchmark_id) => (dispatch) => {
  if (isBlank(benchmark_id)) return null;

  dispatch(updateScenarioData({ loading: true }))
  loadForecastSimulatorScenarioData(benchmark_id, { only_scenario: true }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadFailure });

    const { data } = response;
    const { scenario } = data;
    dispatch(updateScenarioData({ benchmark_scenario: scenario, benchmark_id, loading: false }));
  })
}

export const updateViewOptions = (newViewOptions, callback = () => {}) => (dispatch, getState) => {
  updateForecastSimulatorScenarioData(getState().forecast_simulator_scenario.scenario_id, {scenario: {view_options: newViewOptions}}).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, {
      ...response,
      callback: loadFailure
    }, callback);
    const { data } = response;
    const { scenario } = data;
    dispatch(updateScenarioData({
      scenario: {
        ...getState().forecast_simulator_scenario.scenario,
        ...scenario
      },
    }));
    callback(true)
  })
}
export const updateScenario = (scenario_id, updateData, callback, showLoading = true) => (dispatch, getState) => {
  if(showLoading) dispatch(updateScenarioData({ loading: true }))
  updateForecastSimulatorScenarioData(scenario_id, updateData).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadFailure }, callback);
    const { data } = response;
    const { scenario } = data;
    dispatch(updateScenarioData({
      scenario: {
        ...getState().forecast_simulator_scenario.scenario,
        ...scenario
      },
      loading: false
    }));
    callback(true)
  })
}

export const removeScenario = (scenario_id, callback) => (dispatch) => {
  dispatch(updateScenarioData({ loading: true }))
  removeForecastSimulatorScenarioData(scenario_id).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadFailure }, callback);

    callback(true)
  })
}

export const loadFailure = error => ({
  type: LOAD_SCENARIO_FAILED,
  payload: {
    error,
    loading: false
  }
});

export const updateScenarioData = (data) => ({
  type: UPDATE_SCENARIO_DATA,
  payload: {
    ...data
  }
});

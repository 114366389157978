import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import { updateRecordDecision } from "../../../store/sidebar/actions";
import {isBlank, isPresent} from "../../../helpers/common";
import { RichTextEditor } from "../../../common/RichTextEditor";
import AccordionWrapper from "../../../common/AccordionWrapper";

const ExpectedResultsInput = ({ decision, updateRecordDecision }) => {
  if (isBlank(decision)) return null;

  const [expectedResults, setExpectedResults] = useState(decision.expected_results || '');

  const onChangeExpectedResults = (_e) => { updateRecordDecision({ expected_results: expectedResults }); };

  useEffect(() => {
    if (expectedResults !== decision.expected_results) {
       setExpectedResults(decision.expected_results)
    }
  }, [decision.expected_results])

  const accordionObject = {
    header: <h3 className="mb-0">Expected results</h3>,
    body: <RichTextEditor quillId={'Expected Results'} value={expectedResults} setValue={setExpectedResults}
                          placeholder="Describe what is expected to happen" />,
    defaultExpand: isPresent(decision.expected_results)
  };

  return <div onBlur={onChangeExpectedResults}>
    <AccordionWrapper accordionObject={accordionObject} className='px-3 mb-3' />
  </div>
}
const mapStateToProps = (state) => ({ decision: state.sidebar.decision });
export default connect(mapStateToProps, { updateRecordDecision })(ExpectedResultsInput);

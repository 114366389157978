import React from 'react';
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import Decision from "../../../models/decision";
import {openModal} from "../../../store/modals/actions";
import {isPresent} from "../../../helpers/common";
import Moment from 'moment';
import { isCollaborator, isDecider } from "../../../helpers/user_helpers";

const RenderFollowupResults = ({ decision, user, openModal}) => {
  if (!isDecider(user) && !isCollaborator(user)) return null;

  const decisionObject = new Decision(decision);
  const followup_due_date = decisionObject.lastFollowup.followup_due_date
  const current_date = Moment(new Date()).format('YYYY-MM-DD')
  const showFollowup = !decisionObject.isDecisionWithResults && decisionObject.isDecisionRecorded && isPresent(followup_due_date) && (followup_due_date <= current_date)

  return showFollowup ?
    <Button onClick={() => openModal({ type: 'FollowupResultsModal'})} className="btn btn-secondary w-100">
      Enter the results & actions taken
    </Button>:
    null
}

const mapStateToProps = ({ decision, current_user }) => ({ decision, user: current_user });
export default connect(mapStateToProps, {openModal})(RenderFollowupResults);

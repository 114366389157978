import React, {useMemo, useState} from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import OrgLogo from "../../tree_view/header/OrgLogo";
import { redirectToHomeCallback } from "../../helpers/decision_helpers";
import {isAdmin, isMember} from "../../helpers/home_helpers";
import ActionsDropdown from "./ActionsDropdown";
import HamburgerDropdown, { settingsCallback } from "./HamburgerDropdown";
import { openModal } from "../../store/modals/actions";
import Tabs from "./Tabs";
import ScenarioName from "./ScenarioName";
import BenchmarkScenario from "./BenchmarkScenario";
import SettingsButton from "./SettingsButton";
import { deleteWarning } from "../../helpers/alert_helpers";
import {
  loadForecastSimulatorDBenchmarkScenario,
  removeScenario
} from "../../store/forecast_simulator_scenario/actions";
import { resetForecastData } from "../../store/homepage/actions";

const Header = ({
                  activeTab, setActiveTab,
                  current_org, current_user,
                  forecast_simulator_scenario,
                  openModal, removeScenario, resetForecastData, loadForecastSimulatorDBenchmarkScenario,
                  scenarioIsLoaded
                }) => {
  const history = useHistory();
  const isAdminOrMember = useMemo(() => isAdmin(current_user) || isMember(current_user), [current_user]);
  const settingsBtnClick = settingsCallback(openModal)
  const processBackClick = () => redirectToHomeCallback(true, history);

  const deleteCallback = () =>
    deleteWarning(confirmed => {
      if (!confirmed) return;

      removeScenario(forecast_simulator_scenario.scenario_id, success => {
        if (success) resetForecastData()
        redirectToHomeCallback(success, history)
      })
    }, { custom_description: 'delete this scenario permanently' })

  return <header className="decision-tree-header forecast-simulator-header ">
    <nav className="navbar navbar-expand bd-navbar px-3">
      <div className="d-flex align-items-center active">
        <div className="logo-dropdown pointer d-none d-lg-flex me-2">
          <OrgLogo current_org={current_org} processBackClick={processBackClick} />
        </div>
      </div>
      <Tabs {...{ activeTab, setActiveTab }} />
      <ScenarioName {...{ forecast_simulator_scenario, scenarioIsLoaded }} />
      <BenchmarkScenario {...{ forecast_simulator_scenario, scenarioIsLoaded, loadForecastSimulatorDBenchmarkScenario }} />
      <div className="navbar-nav ms-auto" hidden={!isAdminOrMember}>
        <div className='d-lg-none'>
          <HamburgerDropdown {...{ processBackClick, deleteCallback }} />
        </div>
        <ul className="navbar-nav ms-auto d-none d-lg-flex align-items-center">
          <li className="nav-item ms-2">
            <SettingsButton onClick={settingsBtnClick} />
          </li>
          <li className="nav-item ms-2">
            <ActionsDropdown btnId="header-actions-dropdown" {...{ deleteCallback }} />
          </li>
        </ul>
      </div>
    </nav>
  </header>;
}

const mapStateToProps = ({ current_org, current_user, forecast_simulator_scenario }) => ({
  current_org, current_user, forecast_simulator_scenario
});
export default connect(mapStateToProps, { openModal, removeScenario, resetForecastData, loadForecastSimulatorDBenchmarkScenario })(Header);

import {useEffect} from "react";

export const useWhiteBackgroundEffect = () => {
  useEffect(() => {
    document.body.classList.add('bg-white');
    document.documentElement.classList.add('bg-white');
    return () => {
      document.body.classList.remove('bg-white');
      document.documentElement.classList.remove('bg-white');
    };
  }, []);
}
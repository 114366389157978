import React, {Fragment, useEffect, useRef, useState} from 'react';
import { connect } from "react-redux";
import {currencyFormat, isBlank, isPresent} from "../../helpers/common";
import Decision, { FOLLOWUP_EXPECTATIONS } from "../../models/decision";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { followupNow, updateFollowup } from "../../store/decision/actions";
import { ModalBodyWithClose, ModalDoneFooterP3 } from "../../common/modals";
import { RichTextEditor } from "../../common/RichTextEditor";
import CurrencyInput from "../../common/CurrencyInput";
import ShareData, { checkNotifyAllSharedWarning } from "../../models/share_data";
import {setRatingScaleCallback} from "./RateBuyInModal";

const InputCurrencyHeader = ({ headerText, expectedText = '', value }) =>
  <div>
    <h3 className="d-inline-block">
      {headerText}
    </h3>
    {isPresent(expectedText) && isPresent(value) ? <i className="text-muted">({expectedText}: {value})</i> : null}
  </div>

export const showFollowupResultsModal = (modal) => modal.type === "FollowupResultsModal" && modal.shown;

const FollowupResultsModal = ({
                                decision, share_data,
                                show, key = '',
                                onClose = () => null,
                                submitFollowup, submitEditFollowup
                              }) => {
  const shareData = new ShareData(share_data)
  const decisionObject = new Decision(decision)
  const followup = decisionObject.lastFollowup
  const editFollowup = decisionObject.isDecisionWithResults
  const [followup_expectation, setExpectations] = useState(followup.followup_expectation)
  const [followup_what_happened, setWhatHappened] = useState(followup.followup_what_happened || '')
  const [changeDecision, setChangeDecision] = useState(isPresent(decisionObject.followupChoice))
  const [changeDecisionText, setChangeDecisionText] = useState(decisionObject.followupChoice?.description)
  const [changeDecisionTextActive, setChangeDecisionTextActive] = useState(false)
  const [realizedValue, setRealizedValue] = useState(followup.realized_value);
  const [realizedInvestment, setRealizedInvestment] = useState(followup.realized_investment);
  const realizedInputsValues = realizedValue || realizedInvestment
  const [showRealizedInputs, setShowRealizedInputs] = useState(false)
  const [submit, setSubmit] = useState(false)

  const followupExpectationsBubble = (expectationData, index, array) => {
    const isFirst = index === 0
    const isLast = index === array.length - 1
    const inputRef = useRef(null)

    setRatingScaleCallback(inputRef, show, followup_expectation, setExpectations, true)

    return <Fragment key={`followup-expectation-${index}-fragment`}>
      <div className={`rating-circle ${expectationData.className} ${followup_expectation === expectationData.value ? 'active' : (isPresent(followup_expectation) ? 'non-active' : '')}`} key={`followup-expectation-${index}`}>
        <input type="radio" name="radio-group" ref={inputRef}
               id={`${expectationData.className}id`}
               className={`pointer bg-transparent ${isFirst ? 'first' : isLast ? 'last' : ''}`}
               defaultValue={expectationData.value} />
        <label className="pointer" htmlFor={`${expectationData.className}id`}>
          {index + 1}
        </label>
      </div>
    </Fragment>
  }

  const reset = (needCloseModal = true) => {
    setExpectations(followup.followup_expectation)
    setWhatHappened(followup.followup_what_happened || '')
    setChangeDecisionText(decisionObject.followupChoice?.description)
    setChangeDecision(isPresent(decisionObject.followupChoice))
    setRealizedValue(followup.realized_value)
    setRealizedInvestment(followup.realized_investment)
    if(needCloseModal) onClose()
  }

  const submitData = () => {
    setSubmit(true)
    const data = {
      decision_followup: {
        followup_expectation, followup_what_happened,
        realized_value: realizedValue, realized_investment: realizedInvestment
      },
      choice_description: changeDecision ? changeDecisionText : '',
      followup_participants_notify: true
    }
    const callback = (success) => {
      setSubmit(false)
      if (success) onClose();
    }

    const addResults = (hash = {}) => submitFollowup({ ...data, ...hash, create: isBlank(followup.slug) }, callback)
    if (shareData.isSharedWIthNonOrg && !editFollowup) {
      checkNotifyAllSharedWarning(true, shareData.shareList, addResults)
    } else {
      editFollowup ? submitEditFollowup(data, followup.slug, callback) : addResults()
    }
  }

  const doSetChangeDecision = () => {
    setChangeDecision(true);
    setChangeDecisionTextActive(true)
  }

  useEffect(() => {
    if (isBlank(realizedInputsValues)) {
      setShowRealizedInputs(false)
    } else {
      setShowRealizedInputs(true)
    }
  }, [show])

  useEffect(() => {
    reset(false)
  }, [followup.followup_what_happened, followup.followup_expectation, followup.realized_value,
    followup.realized_investment, decisionObject.followupChoice])

  return <Modal key={key} size="lg" backdrop="static" show={show} onHide={reset}>
    <ModalBodyWithClose bsPrefix="followup-modal pb-0" onClose={reset} header="Enter results and actions taken">
      <h3>How do the results compare to your expectations?</h3>
      <div className="mb-3">
        <div className="radio-pillbox w-100">
          <div className="radio-container d-flex justify-content-between">
            { FOLLOWUP_EXPECTATIONS.map(followupExpectationsBubble) }
          </div>
        </div>
      </div>
      <h3>Did the final decision change?</h3>
      <div className="row mb-3">
        <div className="d-flex">
          <Button className={`w-100 me-1 ${changeDecision ? 'btn-primary' : 'btn-secondary'}`} onClick={ doSetChangeDecision }>Yes</Button>
          <Button className={`w-100 ms-1 ${changeDecision ? 'btn-secondary' : 'btn-primary'}`} onClick={() => { setChangeDecision(false) }}>No</Button>
        </div>
      </div>
      <div className="mb-3" hidden={!changeDecision} ref={inputElement => {
        if (inputElement && changeDecisionTextActive) {
          inputElement.focus();
          setChangeDecisionTextActive(false)
        }
      }}>
        <h3>What was the final decision?</h3>
        <RichTextEditor quillId={'final decision'} value={changeDecisionText || ""} setValue={setChangeDecisionText} placeholder="Enter the final decision" />
      </div>
      <div className="mb-3">
        <h3>What happened and what did you learn?</h3>
        <RichTextEditor quillId={'learn'} value={followup_what_happened} setValue={setWhatHappened}
                        placeholder="Describe the execution of this decision." />
      </div>
      {
        !showRealizedInputs ?
          <Button onClick={() => {setShowRealizedInputs(true)}} className="btn btn-secondary w-100 mb-3">
            Enter realized value & cost
          </Button> :
          <Fragment>
            <div className={`mb-3`}>
              <CurrencyInput key={'currency-input-opportunity'}
                             header={
                               <InputCurrencyHeader headerText={'What is the realized value?'}
                                                    expectedText={'Expected value'}
                                                    value={currencyFormat(decision.expected_opportunity || decision.recommendation?.expected_opportunity)}
                               />}
                             value={realizedValue}
                             onChange={(value) => setRealizedValue(value || '')}
                             placeholder={'Enter a dollar amount'}
                             name={'expected-opportunity'}
                             id={'expected-opportunity'}/>
            </div>

            <div className={`mb-3`}>
              <CurrencyInput key={'currency-input-investment'}
                              header={
                                <InputCurrencyHeader headerText={'What is the realized investment or cost?'}
                                                     expectedText={'Expected cost'}
                                                     value={currencyFormat(decision.expected_investment || decision.recommendation?.expected_investment)}
                                />}
                              value={realizedInvestment}
                              onChange={(value) => setRealizedInvestment(value || '')}
                              placeholder={'Enter a dollar amount'}
                              name={'expected-investment'}
                              id={'expected-investment'} />
            </div>
          </Fragment>
      }
    </ModalBodyWithClose>
    <ModalDoneFooterP3 disabled={submit} onClose={submitData} />
  </Modal>
}
const mapStateToProps = ({ decision, share_data }) => ({ decision, share_data });
const mapDispatchToProps = (dispatch) => ({
  submitFollowup: (data, callback) => {
    dispatch(followupNow(data, callback))
  },
  submitEditFollowup: (data, slug, callback) => {
    dispatch(updateFollowup(data, slug, callback))
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(FollowupResultsModal);

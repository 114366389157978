import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import SlidingPanel from "react-sliding-side-panel";
import { setTreeSidebarOpen } from "../../store/sidebar/actions";
import CloseIcon from "../../common/CloseIcon";
import PanelTreeEditor from '../../tree_editor/tree_view/PanelTreeEditor'
import { isPresent } from "../../helpers/common";

export const TreePanel = ({ sidebar, openSideBar, isTemplate = false, isDSight = false }) => {
  const [treeMode, setTreeMode] = useState(sidebar.treeMode);

  useEffect(() => {
    if(isPresent(sidebar.treeMode)) {
      setTreeMode(sidebar.treeMode);
    }
  }, [sidebar.treeMode])
  const onClose = () => openSideBar(false);
  return (
    <SlidingPanel
    type="left"
    isOpen={sidebar.treeSidebar}
    panelContainerClassName="sidebar-panel-wrapper tree-panel-wrapper decision-tree-panel-width left-side-panel-position tree-page"
    panelClassName="sidebar-panel-wrapper-content tree-panel-content h-auto"
    size={50}>
      <div className="mt-2 me-3 float-end">
        <CloseIcon {...{ onClose }} />
      </div>
      <PanelTreeEditor {...{ isDSight, treeMode, isTemplate, onClose }} />
    </SlidingPanel>
  )
}

const mapStateToProps = ({ sidebar }) => ({ sidebar });
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => { dispatch(setTreeSidebarOpen(options)); }
});
export default connect(mapStateToProps, mapDispatchToProps)(TreePanel);

import React from 'react';
import { connect } from "react-redux";
import { setDecisionSidebarOpen } from "../../store/sidebar/actions";
import SlidingPanel from "react-sliding-side-panel";
import PanelHeader from "../../tree_view/side_panel/PanelHeader";
import { isMobile } from "react-device-detect";
import SetDescriptionSection from "../../template_set_view/side_panel/sections/SetDescriptionSection";
import { removeDecisionSet, updateDecisionSet } from "../../store/decision_set/actions";
import SetMoreActionsSection from "../../template_set_view/side_panel/sections/SetMoreActionsSection";
import InvitesSection from "./sections/InvitesSection";
import { TABS_URLS } from "../../helpers/home_helpers";
import { forceReloadHomepageDecisions } from "../../store/homepage/actions";
import SequenceSection from "./sections/SequenceSection";
import DataSourcesSection from "./sections/DataSourcesSection";
import { resetDecisionSetData } from "../../store/decision_set/common_actions";

const DecisionSetDetailsPanel = ({
                                   sidebar, decision_set,
                                   openSideBar, updateDecisionSet,
                                   removeDecisionSet, resetDecisionSet, forceReloadHomepageDecisions,
                                   ...opts
                                 }) =>
  <SlidingPanel
    type="left"
    isOpen={sidebar.decisionDetailsSidebar}
    panelContainerClassName={`sidebar-panel-wrapper decision-details-panel-wrapper details-panel-width template-panel left-side-panel-position tree-page ${isMobile ? 'pb-5 mb-5' : ''}`}
    panelClassName={`sidebar-panel-wrapper-content decision-details-panel-content`}
    size={52}
  >
    <PanelHeader headerName={'Details'} openSideBar={openSideBar} preview={true} />
    <SetDescriptionSection updateSet={updateDecisionSet} set={decision_set} set_key='decision_set' {...opts} classNames='mb-2' />
    <InvitesSection {...opts} />
    <DataSourcesSection addClass="p-3 border-bottom" />
    <SetMoreActionsSection set={decision_set} setTitle='decision' homeTab={TABS_URLS.create_decision} {...opts}
                           removeSet={removeDecisionSet} resetActions={[resetDecisionSet, forceReloadHomepageDecisions]} />
    <SequenceSection />
  </SlidingPanel>

const mapStateToProps = ({ sidebar, decision_set }) => ({ sidebar, decision_set });
const mapDispatchToProps = (dispatch) => ({
  forceReloadHomepageDecisions: () => dispatch(forceReloadHomepageDecisions()),
  openSideBar: (options) => { dispatch(setDecisionSidebarOpen(options)); },
  updateDecisionSet: (value, callback) => { dispatch(updateDecisionSet(value, callback)) },
  removeDecisionSet: (callback) => dispatch(removeDecisionSet(callback)),
  resetDecisionSet: () => dispatch(resetDecisionSetData())
});
export default connect(mapStateToProps, mapDispatchToProps)(DecisionSetDetailsPanel);

import { isBlank } from "./common";

export const isBlankRichTextValue = (value) => {
  if (isBlank(value)) return true

  return !!isBlank(value.toString().replace(/<br>|<\/br>|<p><br><\/p>|<p>\s+<\/p>/g, ""));
}

export const focusToEnd = (focusElement) => {
  const len = focusElement.current.getEditor().getLength()
  const selection = { index: len, length: len };
  focusElement.current.setEditorSelection(focusElement.current.editor, selection);
  focusElement.current.focus();
}

import React, { useEffect } from 'react';
import { connect } from "react-redux";
import SlidingPanel from "react-sliding-side-panel";
import { setSetSummarySidebarOpen } from "../../store/sidebar/actions";
import {isMobile} from "react-device-detect";
import PanelHeader from "../../tree_view/side_panel/PanelHeader";
import SummaryFormSection from "./sections/SummaryFormSection";
import { isStoreLoading } from "../../helpers/callbacks_helpers";
import { Loader } from "../../common/Loader";
import { saveSideBarData } from "../../store/sidebar/common_actions";

const DecisionSetSectionsPanel = ({ addClass, sidebar, decision_set, openSideBar, saveSideBarData }) => {
  useEffect(() => {
    return () => {
      openSideBar(false);
    }
  }, [])
  return <SlidingPanel
    type="right"
    isOpen={sidebar.setSummarySidebar}
    panelContainerClassName={`sidebar-panel-wrapper set-sections-panel-wrapper details-panel-width template-panel ${addClass} ${isMobile ? 'pb-5 mb-5' : ''}`}
    panelClassName={`right-sidebar-panel-wrapper-content set-sections-panel-content ${isMobile ? '' : 'd-flex flex-column'}`}
    size={50}
    onOpened={() => saveSideBarData({ isOpened: true })}
    onClosed={() => saveSideBarData({ isOpened: false })}
  >
    <PanelHeader headerName={'Decision flow summary'} openSideBar={openSideBar} preview={true} />
    { isStoreLoading(decision_set) ? <Loader/> : <SummaryFormSection/> }
  </SlidingPanel>
}

const mapStateToProps = ({ sidebar, decision_set }) => ({ sidebar, decision_set });
export default connect(mapStateToProps, { openSideBar: setSetSummarySidebarOpen, saveSideBarData })(DecisionSetSectionsPanel);

import React from 'react'

const {MS_TEAMS_APP_ID} = process.env;

const MsTeamsFragment = () =>
  <>
    Please<a href={`https://teams.microsoft.com/l/app/${MS_TEAMS_APP_ID}`} title="Cloverpop app" className={'px-1'}>add the Cloverpop app</a>
    to your organization to receive Teams notifications. If the app has already been added, please remove and reinstall the app.
  </>

export default ({type}) =>
  type === 'ms_teams' && <MsTeamsFragment />

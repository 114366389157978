import React from "react";
import AssignedUser from "../shared/AssignedUser";
import Status from "./Status";

export default ({ driverData, assignedUser }) => {
  return <>
    <AssignedUser bubbleIcon={`driver-assignment-${driverData.driver.slug}`} assignedUser={assignedUser} />
    <Status driverData={driverData} />
  </>
}

import {ContinuousPage} from "./tree_view/ContinuousPage";
import {DriversPage} from "./tree_view/DriversPage";
import {convertInchesToTwip, convertMillimetersToTwip, NumberFormat, PageOrientation} from "docx";
import Decision, {decisionDataSources, decisionSelectedCategories} from "../models/decision";
import {isBlank, isPresent} from "../helpers/common";
import {driversToArray} from "../helpers/drivers_helpers";
import {KeyDriverPage} from "./tree_view/KeyDriverPage";
import DataSource, {DecisionOrDSightDataSourcesTypes} from "../models/data_source";
import {KeyPerDriverPage} from "./tree_view/KeyPerDriverPage";
import {DecisionDetailsPage} from "./tree_view/DecisionDetailsPage";
import {ImagesSupplement} from "./tree_view/ImagesSupplement";
import {CoverPage} from "./tree_view/CoverPage";

export const pageSize = (format) => {
  return {
    orientation: PageOrientation.PORTRAIT,
      height: convertMillimetersToTwip(format === 'letter' ? 279.4 : 297),
      width: convertMillimetersToTwip(format === 'letter' ? 215.9 : 210)
  }
}

export const pageProperties = ({format, isContinuousSection = false}) => {
  return {
    properties: {
      titlePage: true,
      page: {
        size: pageSize(format),
        margin: {
          bottom: convertInchesToTwip(56/72),
          top: convertInchesToTwip(27/72),
          right: convertInchesToTwip(36/72),
          left: convertInchesToTwip(36/72),
          header: convertInchesToTwip(27/72),
          footer: convertInchesToTwip(18/72),
        },
        pageNumbers: {
          formatType: NumberFormat.DECIMAL,
          ...(isContinuousSection ? {start: 1} : {}),
        },
      },
    }
  }
}

export const RootDocxDocument = (format, decision, driversData, filteredDrivers, dataSources, extraImages, org_driver_types, org_categories, decisionObj) => {
  const sortedDriverDataSources = decisionDataSources(dataSources, filteredDrivers, DecisionOrDSightDataSourcesTypes)
  const imagesDataSources = sortedDriverDataSources.filter(source => new DataSource(source).isPdfImage)
  const hideDecisionDetailsPage = (isBlank(decision.collaboration_context) && isBlank(org_categories) && isBlank(decisionObj.collaborators) && isBlank(sortedDriverDataSources))

  const KeyDriversPages = []
  decisionObj.isRateAndCompareDriverChoicesHidden ?
    driversData.forEach((driverData, index) => KeyDriversPages.push(KeyDriverPage(format, decision, sortedDriverDataSources, driverData, extraImages, org_driver_types, index))) :
    driversData.forEach(driverData => KeyDriversPages.push(KeyPerDriverPage(format, decision, sortedDriverDataSources, driverData, extraImages, org_driver_types, decisionObj)))

  const rootDocument = [
    CoverPage(decision, format, extraImages),
    ContinuousPage(format, decision, filteredDrivers, dataSources, extraImages),
  ];

  if (isPresent(filteredDrivers)) {
    rootDocument.push(DriversPage(format, decision, filteredDrivers, extraImages));
  }
  rootDocument.push(
    ...KeyDriversPages
  );
  if (!hideDecisionDetailsPage) {
    rootDocument.push(DecisionDetailsPage(format, decision, sortedDriverDataSources, org_categories, extraImages));
  }
  rootDocument.push(...ImagesSupplement(format, imagesDataSources, extraImages));

  return rootDocument;
};
import React, { useState } from 'react';
import { connect } from "react-redux";
import { updateRecommendation } from "../../../store/sidebar/actions";
import {isBlank, isPresent} from "../../../helpers/common";
import { RichTextEditor } from "../../../common/RichTextEditor";
import AccordionWrapper from "../../../common/AccordionWrapper";

const ExpectedResultsInput = ({ decision, updateRecommendation }) => {
  if (isBlank(decision) || isBlank(decision.recommendation)) return null;

  const recommendation = decision.recommendation;
  if (isBlank(recommendation)) return null;

  const [expectedResults, setExpectedResults] = useState(recommendation.expected_results || '');

  const onChangeExpectedResults = (_e) => { updateRecommendation({ decision_recommendation: { expected_results: expectedResults, slug: recommendation.slug } }); };

  const accordionObject = {
    header: <h3 className="mb-0">Expected results</h3>,
    body: <RichTextEditor quillId={'Expected Results'} value={expectedResults} setValue={setExpectedResults}
                          placeholder="Describe what is expected to happen"  />,
    defaultExpand: isPresent(recommendation.expected_results)
  };

  return <div onBlur={onChangeExpectedResults}>
    <AccordionWrapper accordionObject={accordionObject} className='px-3 mb-3' />
  </div>
}
const mapStateToProps = (state) => ({ decision: state.sidebar.decision });
export default connect(mapStateToProps, { updateRecommendation })(ExpectedResultsInput);

import React, { Fragment } from 'react';
import { connect } from "react-redux";
import {
  isTreeBuilder,
  isTreeCollaborators,
  isTreeDecisionCategorization,
  isTreeFraming, isTreePreview
} from "../../helpers/wizard_helpers";
import HelpButtons from "../../common/HelpButtons";

export const Index = ({wizard, template}) => {
  return <Fragment>
    <div hidden={!isTreeDecisionCategorization(wizard) || isTreePreview(wizard)}>
      <div className="px-3 mb-3">
        <div>Categories are used to find, analyze, and report on decisions. Make selections that best fit this decision.</div>
      </div>
    </div>
    <div hidden={!isTreeFraming(wizard) || isTreePreview(wizard)}>
      <div className="px-3 mb-3">
        <h3>Decision</h3>
        <p>
          The decision title frames the problem, possible choices, and what will be decided.
        </p>
        <p>
          It is best to phrase the title as a question. Try starting the question with "How should we..." or "What should we do about..." to keep a wider view of the decision.
        </p>
      </div>
      <div className="px-3 mb-3">
        <h3>Response type</h3>
        <p>
          Choose how you want to answer this decision.
        </p>
        <p>
          “Open-ended” provides a blank field.
        </p>
        <p>
          “Pick top choice” selects a single choice.
        </p>
        <p>
          “Pick all that apply” allows selecting multiple choices.
        </p>
        <p>
          “Rank a list” selects and orders choices.
        </p>
        <p>
          Select the “Rate and compare choices per driver” checkbox to add decision choices to drivers for comparison and assessment. Drivers can be weighted to provide an overall choice ranking based on the impact of each driver.
        </p>
      </div>
    </div>
    <div hidden={!isTreeCollaborators(wizard) || isTreePreview(wizard)}>
      <div className="px-3 mb-3">
        <p>Add people or groups who can start decisions from this template. Anyone added as a template user will see the template on the <strong>Create a decision</strong> page. </p>
        <p>The template will not be shown to users or group members who are not template users. </p>
      </div>
    </div>
    <div hidden={!isTreeBuilder(wizard) || isTreePreview(wizard)}>
      <div className="px-3 mb-3">
        <p>
          The decision tree allows you to build support for your decision through data and insights. Add a tree of questions, or drivers, that support the overall decision and address the important details that impact it.
        </p>
        <p>
          Hover or click on a driver to show buttons to add, edit, or delete.
        </p>
        <p>
          Click on the <strong>add</strong> button to add a supporting driver.
        </p>
        <p>
          Click on the <strong>edit</strong> button to update a driver.
        </p>
        <p>
          Click on the <strong>delete</strong> button to remove a driver. If a driver is deleted, all sub-questions will also be removed.
        </p>
        <p>
          To use the <strong>Copy</strong> and <strong>Paste</strong> buttons, click on the driver you want to copy and and select the driver you want to paste to. This will duplicate the entire copied branch.
        </p>
      </div>
    </div>
    <div>
      <div className="px-3 mb-3" hidden={!isTreePreview(wizard)}>
        <p hidden={!template.rate_compare_choices}>
          Use the Ratings and weights panel to assign weight to drivers.
        </p>
        <p>
          Use the Details panel to edit the context, due date, categories, collaborators, or add data sources.
        </p>
      </div>
    </div>
    <HelpButtons />
  </Fragment>;
}
const mapStateToProps = ({ wizard, template }) => ({
  wizard, template
});
export default connect(mapStateToProps)(Index);

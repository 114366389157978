import React from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import TemplateCard from './cards/TemplateCard';
import DSightTemplatePreviewDriverCard from './cards/DSightTemplatePreviewDriverCard';
import { openModal } from "../../store/modals/actions";
import { openDSightDecisionModal } from "../../dsight/template_view/modals/DSightDecisionModal";
import { treeInitData } from "./shared/helpers";

const PoweredByDsightIcon = ({ template }) =>
  <Link to={`/dsight/templates/${template.slug}/analysis`} className='dsight-powered-logo--link' target={`_blank`} />

const DSightTemplatePreviewTree = ({ tree, template, user, contactsData, openModal }) => {
  const { rootDrivers } = treeInitData(tree, {})

  const onTemplateClick = () => openDSightDecisionModal({ openModal, template })

  return <div className="container-fluid p-0">
    <div className={`my-2 mx-auto vertical-tree`}>
      <TemplateCard {... { template, onTemplateClick } } />
      {
        rootDrivers.map(driverData =>
          <DSightTemplatePreviewDriverCard key={`template-driver-card-${driverData.driver.slug}`}
                                    {...{ driverData, tree, contactsData, user, template, openModal } } />
        )
      }
    </div>
    <PoweredByDsightIcon template={template} />
  </div>
}
const mapStateToProps = ({ tree, template, current_user, contacts }) => ({
  tree, template,
  user: current_user,
  contactsData: contacts
});
export default connect(mapStateToProps, { openModal })(DSightTemplatePreviewTree);

import React, {Fragment} from 'react';
import { Page, View, Text } from '@react-pdf/renderer';
import styles from '../../styles/common'
import DocumentFooter from "./DocumentFooter";
import DocumentHeader from "./DocumentHeader";
import {isBlank, isPresent} from "../../../helpers/common";
import {
  DriverTypeBlock,
  ConfidenceBlock,
  DetailsBlock,
  DriverComments,
  DriverDataSources,
  NotAnswerBlock
} from "./KeyDriverPage";
import Driver from "../../../models/driver";
import {Html} from "react-pdf-html";
import {modifyRichText, quillIndentStyles} from "../../helpers/rich_text";
import {calculateRatingScore, RATING_SCALES_DATA} from "../../../helpers/drivers_helpers";
import { MAX_SCALE, MIN_SCALE } from "../../../models/DriverChoiceRating";

const DriverWeight = ({ driver, driverObj }) => {
  if (isBlank(driver.question)) return null;

  return <View style={[styles.h2, styles.row, {minWidth: '100%'}]} id={driver.slug}>
    <Text style={{maxWidth: '90%'}}> {driver.question} </Text>
    {!driver.parent_id && <Text style={[{marginLeft: 'auto'}, {marginRight: 0}]}>{`${driverObj.weightPercentage}%`}</Text>}
  </View>
}

const DriverRatingChoices = ({ sortedDriverChoicesRatings }) => {
  if (isBlank(sortedDriverChoicesRatings)) return null;

  return <View>
    {
      sortedDriverChoicesRatings.map((driverChoiceRating, index) =>
        <DriverRatingChoice {...{ ...driverChoiceRating}} index={index} key={`choice-row-${driverChoiceRating.choice_slug}`} />
      )
    }
  </View>
}

const CustomRating = ({rating, startScale, endScale, isYesOrNo}) => {
  const isHundredScale = startScale === MIN_SCALE && endScale === MAX_SCALE && !isYesOrNo

  return <Fragment>
    {
      isYesOrNo ?
        rating === MAX_SCALE ?
          'Yes' : 'No':
        rating
    }
    {
      !isHundredScale && <Text style={styles.muted}>&nbsp;
        ({calculateRatingScore(rating, startScale, endScale)})
      </Text>
    }
  </Fragment>
}

const DriverRatingChoice = ({ choice, evaluation, rating, driver, index = 0}) => {
  if(driver.driver.parent_id && isBlank(evaluation)) return null

  const startScale = RATING_SCALES_DATA[driver.driver?.rating_scale]?.start_scale || MIN_SCALE
  const endScale = RATING_SCALES_DATA[driver.driver?.rating_scale]?.end_scale || MAX_SCALE
  const isYesOrNo = RATING_SCALES_DATA[driver.driver?.rating_scale]?.yes_or_no;

  return <View style={[styles.mb3]}>
    <View style={[index === 0 ? styles.mb0 : styles.mb2, styles.h3, styles.row, {maxWidth: '100%'}]}>
      <Text style={{maxWidth: '90%'}}> {choice.description} </Text>
      {
        !driver.driver.parent_id &&
        <Text style={[{marginLeft: 'auto'}, {marginRight: 0}]}>
          {
            isPresent(rating) ?
              isPresent(driver.driver.rating_scale) ?
                <CustomRating rating={rating} startScale={startScale} endScale={endScale} isYesOrNo={isYesOrNo} />:
                rating
              : "—"
          }
        </Text>
      }
    </View>
    <Evaluation evaluation={evaluation} />
  </View>
}

const Evaluation = ({evaluation}) => {
  if (isBlank(evaluation)) return null

  return <View  style={[styles.mt1, styles.text, {maxWidth: '95%'}]}>
    <Html stylesheet={quillIndentStyles()} style={[styles.text]}>{modifyRichText(evaluation)}</Html>
  </View>
}

const PerDriverSubPage = ({decision, driver, decisionObj, drivers, driver_sources_slugs, data_sources, org_driver_types }) => {
  if (isBlank(driver.question)) return null;

  const driverObj = new Driver(driver, [], {}, decisionObj);
  const sortedDriverChoicesRatings = driverObj.sortedDriverChoicesRatings || []
  const isNotAnsweredRating = sortedDriverChoicesRatings.some(d => d.driver.driver.parent_id && isBlank(d.evaluation))
  const isAnswered = driverObj.withEnteredChoicesRating && decision.rate_compare_choices && isBlank(driver.parent_id)

  return <View>
    <DriverWeight {...{ driver, driverObj }} />
    { !isAnswered && isNotAnsweredRating ? <NotAnswerBlock driver={driver} /> : <></>}
    <DriverRatingChoices {...{ sortedDriverChoicesRatings }} />
    <DetailsBlock {...{ driver }} />
    <ConfidenceBlock {...{ driver }} />
    <DriverTypeBlock {...{ org_driver_types, decision, driver }} />
    <DriverComments {...{ driver, decision }} />
    <DriverDataSources {...{ driver, driver_sources_slugs, data_sources }} />
  </View>
}

const KeyPerDriverPage = ({driver, decisionObj, org, decision, drivers, children = [], format,
                            driver_sources_slugs = [], data_sources= [], org_driver_types= []}) => {
  if (isBlank(driver) || isBlank(driver.question)) return null;

  return <Page size={format} style={styles.container}>
    <DocumentHeader description={driver.question} title={'Drivers'} src_id={'#drivers_page'} />
    <View style={styles.body}>
      <PerDriverSubPage {...{ org_driver_types, decision, decisionObj, driver, data_sources, driver_sources_slugs, drivers, children }} />
    </View>
    <DocumentFooter org={org} />
  </Page>
}
export default KeyPerDriverPage;

import React from 'react';
import { connect } from "react-redux";
import DropdownButton from "react-bootstrap/DropdownButton";
import BaseHamburgerBtn from "../../common/BaseHamburgerBtn";
import { openModalCallback } from "../../template_set_view/modals/SetNameModal";
import { openModal } from "../../store/modals/actions";
import { DeleteSetItem } from "../../template_set_view/header/HamburgerDropdown";
import {
  setSetDecisionsSidebarOpen,
  setSetSectionsSidebarOpen,
  setAssignDecisionsSidebarOpen,
  setSetSummarySidebarOpen
} from "../../store/sidebar/actions";
import { openTemplateSetUsersEntry } from "../../template_set_view/modals/TemplateSetUsersModal";
import { isSummaryPending } from "../../helpers/set_helpers";

const SetDropdown = ({
                      set,
                      dropdownClassName,
                      btnClassName,
                      btnId, title,
                      homeTab, setTitle, isManager = true, isSetUser = false,
                      resetSet, removeSet,
                      resetSidebars, openModal, reloadRecords, isTemplateSet = false,
                      setSetSectionsSidebarOpen, setSetDecisionsSidebarOpen,
                       setAssignDecisionsSidebarOpen, setSetSummarySidebarOpen
                    }) =>
  <DropdownButton id={btnId} title={title} className={dropdownClassName} bsPrefix={btnClassName}>
    <BaseHamburgerBtn onSelect={setSetDecisionsSidebarOpen} title={`Add or remove ${setTitle}s`} />
    <BaseHamburgerBtn hidden={!isManager} onSelect={setAssignDecisionsSidebarOpen} title={`${isTemplateSet ? 'Assign templates' : 'Assign unstarted decisions'}`} />
    <BaseHamburgerBtn hidden={!isTemplateSet} onSelect={openTemplateSetUsersEntry(openModal)} title="Edit decision flow users" />
    <BaseHamburgerBtn hidden={!isTemplateSet && !isManager} onSelect={openModalCallback(openModal, set)} title={`Edit decision flow name`}/>
    <BaseHamburgerBtn onSelect={setSetSectionsSidebarOpen} title="Add or remove sections" />
    {
      !isTemplateSet &&
      (isManager || isSetUser) &&
      isSummaryPending(set) &&
      <BaseHamburgerBtn onSelect={setSetSummarySidebarOpen} title={`Create a flow summary`} />
    }
    <DeleteSetItem {...{ set, setTitle, homeTab, reloadRecords, resetSidebars, resetSet, removeSet, hidden: (!isTemplateSet && !isManager) }} />
  </DropdownButton>

const mapDispatchToProps = (dispatch) => ({
  openModal: (data) => dispatch(openModal(data)),
  setSetSummarySidebarOpen: () => dispatch(setSetSummarySidebarOpen(true)),
  setSetSectionsSidebarOpen: () => dispatch(setSetSectionsSidebarOpen(true)),
  setSetDecisionsSidebarOpen: () => dispatch(setSetDecisionsSidebarOpen(true)),
  setAssignDecisionsSidebarOpen: () => dispatch(setAssignDecisionsSidebarOpen(true))
});
export default connect(null, mapDispatchToProps)(SetDropdown);

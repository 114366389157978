import React, {useEffect, useRef, useState} from 'react';
import { connect } from "react-redux";
import { isBlank } from "../../../../helpers/common";
import { RichTextEditor } from "../../../../common/RichTextEditor";

const RationaleInput = ({ decision, reasons, setReasons }) => {
  if (isBlank(decision)) return null;

  const focusElement = useRef(null);
  const focus = () => {
    setTimeout(() => {
      focusElement.current.focus();
    }, 1000);
  }

  return <div className="mb-3">
    <h3>Rationale</h3>
    <RichTextEditor quillId={'Rationale'} value={reasons} setValue={setReasons}
                    placeholder="Enter a detailed explanation"
                    onFocus={focus}
                    ref={focusElement}
    />
  </div>
}
const mapStateToProps = (state) => ({ decision: state.sidebar.decision });
export default connect(mapStateToProps)(RationaleInput);

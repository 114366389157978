import { LOAD_SCENARIO_FAILED, LOAD_SCENARIO_STARTED, UPDATE_SCENARIO_DATA } from "./types";

const initialState = {
  scenario_id: null,
  benchmark_id: null,
  loaded: false,
  loading: false,
  config: {},
  scenario: {},
  config_scenarios: [],
  benchmark_scenario: {},
  error: ''
};

export function forecastSimulatorScenarioReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case UPDATE_SCENARIO_DATA: {
      return {
        ...state,
        ...action.payload
      };
    }
    case LOAD_SCENARIO_STARTED: {
      return {
        ...state,
        ...action.payload,
        error: '',
        loading: true
      }
    }
    case LOAD_SCENARIO_FAILED: {
      return {
        ...state,
        ...action.payload,
        loading: false
      }
    }
    default:
      return state;
  }
}

import React, {Fragment, useEffect} from 'react';
import SetHeader from "../decision_set_search_view/header";
import { TABS_URLS } from "../helpers/home_helpers";
import SidebarTreeNavigationTemplateSet from "../template_set_view/navigation";
import AlertsSection from "../alerts";
import DecisionSetDecisionsPanel from "../decision_set_view/side_panel/DecisionSetDecisionsPanel";
import DecisionSetDetailsPanel from "../decision_set_view/side_panel/DecisionSetDetailsPanel";
import AssignDecisionsPanel from "../decision_set_view/side_panel/AssignDecisionsPanel";
import SetNameModal, {showModal} from "../template_set_view/modals/SetNameModal";
import {useLocation} from "react-router-dom";
import queryString from "query-string";
import DecisionSetPanel from "../tree_view/side_panel/DecisionSetPanel";
import DecisionSetSectionsPanel from "../decision_set_view/side_panel/DecisionSetSectionsPanel";
import HelpPanel from "../decision_set_view/side_panel/HelpPanel";
import StartUnstartedDecisionModal, {
  showStartUnstartedDecisionModal
} from "../decision_set_view/modals/StartUnstartedDecisionModal";
import LinkPanel from "../tree_view/side_panel/link_panel/LinkPanel";
import DecisionSetSummaryPanel from "../decision_set_view/side_panel/DecisionSetSummaryPanel";
import SummaryDetailsModal, { showSummaryDetailsModal } from "../decision_set_view/modals/SummaryDetailsModal";

const Sidebars = (opts) => {
  const { search } = useLocation();

  useEffect(() => {
    const { left_side_bar_open } = queryString.parse(search)
    opts.setSetDecisionsSidebarOpen(left_side_bar_open === 'set_decisions');
  }, []);

  return (
    <div className="sidebars">
      <DecisionSetDetailsPanel {...opts} />
      <DecisionSetPanel addClass="left-side-panel-position tree-page" showMenu={true} {...opts} openNewTab={true} />
      <DecisionSetSectionsPanel addClass="left-side-panel-position tree-page"/>
      <DecisionSetDecisionsPanel addClass="left-side-panel-position tree-page"/>
      <AssignDecisionsPanel addClass="left-side-panel-position tree-page" />
      <DecisionSetSummaryPanel addClass="right-side-panel-position tree-page" />
      <div className="help-side-panels">
        <HelpPanel isDecisionSet={true}/>
      </div>
      <LinkPanel key="link-side-panel" />
    </div>
  )
}

export default ({
                  modal, decision_set, children,
                  closeModal,
                  updateDecisionSet, removeDecisionSet, resetDecisionSet,
                  forceReloadHomepageDecisions, addDecision,
                  ...opts
                }) => {
  const setOptions = {
    homeTab: TABS_URLS.create_decision,
    reloadRecords: forceReloadHomepageDecisions,
    removeSet: removeDecisionSet,
    resetSet: resetDecisionSet,
    addDecision: addDecision,
    set: decision_set
  }

  return <Fragment>
    <AlertsSection/>
    <SetHeader {...{ set: decision_set, setTitle: 'decision', ...setOptions, ...opts }} />
    <div className="d-flex">
      <SidebarTreeNavigationTemplateSet isDecisionSet={true} addDecision={addDecision} />
      {children}
    </div>
    <Sidebars {...setOptions} {...opts} />
    <div className="modals">
      <SetNameModal shown={showModal(modal, decision_set)} set={decision_set} set_key='decision_set' setTitle='decision'
                    onClose={closeModal} updateSet={updateDecisionSet} />
      <StartUnstartedDecisionModal shown={showStartUnstartedDecisionModal(modal)} onClose={closeModal} />
      <SummaryDetailsModal shown={showSummaryDetailsModal(modal)} onClose={closeModal} />
    </div>
  </Fragment>
}

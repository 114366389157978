import React from "react";
import Wrapper from "../Wrapper";
import Status from "../template/Status";
import Description from "../template/Description";
import AssignedUser from "../shared/AssignedUser";
import {isBlank} from "../../../helpers/common";
import {Template} from "../../../models/template";

export const assignedTemplateUser = (templateObj, assigned_collaborator_email) => {
  if (isBlank(templateObj.collaborator_admissions)) return {};

  return templateObj.collaborator_admissions.find(admission => admission.user_email === assigned_collaborator_email)?.user
}

export default ({ template, onTemplateClick, clickable = true }) => {
  const templateObj = new Template(template)
  const assignedUser = assignedTemplateUser(templateObj, template.assigned_collaborator_email);

  return <Wrapper>
    <div className="d-flex" style={{ height: "36px" }}>
      <div className="my-auto lh-sm w-100 me-1">
        <Description {...{ description: template.description, onClick: onTemplateClick, clickable } } />
      </div>
      <AssignedUser bubbleIcon={`template-assignment-${template.slug}`} assignedUser={assignedUser} />
      <Status template={template} />
    </div>
  </Wrapper>
}

import { UPDATE_ORG_DATA, UPDATE_ORG_FAILED } from "./types";

export const updateOrgData = (data) => ({
  type: UPDATE_ORG_DATA,
  payload: {
    ...data
  }
});
export const updateOrgFailure = error => ({
  type: UPDATE_ORG_FAILED,
  payload: {
    error
  }
});

import React, { useCallback, useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { isBlank } from "../../helpers/common";
import {
  isFirstWizardStep, isHistoricalDecision, isTreeBuilder, isTreePreview, isWizardSingleStep, isWizardV3,
  WIZARD_STEPS,
  wizardStepKey
} from "../../helpers/wizard_helpers";
import ActionsDropdown, { startDecisionCallback } from "./ActionsDropdown";
import HamburgerDropdown from "./HamburgerDropdown";
import Button from 'react-bootstrap/Button'
import {
  buildWizardTree,
  previewWizardTree,
  prevTemplateWizardStep,
  prevWizardStep,
  resetWizard
} from "../../store/wizard/actions";
import {
  forceReloadHomepageDecisions,
  forceReloadHomepageTemplates,
  resetSearchAndFilters
} from "../../store/homepage/actions";
import { resetSidebars, setDecisionSidebarOpen } from "../../store/sidebar/actions";
import { resetTree, resetTreeDrivers, updateTreeData } from "../../store/tree/common_actions";
import { resetTemplateSetData } from "../../store/template_set/common_actions";
import { getWindowDimensions, LARGE_SCREEN_WIDTH } from "../../store";
import { resetPlaybookNotes } from "../../store/playbook_notes/actions";
import PlaybookHeaderButton from "../../template_view/header/PlaybookHeaderButton";
import { loadContacts } from "../../store/contacts/actions";
import { updateDecisionData } from "../../store/decision/common_actions";
import { checkLoadingEffect } from "../../helpers/callbacks_helpers";
import {
  cancelPreviewTreeCallback,
  goToHomepageCallback,
  goToSetsCallback, hideNextStepBtn, hidePreviewBtn, hideStartDecisionBtn, nextStepTreeAction,
  previewTreeCallback,
  prevStepCallback,
  showPlaybookButton, wizardSetCallbacks
} from "./helpers";
import { resetDecisionSetData } from "../../store/decision_set/common_actions";
import DoneHeaderButton from "../../template_view/header/DoneHeaderButton";
import OrgLogo from "../../tree_view/header/OrgLogo";

export const WizardStepHeaderTitle = ({ wizard, title }) =>
  <>
    Step {wizard.step_index + 1} of {wizard.flow_steps.length}: <span className="text-primary">{title}</span>
  </>

const StepTitle = ({ wizard, isTemplate }) => {
  if (isBlank(wizard.flow_steps)) return null;

  if (isTreePreview(wizard)) return <h5>Decision preview</h5>

  const stepHash = WIZARD_STEPS[wizardStepKey(wizard)]
  const title = (
    (isTemplate && stepHash?.template_title) ||
    (isWizardV3(wizard) && isWizardSingleStep(wizard) && stepHash?.v3_title) ||
    stepHash?.title
  )

  return <h5 className="decision-description">
    <span>
      {
        wizard.flow_steps.length < 2 ? title : <WizardStepHeaderTitle {...{ wizard, title }} />
      }
    </span>
  </h5>
}

const wizardProgress = (wizard) => {
  if (isBlank(wizard.flow_steps)) return 0;

  if (wizard.step_index + 1 === wizard.flow_steps.length) return 95;
  return ((wizard.step_index + 1) / wizard.flow_steps.length).toFixed(2) * 100
}

const HeaderLogo = ({ processBackClick, current_org }) =>
  <div className="logo-dropdown pointer d-none d-lg-flex me-2">
    <OrgLogo current_org={current_org} processBackClick={processBackClick}/>
  </div>

const BackIcon = ({ processBackClick }) =>
  <span className="btn btn-secondary btn-lg-round d-none d-lg-flex justify-content-around me-2" onClick={processBackClick}>
    <i className="fas fa-lg fa-arrow-left"/>
  </span>

const HeaderIcon = ({ wizardLoaded, wizard, isSetObject, ...opts }) => {
  if (!wizardLoaded) return null;

  const displayLogo = isFirstWizardStep(wizard) && !isSetObject() && !isTreePreview(wizard)
  return (displayLogo && <HeaderLogo {...opts} />) || <BackIcon {...opts} />
}

const WizardHeader = ({
                        wizard, tree, decision, template, contactsData, current_org,
                        previewWizardTree, buildWizardTree, prevWizardStep, prevTemplateWizardStep, resetPlaybookNotes,
                        stepRef, isTemplate = false, resetSearchAndFilters,
                        forceReloadHomepageTemplates, forceReloadHomepageDecisions,
                        resetSidebars, resetWizard, resetTree, resetTreeDrivers,
                        openDecisionDetailsSideBar, loadContacts, updateDecisionData, updateTreeData,
                        ...opts
                      }) => {
  checkLoadingEffect(contactsData, loadContacts);
  const [wizardLoaded, setWizardLoaded] = useState(false);

  useEffect(() => {
    setWizardLoaded(wizard.loaded)
  }, [wizard]);

  useEffect(() => {
    if (!isTemplate && contactsData.loaded && !contactsData.loading && !contactsData.invite_yourself) {
      setTimeout(loadContacts, 50)
    }
  }, [isTemplate, contactsData.loaded, contactsData.invite_yourself])

  const history = useHistory();
  const previewTree = useCallback(
    previewTreeCallback({
      wizard, tree, decision, contactsData, isTemplate,
      updateTreeData, updateDecisionData, previewWizardTree,
      stepRef
    }), [wizard])
  const buildTree = useCallback(
    cancelPreviewTreeCallback({
      wizard, tree, decision, stepRef,
      updateTreeData, resetSidebars, buildWizardTree, resetTreeDrivers,
      contactsData, isTemplate,
      updateDecisionData
    }), [wizard])
  const {
    isSetObject, resetSetCallback, setObjectSlug, objectSlug
  } = wizardSetCallbacks({ ...opts, isTemplate, decision, template })
  const processBackClick = useCallback(() => {
    if (wizard.submit) return;

    if (isTreePreview(wizard)) {
      buildTree()
    } else if (isFirstWizardStep(wizard) && isSetObject()) {
      goToSetsCallback([isTemplate ? forceReloadHomepageTemplates : forceReloadHomepageDecisions, resetSearchAndFilters, resetSetCallback, resetSidebars, resetWizard, resetPlaybookNotes],
        history,
        setObjectSlug(),
        isTemplate
      )()
    } else if (isFirstWizardStep(wizard)) {
      goToHomepageCallback([isTemplate ? forceReloadHomepageTemplates : forceReloadHomepageDecisions, resetSearchAndFilters, resetSidebars, resetTree, resetWizard, resetPlaybookNotes, resetSetCallback], history)()
    } else {
      prevStepCallback(isTemplate, resetSidebars, prevTemplateWizardStep, prevWizardStep)()
    }
  }, [wizard])

  const processNextClick = useCallback(() => {
    if (wizard.submit ||wizard.disabledSubmit) return;

    nextStepTreeAction({ wizard, stepRef })
  }, [wizard])
  const completeWizardHandler = startDecisionCallback( {
    stepRef, history, isTemplate, setSlug: setObjectSlug(), wizard, slug: objectSlug(),
    actions: [
      isTemplate ? forceReloadHomepageTemplates : forceReloadHomepageDecisions,
      resetSearchAndFilters, resetSidebars, resetWizard, resetTree, resetPlaybookNotes,
      resetSetCallback, () => updateTreeData({ loaded: false })
    ]
  })

  useEffect(() => {
    const { width } = getWindowDimensions();
    if (isTemplate && isTreeBuilder(wizard) && isTreePreview(wizard) && width > LARGE_SCREEN_WIDTH) {
      openDecisionDetailsSideBar(true);
    }
  }, [isTreePreview(wizard)])

  if (stepRef.current) stepRef.current.previewTree = previewTree;

  return <header className="decision-tree-header">
    <nav className="navbar navbar-expand bd-navbar px-3">
      <div className="d-flex align-items-center active">
        <HeaderIcon {...{ wizardLoaded, wizard, isSetObject, current_org, processBackClick }} />
        <StepTitle {...{ wizard, isTemplate }} />
      </div>
      <div className="navbar-nav ms-auto">
        <div className='d-lg-none'>
          <HamburgerDropdown ref={stepRef} isTemplate={isTemplate}/>
        </div>
        <ul className="navbar-nav ms-auto d-none d-lg-flex align-items-center">
          <li className="nav-item ms-2" hidden={hidePreviewBtn(wizard) || isWizardV3(wizard)}>
            <Button onClick={previewTree} className="btn-secondary btn" disabled={wizard.submit}>Preview decision</Button>
          </li>
          <li className="nav-item ms-2" hidden={hideStartDecisionBtn(wizard)}>
            <Button onClick={completeWizardHandler} className="btn-primary btn" disabled={wizard.submit}>Start decision</Button>
          </li>
          <li className="nav-item ms-2" hidden={hideNextStepBtn(wizard)}>
            <span className={`btn ${((wizard.submit || wizard.disabledSubmit) && 'btn-light') || 'btn-primary'} btn-lg-round d-none d-lg-flex p-0`} onClick={processNextClick}>
              <i className="fas fa-lg fa-arrow-right m-auto"/>
            </span>
          </li>
          {showPlaybookButton(isTemplate, wizard, decision) ? <PlaybookHeaderButton/> : null}
          {isHistoricalDecision(wizard) ? <DoneHeaderButton ref={stepRef} isTemplate={isTemplate} /> : null}
          <li className="nav-item ms-2">
            <ActionsDropdown btnId="header-actions-dropdown" ref={stepRef} isTemplate={isTemplate}/>
          </li>
        </ul>
      </div>
    </nav>
    <ProgressBar now={wizardProgress(wizard)} hidden={isTreePreview(wizard) || isWizardSingleStep(wizard)}/>
  </header>;
}

const mapStateToProps = ({ wizard, tree, decision, template, contacts, current_org }) => ({
  wizard, tree, decision, template, current_org,
  contactsData: contacts
});

export const headerDispatchProps = (dispatch) => ({
  previewWizardTree: () => dispatch(previewWizardTree()),
  buildWizardTree: () => dispatch(buildWizardTree()),
  prevWizardStep: () => dispatch(prevWizardStep()),
  prevTemplateWizardStep: () => dispatch(prevTemplateWizardStep()),
  forceReloadHomepageDecisions: () => dispatch(forceReloadHomepageDecisions()),
  forceReloadHomepageTemplates: () => dispatch(forceReloadHomepageTemplates()),
  resetSidebars: () => dispatch(resetSidebars()),
  resetWizard: () => dispatch(resetWizard()),
  resetTree: () => dispatch(resetTree()),
  resetTreeDrivers: () => dispatch(resetTreeDrivers()),
  resetDecisionSet: () => dispatch(resetDecisionSetData()),
  resetPlaybookNotes: () => dispatch(resetPlaybookNotes()),
  resetSearchAndFilters: () => dispatch(resetSearchAndFilters()),
  loadContacts: (callback) => dispatch(loadContacts(callback)),
  updateDecisionData: (data) => dispatch(updateDecisionData(data)),
  updateTreeData: (data) => dispatch(updateTreeData(data))
})

const mapDispatchToProps = (dispatch) => ({
  ...headerDispatchProps(dispatch),
  openDecisionDetailsSideBar: (flag) => dispatch(setDecisionSidebarOpen(flag)),
  resetTemplateSet: () => dispatch(resetTemplateSetData())
});
const wrapper = React.forwardRef((props, ref) => <WizardHeader {...props} stepRef={ref}/>)
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(wrapper);

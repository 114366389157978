import React from 'react';
import { connect } from "react-redux";
import { setPlaybookSidebarOpen } from "../../store/sidebar/actions";
import BaseHamburgerBtn from "../../common/BaseHamburgerBtn";

const PlaybookHamburgerButton = ({ setPlaybookSidebarOpen }) =>
  <BaseHamburgerBtn onSelect={setPlaybookSidebarOpen} title="Show playbook" />

const mapDispatchToProps = (dispatch) => ({
  setPlaybookSidebarOpen: () => dispatch(setPlaybookSidebarOpen(true))
});
export default connect(null, mapDispatchToProps)(PlaybookHamburgerButton);

import React, {useEffect, useMemo, useRef, useState} from 'react';
import { connect } from "react-redux";
import Decision, {DESCRIPTION_INPUT_LIMIT} from "../../../models/decision";
import {isBlank, isPresent, stripHtmlTags} from "../../../helpers/common";
import { RichTextEditor } from "../../../common/RichTextEditor";
import CharCounter from "../../../common/CharCounter";
import {focusToEnd} from "../../../helpers/rich_text_helpers";
import { hideListChoicesLink } from "../sections/DraftMoreActionsSection"

export const ANSWER_PLACEHOLDER = "Enter a brief summary";

export const ANSWER_DEFAULT_HEADER = "Answer";

const Counter = ({ isAnswerField, showChar, value, isExceedLimit }) =>
  isAnswerField &&
    <CharCounter show={showChar} field={stripHtmlTags(value)}
                 limit={DESCRIPTION_INPUT_LIMIT}
                 extraClassName={`driver-badge-pill ${isExceedLimit ? 'warning' : ''}`}
    />

const DriverRichTextInput = ({
                               innerRef,
                               driverData, decision, updateDriver,
                               header, field, placeholder = ANSWER_PLACEHOLDER,
                               hideForRateChoices = false, setAutofocus = false
                             }) => {
  if (isBlank(driverData?.driver)) return null;

  const decisionObject = new Decision(decision);
  if (hideForRateChoices && !decisionObject.isRateAndCompareDriverChoicesHidden) return null;

  const [value, setValue] = useState((driverData?.driver || {})[field] || '');
  const [prevValue, setPrevValue] = useState(value);
  const [showChar, setShowChar] = useState(false);
  const focusElement = useRef(null);

  useEffect(() => {
    if(setAutofocus && isPresent(focusElement.current)) focusToEnd(focusElement);
  }, [focusElement.current])

  const choices = useMemo(() => {
      if (decisionObject.isRecommendationFlow &&
         !decisionObject.isRecordedOrRecommended &&
          decisionObject.sortedRecommendationChoices(true).length > 0) {
        return decisionObject.sortedRecommendationChoices(true)
      } else {
        return decisionObject.sortedChoices(true)
      }
    }
    , [decisionObject]);
  const driverRichTextInputHeader = header === ANSWER_DEFAULT_HEADER ? driverData.driver.question : header

  const onChangeValue = (callback = __ => null) => {
    setShowChar(false)
    if (prevValue.trim() === value.trim()) return false;

    const updateData = { slug: driverData.driver.slug }
    updateData[field] = value
    updateData['draft'] = driverData.driver.draft;
    updateDriver(updateData, (success) => {
      if (success && (typeof callback === "function")) {
        callback()
      }
    });
    return true;
  };

  if (innerRef) {
    innerRef.current = {
      onDone: (resolve) => {
        if (!onChangeValue(resolve)) resolve(true)
      }
    }
  }

  const onFocusField = () => {
    setPrevValue(value)
    setShowChar(true)
  }

  const isAnswerField = useMemo(() => field === 'answer', [field])
  const isExceedLimit = useMemo(() => stripHtmlTags(value).length > DESCRIPTION_INPUT_LIMIT, [value])

  return <div id={`driver_${field}`} className="mb-3 px-3" onBlur={onChangeValue} onFocus={onFocusField}>
    <div className={`${hideListChoicesLink(decision, decisionObject, choices) ? '' : 'd-flex'}`}>
      <h3 className="text-ellipsis">{ driverRichTextInputHeader }</h3>
    </div>
    <Counter {...{ isAnswerField, showChar, value, isExceedLimit }} />

    <RichTextEditor theme={`${isAnswerField ? 'bubble' : 'snow'}`}
                    placeholder={placeholder}
                    quillId={header}
                    value={value}
                    setValue={setValue}
                    ref={focusElement}
                    className={showChar ? 'primary-box-shadow' : ''} />
  </div>
}
const wrapper = React.forwardRef((props, ref) => <DriverRichTextInput {...props} innerRef={ref} />)
export default connect(null, null, null, { forwardRef: true })(wrapper);

import React from "react"
import {connect} from "react-redux";
import FinalResultsSection from "../../tree_view/side_panel/sections/FinalResultsSection";
import ApprovalSection from "../../tree_view/side_panel/sections/ApprovalSection";
import ChoicesSection from "./ChoicesSection"
import DetailsSection from "./DetailsSection"
import Decision from "../../models/decision";

const Body = ({decision}) => {
  const decisionObject = new Decision(decision);

  return <div className="container-fluid">
    <div className={`px-3 px-md-0 my-3 mx-auto vertical-tree`}>
      <h1 className="vertical-tree-elements">{decision.description}</h1>
      <FinalResultsSection isLegacy={true} />
      <ApprovalSection isLegacy={true}/>
      <DetailsSection />
      <ChoicesSection {...{decision, decisionObject}} hidden={decisionObject.hideDecisionChoices}/>
    </div>
  </div>
}

const mapStateToProps = ({ decision }) => ({ decision });
export default connect(mapStateToProps)(Body);
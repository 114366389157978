import React from 'react';
import { connect } from "react-redux";
import TemplateCard from './cards/TemplateCard';
import DSightTemplateDriverCard from './cards/DSightTemplateDriverCard';
import { openModal } from "../../store/modals/actions";
import { openDSightDecisionModal } from "../../dsight/template_view/modals/DSightDecisionModal";
import { treeInitData } from "./shared/helpers";

const PoweredByDsightIcon = () => <div className='dsight-powered-logo ms-2' />

const DSightTemplateTree = ({ tree, template, user, contactsData, openModal }) => {
  const { rootDrivers } = treeInitData(tree, {})

  const onTemplateClick = () => openDSightDecisionModal({ openModal, template })

  return <div className="container-fluid p-0">
    <div className={`my-2 mx-auto vertical-tree`}>
      <TemplateCard {... { template, onTemplateClick } } />
      {
        rootDrivers.map(driverData =>
          <DSightTemplateDriverCard key={`template-driver-card-${driverData.driver.slug}`}
                              {...{ driverData, tree, contactsData, user, template, openModal } } />
        )
      }
    </div>
    <PoweredByDsightIcon />
  </div>
}
const mapStateToProps = ({ tree, template, current_user, contacts }) => ({
  tree, template,
  user: current_user,
  contactsData: contacts
});
export default connect(mapStateToProps, { openModal })(DSightTemplateTree);

import React from 'react';

const removeChoice = (slug, onChangeChoice, deleteConsideredChoice) => {
  $(`#rowDecisionChoice-${slug}`).removeClass('d-flex').addClass('d-none');
  deleteConsideredChoice(slug)
  onChangeChoice('', slug)
}
export default ({
                  description, slug,
                  submitted, newChoice,
                  onChangeChoice, setNewChoice, onPasteChoice,
                  onSaveConsideredChoice = () => {},
                  deleteConsideredChoice = () => {},
                  setIsMouseOverInput = () => {},
                  setMouseOverRemoveSlug = () => {}
                }) =>
  <div className="d-flex align-items-center" id={`rowDecisionChoice-${slug}`} onBlur={() => onSaveConsideredChoice(description, slug)}>
    <input className="form-control mb-2"
           id={`decisionChoice-${slug}`}
           key={`decision-choice-${slug}`}
           placeholder="Enter a choice"
           readOnly={submitted}
           disabled={submitted}
           value={description}
           onMouseEnter={() => setIsMouseOverInput(true)}
           onMouseLeave={() => setIsMouseOverInput(false)}
           onChange={(e) => onChangeChoice(e.target.value, slug)}
           onPaste={onPasteChoice && ((e) => onPasteChoice(e, slug))}
           ref={inputElement => {
             if (inputElement && newChoice) {
               inputElement.focus();
               setNewChoice(false)
             }
           }}
    />
    <span className="pointer btn btn-light btn-sm btn-sm-round text-danger mb-2 ms-1 px-2"
          onMouseEnter={() => setMouseOverRemoveSlug(slug)} onMouseLeave={() => setMouseOverRemoveSlug('')}
          onClick={() => removeChoice(slug, onChangeChoice, deleteConsideredChoice)}>
      <i className="fas fa-times w-100"/>
    </span>
  </div>

import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import Container from 'react-bootstrap/Container';
import {connect} from "react-redux";
import {
  assignedByUsersToString,
  driversToArray
} from "../helpers/drivers_helpers";
import {isPresent, pluralize_text} from "../helpers/common";
import QuickDriversHeader from "./header/QuickDriversHeader";
import {
  loadQuickDriverData, updateQuickDriver
} from "../store/quick_driver_entry_page/actions";
import DriverSection from "./DriverSection";
import {Loader} from "../common/Loader";
import {maxHeight} from "csstips";

const loadDataCallback = (loadQuickDriverData, setLoaded, setData) => {
  useEffect(() => {
    const callback = (success, data) => {
      if (success) {
        setData(data)
        setLoaded(true)
      }
    }
    loadQuickDriverData(callback)
  }, []);
}

export const SuccessSection = () =>
  <div className="mx-auto quick-driver-container bg-white p-3 m-3 rounded text-center">
    <div className="text-success" style={{marginBottom: 18}}>
      <i className="fa-regular fa-circle-check" style={{fontSize: '170px'}} />
    </div>
    <h3 className='mb-0'> Thank you!</h3>
    <div>Your response has been added to the decision.</div>
  </div>

export const QuickDriversPage = ({loadQuickDriverData, updateQuickDriver}) => {
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const {assign_user_email, drivers, decision} = data

  loadDataCallback(loadQuickDriverData, setLoaded, setData)

  const filteredDrivers = useMemo(() => isPresent(drivers) && driversToArray(drivers).filter((driverData) => driverData.driver.assign_to_user === assign_user_email), [drivers])
  const assignedByUsers = useMemo(() => assignedByUsersToString(filteredDrivers), [filteredDrivers])
  const getParams = new URLSearchParams(document.location.search)
  const driverScrollSlug = getParams.get('driver_scroll')

  useEffect(() => {
    if(isPresent(driverScrollSlug) && loaded) {
      setTimeout(() => {
        const selectedEl = document.getElementById(`driver-card-${driverScrollSlug}`);
        if (selectedEl) {
          selectedEl.scrollIntoView({ behavior: 'smooth' });
        }
        getParams.delete('driver_scroll')
      }, 100)
    }
  }, [loaded])

  if (!loaded) return <Loader />

  return loaded && <Fragment>
    <QuickDriversHeader />
    <Container>
      <div className="container home my-3 mx-auto">
        <div className="mx-auto quick-driver-container">
          <div className="header">
            <span className='h3 text-primary'>Welcome to Cloverpop!</span>&nbsp;
            <span className='h3 text-dark-gray'>
              {assignedByUsers} assigned you {filteredDrivers.length === 1 ? 'a ' : ''}
              decision {pluralize_text(filteredDrivers.length, 'driver', 'drivers')} for "{decision.description}"
            </span>
          </div>
        </div>
        {
          filteredDrivers.map(driver =>
            <DriverSection key={`driver-row-${driver.driver.slug}`}
                           {...{updateQuickDriver, data, setData, assign_user_email, driver, decision}} />)
        }
      </div>
    </Container>
  </Fragment>
}

export default connect(null, {loadQuickDriverData, updateQuickDriver})(QuickDriversPage);

import React from "react";
import Toggle from "../../../common/Toggle";
import { isPresent } from "../../../helpers/common";

export const TABS = {
  templates: 'templates',
  scratch: 'scratch'
}
const itemTabs = [
  {
    value: TABS.templates,
    body: 'From templates'
  },
  {
    value: TABS.scratch,
    body: 'From scratch'
  },
];

export default ({ header, viewTab, setViewTab, hidden = false }) =>
  <>
    {isPresent(header) && <div className="row">
      <div className="col">
        <h1>{header}</h1>
      </div>
    </div>}

    {!hidden && <div className="mb-2">
      <Toggle items={itemTabs} selectedItem={viewTab} onClick={setViewTab}/>
    </div>}
  </>


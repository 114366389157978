import React, {useMemo, useState} from "react";
import Decision, {DESCRIPTION_INPUT_LIMIT} from "../../models/decision";
import CharCounter from "../../common/CharCounter";
import {RichTextEditor} from "../../common/RichTextEditor";
import {ANSWER_PLACEHOLDER} from "../../tree_view/side_panel/driver_input/DriverRichTextInput";
import {stripHtmlTags} from "../../helpers/common";

const SummaryInput = ({ driver, driverData, onChangeDriver, decision }) => {
  const {slug, answer} = driverData
  const [showChar, setShowChar] = useState(false);
  const isExceedLimit = useMemo(() => stripHtmlTags(answer || '').length > DESCRIPTION_INPUT_LIMIT, [answer])

  const decisionObject = new Decision(decision);
  if (!decisionObject.isRateAndCompareDriverChoicesHidden) return null;

  const Counter = () =>
    <CharCounter show={showChar} field={stripHtmlTags(answer || '')}
                 limit={DESCRIPTION_INPUT_LIMIT}
                 extraClassName={`driver-badge-pill ${isExceedLimit ? 'warning' : ''}`}
    />

  return <>
    <h3 className='px-3'>{driver.driver.question}&nbsp;
      <span className="required-muted-text text-muted">(required)</span>
    </h3>
    <div id={`driver_answer`} className='mb-3 px-3' onBlur={() => setShowChar(false)} onFocus={() => setShowChar(true)}>
      <Counter />
      <RichTextEditor quillId={`summary-input-${slug}`} theme = 'bubble'
                      value={answer || ''}
                      setValue={newValue => onChangeDriver(newValue, 'answer')}
                      className={`mb-2 ${showChar ? 'primary-box-shadow' : ''}`}
                      placeholder={ANSWER_PLACEHOLDER} />
    </div>
  </>

}

export default SummaryInput

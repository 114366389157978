import React from 'react';
import {Page, View} from '@react-pdf/renderer';
import styles from '../../styles/common'
import { isBlank } from "../../../helpers/common";
import { Html } from "react-pdf-html";
import DocumentHeader, {DummyHeader} from "./DocumentHeader";
import DocumentFooter from "./DocumentFooter";
import { SectionBlockRow } from "./DecisionDetailsPage";
import DecisionBlockSection, {
  DriverWeightsBlock,
  KeyDriversBlock,
  WeightedRatingChoicesBlock
} from "./DecisionBlockSection";
import DecisionRecommendationBlock from "./DecisionRecommendationBlock";
import ApprovalPageBlock from "./ApprovalPageBlock";
import DecisionResultsBlock from "./DecisionResultsBlock";
import Decision from "../../../models/decision";
import {modifyRichText, quillIndentStyles} from "../../helpers/rich_text";

const ONE_ROW_LENGTH = 61
const ContextBlock = ({decision, subStyles = []}) => {
  if (isBlank(decision.collaboration_context)) return null;

 return <View style={[...subStyles]}>
    <SectionBlockRow header={'Context'} >
      <View style={[styles.text, {marginTop: 1}]} >
        <Html stylesheet={quillIndentStyles()} style={styles.text}>{modifyRichText(decision.collaboration_context)}</Html>
      </View>
    </SectionBlockRow>
  </View>
}

const DecisionBlockContent = ({drivers, decision}) => <>
  <KeyDriversBlock drivers={drivers} decision={decision} />
  <WeightedRatingChoicesBlock drivers={drivers} decision={decision} />
  <DriverWeightsBlock drivers={drivers} decision={decision} />
</>

const ContinuousPage = ({ org, decision, format, data_sources, drivers = [] }) => {
  const recommendation = decision.recommendation;
  const decisionObj = new Decision(decision, drivers);
  const isOneRowHeader = decision.description.length < ONE_ROW_LENGTH;
  const subStyles = isOneRowHeader ? [styles.mt0] : [{marginTop: 28}]
  const hideDecisionRecommendationContainer = decisionObj.isDecisionRecorded || !decisionObj.isRecordedOrRecommended || isBlank(recommendation.recommended_at)

  return <Page size={format} style={[styles.container]}>
    <DummyHeader text={decision.description} id={'continuous_page'} oneLineHeader={isOneRowHeader}/>
    <DocumentHeader description={decision.description} title={'Decision'} src_id={'#continuous_page'} />
    <View style={styles.body} >
      <ContextBlock decision={decision} subStyles={subStyles} />
      <DecisionBlockSection decision={decision} data_sources={data_sources} drivers={drivers} subStyles={subStyles} />
      {
        decisionObj.isDecisionRecorded ?
          <DecisionBlockContent {...{decision, drivers}}/> :
          hideDecisionRecommendationContainer ?
            <KeyDriversBlock drivers={drivers} decision={decision} subStyles={isBlank(decision.collaboration_context) ? subStyles : [styles.mt0]}/> : null
      }
      <DecisionRecommendationBlock decision={decision} data_sources={data_sources} drivers={drivers} subStyles={decisionObj.isDecisionRecorded ? [styles.mt0] : subStyles} />
      {
        hideDecisionRecommendationContainer ? null: <DecisionBlockContent {...{decision, drivers}}/>
      }
      <ApprovalPageBlock decision={decision} />
      <DecisionResultsBlock decision={decision} />
    </View>
    <DocumentFooter org={org} />
  </Page>
}
export default ContinuousPage;

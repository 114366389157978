import React, { useEffect } from "react";
import { connect } from "react-redux";
import { isPresent, successActions } from "../helpers/common";
import {
  loadDecisionSet,
  removeDecisionSet,
  updateDecisionSet
} from "../store/decision_set/actions";
import { closeModal } from "../store/modals/actions";
import { updateEntryPointData } from "../EntryPoint";
import { Loader } from "../common/Loader";
import DecisionSet from "../models/decision_set";
import { forceReloadHomepageDecisions } from "../store/homepage/actions";
import {
  setDecisionInputSidebarOpen,
  setDecisionOrderSidebarOpen,
  setRecommendationInputSidebarOpen,
  setSetDecisionsSidebarOpen
} from "../store/sidebar/actions";
import { resetDecisionState } from "../store/decision/common_actions";
import { checkLoadingEffect, isStoreLoading } from "../helpers/callbacks_helpers";
import { resetTree } from "../store/tree/common_actions";
import CreateDecisionTab from "../homepage/tabs/CreateDecisionTab";
import {useHistory, useLocation} from "react-router-dom";
import DecisionSetWrapper from "../common/DecisionSetWrapper";
import { setSectionSlugParam } from "../helpers/set_helpers";
import Switcher from "./Switcher";
import { resetDecisionSetData } from "../store/decision_set/common_actions";

const DecisionSetAddDecisionView = (
  {
    match, home,
    decision, decision_set, current_user,
    loadDecisionSet, resetDecision, resetTree, closeDecisionOrderSideBar,
    closeRecommendationInputSideBar, closeDecisionInputSideBar,
    ...opts
  }) => {
  const { search } = useLocation();
  useEffect(() => updateEntryPointData(match, 'decision_sets'), [match?.params?.id]);
  useEffect(() => {
    if (isPresent(decision.slug)) successActions(true, [resetDecision, resetTree, closeDecisionOrderSideBar, closeRecommendationInputSideBar])
  }, []);

  const history = useHistory();

  checkLoadingEffect(decision_set, loadDecisionSet)
  const { isManager, isSetUser, slug } = new DecisionSet(decision_set, current_user)

  const handleToggle = () => {
    history.push(`/decision_sets/${slug}/search${setSectionSlugParam(document)}`);
  };

  return <DecisionSetWrapper {...{...opts, decision_set, isManager, isSetUser}} addDecision={true} >
    {
      isStoreLoading(decision_set) ?
        <Loader /> :
        <div className="w-100 wrapper-container width-fit-content">
          <div className="home container mt-3">
            <h1>{decision_set.name}</h1>
            <Switcher selectedItem={'createADecision'} handleToggle={handleToggle} />
            <CreateDecisionTab sectionHeader={''} isDecisionSet={true} decision_set={decision_set}/>
          </div>
        </div>
    }
  </DecisionSetWrapper>
};

const mapStateToProps = ({ home, decision_set, decision, modal, current_user }) => ({ home, decision_set, decision, modal, current_user });
const mapDispatchToProps = (dispatch) => ({
  loadDecisionSet: () => dispatch(loadDecisionSet()),
  updateDecisionSet: (value, callback) => { dispatch(updateDecisionSet(value, callback)) },
  removeDecisionSet: (callback) => dispatch(removeDecisionSet(callback)),
  closeDecisionOrderSideBar: () => dispatch(setDecisionOrderSidebarOpen(false)),
  closeRecommendationInputSideBar: () => dispatch(setRecommendationInputSidebarOpen(false)),
  closeDecisionInputSideBar: () => dispatch(setDecisionInputSidebarOpen(false)),
  resetDecisionSet: () => dispatch(resetDecisionSetData()),
  resetDecision: () => dispatch(resetDecisionState()),
  resetTree: () => dispatch(resetTree()),
  forceReloadHomepageDecisions: () => { dispatch(forceReloadHomepageDecisions()) },
  setSetDecisionsSidebarOpen: (value) => { dispatch(setSetDecisionsSidebarOpen(value)) },
  closeModal: () => { dispatch(closeModal()) }
});

export default connect(mapStateToProps, mapDispatchToProps)(DecisionSetAddDecisionView);

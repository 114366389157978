import $ from 'jquery';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import Button from 'react-bootstrap/Button';
import { isBlank, successActions } from "../../helpers/common";
import { isVisitor } from "../../helpers/user_helpers";
import { postUserDriverComment } from "../../store/modals/actions";
import { isEnter, isEsc } from "../../helpers/keys_helpers";
import { mapModalSidebarDriverToProps } from "./EditCommentRow";
import DiscussionMentionsInput from "../side_panel/discussion/DiscussionMentionsInput";
import { reloadContacts } from "../../store/contacts/actions";
import { mentioneeWarning } from "../../helpers/alert_helpers";
import { initLastOpenedFieldUseEffect, NEW_COMMENT_KEY } from "./comments_helpers";
import UserAvatarImage from "../../common/UserAvatarImage";
import { resetDecisionSetData } from "../../store/decision_set/common_actions";

export const PLACEHOLDER = 'Enter your comments'

const AddCommentForm = ({
                          innerRef, driver, user,
                          postUserDriverComment, reloadContacts, resetDecisionSetData,
                          displayAddCommentForm, setDisplayAddCommentForm,
                          lastOpenedField, setLastOpenedField,
                          onAddCommentClick, noComments = false
                       }) => {
  if (isVisitor(user)) return null;

  const ref = useRef(null);
  const [userComment, setUserComment] = useState('')
  const [submitState, setSubmitState] = useState(false);

  useEffect(() => {
    if (displayAddCommentForm) {
      $('.add-comment-form').find('input.singleLine-mentions').focus();
    }
  }, [displayAddCommentForm])

  const postComment = (callback = __ => null) => {
    if (!displayAddCommentForm) return false;
    if (isBlank(userComment.trim())) {
      onClose();
      return false;
    }

    setSubmitState(true)
    postMessageRequest((arg1, arg2) => onSuccessCallback(arg1, arg2, callback))
    return true;
  }
  initLastOpenedFieldUseEffect({
    lastOpenedField,
    fieldKey: NEW_COMMENT_KEY,
    submitAction: postComment,
    showCondition: displayAddCommentForm
  })
  const onClose = (callback = __ => null) => {
    if (lastOpenedField === NEW_COMMENT_KEY) {
      setLastOpenedField(false)
      if (displayAddCommentForm && innerRef) {
        innerRef.current = null
      }
    }
    setDisplayAddCommentForm(false)
    setUserComment('')
    ref.current.clear();
    callback()
  }

  const postMessageRequest = (callback) => {
    if(submitState) return false;

    mentioneeWarning(
      ref.current.newMentioned(),
      (make_mentionee_participant) => postUserDriverComment(driver, {
        feedback: { description: userComment },
        all_new_mentions: ref.current.mentioned().map(h => h['email']),
        mentions: ref.current.newMentioned().map(h => h['email']),
        make_mentionee_participant
      }, (status) => callback(status, make_mentionee_participant))
    )
  }

  const onSuccessCallback = (success, make_mentionee_participant, callback = () => null) => {
    setSubmitState(false)
    if (success) {
      onClose(callback);
      successActions(make_mentionee_participant, [reloadContacts, resetDecisionSetData])
    }
  }
  const onKeyDown = (e) => {
    if (isEsc(e.keyCode)) {
      e.preventDefault();
      onClose()
      return false;
    } else if (isEnter(e.keyCode)) {
      e.preventDefault();
      e.stopPropagation();
      postComment()
    }
  }

  const onChange = ({userMessage}) => setUserComment(userMessage)

  if (displayAddCommentForm && lastOpenedField === NEW_COMMENT_KEY && innerRef) {
    innerRef.current = {
      onDone: (resolve) => {
        if (!postComment(resolve)) resolve(true)
      },
      onClose
    }
  }

  return <>
    <div className="add-comment-form" hidden={!displayAddCommentForm}>
      <div className="d-flex mt-2">
        <div className="icon align-self-end me-2">
          <UserAvatarImage user={user} size='sm' />
        </div>
        <div className="message-input-max-width message-input-max-height w-100">
          <DiscussionMentionsInput ref={ref} onKeyDown={(e) => onKeyDown(e)} placeholder={PLACEHOLDER} initialValue={userComment} onChange={onChange} />
        </div>
      </div>
    </div>
    <Button onClick={onAddCommentClick} bsPrefix={`btn btn-secondary w-100 ${ noComments ? '' : 'mt-2'}`} hidden={displayAddCommentForm}>
      Add comments
    </Button>
  </>
}
const mapStateToProps = ({ tree, current_user, ...opts }, { driver = null }) => ({
  ...mapModalSidebarDriverToProps({ ...opts, driver }),
  default_user_avatar_url: tree.default_user_avatar_url,
  user: current_user
});
const wrapper = React.forwardRef((props, ref) => <AddCommentForm {...props} innerRef={ref} />)
export default connect(mapStateToProps, {
  postUserDriverComment, reloadContacts, resetDecisionSetData
}, null, { forwardRef: true })(wrapper);

import {
  AlignmentType,
  Bookmark, convertInchesToTwip,
  convertMillimetersToTwip,
  ExternalHyperlink,
  HeadingLevel,
  InternalHyperlink,
  Paragraph,
  TextRun
} from "docx";
import {PRIMARY_COLOR} from "../../../pdf/styles/common";
import {borderProps, DOCX_FONT, FONT_SM, space18pts, space4d5pts} from "../../styles/docx_styles";
import {isBlank} from "../../../helpers/common";
import {richText} from "../../helpers/richTextParser";

export const externalLink = ({linkText, link, format = {}}) => {
  return new ExternalHyperlink({
    children: [
      new TextRun({
        text: linkText,
        color: PRIMARY_COLOR,
        font: DOCX_FONT,
        size: FONT_SM,
        ...format
      }),
    ],
    link: link,
  })
}

export const internalLink = ({text = '', anchor = ''}) => {
  return new InternalHyperlink({
    children: [
      new TextRun({
        text: text,
        color: PRIMARY_COLOR,
        font: DOCX_FONT,
        size: FONT_SM
      }),
    ],
    anchor: anchor,
  })
}

export const baseBlockSection = ({header = '', text = '', heading = HeadingLevel.HEADING_2, style = "normalPara", instance = 0 }) => {
  if (isBlank(header) || isBlank(text)) return [];

  return [
    baseHeadParagraph ({text: header, heading}),
    ...baseRichBlock({text, style, instance})
  ]
}

export const baseRichBlock = ({text = '', style = "normalPara", alignment = AlignmentType.LEFT, instance = 0}) => {
  if (isBlank(text)) return [];

  return richText(text, alignment, style, instance)
}

export const baseHeadParagraph = ({text = '', heading = HeadingLevel.HEADING_2, alignment = AlignmentType.LEFT}) => {
  if (isBlank(text)) return [];

  return new Paragraph({alignment, text, heading})
}

export const baseParagraph = (children = [], alignment = AlignmentType.LEFT, style = "normalPara", bulletList = {}, orderList = {}, indent = {}) => {
  if (isBlank(children)) return {};

  return new Paragraph({
    alignment,
    style,
    ...indent,
    ...bulletList,
    ...orderList,
    children: [
      ...children
    ]
  })
}

export const bookMarkHeader = ({id = '', text = '', heading = HeadingLevel.HEADING_2}) =>
  new Paragraph({
    heading,
    children: [
      new Bookmark({
        id: id,
        children: [
          new TextRun(text),
        ],
      })
    ],
  })

export const bulletParagraph = ({text, level = 0, style = "normalPara"}) => {
  return new Paragraph({
    text,
    style,
    numbering: {
      reference: "small-bullet-points",
      level: 0,
    },
  })
}

export const paragraphBorder = () =>
  new Paragraph({
    style: "normalPara",
    ...borderProps,
    ...space18pts
  })

const liIcon = (driver, depth) => {
  if (isBlank(driver.parent_id) || isBlank(depth)) return []

  return new TextRun({
    text: depth % 2 ? `•  ` : '◦  '
  })
}

export const wrapLink = ({text = '', anchor = '', id = '', depth, driver = {}, space = space4d5pts}) =>
  new Paragraph({
    ...space,
    indent: { left: isBlank(depth) ? 0 : convertMillimetersToTwip((depth + 1) * 5) },
    children: [
      liIcon(driver, depth),
      new Bookmark({
        id: id,
        children: [
          internalLink({text: text, anchor: anchor})
        ],
      })
    ],
  })
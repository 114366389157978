import React from "react";
const indentationSize = 24

export default ({ children, indentStep = 0, onClick = () => {}, clickable = false, expanded = false }) => {
  const indentPx = indentStep * indentationSize;
  return <div className={`position-relative bg-white rounded ${clickable ? 'pointer' : 'cursor-default'} ${expanded ? 'p-3' : 'ps-3 pe-2 py-2'} my-2`}
              onClick={onClick}
              style={{ marginLeft: `${indentPx}px`, minHeight: 60 }}>
    {children}
  </div>
}

import React from 'react';
import CloseIcon from "../../common/CloseIcon";
import { isPresent } from "../../helpers/common";
import { EditIcon } from "../../common/EditIcon";

export default ({ headerName, openSideBar, preview, edit, setEdit = () => {}, addClass = '', children }) =>
  <div className="side-panel header px-3">
    <div className="d-flex my-auto pt-2">
      <h2 className={`${addClass}`}>{headerName}</h2>
      <div className={'d-flex ms-auto'}>
        <div className="ms-2">
          { children }
        </div>
        <div className="ms-2" hidden={preview || isPresent(edit)}>
          <EditIcon onClick={() => { setEdit(true) }} />
        </div>
        <div className="ms-2">
          <CloseIcon onClose={() => openSideBar(false)} />
        </div>
      </div>
    </div>
  </div>

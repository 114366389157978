import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DecisionDueDateStyles } from "./styles/decision_due_date_styles";

export const DATEPICKER_TYPES = {
  due_date: 'due-date',
  historical_decided_at: 'historical-decided-at'
}

const buildValue = (id, date) => id === DATEPICKER_TYPES.due_date ? `Decision due by ${date.format('D MMM, yyyy')}` : date.format('D MMM, yyyy')

const ExampleCustomInput = React.forwardRef(({ value, onClick, id, date, header }, ref) => (
  <>
    <h3>{header}</h3>
    <div style={DecisionDueDateStyles.arrowOnInputContainer()}>
      <input className="form-control bg-white pointer" id="due-date-header" placeholder="Enter the decision due date"
             value={buildValue(id, date)} readOnly
             onChange={(e) => e.preventDefault()} style={DecisionDueDateStyles.input()}
             {...{ onClick, ref }}
      />
      <span className="arrow-icon" style={DecisionDueDateStyles.arrowIcon()} {...{ onClick }}/>
    </div>
  </>
));

const popperModifiers = [
  {
    name: 'keepTogether',
    options: {
      enabled: true
    }
  },
  {
    name: "arrow",
    options: {
      padding: { left: 20 }
    }
  }
]

export default ({ date = new Date(), name = DATEPICKER_TYPES.due_date, id= DATEPICKER_TYPES.due_date,
                  header = 'Due date',
                  onChangeDate = () => {}, ...opts }) =>
  <DatePicker
    wrapperClassName="date-picker" name={name} id={id}
    selected={date.toDate()} onChange={onChangeDate}
    popperModifiers={popperModifiers}
    { ...opts }
    customInput={<ExampleCustomInput id={id} date={date} header={header} />}
  />

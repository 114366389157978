import React from "react";
import UserAvatarImage from "../../../common/UserAvatarImage";
import CreatedData from "../../../homepage/tabs/forecast_simulator/CreatedData";
import { ScenarioActions } from "./ScenarioActions";
import Button from "react-bootstrap/Button";

export default ({ forecast_simulator_scenario, user, runModelActive, onRunModel }) =>
  <div className="decision-tree-header actions-row border-0">
    <div className="d-flex align-items-center" key={`created-scenario-${user.email}`}>
      <UserAvatarImage user={user} size='md' classNames={'me-2'}/>
      <CreatedData scenario={forecast_simulator_scenario.scenario} classNames={'text-muted'} user={user}/>
      <div className="ms-auto">
        <Button onClick={onRunModel}
                className="mx-2"
                disabled={!runModelActive}>Run model</Button>
        <ScenarioActions btnId="scenario-actions"/>
      </div>
    </div>
  </div>;

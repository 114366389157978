import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { setRecommendationInputSidebarOpen } from "../../../store/sidebar/actions";
import { isBlank } from "../../../helpers/common";
import Button from 'react-bootstrap/Button';
import { decisionObjects, isNonUser } from "./helpers";
import {isDSightDecision} from "../../../helpers/home_decision_helpers";

const RenderRecommendationInputSidebar = ({
                                            current_user, decision, sidebar,
                                            openRecommendationInputSidebar
                                          }) => {
  const {
    recommendation, recommendationObject, decisionObject
  } = decisionObjects(decision)
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  },[]);

  if (isNonUser(current_user)) return null;
  if (decisionObject.isTreeHistorical && decisionObject.withRecommendation && recommendationObject.isEnteredRecommendation) return null;
  if (recommendationObject.isEnteredRecommendation || decisionObject.isDecisionRecorded) return null;
  if (decisionObject.withRecommendation && !decisionObject.isRecommendationFlow && !decisionObject.isTreeHistorical) return null;
  if (isBlank(decision) || isBlank(recommendation) || isBlank(recommendationObject) || isDSightDecision(decision)) return null;

  return loading ? null : (
    <Button onClick={() => openRecommendationInputSidebar(!sidebar.recommendationInputSidebar)}
            className="btn btn-secondary w-100">
      Enter the recommendation
    </Button>
  )
}

const mapStateToProps = ({ current_org, current_user, decision, sidebar }) => ({
  current_org, current_user, decision, sidebar
});

const mapDispatchToProps = (dispatch) => ({
  openRecommendationInputSidebar: (flag) => dispatch(setRecommendationInputSidebarOpen(flag))
});
export default connect(mapStateToProps, mapDispatchToProps)(RenderRecommendationInputSidebar);

import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { isBlank, isPresent } from "../../helpers/common";
import Modal from "react-bootstrap/Modal";
import {
  isOpenEndedCallback,
  onChangeResponseTypeCallback
} from "../../tree_wizard/steps_wizard/steps/FramingDecisionStep";
import { ModalDoneFooter } from "../../common/modals";
import DescriptionSection from "./entry_modal/DescriptionSection";
import ResponseTypeSection from "./entry_modal/ResponseTypeSection";
import RateCompareChoicesSection from "./entry_modal/RateCompareChoicesSection";
import ChoicesSection from "./entry_modal/ChoicesSection";
import TitleNotesSection from "./entry_modal/TitleNotesSection";
import DecisionAssignToBlock from "./helpers/DecisionAssignToBlock";
import {
  buildTemplateChoices,
  buildResponseType,
  submitEntryCallback
} from "./entry_modal/helpers";
import ModalHeaderTitle from "../../common/ModalHeaderTitle";
import IncludeRecommendationSection from "./entry_modal/IncludeRecommendationSection";
import HideRecommendationSection from "./entry_modal/HideRecommendationSection";

export const openTemplateEntry = ({ openModal, template }) => openModal({ decision: template, drivers: [], slug: template.slug, type: 'TemplateEntryModal' })
export const showTemplateEntry = (modal) => modal.type === "TemplateEntryModal" && modal.shown;

const TemplateEntryModal = ({
                              show, onClose,
                              template, playbook_notes,
                              key = '', updateTemplate
                            }) => {
  if(isBlank(template)) return null;

  const [description, setDescription] = useState(template.description);
  const [treeEnum, setTreeEnum] = useState(template.flow);
  const [hideRecommendation, setHideRecommendation] = useState(template.hide_recommendation);
  const [responseType, setResponseType] = useState(buildResponseType(template));
  const [choices, setChoices] = useState(buildTemplateChoices(template));
  const [submitted, setSubmitted] = useState(false);
  const [titleNotes, setTitleNotes] = useState(playbook_notes?.decision_title);
  const [assignedToUser, setAssignedToUser] = useState(template.assigned_collaborator_email)

  const disabledSubmit = () => isBlank(description);
  const isOpenEnded = isOpenEndedCallback(responseType);
  const onChangeResponseType = onChangeResponseTypeCallback(() => buildTemplateChoices(template), isOpenEnded, setChoices, setResponseType);

  const [rateCompareChoices, setRateCompareChoices] = useState(isPresent(template.rate_compare_choices))

  const updateData = () => {
    const data = {
      template: {
        description, choice_entry_widget_type: responseType,
        rate_compare_choices: rateCompareChoices,
        assigned_collaborator: assignedToUser,
        flow: treeEnum,
        hide_recommendation: hideRecommendation
      },
      decision_title: titleNotes
    }
    if(!isOpenEnded()) data.choices = choices.map((c) => c.description)
    return data;
  }
  const submissionCallback = submitEntryCallback(updateTemplate, updateData(), setSubmitted, onClose);

  useEffect(() => {
    if (show) {
      setDescription(template.description)
      setResponseType(buildResponseType(template))
      setChoices(buildTemplateChoices(template))
    }
  }, [show, template])

  return <Modal key={key} size="lg" backdrop="static" show={show} onHide={onClose}>
    <Modal.Body>
      <form>
        <ModalHeaderTitle {...{ onClose, title: 'Edit decision' }} />
        <DescriptionSection {...{ description, setDescription, submitted, isTemplate: true, className: 'mb-3' }} />
        <IncludeRecommendationSection {...{ treeEnum, setTreeEnum, classNames: 'mb-2 mt-2-negative' }} />
        <HideRecommendationSection {...{ hideRecommendation, setHideRecommendation, classNames: 'mb-3' }} />
        <TitleNotesSection {...{ isTemplate:true, titleNotes, setTitleNotes }} />
        <>
          <ResponseTypeSection {...{ responseType, onChangeResponseType, submitted }} />
          <RateCompareChoicesSection {...{ rateCompareChoices, setRateCompareChoices, hidden: isOpenEnded() }} />
          <ChoicesSection {...{ choices, setChoices, submitted, hidden: isOpenEnded() }} />
          <DecisionAssignToBlock {...{ assignedToUser, setAssignedToUser, allowGuest: false, allowPending: false, className: 'mt-3', allowNew: false }} />
        </>
      </form>
    </Modal.Body>
    <ModalDoneFooter onClose={submissionCallback} disabled={disabledSubmit() || submitted} />
  </Modal>
};

const mapStateToProps = ({ modal, playbook_notes }) => ({ template: modal.decision, playbook_notes })
export default connect(mapStateToProps)(TemplateEntryModal);

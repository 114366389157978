import React from 'react';
import Wrapper from '../Wrapper';
import DriverInfo from "../driver/DriverInfo";
import AggregatedData from "../driver/AggregatedData";
import Header from "../driver/Header";
import WizardContent from "../driver/WizardContent";
import { assignedDriverUser } from "./DriverCard";
import { driverInitData } from "../shared/helpers";

const PreviewDecisionWizardDriverCard = ({ driverData, decision, tree, indentStep = 1, decisionObject, user,
                                           collaborators, openModal }) => {
  const { driverObject, childrenDrivers, setShowChildren, showChildren } = driverInitData(tree, driverData, decisionObject);
  const assignedUser = assignedDriverUser(collaborators, {}, driverObject);

  const onEditClick = () => openModal({
    decision, drivers: tree.drivers, data_sources: tree.data_sources,
    slug: driverData.driver.slug, type: 'DriverDetailsModal'
  })

  return <>
    <Wrapper indentStep={indentStep} >
      <div className={`d-flex`}>
        <div className="my-auto lh-sm w-100 me-1">
          <Header {...{ user, driverData, driverObject, childrenDrivers, setShowChildren, onEditClick }} />
          <DriverInfo {...{ driverData, driverObject, decisionObject, user  }} />
          <AggregatedData {...{ driverData }} />
        </div>
        <WizardContent {...{ driverData, driverObject, assignedUser } } />
      </div>
    </Wrapper>
    {showChildren && childrenDrivers.map((child) => {
      return <PreviewDecisionWizardDriverCard key={`driver-card-${child.driver.slug}`}
                                              {...{
                           driverData: child,
                           indentStep: indentStep + 1,
                           decisionObject, user, tree, openModal, collaborators
                         } } />
    })}
  </>
}
export default PreviewDecisionWizardDriverCard;

import { isHistoricalFlow, isLastStep, isTreePreview, isWizardV3 } from "../../helpers/wizard_helpers";

export const submitTreeStep = (wizard, stepRef) => {
  if (isHistoricalFlow(wizard)) {
    return stepRef?.current?.submitStep();
  }
  if (isWizardV3(wizard) && isLastStep(wizard) && !isTreePreview(wizard)) {
    return stepRef?.current?.previewTree();
  }

  stepRef?.current?.submitStep()
}

export const submitPreviewStepParam = (wizard) => {
  if (isHistoricalFlow(wizard)) return false;

  return isLastStep(wizard) && isWizardV3(wizard);
}

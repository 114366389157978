import React from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetSidebars } from "../../../store/sidebar/actions";
import { openModal } from "../../../store/modals/actions";
import { resetTemplate } from "../../../store/template/common_actions";
import { resetTree } from "../../../store/tree/common_actions";
import PlaybookHamburgerButton from "../../../template_view/header/PlaybookHamburgerButton";
import BaseHamburgerBtn, { BaseDropdownBtn } from "../../../common/BaseHamburgerBtn";
import { HamburgerMenuIconBtn } from "../../../common/dropdowns";
import DetailsHamburgerButton from "../../../template_view/header/DetailsHamburgerButton";
import HelpHamburgerButton from "../../../template_view/header/HelpHamburgerButton";
import { goToHomepageCallback } from "../../../tree_wizard/header/helpers";

export const HamburgerDropdown = ({
                                    template,
                                    openModal,
                                    resetSidebars, resetTree, resetTemplate,
                                  }) => {
  const history = useHistory();
  const goToHomepage = goToHomepageCallback([resetSidebars, resetTree, resetTemplate], history)

  return <HamburgerMenuIconBtn>
    <DetailsHamburgerButton />
    <PlaybookHamburgerButton />
    <BaseDropdownBtn eventKey={template.slug} onSelect={() => openModal({type: 'TemplateUsersModal'})} title="Edit template users" />
    <HelpHamburgerButton />
    <BaseHamburgerBtn onSelect={goToHomepage} title="Go to homepage" />
  </HamburgerMenuIconBtn>
}

const mapStateToProps = ({template}) => ({ template });
const mapDispatchToProps = (dispatch) => ({
  openModal: (data) => dispatch(openModal(data)),
  resetSidebars: () => dispatch(resetSidebars()),
  resetTree: () => dispatch(resetTree()),
  resetTemplate: () => dispatch(resetTemplate())
});
export default connect(mapStateToProps, mapDispatchToProps)(HamburgerDropdown);

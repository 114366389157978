import React from 'react';
import { SectionName } from "./ZTreeSectionNode";
import EditSection from './actions/EditSection';
import RemoveSection from './actions/RemoveSection';

const ZTreeHoverSectionNode = ({ treeNode, ...opts }) => {
  return <div className="d-flex align-items-center h-inherit">
    <SectionName treeNode={treeNode}/>
    <div className="ms-auto">
      <EditSection {...{ ...opts, treeNode }} />
      <RemoveSection {...{ ...opts, treeNode }} />
    </div>
  </div>
}
export default ZTreeHoverSectionNode;

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { equalsArraysIgnoreOrder, isBlank, isPresent } from '../../../helpers/common';
import { connect } from "react-redux";
import { resetSearch } from '../../../store/homepage/actions';
import { Accordion, AccordionContext, Card } from 'react-bootstrap'
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ReportProperty from "../../../models/report_property";
import PropertyOptionsTypeahead from "../report_insights/PropertyOptionsTypeahead";
import ResetFiltersCard from "./ResetFiltersCard";
import SearchIcon from "./SearchIcon";
import { setFilterAutofocus } from "../../../helpers/filter_helpers";

export const calcSelectedProperties = (org_report_properties, home) =>
  org_report_properties.available_report_properties.map((property, i) =>
    ({ property: property, options: property.options.filter((option) => home.property_options.includes(option.id)) })
  ).filter((selectedProperty) => isPresent(selectedProperty.options))

export const calcSelectedOpenProperties = (org_report_properties, home) =>
  org_report_properties.available_report_properties.map((property, i) =>
    ({ property: property, options: (property.options[0]?.report_property_options || []).filter((option) => home.report_property_options.includes(option.id)) })
  ).filter((selectedProperty) => isPresent(selectedProperty.options))

export const Menu = ({home, org_report_properties, loadAction, saveMenu, onSave, openedSection, setOpenedSection }) => {
  const [submit, setSubmit] = useState(false);
  const calcProperties = calcSelectedProperties(org_report_properties, home);
  const calcOpenProperties = calcSelectedOpenProperties(org_report_properties, home);
  const [selectedPropertyOptions, setSelectedPropertyOptions] = useState(calcProperties);
  const [selectedReportPropertyOptions, setSelectedReportPropertyOptions] = useState(calcOpenProperties);

  useEffect(() => {
    if(saveMenu) {
      save();
      onSave();
    }
  }, [saveMenu]);

  const defaultFilters = useCallback(() =>
      isBlank(selectedPropertyOptions) && isBlank(selectedReportPropertyOptions),
    [selectedPropertyOptions, selectedReportPropertyOptions]
  );

  const filtersChanged = useCallback(() => {
    const propertiesChanged = !equalsArraysIgnoreOrder(home.property_options, buildPropertyOptionsFilterValues);
    const openPropertiesChanged = !equalsArraysIgnoreOrder(home.report_property_options, buildReportPropertyOptionsFilterValues);
    return home.property_options.length !== buildPropertyOptionsFilterValues.length || propertiesChanged || openPropertiesChanged
  }, [selectedPropertyOptions, selectedReportPropertyOptions]);

  const buildPropertyOptionsFilterValues = useMemo(() => {
    return selectedPropertyOptions.map(optionData => optionData.options.map((o) => o.id)).flat()
  }, [selectedPropertyOptions]);

  const buildReportPropertyOptionsFilterValues = useMemo(() => {
    return selectedReportPropertyOptions.map(optionData => optionData.options.map((o) => o.id)).flat()
  }, [selectedReportPropertyOptions]);

  const reset = useCallback(() => {
    if(defaultFilters()) return;
    setSelectedPropertyOptions([]);
    setSelectedReportPropertyOptions([]);
  }, [selectedPropertyOptions, selectedReportPropertyOptions]);

  const save = useCallback(() => {
    if(filtersChanged()) {
      setSubmit(true);
      loadAction({
        property_options: buildPropertyOptionsFilterValues,
        report_property_options: buildReportPropertyOptionsFilterValues,
      })
      setSubmit(false);
    }
    onSave();
  }, [selectedPropertyOptions, selectedReportPropertyOptions]);

  const ContextAwareToggle = ({ children, eventKey, callback, propertyObject, setOpenToggle }) => {
    const selectedOptions = propertyObject.isOpen ? selectedReportPropertyOptions : selectedPropertyOptions;
    const currentEventKey = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));
    const isCurrentEventKey = currentEventKey.activeEventKey?.includes(eventKey) || false;
    let filterTextArray = []
    const property = org_report_properties.available_report_properties.find((c) => c.name === children);
    if (isPresent(property)) {
      const selectedPropertyOptionsData = selectedOptions.find((optionData) => optionData.property.slug === property.slug);
      filterTextArray = selectedPropertyOptionsData?.options?.map((o) => o.description);
    }
    const filterActive = () => {
      return isPresent(filterTextArray);
    }

    useEffect(() => {
      setOpenToggle(filterActive());
    }, [filterTextArray]);

    return (
      <div className="d-flex p-3" onClick={filterActive() ? null : decoratedOnClick}>
        <div className={`fw-bolder filter-text text-ellipsis me-1`}>{children}</div>
        <div className="search-actions filter-text d-flex ms-auto">
          <div className="selected-actions-text" hidden={filterActive() || isCurrentEventKey}>Any</div>
          <div className={`dropdown-toggle ms-1 ${isCurrentEventKey ? 'open' : ''}`} hidden={filterActive()}></div>
        </div>
      </div>
    );
  }

  const setPropertyOptions = (property, options) => {
    const newPropertySelectedOptions = { property, options };
    const newSelectedOptionsArray = selectedPropertyOptions.filter((option) => option.property.slug !== property.slug);
    setSelectedPropertyOptions([...newSelectedOptionsArray, newPropertySelectedOptions]);
  };

  const setReportPropertyOptions = (property, options) => {
    const newPropertySelectedOptions = { property, options };
    const newSelectedOptionsArray = selectedReportPropertyOptions.filter((option) => option.property.slug !== property.slug);
    setSelectedReportPropertyOptions([...newSelectedOptionsArray, newPropertySelectedOptions]);
  };

  const PropertyCard = ({ property, eventKey }) => {
    const setAutoFocus = useCallback(() => setFilterAutofocus(setOpenedSection, property.name), [property.name]);
    const propertyObject = useMemo(() => new ReportProperty(property), [property]);
    const [openToggle, setOpenToggle] = useState(false);

    return (
      <Card className="shadow">
        <Card.Header className="bg-white border-0 pointer p-0" onClick={setAutoFocus}>
          <ContextAwareToggle eventKey={eventKey}
                              propertyObject={propertyObject}
                              setOpenToggle={setOpenToggle}>
            {property.name}
          </ContextAwareToggle>
        </Card.Header>
        <Accordion.Collapse eventKey={eventKey} className={`${openToggle ? 'show' : ''}`}>
          <Card.Body className="pt-0 px-3 pb-3">
            <SearchIcon />
            <PropertyOptionsTypeahead
              disabled={submit}
              values={(propertyObject.isOpen ? selectedReportPropertyOptions : selectedPropertyOptions).find((optionData) => optionData.property.slug === property.slug)?.options || []}
              setValues={propertyObject.isOpen ? setReportPropertyOptions : setPropertyOptions}
              property={property}
              propertyObject={propertyObject}
              autoFocus={openedSection === property.name}
              id={`property-options-input-${eventKey}`}
              minLength={propertyObject.isOpen ? 1 : 0}
              flip={false}
            />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    )
  }

  return <>
    <ResetFiltersCard {...{ save, reset, defaultFilters }} />
    <Accordion alwaysOpen>
      {org_report_properties.available_report_properties.map((property, i) =>
        <PropertyCard key={`property-card-${property.slug}`} eventKey={property.display_order} property={property} />)
      }
  </Accordion>
  </>
}

const mapStateToProps = ({ home, org_report_properties, current_org }) => ({
  home, org_report_properties, current_org
});
export default connect(mapStateToProps, { resetSearch })(Menu);

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { isDSightTemplate, isTemplateDraft } from "../helpers/template_helpers";
import BubbleTooltip from "../bubble_tooltip/BubbleTooltip";
import { safeDescription } from "../helpers/decision_helpers";
import { Link, useHistory } from "react-router-dom";
import { isBlank, isPresent } from "../helpers/common";
import { StartFromScratchBlock } from "../homepage/tabs/create_decision_tab/StartFromScratch";
import TemplatesTab from "../homepage/tabs/TemplatesTab";
import { Loader } from "../common/Loader";
import { setDecisionOrderSidebarOpen } from "../store/sidebar/actions";
import { isStoreLoading } from "../helpers/callbacks_helpers";
import TemplateSet from "../models/template_set";
import { calcSequenceIndexByIndex, calcSequenceNumber } from "../models/decision_set";

const DraftTemplateTile = ({template}) =>
  <div className="position-relative col col-md-6 col-lg-4 col-xl-3 homepage_tile mb-3">
    <BubbleTooltip object_hash={template} isTemplateSetSection={true} />
    <div className="text-dark text-center">
      <div className="tile-content bg-white pt-3 px-2 pb-2 py-5">
        <h4 className="decision-title mb-3">
          <span className="text-gray">Draft: </span>
          {safeDescription(template)}
        </h4>
        <Link to={`/templates/${template.slug}/wizard`} target={`_blank`}>
          <div className="btn btn-primary py-1 w-100">
            <span>Continue</span>
          </div>
        </Link>
      </div>
    </div>
  </div>

const TemplateTile = ({
                        template, template_set, preview,
                        startIndex = 0, index = 0
                      }) => {
  const sequence_number = calcSequenceNumber(template_set, startIndex, index)

  if (isTemplateDraft(template)) {
    return preview ? null : <DraftTemplateTile template={{ ...template, sequence_number }} />
  }

  return <div className="position-relative col col-md-6 col-lg-4 col-xl-3 homepage_tile mb-3">
    <BubbleTooltip object_hash={{ ...template, sequence_number }} isTemplateSetSection={true} />
    <div className="text-dark text-center">
      <div className="tile-content bg-white pt-3 px-2 pb-2">
        <h4 className="decision-title mb-3">{safeDescription(template)}</h4>
        <Link to={`${isDSightTemplate(template) ? '/dsight' :  ''}/templates/${template.slug}${preview ? '/preview' : ''}`} className="d-block">
          <div className="btn btn-secondary align-center h-36 py-1 w-100">
            <span>{preview ? 'Preview decision' : 'View template'}</span>
          </div>
        </Link>
      </div>
    </div>
  </div>
}

const TemplatesList = ({ templates, ...opts }) =>
  templates.map((template, index) =>
    <TemplateTile key={`set-template-tile-${template.slug}-${index}`}
                  {...{ ...opts, template, index }}
    />
  )

const Section = ({ templates, section, ...opts }) =>
  <>
    <div className="row">
      <div className="col">
        <h2>{section.name}</h2>
      </div>
    </div>
    <TemplatesList {...{ ...opts, templates }} />
  </>

const TemplateSetTemplatesList = ({ template_set, ...opts }) => {
  if(isStoreLoading(template_set)) return <Loader/>;

  const templateSetObj = new TemplateSet(template_set)
  if(isBlank(template_set.sections)) return <TemplatesList {...{ ...opts, template_set, templates: templateSetObj.templates }} />

  const sections = template_set.sections
  return <>
    <TemplatesList {...{ ...opts, template_set, templates: templateSetObj.templatesWithoutSection }} />
    {
      sections.map((section, index) =>
        isPresent(section.templates) ?
          <Section key={`template-sets-section-${section.slug}-tiles`}
                   {...{
                     ...opts, section, template_set,
                     templates: templateSetObj.templatesInSection(section),
                     startIndex: calcSequenceIndexByIndex(sections, index, templateSetObj.templatesWithoutSection)
                   }} /> :
          null
      )
    }
  </>
}

export const checkTemplateSetSidebarParams = ({
                                                template_set, sidebar, setDecisionOrderSidebarOpen
                                              }) => {
  useEffect(() => {
    if (template_set.loaded) {
      const getParams = new URLSearchParams(document.location.search)
      const left_side_bar_open = getParams.get('left_side_bar_open')
      if (isBlank(left_side_bar_open) && sidebar.decisionOrderSidebar) {
        setDecisionOrderSidebarOpen(false);
        return;
      }
      switch (left_side_bar_open) {
        case 'decision_order':
          setDecisionOrderSidebarOpen(true);
          return;
      }
    }
  }, [template_set.loaded])
}

const TemplateSetsSection = ({
                               template_set,
                               preview = false,
                               ...opts
                            }) => {
  const [showAddToSet, setShowAddToSet] = useState(false);
  const { push } = useHistory();
  checkTemplateSetSidebarParams({ ...opts, template_set })

  return <>
    <div className="row">
      <h1>{template_set.name}</h1>
      <TemplateSetTemplatesList {...{ ...opts, template_set, preview }} />
      {
        (isBlank(template_set.templates) && !preview && !showAddToSet) &&
          <StartFromScratchBlock title={"Add a template"} addClass="py-5" showNullResults={false}
                                 onClick={() => push(`/template_sets/${template_set.slug}/add_template`)} />
      }
    </div>
    {showAddToSet && !preview ? <TemplatesTab isTemplateSet={true} /> : null}
  </>
}
const mapStateToProps = ({ template_set, sidebar }) => ({ template_set, sidebar });
export default connect(mapStateToProps, { setDecisionOrderSidebarOpen })(TemplateSetsSection);

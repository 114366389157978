import React, {useEffect, useRef, useState} from 'react';
import CharCounter from "../../../common/CharCounter";
import { QUESTION_INPUT_LIMIT } from "../../../models/driver";

export default ({
                  isDSight, question,
                  setQuestionValue, onChangeQuestion = () => {},
                  showTitle = true, onKeyDown = () => {}
                }) => {
  if (isDSight) return null;
  const [showChar, setShowChar] = useState(false);

  const inputElement = useRef(null);

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.addEventListener('keydown', onKeyDown)
      return () => {
        inputElement.current.removeEventListener('keydown', onKeyDown)
      }
    }
  }, [inputElement.current])

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, []);

  return <div className={`${showTitle ? 'mb-3' : 'w-100'}`}>
    {showTitle && <h3>Driver</h3>}
    <CharCounter show={showChar} field={question} limit={QUESTION_INPUT_LIMIT}  />
    <input className="form-control"
           type="text"
           placeholder="Enter a question or title"
           value={question}
           onChange={(e) => setQuestionValue(e.target.value)}
           onKeyDown={onKeyDown}
           autoComplete="off" ref={inputElement}
           maxLength={QUESTION_INPUT_LIMIT}
           onBlur={() => {onChangeQuestion(); setShowChar(false);}}
           onFocus={() => setShowChar(true)}
    />
  </div>
};

import React, {useMemo, useState, useEffect} from 'react';
import { connect } from "react-redux";
import { isBlank, isPresent } from "../../helpers/common";
import Decision from '../../models/decision';
import Modal from "react-bootstrap/Modal";
import {
  isOpenEndedCallback,
  onChangeResponseTypeCallback
} from "../../tree_wizard/steps_wizard/steps/FramingDecisionStep";
import { ModalDoneFooter } from "../../common/modals";
import DescriptionSection from "./entry_modal/DescriptionSection";
import ResponseTypeSection from "./entry_modal/ResponseTypeSection";
import RateCompareChoicesSection from "./entry_modal/RateCompareChoicesSection";
import ChoicesSection from "./entry_modal/ChoicesSection";
import {
  buildResponseType,
  buildDecisionChoices,
  buildRecommendationChoices,
  submitEntryCallback
} from './entry_modal/helpers';
import FlowSwitcher from "./entry_modal/FlowSwitcherSection";
import { updateDecisionFlow, updateRecommendation, updateDecision } from "../../store/modals/actions";
import { recommendationUpdateData } from "./RecommendationEntryModal";
import { decisionUpdateData } from "./DecisionEntryModal";
import DecisionAssignToBlock from "./helpers/DecisionAssignToBlock";
import ModalHeaderTitle from "../../common/ModalHeaderTitle";
import {reloadContacts} from "../../store/contacts/actions";
import { resetDecisionSetData } from "../../store/decision_set/common_actions";

const MultiEntryModal = ({
                           show, onClose, decision, key = '',
                           updateDecision, updateRecommendation, updateDecisionFlow,
                           reloadContacts, resetDecisionSetData
                        }) => {
  if(isBlank(decision)) return null;

  const decisionObj = useMemo(() => new Decision(decision), [show, decision])

  const prevFlowEnum = decision.flow_enum || "";
  const [flowEnum, setFlowEnum] = useState(decision.flow_enum);
  const [description, setDescription] = useState(decision.description);
  const [responseType, setResponseType] = useState(decisionObj.isTreeRecommendation ?
    buildResponseType(decision.recommendation) : buildResponseType(decision)
  );
  const [choices, setChoices] = useState(decisionObj.isTreeRecommendation ?
    buildRecommendationChoices(decision.recommendation) : buildDecisionChoices(decision)
  );
  const [assignedToUser, setAssignedToUser] = useState(
    decisionObj.isTreeRecommendation ?
      decision.recommendation.assigned_collaborator_email :
      decision.assigned_collaborator_email
  )
  const [submitted, setSubmitted] = useState(false);

  const disabledSubmit = () => isBlank(description);
  const isOpenEnded = isOpenEndedCallback(responseType);
  const onChangeResponseType = onChangeResponseTypeCallback(() =>
      decisionObj.isTreeRecommendation ? buildRecommendationChoices(decision.recommendation) : buildDecisionChoices(decision),
    isOpenEnded, setChoices, setResponseType
  );

  const [rateCompareChoices, setRateCompareChoices] = useState(isPresent(decision.rate_compare_choices))

  const updateData = () =>
    decisionObj.isTreeRecommendation ?
      recommendationUpdateData(decision, description, rateCompareChoices, responseType, choices, isOpenEnded, assignedToUser) :
      decisionUpdateData(description, responseType, rateCompareChoices, choices, isOpenEnded, assignedToUser)

  useEffect(() => {
    if (show) {
      const decisionObj = new Decision(decision)
      setResponseType(decisionObj.isTreeRecommendation ?
        buildResponseType(decision.recommendation) : buildResponseType(decision)
      );
      setChoices(decisionObj.isTreeRecommendation ?
        buildRecommendationChoices(decision.recommendation) : buildDecisionChoices(decision)
      );
      setRateCompareChoices(isPresent(decision.rate_compare_choices))
      setAssignedToUser(
        decisionObj.isTreeRecommendation ?
          decision.recommendation.assigned_collaborator_email :
          decision.assigned_collaborator_email
      )
      setFlowEnum(decision.flow_enum);
    }
  }, [show, decision])

  const updateAction = decisionObj.isTreeRecommendation ? updateRecommendation : updateDecision;

  const successActions = () => {
    setSubmitted(false);
    reloadContacts()
    resetDecisionSetData()
    onClose();
  }

  const changeFlowCallback = () => {
    if (flowEnum === prevFlowEnum) {
      successActions();
    } else {
      updateDecisionFlow({decision: {flow_enum: flowEnum}}, (status) => {
        if(status) {
          successActions()
        } else {
          setSubmitted(false);
        }
      })
    }
  }

  const submissionCallback = submitEntryCallback(updateAction, updateData(), setSubmitted, onClose, changeFlowCallback);

  return <Modal key={key} backdrop="static" size="lg" show={show} onHide={onClose}>
    <Modal.Body>
      <form>
        <ModalHeaderTitle {...{ onClose, title: 'Edit decision' }} />
        <DescriptionSection {...{ description, setDescription, submitted, className: 'mb-3' }}/>
        <FlowSwitcher {...{ flowEnum, onChangeFlowEnum: setFlowEnum, submitted, className: 'mt-2-negative' }} />
        <>
          <ResponseTypeSection {...{ responseType, onChangeResponseType, submitted }} />
          <RateCompareChoicesSection {...{ rateCompareChoices, setRateCompareChoices, hidden: isOpenEnded() }} />
          <ChoicesSection {...{ choices, setChoices, submitted, hidden: isOpenEnded() }} />
          <DecisionAssignToBlock {...{ assignedToUser, setAssignedToUser, className: 'mt-3' }} />
        </>
      </form>
    </Modal.Body>
    <ModalDoneFooter onClose={submissionCallback} disabled={disabledSubmit() || submitted} />
  </Modal>
};

const mapStateToProps = ({ modal }) => ({ decision: modal.decision })
export default connect(mapStateToProps, {
  updateDecisionFlow, updateRecommendation, updateDecision, reloadContacts, resetDecisionSetData
})(MultiEntryModal);

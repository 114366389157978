import React from "react";

export const SectionName = ({ treeNode, pointer = false }) =>
  <span className={`selectable-area decision-order text-truncate w-100 d-block fw-bold ${pointer && treeNode.isRoot ? 'pointer' : 'default-cursor'}`}>
    <span id={`${treeNode.tId}_span`} className={'node_name'}>{treeNode.name}</span>
  </span>

export default ({ treeNode }) =>
  <div className="d-flex align-items-center h-inherit justify-content-between w-100">
    <SectionName treeNode={treeNode} />
  </div>

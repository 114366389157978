import React, { useCallback } from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  isFirstWizardStep,
  isLastWizardStep,
  isTreeBuilder,
  isTreePreview,
  isWizardV2,
  isWizardV3
} from "../../helpers/wizard_helpers";
import {
  headerDispatchProps
} from "./index";
import {
  DeleteItemSpan,
  deleteObjectCallback,
  finishLaterCallback,
  headerActionsDispatchProps,
  startDecisionCallback
} from "./ActionsDropdown";
import BaseHamburgerBtn from "../../common/BaseHamburgerBtn";
import PlaybookHamburgerButton from "../../template_view/header/PlaybookHamburgerButton";
import DetailsHamburgerButton from "../../template_view/header/DetailsHamburgerButton";
import { HamburgerMenuIconBtn } from "../../common/dropdowns";
import HelpHamburgerButton from "../../template_view/header/HelpHamburgerButton";
import {
  cancelPreviewTreeCallback,
  goToHomepageCallback, hidePreviewBtn,
  previewTreeCallback,
  prevStepCallback,
  showPlaybookButton, wizardSetCallbacks, treeSidebarOpenCallback
} from "./helpers";

export const hideDetailsHeaderButton = (wizard) => !isTreePreview(wizard)

export const HamburgerDropdown = ({
                                    wizard, decision, tree, contactsData,
                                    stepRef, isTemplate = false,
                                    deleteDecision, deleteTemplate,
                                    previewWizardTree, buildWizardTree, prevWizardStep, prevTemplateWizardStep,
                                    forceReloadHomepageDecisions, forceReloadHomepageTemplates, updateTreeData,
                                    resetSidebars, resetWizard, resetTree, resetPlaybookNotes, resetSearchAndFilters, resetTreeDrivers,
                                    updateDecisionData, setTreeSidebarOpen,
                                    ...opts
                                  }) => {
  const history = useHistory();
  const {
    resetSetCallback, setObjectSlug, objectSlug
  } = wizardSetCallbacks({ ...opts, isTemplate, decision })
  const editTreeDecisionHandler = useCallback(treeSidebarOpenCallback(setTreeSidebarOpen), [])
  const goToHomepage = useCallback(goToHomepageCallback([isTemplate ? forceReloadHomepageTemplates : forceReloadHomepageDecisions, resetSearchAndFilters, resetSidebars, resetTree, resetWizard, resetPlaybookNotes, resetSetCallback], history), [wizard])
  const deleteDecisionHandler = useCallback(deleteObjectCallback({
    isTemplate, deleteTemplate, deleteDecision, history, setSlug: setObjectSlug(),
    actions: [forceReloadHomepageDecisions, forceReloadHomepageTemplates, resetSearchAndFilters, resetSidebars, resetWizard, resetTree, resetPlaybookNotes, resetSetCallback]
  }), [])
  const finishLaterHandler = useCallback(
    finishLaterCallback({
      stepRef, history, isTemplate, setSlug: setObjectSlug(), wizard,
      actions: [
        isTemplate ? forceReloadHomepageTemplates : forceReloadHomepageDecisions, resetSearchAndFilters,
        resetSidebars, resetWizard, resetTree, resetPlaybookNotes, resetSetCallback
      ]
    }), [wizard, setObjectSlug()])
  const completeWizardHandler = startDecisionCallback( {
    stepRef, history, isTemplate, setSlug: setObjectSlug(), wizard, slug: objectSlug(),
    actions: [
      isTemplate ? forceReloadHomepageTemplates : forceReloadHomepageDecisions,
      resetSearchAndFilters, resetSidebars, resetWizard, resetTree, resetPlaybookNotes,
      resetSetCallback, () => updateTreeData({ loaded: false })
    ]
  })
  const previewTree = useCallback(previewTreeCallback({
    wizard, tree, decision, contactsData, isTemplate,
    updateTreeData, updateDecisionData, previewWizardTree,
    stepRef
  }), [wizard])
  const buildTree = useCallback(
    cancelPreviewTreeCallback({
      wizard, tree, decision, stepRef,
      updateTreeData, resetSidebars, buildWizardTree, resetTreeDrivers,
      contactsData, isTemplate,
      updateDecisionData
    }), [wizard])
  const prevStep = useCallback(prevStepCallback(isTemplate, resetSidebars, prevTemplateWizardStep, prevWizardStep), [wizard])

  const hideStartBtn = () => {
    if (isWizardV2(wizard)) return false;
    if (isWizardV3(wizard)) return false;

    return !isLastWizardStep(wizard);
  }

  return <HamburgerMenuIconBtn>
    <BaseHamburgerBtn onSelect={isTreePreview(wizard) ? buildTree : prevStep} hidden={isFirstWizardStep(wizard) && !isTreePreview(wizard)} title="Back to previous step"/>
    <BaseHamburgerBtn onSelect={previewTree} hidden={hidePreviewBtn(wizard)} title="Preview decision"/>
    { hideDetailsHeaderButton(wizard) ? null : <DetailsHamburgerButton title="Details"/> }
    { showPlaybookButton(isTemplate, wizard, decision) ? <PlaybookHamburgerButton/> : null }
    <BaseHamburgerBtn onSelect={completeWizardHandler} hidden={hideStartBtn()} disabled={wizard.submit || wizard.disabledSubmit} title={isTemplate ? 'Done' : 'Start decision'}/>
    <BaseHamburgerBtn onSelect={editTreeDecisionHandler} disabled={wizard.submit} title="Edit decision tree"/>
    <BaseHamburgerBtn onSelect={finishLaterHandler} disabled={wizard.submit} title="Save and finish later"/>
    <BaseHamburgerBtn onSelect={deleteDecisionHandler} disabled={wizard.submit}>
      <DeleteItemSpan isTemplate={isTemplate}/>
    </BaseHamburgerBtn>
    <HelpHamburgerButton hidden={(!isTreePreview(wizard))}/>
    <BaseHamburgerBtn hidden={!isFirstWizardStep(wizard)} onSelect={goToHomepage} title="Go to homepage"/>
  </HamburgerMenuIconBtn>
}

const mapStateToProps = ({wizard, decision, template, tree, contacts}) => ({
  wizard, decision, template, tree,
  contactsData: contacts
});
const mapDispatchToProps = (dispatch) => ({
  ...headerDispatchProps(dispatch),
  ...headerActionsDispatchProps(dispatch)
});
const wrapper = React.forwardRef((props, ref) => <HamburgerDropdown {...props} stepRef={ref} />)
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(wrapper);

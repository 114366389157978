import React from 'react';
import { connect } from "react-redux";
import BaseHamburgerBtn, { BaseHamburgerItem, DeleteItemElement } from "../../common/BaseHamburgerBtn";
import { IconDropdown } from "../../common/dropdowns";
import { openEditNameModal } from "../modals/EditNameModal";
import { openModal } from "../../store/modals/actions";
export const ActionsDropdown = ({ btnId, openModal, deleteCallback }) => {

  const iconDropdownEvents = {
    editHandler: 'editHandler',
    deleteHandler: 'deleteHandler',
    saveAsNewHandler: 'saveAsNewHandler',
    createDecisionHandler: 'createDecisionHandler',
    generateExcelHandler: 'generateExcelHandler',
  }

  const iconDropdownSelect = (eventKey) => {
    switch (eventKey) {
      case iconDropdownEvents.editHandler:
        return openEditNameModal({ openModal });
      case iconDropdownEvents.deleteHandler:
        return deleteCallback()
      case iconDropdownEvents.saveAsNewHandler:
        return () => {}
      case iconDropdownEvents.createDecisionHandler:
        return () => {}
      case iconDropdownEvents.generateExcelHandler:
        return () => {}
    }
  }

  return <IconDropdown id={btnId} bsPrefix="h5 m-0 btn-link pt-2"
                       className="btn btn-secondary btn-lg-round d-flex p-0"
                       onSelect={iconDropdownSelect}>
    <BaseHamburgerBtn eventKey={iconDropdownEvents.saveAsNewHandler} title="Save as a new scenario"/>
    <BaseHamburgerBtn eventKey={iconDropdownEvents.editHandler} title="Edit scenario name"/>
    <BaseHamburgerBtn eventKey={iconDropdownEvents.createDecisionHandler} title="Create a decision"/>
    <BaseHamburgerBtn eventKey={iconDropdownEvents.generateExcelHandler} title="Generate Excel file"/>
    <BaseHamburgerItem eventKey={iconDropdownEvents.deleteHandler}>
      <DeleteItemElement name="scenario" />
    </BaseHamburgerItem>
  </IconDropdown>;
}
const mapStateToProps = ({}) => ({});
export default connect(mapStateToProps, { openModal })(ActionsDropdown);

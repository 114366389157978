import React, { useRef, useState } from 'react';
import { connect } from "react-redux";
import { updateTemplateNotes } from "../../../store/playbook_notes/actions";
import { RichTextEditor } from "../../../common/RichTextEditor";

export const NotesFormInput = ({
                                 header, value,
                                 onChange, placeholder,
                                 className = 'mb-3',
                                 inputRef
                              }) => {
  const reactQuillRef = useRef();
  const [formValue, setFormValue] = useState(value || '')

  return <div>
    <h3>{header}</h3>
    <div className={`d-flex ${className}`}>
      <RichTextEditor quillId={header}
                      className="w-100"
                      value={formValue} setValue={setFormValue} ref={reactQuillRef} placeholder={placeholder}
                      onFocus={() => {
                        inputRef.current = {
                          submit: () => {
                            reactQuillRef?.current?.blur()
                          }
                        }
                      }}
                      onBlur={() => {
                        inputRef.current = null;
                        onChange(formValue)
                      }}
      />
    </div>
  </div>
}

export const HowToMakeDecisionSection = ({ playbook_notes, updateNotes, inputRef }) =>
  <NotesFormInput header={'How to make this decision'}
                  placeholder={'Enter any comments or instructions for answering the decision question'}
                  value={playbook_notes.how_to} inputRef={inputRef}
                  onChange={(value) => updateNotes({ how_to: value })} />

export const TreeStructureSection = ({ playbook_notes, updateNotes, inputRef }) =>
  <NotesFormInput header={'Tree & structure'}
                  placeholder={'Enter any comments or instructions for the decision tree'}
                  value={playbook_notes.tree_structure} inputRef={inputRef}
                  onChange={(value) => updateNotes({ tree_structure: value })} />

export const CollaboratorsSection = ({ playbook_notes, updateNotes, inputRef }) =>
  <NotesFormInput header={'Who should be involved'}
                  placeholder={'Enter any comments or instructions for adding collaborators'}
                  value={playbook_notes.collaborators} inputRef={inputRef}
                  onChange={(value) => updateNotes({ collaborators: value })} />

export const DataSourcesSection = ({ playbook_notes, updateNotes, inputRef }) =>
  <NotesFormInput header={'Data sources'}
                  placeholder={'Enter any comments or instructions for data sources'}
                  value={playbook_notes.data_sources} inputRef={inputRef}
                  onChange={(value) => updateNotes({ data_sources: value })} />

export const OtherNotesSection = ({ playbook_notes, updateNotes, inputRef }) =>
  <NotesFormInput header={'Other notes'} className={''}
                  placeholder={'Enter any miscellaneous comments or instructions'}
                  value={playbook_notes.other} inputRef={inputRef}
                  onChange={(value) => updateNotes({ other: value })} />

export const PlaybookForm = (props) =>
  <div className="playbook-notes-container">
    <HowToMakeDecisionSection {...props} />
    <TreeStructureSection {...props} />
    <CollaboratorsSection {...props} />
    <DataSourcesSection addClass="p-3 border-bottom" {...props} />
    <OtherNotesSection {...props} />
  </div>

const mapStateToProps = ({ playbook_notes }) => ({ playbook_notes });
const mapDispatchToProps = (dispatch) => ({
  updateNotes: (data) => { dispatch(updateTemplateNotes(data)); }
});
const wrapper = React.forwardRef((props, ref) => <PlaybookForm {...props} inputRef={ref} />)
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(wrapper);

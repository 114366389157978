import React, { useRef, useState } from 'react';
import { connect } from "react-redux";
import PlaybookForm from "./sections/PlaybookForm";
import PlaybookDataSourcesSection from "../../template_view/side_panel/sections/PlaybookDataSourcesSection";
import PlaybookNotesSection from "../../template_view/side_panel/sections/PlaybookNotesSection";
import {
  mapDispatchToProps,
  PlaybookHeader,
  PlaybookPanelWrapper
} from "../../template_view/side_panel/TemplatePlaybookPanel";
import { isCollaborator } from "../../helpers/user_helpers";
import { isPlaybookNotesPresent } from "../../helpers/playbook_helpers";

export const DecisionPlaybookPanel = ({ tree, sidebar, decision, current_user, openSideBar, addClass, position }) => {
  if (!isPlaybookNotesPresent(decision)) return null;

  const inputRef = useRef(null)
  const [edit, setEdit] = useState(false)

  return <PlaybookPanelWrapper sidebar={sidebar} preview={!isCollaborator(current_user)}
                               openSideBar={openSideBar}
                               inputRef={inputRef} edit={edit} setEdit={setEdit}
                               addClass={addClass} position={position}>
    <PlaybookHeader edit={edit} tree={tree}>
      { edit ? <PlaybookForm ref={inputRef} /> : <PlaybookNotesSection /> }
    </PlaybookHeader>
    <PlaybookDataSourcesSection edit={edit} isTemplate={false} isPlaybook={true} />
  </PlaybookPanelWrapper>
}
const mapStateToProps = ({ tree, sidebar, decision, current_user }) => ({ tree, sidebar, decision, current_user });
export default connect(mapStateToProps, mapDispatchToProps)(DecisionPlaybookPanel);

import React, {Fragment} from 'react'
import { connect } from "react-redux";
import ZTree from "./ZTree";
import { DoneBtnW100 } from "../../common/DoneButton";
import { isMobile } from "react-device-detect";
import { Loader } from "../../common/Loader";
import { openModal, transferDataAndCloseModal } from "../../store/modals/actions";
import AddSectionModal, { showAddSectionModal } from "../modals/AddSectionModal";
import EditSectionModal, { showEditSectionModal } from "../modals/EditSectionModal";
import { Button } from "react-bootstrap";
import {
  createDecisionSetSection,
  updateDecisionSetSection,
  dragDecisionSetSection,
  copyDecisionSetSection
} from "../../store/decision_set/actions";
import { createTemplateSetSection, updateTemplateSetSection, dragTemplateSetSection } from "../../store/template_set/actions";
import {isBlank} from "../../helpers/common";
import {disableCopyBtn, resetCopyPaste} from "../tree_view/DriverActions";
import {updateDecisionSetData} from "../../store/decision_set/common_actions";

const Footer = ({ onClose }) =>
  <div className="row">
    <div className="col-12">
      <DoneBtnW100 onClose={onClose} />
    </div>
  </div>

const createSectionAction = (isTemplateSet, createDecisionSetSection, createTemplateSetSection) =>
  isTemplateSet ? createTemplateSetSection : createDecisionSetSection

const updateSectionAction = (isTemplateSet, updateDecisionSetSection, updateTemplateSetSection) =>
  isTemplateSet ? updateTemplateSetSection : updateDecisionSetSection

const dragSectionAction = (isTemplateSet, dragDecisionSetSection, dragTemplateSetSection) =>
  isTemplateSet ? dragTemplateSetSection : dragDecisionSetSection

const onCopySection = (set, updateTreeData) => updateTreeData({ copied_ztree_node: set.selected_ztree_node, selected_ztree_node: {}});

const PanelTreeEditor = ({
                           set, modal,
                           preview = false, isTemplateSet = false,
                           isDSight= false,
                           onClose, openModal, transferDataAndCloseModal, updateDecisionSetSection,
                           updateTemplateSetSection, createDecisionSetSection, createTemplateSetSection,
                           dragDecisionSetSection, dragTemplateSetSection, updateDecisionSetData, copyDecisionSetSection
                        }) => {
  if(!set.loaded) return <Loader />;

  const onAddSection = (e) => {
    e.preventDefault();
    openModal({ type: 'SetSectionsPanelAddSectionModal', set_section: { name: '' }})
  }
  const onPasteSection = () => {
    const { slug } = set.copied_ztree_node
    copyDecisionSetSection({ slug }, (success) => {
      if(success) resetCopyPaste(updateDecisionSetData)
    });
  }

  return (
    <div className="pt-0 p-3">
      <div className="tree-container mb-2">
        <div className={`tree-body edit-mode`}>
          <ZTree {...{ set, isTemplateSet, preview, isDSight }}
                 dragSectionAction={dragSectionAction(isTemplateSet, dragDecisionSetSection, dragTemplateSetSection)} />
        </div>
      </div>
      <div className={`${(isMobile ? "footer-done-space" : "")}`}>
        <div className="row mb-2 px-2">
          <div className="col mw-fit-content p-0">
            <Button onClick={onAddSection} className="btn-secondary w-100">Add a section</Button>
          </div>
          {
            isTemplateSet ?
              null:
              <Fragment>
                <div className="col-12 col-md-auto mw-fit-content mb-2 p-0 px-md-2">
                  <Button className="btn-secondary w-100" disabled={disableCopyBtn(set)} onClick={() => onCopySection(set, updateDecisionSetData)}>Copy</Button>
                </div>
                <div className="col-12 col-md-auto mw-fit-content mb-2 p-0">
                  <Button className="btn-secondary w-100" disabled={isBlank(set.copied_ztree_node)} onClick={onPasteSection}>Paste</Button>
                </div>
              </Fragment>
          }
        </div>
        <Footer onClose={onClose} />
      </div>
      <div className="modals">
        <AddSectionModal key={'set-sections-add-section'}
                         show={showAddSectionModal(modal)}
                         title={'Add a section'}
                         onClose={transferDataAndCloseModal}
                         createSectionAction={createSectionAction(isTemplateSet, createDecisionSetSection, createTemplateSetSection)} />
        <EditSectionModal key={`set-sections-add-section-${modal.slug}`}
                          show={showEditSectionModal(modal)}
                          title={'Edit section name'}
                          updateSectionAction={updateSectionAction(isTemplateSet, updateDecisionSetSection, updateTemplateSetSection)}
                          onClose={transferDataAndCloseModal} />
      </div>
    </div>
  )
}
const mapStateToProps = ({ modal, decision_set, template_set }, { isTemplateSet }) => ({
  modal, set: isTemplateSet ? template_set : decision_set
});
export default connect(mapStateToProps, {
  openModal, transferDataAndCloseModal, updateDecisionSetSection, updateTemplateSetSection,
  createDecisionSetSection, createTemplateSetSection, dragDecisionSetSection, dragTemplateSetSection,
  updateDecisionSetData, copyDecisionSetSection
})(PanelTreeEditor);

import React, { useCallback, useEffect } from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  isDefaultReportFilters,
  selectedPropertyOptionsByProperty,
  selectedOpenPropertyOptionsByProperty
} from "../../../helpers/filter_helpers";
import { isGuest } from "../../../helpers/home_helpers";
import SearchReportFilters from "../filters/SearchReportFilters";
import { isBlank, isPresent, pluralize, toSentence } from "../../../helpers/common";
import {
  isHomepageSectionLoading,
  needToLoadHomepageSection,
  loadReportsData,
  startNewReport
} from "../../../store/homepage/actions";
import { ReportTile } from "./tiles/ReportTile";
import { homeSearchState } from "../SearchSection";
import { checkSearchChangeEffect } from "../../../helpers/callbacks_helpers";
import Button from "react-bootstrap/Button";
import { BtnDropdownToggleInlineShadowText } from "../../../common/dropdowns";
import { BaseDropdownBtn } from "../../../common/BaseHamburgerBtn";
import { StartFromScratchBlock } from "../create_decision_tab/StartFromScratch";
import ReportProperty from "../../../models/report_property";
import { Loader } from "../../../common/Loader";
import { Pagination } from "../../../common/Pagination";
import NullSearchResults from "../helpers/NullSearchResults";
import SearchRow, { resetSearchAction } from "../helpers/SearchRow";

export const SortByTypes = {
  MOST_RECENTLY_ADDED: 'most_recent_activity',
  DECISION_USAGE: 'decision_usage',
  COST: 'cost'
};

const SortByData = [
  { value: SortByTypes.MOST_RECENTLY_ADDED, label: 'Most recently added' },
  { value: SortByTypes.DECISION_USAGE, label: 'Decision usage' },
  { value: SortByTypes.COST, label: 'Cost' }
];

const resetPropertyOptionsByProperty = (home, property, loadReportsData) => {
  const allPropertyOptionsIdsByProperty = property.options.map((option) => option.id)
  const filteredSelectedProperties = home.property_options.filter((property_option_id) => !allPropertyOptionsIdsByProperty.includes(property_option_id));
  loadReportsData({ property_options: filteredSelectedProperties })
};

const resetOpenPropertyOptionsByProperty = (home, property, loadReportsData) => {
  const allPropertyOptionsIdsByProperty = property.options[0]?.report_property_options?.map((option) => option.id) || []
  const filteredSelectedOpenProperties = home.report_property_options.filter((id) => !allPropertyOptionsIdsByProperty.includes(id));
  loadReportsData({ report_property_options: filteredSelectedOpenProperties })
};

const resetAllFilters = (setSearchQuery, setLoading, action) => {
  setLoading(true);
  setSearchQuery('');
  action({ property_options: [], search: '', report_property_options: [] })
};

const ReportsCount = ({ home }) =>
  <div className="reports-count text-nowrap py-3 me-3">
    {pluralize(home.reports.total_count, 'report')}
  </div>

const SelectedFiltersPill = ({ property, home, loadReportsData }) => {
  const openProperty = new ReportProperty(property).isOpen;
  const selectedPropertyOptions = openProperty ? selectedOpenPropertyOptionsByProperty(property, home) :
    selectedPropertyOptionsByProperty(property, home)
  const resetCallback = openProperty ? resetOpenPropertyOptionsByProperty : resetPropertyOptionsByProperty
  return <Button bsPrefix="btn btn-secondary bg-secondary h5 h-36 rounded-pill text-black float-start py-1 px-2 me-1"
                 onClick={() => resetCallback(home, property, loadReportsData)}
                 hidden={isBlank(selectedPropertyOptions)}>
    <span className="pe-1">{property.name}:</span>
    <span className="fw-normal">{toSentence(selectedPropertyOptions)}</span>
    <i className="fas fa-times ms-1" />
  </Button>
}

export const SelectedFiltersPills = ({ home, org_report_properties, loadReportsData }) =>
  <div className="col filter-pills">
    {
      org_report_properties.available_report_properties.map((property, i) =>
        <SelectedFiltersPill key={`selected-property-${property.slug}`} property={property} home={home} loadReportsData={loadReportsData} />
      )
    }
  </div>

const SelectedFiltersContainer = ({ home, org_report_properties, loadReportsData }) =>
  isDefaultReportFilters(home) ? null :
    <div className="row mb-2">
      <SelectedFiltersPills {...{ home, org_report_properties, loadReportsData }} />
    </div>

const SortByCriteria = ({ home, current_org, onChangeSortingCriteria }) => {
  const filterCostOption = (data) => current_org.allow_report_cost ? true : data.value !== SortByTypes.COST

  return <div className="ms-auto text-end py-3">
    <div className="d-inline-flex me-1">Sort by</div>
    <BtnDropdownToggleInlineShadowText id="sort-by-dropdown" bsPrefix="pe-0" onSelect={onChangeSortingCriteria}
                                       title={SortByData.find((sortBy) => sortBy.value === home.sort_order)?.label?.toLowerCase()}>
      {SortByData.filter(filterCostOption).map((sortBy) =>
        <BaseDropdownBtn key={`sort-order-${sortBy.value}`} eventKey={sortBy.value} title={sortBy.label}/>
      )}
    </BtnDropdownToggleInlineShadowText>
  </div>;
}

export const ReportsSection = ({ home, org_report_properties, current_org, current_user,
                                 startNewReport, loadReportsData }) => {
  if (isGuest(current_user) || !org_report_properties.loaded) return null;
  const history = useHistory()

  const {
    searchQuery, setSearchQuery,
    loading, setLoading, resetSearch
  } = homeSearchState(home)

  const filtersUsed = isPresent(searchQuery) || !isDefaultReportFilters(home);
  const showNullResults = filtersUsed && isBlank(home.reports.data);

  useEffect(() => {
    if (needToLoadHomepageSection(home, 'reports')) loadReportsData();
  }, [home.reports.loaded])

  checkSearchChangeEffect(loading, setLoading, searchQuery, loadReportsData)

  const ReportsList = useCallback(() => {
    if (isHomepageSectionLoading(home, 'reports')) return null;

    if(showNullResults) return <NullSearchResults home={home} resetAllFilters={() => resetAllFilters(setSearchQuery, setLoading, loadReportsData)} />

    return home.reports.data.map(r =>
      <ReportTile report={r} key={`homepage-report-tile-${r.slug}`} home={home}/>
    );
  }, [home, searchQuery])

  const onChangeSortingCriteria = (eventKey) => loadReportsData({ search: searchQuery, sort_order: eventKey })
  const handlePageClick = (new_page) => loadReportsData({ current_page: new_page })

  const onStart = () => {
    startNewReport({}, slug => {
      if (isPresent(slug)) history.push(`/reports/${slug}/report_wizard`)
    })
  }

  const hasReportProperties = org_report_properties.available_report_properties.length > 0;

  return <>
    <div className="row">
      <div className="col">
        <h1>Reports</h1>
      </div>
    </div>
    <SearchRow rowStyles={`${isDefaultReportFilters(home) ? '' : 'mb-2'}`}
               fullWidth={!hasReportProperties}
               {...{ searchQuery, setSearchQuery, placeholder: 'Search reports' }}
               resetSearchAction={() => resetSearchAction(setSearchQuery, resetSearch)}>
      {hasReportProperties && <SearchReportFilters loadAction={loadReportsData}/>}
    </SearchRow>
    <SelectedFiltersContainer {...{ home, org_report_properties, loadReportsData }} />
    <div className="row">
      <div className="col d-flex">
        <ReportsCount home={home}/>
        <SortByCriteria home={home} current_org={current_org} onChangeSortingCriteria={onChangeSortingCriteria}/>
      </div>
    </div>
    <div className="row loading mb-3" hidden={!home.reports.loading}>
      <div className="col">
        <Loader/>
      </div>
    </div>
    <div className="row" hidden={home.reports.loading}>
      <StartFromScratchBlock title={"Add a report"}
                             onClick={onStart}
                             addClass={`py-5`}
                             showNullResults={showNullResults} />
      <ReportsList />
    </div>
    <div className="row pb-4" hidden={home.reports.loading || home.reports.total_pages === 1}>
      <Pagination page={home.reports.current_page} totalPages={home.reports.total_pages} setPage={handlePageClick}/>
    </div>
  </>
}

const mapStateToProps = ({ home, current_user, org_report_properties, current_org }) => ({ home, current_user, org_report_properties, current_org });
const mapDispatchToProps = (dispatch) => ({
  loadReportsData: (data) => dispatch(loadReportsData(data)),
  startNewReport: (data = {}, callback) => dispatch(startNewReport(data, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ReportsSection);

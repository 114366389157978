import React, { Fragment, useEffect, useState } from 'react';
import { connect } from "react-redux";
import { saveTemplateWizardStepData, updateWizardData } from "../../../store/wizard/actions";
import { Button, Table } from 'react-bootstrap'
import { isBlank, isPresent } from "../../../helpers/common";
import { isLastStep, WIZARD_STEPS, wizardStepDataBy } from "../../../helpers/wizard_helpers";
import {
  ChoiceEntryType,
  DESCRIPTION_INPUT_LIMIT
} from "../../../models/decision";
import Help from "../../help/index";
import {
  DEFAULT_CHOICES,
  isOpenEndedCallback,
  onChangeChoiceCallback,
  onChangeResponseTypeCallback, onPasteChoiceCallback
} from "../../../tree_wizard/steps_wizard/steps/FramingDecisionStep";
import { RichMutedText, RichTextEditor } from "../../../common/RichTextEditor";
import CharCounter from "../../../common/CharCounter";
import SubmitStepButton from "./SubmitStepButton";
import DecisionAssignToBlock from "../../../tree_view/modals/helpers/DecisionAssignToBlock";
import FlowSwitcher from "../../../tree_view/modals/entry_modal/FlowSwitcherSection";
import RateCompareChoicesSection from "../../../tree_view/modals/entry_modal/RateCompareChoicesSection";
import ResponseTypeSection from "../../../tree_view/modals/entry_modal/ResponseTypeSection";

export const ChoiceRow = ({
                     index, description, submitState, newChoice,
                     onChangeChoice, removeChoice, setNewChoice, onPasteChoice
                   }) =>
  <div id={`decisionChoice-${index}`} className="d-flex mt-1 align-items-center mb-2">
    <input className="form-control"
           placeholder="Enter a choice" readOnly={submitState}
           value={description} onChange={(e) => onChangeChoice(e.target.value, index)}
           onPaste={(e) => onPasteChoice(e, index)}
           ref={inputElement => {
             if (inputElement && newChoice) {
               inputElement.focus();
               setNewChoice(false)
             }
           }}
    />
    <span className="btn btn-light btn-sm btn-sm-round text-danger ms-2 px-2" onClick={() => removeChoice(index)}>
      <i className="fas fa-times w-100"/>
    </span>
  </div>

export const FramingDecisionForm = ({
                                      submitState, stepRef, playbook_notes, decision,
                                      wizard, template, showTitleNotes = true,
                                      onChangeDisabled = (_) => false, isTemplate = false
                                    }) => {
  const stepData = wizardStepDataBy(wizard, WIZARD_STEPS.tree_framing.key)
  const defaultChoices = () =>
    isBlank(stepData?.choices) ? DEFAULT_CHOICES : stepData?.choices

  const [description, setDescription] = useState(template.description || '')
  const [responseType, setResponseType] = useState(template.choice_entry_widget_type || ChoiceEntryType.OPEN_ENDED)
  const [choices, setChoices] = useState(defaultChoices())
  const [newChoice, setNewChoice] = useState(false)
  const [titleNotes, setTitleNotes] = useState(playbook_notes?.decision_title || '')

  const hideHideRecommendation = isPresent(stepData.hide_recommendation)
  const isOpenEnded = isOpenEndedCallback(responseType)
  const onChangeChoice = onChangeChoiceCallback(choices, setChoices)
  const onChangeResponseType = onChangeResponseTypeCallback(defaultChoices, isOpenEnded, setChoices, setResponseType)
  const [showChar, setShowChar] = useState(false);
  const [flowEnum, setFlowEnum] = useState(template?.flow_enum || '');

  const [rateCompareChoices, setRateCompareChoices] = useState(isPresent(template.rate_compare_choices))
  const [assignedToUser, setAssignedToUser] = useState(stepData?.assign_to)
  const onPasteChoice = onPasteChoiceCallback(choices, setChoices, setNewChoice, onChangeChoice);

  useEffect(() => {
    onChangeDisabled(isBlank(description))
  }, [description])

  const addNewChoice = () => {
    setNewChoice(true)
    setChoices([...choices, ''])
  }

  const removeChoice = (index) => {
    $(`#decisionChoice-${index}`).removeClass('d-flex').addClass('d-none');
    onChangeChoice('', index)
  }

  const onChangeFlowEnum = (flow) => setFlowEnum(flow)

  stepRef.current.isOpenEnded = isOpenEnded
  stepRef.current.choices = choices
  stepRef.current.description = description
  stepRef.current.responseType = responseType
  stepRef.current.titleNotes = titleNotes
  stepRef.current.rateCompareChoices = rateCompareChoices
  stepRef.current.assignedToUser = assignedToUser
  if(!isTemplate) {
    stepRef.current.flowEnum = flowEnum
  }

  return <Fragment>
    <div className="w-100 mb-3">
      <h3>Decision</h3>
      <div className='mb-2'>
        <CharCounter show={showChar} field={description} limit={DESCRIPTION_INPUT_LIMIT}/>
        <input className="form-control"
               id="decisionDescription"
               placeholder="Enter the decision question or title"
               value={description}
               readOnly={submitState}
               maxLength={DESCRIPTION_INPUT_LIMIT}
               onBlur={() => setShowChar(false)}
               onFocus={() => setShowChar(true)}
               onChange={(e) => setDescription(e.target.value)}
        />
      </div>
    </div>
    <div className="w-100 mb-3" hidden={!showTitleNotes}>
      <h3>Title notes</h3>
      {
        decision && isPresent(playbook_notes.decision_title) ?
          <div className={'mb-2'}>
            <RichMutedText text={playbook_notes.decision_title}/>
          </div> :
          <RichTextEditor quillId={'Title notes'} value={titleNotes} setValue={setTitleNotes}
                          placeholder="Enter any comments or instructions for the decision question"/>
      }
    </div>
    {!isTemplate ? <FlowSwitcher {...{ flowEnum, onChangeFlowEnum, submitted: submitState, hidden: hideHideRecommendation }} /> : null}

    <ResponseTypeSection {...{ responseType, onChangeResponseType, submitted: wizard.submit || submitState, classNames: 'mb-3' }} />
    <RateCompareChoicesSection classNames={'mb-3 mt-2-negative'}
                        {...{ rateCompareChoices, setRateCompareChoices, hidden: responseType === ChoiceEntryType.OPEN_ENDED }}
    />
    <div className="w-100 mb-3" hidden={responseType === ChoiceEntryType.OPEN_ENDED}>
      <h3>Choices</h3>
      {
        choices.map((description, index) =>
          <ChoiceRow key={`decision-choice-${index}`}
                     {...{ wizard, index, description, submitState, newChoice, setNewChoice, onChangeChoice, removeChoice, onPasteChoice }}/>
        )
      }
      <Button onClick={addNewChoice} disabled={submitState} className="btn-secondary w-100">Add another choice</Button>
    </div>
    {
      decision ? <DecisionAssignToBlock {...{ assignedToUser, setAssignedToUser, className: 'mb-3 mt-2' }} /> : null
    }
  </Fragment>
}

const FramingDecisionStep = ({
                               wizard, template, playbook_notes,
                               saveTemplateWizardStepData, updateWizardData,
                               stepRef
                             }) => {
  const [submitState, setSubmitState] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const submitStep = (nav_data = {}, callback = () => {}) => {
    setSubmitState(true)
    saveTemplateWizardStepData(WIZARD_STEPS.tree_framing.key, {
      complete: isLastStep(wizard),
      ...nav_data,
      wizard: { step_index: wizard.step_index },
      choices: stepRef.current.isOpenEnded() ? [] : stepRef.current.choices,
      decision_title: stepRef.current.titleNotes,
      template: {
        description: stepRef.current.description,
        choice_entry_widget_type: stepRef.current.responseType,
        rate_compare_choices: stepRef.current.rateCompareChoices
      },
      next_step: true
    }, callback)
  }

  stepRef.current = {
    submitStep: (additional_data = {}, callback = () => false) => {
      if (disabled) additional_data.finish_later = true

      submitStep(additional_data, (success, wizardData) => {
        setSubmitState(false)
        callback(success, additional_data.finish_later, wizardData)
      })
    }
  }
  useEffect(() => {
    updateWizardData({ disabledSubmit: disabled })
  }, [disabled])

  return <div className="d-flex">
    <div className="mx-auto">
      <div className={`bg-white rounded p-3 mt-3 mb-5 tree_wizard d-inline-block`}>
        <Table borderless className="mb-2">
          <tbody>
          <tr>
            <td className="d-flex p-0">
              <h1 className="d-table-cell pe-2">Define the decision and select how to answer it.</h1>
              <div className="d-table-cell ms-auto">
                <Help/>
              </div>
            </td>
          </tr>
          </tbody>
        </Table>

        <FramingDecisionForm showTitleNotes={true} wizard={wizard} template={template} playbook_notes={playbook_notes}
                             submitState={wizard.submit || submitState} stepRef={stepRef} isTemplate={true}
                             onChangeDisabled={setDisabled}/>

        <div className="w-100">
          <SubmitStepButton onClick={() => submitStep()} disabled={wizard.submit || submitState || disabled}/>
        </div>
      </div>
    </div>
  </div>
}
const mapStateToProps = ({ wizard, template, playbook_notes }) => ({ wizard, template, playbook_notes });
const mapDispatchToProps = (dispatch) => ({
  saveTemplateWizardStepData: (step, data, callback) => dispatch(saveTemplateWizardStepData(step, data, callback)),
  updateWizardData: (data) => dispatch(updateWizardData(data))
});
const wrapper = React.forwardRef((props, ref) => <FramingDecisionStep {...props} stepRef={ref}/>)
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(wrapper);

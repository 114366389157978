import {
  otherChoicesBlock, enteredBySection
} from "./sections/commonBlocks";
import {currencyFormat, isBlank} from "../../helpers/common";
import Decision from "../../models/decision";
import React from "react";
import {dataSourcesBlockSection} from "./sections/DataSources";
import {dSightAnalysisBlock} from "./sections/DSightBlocks";
import {
  baseBlockSection,
  baseHeadParagraph,
  paragraphBorder
} from "./sections/wrappers";
import {finalDecisionSection} from "./sections/FinalDecision";
import Recommendation from "../../models/recommendation";
import {HeadingLevel} from "docx";

export const RecommendationSection = (decision, drivers, data_sources) => {
  if (isBlank(decision.recommendation)) return [];

  const recommendation = decision.recommendation;
  const decisionObj = new Decision(decision, drivers);
  const recommendationObj = new Recommendation(recommendation);

  if (!decisionObj.isRecordedOrRecommended || isBlank(recommendation.recommended_at)) return [];

  const additionalSections = () => {
    if(decisionObj.isDecisionRecorded) return [];

    return [
      ...dSightAnalysisBlock(decision),
    ]
  }

  return [
    baseHeadParagraph({text: 'Recommendation', heading: decisionObj.isDecisionRecorded ? HeadingLevel.HEADING_2 : HeadingLevel.HEADING_5}),
    ...finalDecisionSection({obj: recommendationObj, instance: 11}),
    ...baseBlockSection({header: 'Rationale', text: recommendation.final_decision_reasons, instance: 12}),
    ...baseBlockSection({header: 'Next steps', text: recommendation.next_steps_description, instance: 13}),
    ...baseBlockSection({header: 'Expected results', text: recommendation.expected_results, instance: 14}),
    ...baseBlockSection({header: 'Expected opportunity value', text: currencyFormat(recommendation.expected_opportunity)}),
    ...baseBlockSection({header: 'Expected investment or cost', text: currencyFormat(recommendation.expected_investment)}),
    enteredBySection({user: recommendation.user, time: recommendation.recommended_at}),
    ...otherChoicesBlock({obj: recommendationObj, isRecommendation: true}),
    ...additionalSections(),
    ...dataSourcesBlockSection({data_sources, fromRecommendation: true}),
    paragraphBorder(),
  ]
}
import React from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetSidebars, setDecisionOrderSidebarOpen, setDecisionSidebarOpen } from "../../store/sidebar/actions";
import { startNewDecision } from "../../store/homepage/actions";
import { successActions } from "../../helpers/common";
import { resetTree } from "../../store/tree/common_actions";
import PlaybookHamburgerButton from "../../template_view/header/PlaybookHamburgerButton";
import DetailsHamburgerButton from "../../template_view/header/DetailsHamburgerButton";
import BaseHamburgerBtn from "../../common/BaseHamburgerBtn";
import { HamburgerMenuIconBtn } from "../../common/dropdowns";
import { isPlaybookNotesPresent } from "../../helpers/playbook_helpers";
import DecisionTreeHamburgerButton from "../../template_view/header/DecisionTreeHamburgerButton";
import { openModal } from "../../store/modals/actions";
import {
  addToTemplateSetCallback,
  getTemplateSetParamsFromTemplate,
  startDecisionFromTemplateCallback,
  startDecisionFromUnstartedTemplateCallback,
  templateSetParams
} from "./index";
import { openDecisionSetModal } from "../../homepage/modals/DecisionSetModal";
import { redirectToHomeCallback } from "../../helpers/decision_helpers";
import { isDecisionStarted, startDecision } from "../../store/decision_set/actions";
import DecisionSetPanelHamburgerButton from "../../template_view/header/DecisionSetPanelHamburgerButton";
import { addTemplateToSet } from "../../store/template_set/actions";
import { isObsoleteDSightTemplate } from "../../helpers/template_helpers";
import Decision from "../../models/decision";
import RateCompareHamburgerButton from "../../tree_view/header/buttons/RateCompareHamburgerButton";
import { resetDecisionSetData } from "../../store/decision_set/common_actions";

export const HamburgerDropdown = ({
                                    template,
                                    openDecisionOrderSideBar,
                                    resetSidebars, resetTree,
                                    ...opts
                                  }) => {
  const history = useHistory();

  const { parentTemplateSet, isParentTemplateSet } = getTemplateSetParamsFromTemplate(template)
  const {
    templateSet, setTemplatePreview,
    decisionSet, isDecisionSet,
    decisionSlug, isUnstartedTemplateInSet
  } = templateSetParams()

  const goToPrevPage = () => {
    successActions(true, [resetSidebars, resetTree])

    setTemplatePreview ?
      history.push(`/template_sets/${templateSet}`) :
      isDecisionSet ?
        history.push(`/decision_sets/${decisionSet}`) :
        isParentTemplateSet ?
          history.push(`/template_sets/${parentTemplateSet}/preview`) :
          redirectToHomeCallback(true, history)
  }
  const goToPrevTitle =
    setTemplatePreview ? 'Go to Decision flow' :
      isDecisionSet ? 'Go to Decision flow' :
        isParentTemplateSet ? 'Go to Preview decision flow' :
          'Go to homepage'

  return <HamburgerMenuIconBtn>
    <RateCompareHamburgerButton hidden={new Decision(template).isRateAndCompareDriverChoicesHidden} />
    <DecisionTreeHamburgerButton />
    <DetailsHamburgerButton />
    {
      isPlaybookNotesPresent(template) ? <PlaybookHamburgerButton/> : null
    }
    {
      isParentTemplateSet || isUnstartedTemplateInSet ? <DecisionSetPanelHamburgerButton /> : null
    }
    {
      setTemplatePreview ?
        <BaseHamburgerBtn onSelect={addToTemplateSetCallback({ ...opts, template, templateSet, history })} title="Add to decision flow" /> :
        isUnstartedTemplateInSet ?
          <BaseHamburgerBtn onSelect={startDecisionFromUnstartedTemplateCallback({ ...opts, decisionSlug, history, decisionSet, template })} title="Start decision" /> :
          isDecisionSet ?
            <BaseHamburgerBtn onSelect={startDecisionFromTemplateCallback({ ...opts, template, history, resetTree, decisionSet })} title="Start decision" /> :
            isParentTemplateSet ?
              <BaseHamburgerBtn onSelect={() => openDecisionSetModal(opts)} title="Create a decision flow" /> :
              isObsoleteDSightTemplate(template) ? null :
                <BaseHamburgerBtn onSelect={startDecisionFromTemplateCallback({ ...opts, template, history, resetTree })} title="Create decision" />
    }
    <BaseHamburgerBtn onSelect={goToPrevPage} title={goToPrevTitle} />
  </HamburgerMenuIconBtn>
}

const mapStateToProps = ({ template, template_set }) => ({ template, template_set });
const mapDispatchToProps = (dispatch) => ({
  openModal: (data) => dispatch(openModal(data)),
  openDecisionDetailsSideBar: (flag) => dispatch(setDecisionSidebarOpen(flag)),
  openDecisionOrderSideBar: (flag) => dispatch(setDecisionOrderSidebarOpen(flag)),
  startNewDecision: (data = {}, callback) => { dispatch(startNewDecision(data, callback)) },
  isDecisionStarted: (data, callback) => dispatch(isDecisionStarted(data, callback)),
  startUnstartedDecision: (data, callback) => dispatch(startDecision(data, callback)),
  resetTree: () => { dispatch(resetTree()) },
  resetSidebars: () => { dispatch(resetSidebars()) },
  resetDecisionSetData: () => dispatch(resetDecisionSetData()),
  addTemplateToSet: (templateSlug, setSlug, callback) => dispatch(addTemplateToSet(templateSlug, setSlug, callback))
});
export default connect(mapStateToProps, mapDispatchToProps)(HamburgerDropdown);

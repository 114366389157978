import { isPresent } from "../../helpers/common";

const periodValues = (rows, period, driver) => {
  return rows.filter(row => row.isInTimePeriod(period)).map(row => driver.fetchValue(row))
}

export const fetchAggregatedValuesFor = (rows, driver, period, config) => {
  const driverRules = driver.driverRules;
  const values = periodValues(rows, period, driver);

  if(values.length === 1) return { value: values[0], format: true }

  if (isPresent(driverRules)) {
    switch(driverRules.aggregate_within[period.timeScaleKey]) {
      case 'N/A':
      case 'none':
      case 'do not aggregate at all':
        return { value: 'do not aggregate at all', format: false }
      case 'sum':
        return { value: values.reduce((acc, value) => acc + parseFloat(value), 0), format: true }
      case 'Average':
      case 'avg':
        return { value: values.reduce((acc, value) => acc + parseFloat(value), 0) / values.length, format: true }
      case 'max':
        return { value: Math.max(...values), format: true }
      case 'min':
        return { value: Math.min(...values), format: true }
      case 'Value Sales / Unit Sales':
      case 'Ratio(Value Sales,Unit Sales)':
        const valueSalesDr = config.allFactsColumns.find(col => col.name === 'Value Sales')
        const unitSalesDr = config.allFactsColumns.find(col => col.name === 'Unit Sales')
        const aggregatedValues = fetchAggregatedValuesFor(rows, valueSalesDr, period, config).value
        const aggregatedUnites = fetchAggregatedValuesFor(rows, unitSalesDr, period, config).value
        if (aggregatedUnites === 0) return { value: 0, format: true };
        return {
          value: aggregatedValues / aggregatedUnites,
          format: true
        }
      case 'Sum weighted by values':
        const valueSales = config.allFactsColumns.find(col => col.name === 'Value Sales')
        const valueSalesValues = periodValues(rows, period, valueSales)
        const sumDriverOnValues = values.reduce((acc, value, index) => acc + (parseFloat(value) * parseFloat(valueSalesValues[index])), 0)
        const sumValues = valueSalesValues.reduce((acc, value) => acc + parseFloat(value), 0)
        return {
          value: sumDriverOnValues / sumValues,
          format: true
        }
      case 'Weighted on(Units)':
      case 'Weighted on Units':
        const unitSales = config.allFactsColumns.find(col => col.name === 'Unit Sales')
        const units_values = periodValues(rows, period, unitSales)
        const sumDriverOnUnits = values.reduce((acc, value, index) => acc + (parseFloat(value) * parseFloat(units_values[index])), 0)
        const sumUnits = units_values.reduce((acc, value) => acc + parseFloat(value), 0)
        return {
          value: (sumDriverOnUnits / sumUnits),
          format: true
        }
      default:
        return { value: `Unsupported for now : ${driverRules.aggregate_within[period.timeScaleKey]}`, format: false }
    }
  }
  return { value: false, format: true }
}

export class ForecastScenarioRow {
  constructor({ id, attributes }, scenario, selectedDriver = null) {
    this.id = id;
    this.attributes = attributes;
    this.scenario = scenario;
    this.config = scenario.config;
    this.selectedDriver = selectedDriver;
  }

  get timePeriod() {
    return this.config.dataTimeScale.timePeriods.find(period => period.id === this.attributes.forecast_time_period_id)
  }

  isInTimePeriod(timePeriod) {
    if (this.timePeriod.startDate < timePeriod.startDate) return false;
    if (this.timePeriod.endDate > timePeriod.endDate) return false;

    return true;
  }

  get cmus() {
    return this.attributes.cmus;
  }

  get cmusGroupKey() {
    return [
      ...this.cmus,
      this.selectedDriver?.id
    ].filter(isPresent).join('_')
  }

  get rowDriverId() {
    return `${this.id}_${this.selectedDriver?.id}`
  }

  fetchDriverData(column) {
    return this.attributes.drivers[column.id.toString()]
  }

  fetchCmu(cmuColumn) {
    const cmuId = this.cmus.find(cmu => isPresent(cmuColumn.values[cmu]))
    return cmuColumn.values[cmuId];
  }
}

export class TableRow {
  constructor(rowData) {
    this.rowData = rowData
  }

  fetchData(key) {
    return this.rowData[key];
  }
}

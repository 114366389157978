import React from 'react';
import { connect } from "react-redux";
import { updateChoicesDraft, setDecisionInputSidebarOpen, updateFinalChoices} from "../../../store/sidebar/actions";
import DoneButton from '../../../common/DoneButton';
import { isMobile } from "react-device-detect";
import Decision from "../../../models/decision";
import {isBlank, isPresent} from "../../../helpers/common";
import ShareData, { checkNotifyAllSharedWarning } from "../../../models/share_data";
import {setDoneClicked} from "../../../helpers/sidebar_helpers";

const Footer = ({
                  decision, share_data,
                  setDecisionInputSidebarOpen, updateFinalChoices, updateChoicesDraft
                }) => {
  if (isBlank(decision)) return null;

  const shareData = new ShareData(share_data)
  const decisionObj = new Decision(decision);
  const openEndedChoice = decisionObj.openEndedChoice[0];
  const needChoiceSubmission = () => isPresent(openEndedChoice) && !decisionObj.isDecisionRecorded && decisionObj.isOpenEnded;
  const submitChoices = (checkShared = false, callback) =>
    checkNotifyAllSharedWarning(
      checkShared && isPresent(openEndedChoice.description),
      shareData.shareList,
      (hash = {}) => {
        updateFinalChoices(openEndedChoice.slug, {
          ...hash,
          final_decision: true,
          reset_final: false,
          ignore_line_breaks: true,
          draft: false
        });
        callback();
      }
    )

  const updateChoicesDraftOnClose = (checkShared = false, callback) => {
    checkNotifyAllSharedWarning(
      checkShared && decisionObj.decisionChoices.some(choice => choice.final_decision),
      shareData.shareList,
      (hash = {}) => {
        if (!decisionObj.isOpenEnded) {
          updateChoicesDraft({...hash, draft: false})
        }
        callback();
      }
    )
  }
  const onClose = () => {
    setDoneClicked('decision-input');
    const updateAction = needChoiceSubmission() ? submitChoices : updateChoicesDraftOnClose
    updateAction(!decisionObj.isDecisionRecorded && shareData.isSharedWIthNonOrg, () => {
      setDecisionInputSidebarOpen(false)
    });
  }

  return <div className={`${isMobile ? 'mb-5' : 'side-panel bottom'} py-3 px-3`} style={{zIndex: 0}}>
    <DoneButton onClose={onClose} />
  </div>
}
const mapStateToProps = ({ sidebar, share_data }) => ({ decision: sidebar.decision, share_data });
export default connect(mapStateToProps, { setDecisionInputSidebarOpen, updateFinalChoices, updateChoicesDraft })(Footer);

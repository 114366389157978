import React from 'react';
import { isBlank, isPresent } from "./common";
import { collectUserSupports, dataWithUser } from "./decision_helpers";
import { htmlParserNoBrakeLine } from "./html_parser";
import {Text} from '@react-pdf/renderer';
import styles from "../pdf/styles/common";

const reactStringReplace = require('react-string-replace');
const ORG_ROLES = ['admin', 'member', 'guest'];
const PENDING_ROLE = 'pending'
export const MENTIONED_REGEXP = new RegExp(/@\[(.*?)]\(contact:\d+\)/g);
export const MENTIONED_ID_REGEXP = new RegExp(/@\[(.*?)]\(contact:(\d+)\)/g);
export const MENTIONED_MARKUP = '@[__display__](contact:__id__)';

export const DECISION_TYPE = 'Decision'
export const DRIVER_TYPE = 'Driver'
export const FEEDBACK_REPLY_TYPE = 'Feedback'
export const USER_SUPPORT_REPLY_TYPE = 'DecisionUserSupport'

const sortCommentsFunctions = (a, b) => {
  if (a.created_at > b.created_at) return 1;
  if (a.created_at < b.created_at) return -1;
  return 0;
};

export const collectFeedbacksAndDriverComments = (decision) => {
  const result = [];
  (decision.feedbacks || []).forEach(feedback => {
    result.push({ ...feedback, type: DECISION_TYPE, object: decision })
  });
  (decision.driver_comments || []).forEach(comment => {
    result.push({ ...comment })
  });
  collectUserSupports(decision).filter(s => isPresent(s.support_comment)).forEach((userSupport) => {
    result.push({
      ...userSupport,
      type: USER_SUPPORT_REPLY_TYPE, object: userSupport, decision,
      id: userSupport.id,
      user_email: userSupport.user.email,
      created_at: userSupport.comment_created_at,
      description: userSupport.support_comment
    })
  });
  return result;
}

export const collectChatMessages = (decision) => {
  const { users } = decision;

  const comments = collectFeedbacksAndDriverComments(decision)
  if (isBlank(comments)) return [];

  const rootMessages = comments.filter(c => isBlank(c.feedback_reply_id))
  const collectFeedbackData = (message) => {
    const user = users.find(u => u.email === message.user_email)
    const replies = comments.filter(f => {

      if (f.reply_type === USER_SUPPORT_REPLY_TYPE) {
        return message.type === USER_SUPPORT_REPLY_TYPE ? f.feedback_reply_id === message.id : false
      }
      return f.feedback_reply_id === message.id
    }).sort(sortCommentsFunctions).map(f => collectFeedbackData(f))
    const likes = message.likes?.map(like => dataWithUser(like, decision)) || []
    return { ...message, user, replies, likes }
  }
  return rootMessages.sort(sortCommentsFunctions).map(m => collectFeedbackData(m))
}

export const collectMentions = ({ contacts }) => {
  let id = 0;
  const mentions = [];
  const canBeMentioned = (item) => item.type === 'User' && item.org_role !== PENDING_ROLE &&
    (item.invited || ORG_ROLES.includes(item.org_role))
  contacts.forEach(item => {
    id++;
    const name = item['full_name'] ? item['full_name'] : null;
    if(name && canBeMentioned(item)) {
      mentions.push({id, display: name, email: item['email']});
    }
  })
  return mentions;
}

export const formatMessageText = (message, pdf = false) => {
  let index = 0;
  let comment_array = reactStringReplace(message.description, MENTIONED_REGEXP, (match) => {
    index++;
    if (pdf) return <Text style={[styles.text]} key={`message-${message.id}-mentioned-${index}`}>{match}</Text>;
    return <b key={`message-${message.id}-mentioned-${index}`}>{match}</b>;
  });
  //formatting only text content
  comment_array[comment_array.length-1] = htmlParserNoBrakeLine(comment_array[comment_array.length-1])
  return comment_array
}

export const parseContactIds = (messageText) => {
  const matchedArray = messageText.matchAll(MENTIONED_ID_REGEXP);

  const ids = [];
  for (const match of matchedArray) {
    ids.push({ display_name: match[1], id: match[2] })
  }
  return ids;
}

import {
  AlignmentType,
  HeadingLevel,
  Paragraph,
  TextRun,
} from "docx";
import {isBlank, isPresent, pluralize} from "../../../helpers/common";
import {MIN_DECISION_OTHER_CHOICES_COUNT} from "../../../tree_view/side_panel/sections/OtherChoicesSection";
import {baseBlockSection, baseHeadParagraph, bulletParagraph, paragraphBorder} from "./wrappers";
import {mutedProps, space4d13pts} from "../../styles/docx_styles";
import * as moment from "moment";
import { userName } from "../../../helpers/user_helpers";

export const otherChoicesBlock = ({obj, isRecommendation = false}) => {
  if(obj.isDecisionRecorded && isRecommendation) return [];

  const otherConsideredChoices = isRecommendation ?
    obj.recommendation.considered_choices :
    obj.decision.considered_choices

  const header = baseHeadParagraph({text: 'Other choices considered'})
  const choiceRow = (choice) => bulletParagraph({text: choice.description})

  if (obj.isOpenEnded && isPresent(otherConsideredChoices)) {
    return [
      header,
      ...otherConsideredChoices.map(choice => choiceRow(choice))
    ]
  }

  if (obj.isOpenEnded || isBlank(obj.otherChoices)) return [];
  if (obj.otherChoices.length < MIN_DECISION_OTHER_CHOICES_COUNT) return [];

  return [
    header,
    ...obj.otherChoices.map(choice => choiceRow(choice))
  ]
}

export const averageBuyIn = (decisionObj) => {
  if (!decisionObj.isDisplayBuyIn) return [];

  return [
    baseHeadParagraph({text: 'Buy-in'}),
    new Paragraph({
      style: "normalPara",
      children: [
        new TextRun({
          text: `${decisionObj.avgSupport} out of 5 `,
        }),
        new TextRun({
          ...mutedProps,
          text: `(${pluralize(decisionObj.userSupports.length, 'rating')})`
        }),
      ]
    }),
  ]
}

export const ContextSection = (decision) => {
  if (isBlank(decision.collaboration_context)) return [];

  return [
    ...baseBlockSection({header: 'Context', text: decision.collaboration_context, heading: HeadingLevel.HEADING_5, instance: 5}),
    paragraphBorder(),
  ]
}

export const enteredBySection = ({user = {}, time = {}, prefix = 'Entered', space = space4d13pts}) => {
  if (isBlank(user) || isBlank(time)) return {};

  return new Paragraph({
    style: 'mutedText',
    alignment: AlignmentType.LEFT,
    ...space,
    children: [
      new TextRun({
        text: `${prefix} by ${userName(user)} ${moment(time).format('DD MMM, yyyy')}`
      })
    ]
  })
}

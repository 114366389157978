import React, { useCallback, useRef, useEffect } from 'react';
import { connect } from "react-redux";
import Select from 'react-select';
import DecisionCategory from "../models/decision_category";
import { ChoiceEntryStyle } from "../tree_wizard/styles/choice_entry_styles";
import { isPresent } from "../helpers/common";

export const CategoryInput = ({
                                wizard, category, categoriesData, submitState,
                                onChangeCategory,
                                lastCategory = null, setLastCategory = () => {}
                              }) => {
  const categoryObject = new DecisionCategory(category)
  const inputRef = useRef(null)

  // useEffect(() => {
  //   if (lastCategory === category.slug) {
  //     inputRef.current?.focus();
  //   }
  // }, [lastCategory, inputRef.current])

  const optionChangeAction = (option) => {
    onChangeCategory(categoryObject, option);
    setLastCategory(category.slug);
  };

  const CategorySelectOneInput = useCallback(({}) =>
      <Select onChange={(option) => optionChangeAction(option)}
              ref={inputRef}
              defaultValue={categoryObject.optionsDataSortedAsc.filter(h => categoriesData.includes(h.value))}
              isDisabled={wizard.submit || submitState} isClearable
              components={{ IndicatorSeparator:() => null }}
              options={categoryObject.optionsDataSortedAsc} placeholder={'Make a selection'}
              styles={ChoiceEntryStyle} />
    , [wizard, submitState, categoriesData])

  const CategorySelectOneTypeheadInput = useCallback(({}) =>
      <Select onChange={(option) => optionChangeAction(option)}
              ref={inputRef}
              defaultValue={categoryObject.optionsDataSortedAsc.filter(h => categoriesData.includes(h.value))}
              isDisabled={wizard.submit || submitState}
              components={{ IndicatorSeparator:() => null }}
              options={categoryObject.optionsDataSortedAsc} styles={ChoiceEntryStyle}
              isSearchable isClearable
              placeholder={'Make a selection'} />
    , [wizard, submitState, categoriesData])

  const CategorySelectAllThatApplyInput = useCallback(({}) => {
    const optionsData = categoryObject.optionsDataSortedAsc
    return <Select onChange={(option) => optionChangeAction(option)}
                   ref={inputRef}
                   defaultValue={categoriesData.map(id => optionsData.find(h => h.value === id)).filter(h => isPresent(h))}
                   isDisabled={wizard.submit || submitState}
                   components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                   options={optionsData} styles={ChoiceEntryStyle}
                   isSearchable isMulti isClearable
                   placeholder={'Select all that apply'} />
  }, [wizard, submitState, categoriesData])

  if (categoryObject.isPickOne) {
    return <CategorySelectOneInput />
  } else if (categoryObject.isPickOneTypehead) {
    return <CategorySelectOneTypeheadInput />
  } else if (categoryObject.isPickAllThatApply) {
    return <CategorySelectAllThatApplyInput />
  } else {
    return null;
  }
}
const mapStateToProps = ({ wizard }) => ({ wizard });
export default connect(mapStateToProps)(CategoryInput);


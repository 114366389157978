import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import { setDecisionInputSidebarOpen, transferDecisionFromSidebar } from "../../../store/sidebar/actions";
import SlidingPanel from "react-sliding-side-panel";
import Footer from "./decision_input/Footer";
import OpenEndedDecisionInput from "./decision_input/OpenEndedDecisionInput";
import RateSupportSwitcher from "./decision_input/RateSupportSwitcher";
import RationaleInput from "./decision_input/RationaleInput";
import DecisionFlowSwitcher from './decision_input/DecisionFlowSwitcher'
import { isMobile } from "react-device-detect";
import { DSightAnalysis } from "../../../tree_view/modals/DriverShowModal";
import { HowToMakeDecisionPBSection } from "../../../template_view/side_panel/sections/PlaybookNotesSection";
import PanelHeader from "../../../tree_view/side_panel/PanelHeader";
import DataSourcesSection from "../../../tree_view/side_panel/sections/DataSourcesSection";
import { saveSideBarData } from "../../../store/sidebar/common_actions";
import DecisionDateInput from "./decision_input/DecisionDateInput";
import * as moment from "moment";
import { isPresent } from "../../../helpers/common";
import NextStepsInput from "./decision_input/NextStepsInput";
import OtherConsideredChoicesInput from "./decision_input/OtherConsideredChoicesInput";
import ExpectedResultsInput from "./decision_input/ExpectedResultsInput";
import ExpectedOpportunityInput from "./decision_input/ExpectedOpportunityInput";
import ExpectedInvestmentInput from "./decision_input/ExpectedInvestmentInput";
import {defaultChoices} from "../../../tree_view/modals/entry_modal/helpers";
import { flow } from "../../../helpers/decision_flows";

const DSightDecisionInputRightPanel = ({
                                        sidebar, playbook_notes,
                                        openSideBar, transferDecisionFromSidebar, saveSideBarData
                                     }) => {
  const [reasons, setReasons] = useState( '');
  const [expectedResults, setExpectedResults] = useState( '');
  const [expectedOpportunity, setExpectedOpportunity] = useState();
  const [expectedInvestment, setExpectedInvestment] = useState();
  const [consideredChoices, setConsideredChoices] = useState([]);
  const [rateSupport, setRateSupport] = useState(false);
  const [openEndedChoice, setOpenEndedChoice] = useState('');
  const [flowEnum, setFlowEnum] = useState(sidebar.decision?.flow_enum || flow.D_SIGHT_RECOMMENDATION);
  const initDate = isPresent(sidebar.decision?.historical_decided_at) ? moment(sidebar.decision.historical_decided_at) : null;
  const [decisionDate, setDecisionDate] = useState(initDate || moment());
  const [nextStepsDescription, setNextStepsDescription] = useState('');
  const fromRecommendationPanel = flowEnum === flow.D_SIGHT_RECOMMENDATION
  const fromDecisionPanel = flowEnum === flow.D_SIGHT
  const recommendation = sidebar.decision?.recommendation
  const updateFields = (object) => {
    const choices = flowEnum === flow.D_SIGHT ? object?.choices : object?.recommendation_choices
    setOpenEndedChoice(choices?.filter(c => c.open_ended)[0]?.description || '')
    setReasons(object?.final_decision_reasons || '')
    setExpectedResults(object?.expected_results || '')
    setExpectedOpportunity(object?.expected_opportunity || '')
    setExpectedInvestment(object?.expected_investment || '')
    setNextStepsDescription(object?.next_steps_description || '')
    setConsideredChoices(isPresent(object?.considered_choices) ? object?.considered_choices : defaultChoices())
  }

  useEffect(() => {
    if (flowEnum === flow.D_SIGHT) {
      updateFields(sidebar.decision)
    } else if (flowEnum === flow.D_SIGHT_RECOMMENDATION) {
      updateFields(recommendation)
    }
  }, [flowEnum, sidebar.decisionInputSidebar])

  return <SlidingPanel
    type="right"
    isOpen={sidebar.decisionInputSidebar}
    panelContainerClassName="right-sidebar-panel-wrapper decision-input-panel-wrapper decision-panel-width"
    panelClassName={`right-sidebar-panel-wrapper-content decision-input-panel-content ${isMobile ? '' : 'd-flex flex-column'}`}
    onOpened={() => saveSideBarData({ isOpened: true })}
    onClose={transferDecisionFromSidebar}
    onClosed={() => saveSideBarData({ decision: null, isOpened: false })}
  >
    <PanelHeader headerName='Enter decision' openSideBar={openSideBar} preview={true} isDsight={true}/>
    <div className="px-3">
      <DSightAnalysis headerClass="h3"
                      decision={sidebar.decision}
                      showDecisionDescription={true}
                      keyPreffix={'dsight-decision-input-left-panel'}
      />
      <div className="border-top pb-3 ms-3-negative me-3-negative" />
      <DecisionFlowSwitcher flowEnum={flowEnum} setFlowEnum={setFlowEnum} setRateSupport={setRateSupport} />
      <HowToMakeDecisionPBSection playbook_notes={playbook_notes} showNA={false}/>
      <OpenEndedDecisionInput openEndedChoice={openEndedChoice} setOpenEndedChoice={setOpenEndedChoice} flowEnum={flowEnum} />
      <RationaleInput reasons={reasons} setReasons={setReasons} />
      <RateSupportSwitcher flowEnum={flowEnum} rateSupport={rateSupport} setRateSupport={setRateSupport} />
      <OtherConsideredChoicesInput flowEnum={flowEnum} decision={sidebar.decision} choices={consideredChoices} setChoices={setConsideredChoices}/>
      <NextStepsInput object={flowEnum === flow.D_SIGHT ? sidebar.decision : recommendation} nextStepsDescription={nextStepsDescription} setNextStepsDescription={setNextStepsDescription} />
      <ExpectedResultsInput object={flowEnum === flow.D_SIGHT ? sidebar.decision : recommendation} expectedResults={expectedResults} setExpectedResults={setExpectedResults} />
      <ExpectedOpportunityInput object={flowEnum === flow.D_SIGHT ? sidebar.decision : recommendation} expectedOpportunity={expectedOpportunity} setExpectedOpportunity={setExpectedOpportunity} />
      <ExpectedInvestmentInput object={flowEnum === flow.D_SIGHT ? sidebar.decision : recommendation} expectedInvestment={expectedInvestment} setExpectedInvestment={setExpectedInvestment} />
      {
        fromDecisionPanel ?
          <DecisionDateInput decisionDate={decisionDate} setDecisionDate={setDecisionDate}/> : null
      }
      <DataSourcesSection fromRecommendationPanel={fromRecommendationPanel} fromDecisionPanel={fromDecisionPanel} addClass="mb-1" />
    </div>
    <Footer flowEnum={flowEnum}
            rateSupport={rateSupport}
            openEndedChoice={openEndedChoice}
            reasons={reasons}
            expectedResults={expectedResults}
            consideredChoices={consideredChoices}
            setConsideredChoices={setConsideredChoices}
            nextStepsDescription={nextStepsDescription}
            expectedOpportunity={expectedOpportunity}
            expectedInvestment={expectedInvestment}
            decisionDate={decisionDate}
    />
  </SlidingPanel>
}
const mapStateToProps = ({ sidebar, playbook_notes}) => ({ sidebar, playbook_notes });
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => { dispatch(setDecisionInputSidebarOpen(options)) },
  transferDecisionFromSidebar: () => { dispatch(transferDecisionFromSidebar()) },
  saveSideBarData: (data = {}) => { dispatch(saveSideBarData(data)) }
});
export default connect(mapStateToProps, mapDispatchToProps)(DSightDecisionInputRightPanel);

import React from 'react';
import Image from "react-bootstrap/Image";
import EntryPoint from "../../EntryPoint";
import Button from "react-bootstrap/Button";

export const redirectToInvitePage = () => {
  const id = location.pathname.replace('/quick_driver_entry/', '');
  window.location.href = `/invites_landing_page/${id}`;
}

const QuickDriversHeader = () => {
  return <header className="homepage-header decision-tree-header">
    <nav className="navbar navbar-expand bd-navbar p-3">
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="logo-dropdown pointer" onClick={redirectToInvitePage}>
          <Image src={EntryPoint.instance.org_default_horizontal_logo} alt={EntryPoint.instance.org_default_alt_text}/>
        </div>
        <div>
        <Button onClick={redirectToInvitePage} className="btn btn-secondary w-100">
          See the full decision
        </Button>
        </div>
      </div>
    </nav>
  </header>;
}

export default QuickDriversHeader;

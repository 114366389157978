import React from 'react';
import Wrapper from '../Wrapper';
import AggregatedData from "../driver/AggregatedData";
import Header from "../template_driver/Header";
import Content from "../template/Content";
import { displayDriverPreviewData } from "../TemplatePreviewTree";
import { driverInitData } from "../shared/helpers";
import {assignedTemplateUser} from "./TemplateCard";
import {Template} from "../../../models/template";

const TemplatePreviewDriverCard = ({ driverData, template, tree, indentStep = 1, user, contactsData, openModal }) => {
  const { driverObject, childrenDrivers, setShowChildren, showChildren } = driverInitData(tree, driverData);
  const templateObj = new Template(template)
  const assignedUser = assignedTemplateUser(templateObj, driverData.driver.assign_to_user);

  const clickable = () => displayDriverPreviewData(driverData)

  const onDriverClick = () => {
    if(clickable()) {
      openModal({
        decision: template,
        drivers: tree.drivers,
        data_sources: tree.data_sources,
        slug: driverData.driver.slug,
        type: 'PreviewDriverDetailsModal'
      })
    }
  }

  return <>
    <Wrapper indentStep={indentStep} >
      <div className={`d-flex`}>
        <div className="my-auto lh-sm w-100 me-1">
          <Header {...{ driverData, driverObject, childrenDrivers, setShowChildren, onEditClick: onDriverClick,
            clickable: clickable() }} />
          <AggregatedData {...{ driverData, hidden: false }} />
        </div>
        <Content {...{ driverData, assignedUser }} />
      </div>
    </Wrapper>
    {
      showChildren &&
        childrenDrivers.map((child) =>
          <TemplatePreviewDriverCard key={`driver-card-${child.driver.slug}`}
                                   {...{
                                     driverData: child,
                                     indentStep: indentStep + 1,
                                     template, tree, user, contactsData, openModal
                                   } } />
        )
    }
  </>
}
export default TemplatePreviewDriverCard;

export default class CustomLoadingOverlay {
  eGui;

  init(params) {
    this.eGui = document.createElement('div');
    this.eGui.innerHTML = `<div class="ag-overlay-loading-center" role="presentation">
               <div role="presentation" style="height:100px; width:100px; background: url('../../../../../assets/logos/cloverpop_logo_ukr_80x80') center / contain no-repeat; margin: 5px auto;"></div>
               <div aria-live="polite" aria-atomic="true">${params.loadingMessage}</div>
            </div>`;
  }

  getGui() {
    return this.eGui;
  }

  refresh(params) {
    return false;
  }
}

import React from 'react';
import { connect } from "react-redux";
import SlidingPanel from "react-sliding-side-panel";
import {setAssignDecisionsSidebarOpen} from "../../store/sidebar/actions";
import PanelTreeEditor from "../../tree_editor/set_decisions/PanelTreeEditor";
import { isMobile } from "react-device-detect";
import PanelHeader from "../../tree_view/side_panel/PanelHeader";

const AssignDecisionsPanel = ({ addClass, sidebar, openSideBar, isTemplateSet = false,
                                headerName = 'Assign decisions', ...opts }) => {
  const onClose = () => openSideBar(false)

  return <SlidingPanel
    type="left"
    isOpen={sidebar.assignDecisionsSidebar}
    panelContainerClassName={`sidebar-panel-wrapper assign-decisions-panel-wrapper details-panel-width template-panel ${addClass} ${isMobile ? 'pb-5 mb-5' : ''}`}
    panelClassName={`sidebar-panel-wrapper-content assign-decisions-panel-content`}
    size={50}>
    <PanelHeader {...{ headerName, openSideBar, preview: true }} />
    <PanelTreeEditor {...{ ...opts, onClose, assignDecisions: true, isTemplateSet, allowGuest: !isTemplateSet }} />
  </SlidingPanel>
}

const mapStateToProps = ({ sidebar }) => ({ sidebar });
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (flag) => {
    dispatch(setAssignDecisionsSidebarOpen(flag));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(AssignDecisionsPanel);

import React, {useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../common/CloseIcon";
import { ModalDoneFooter } from "../../common/modals";
import {isBlank, isPresent} from "../../helpers/common";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { DescriptionRow, NameRow, onSetCreateCallback } from "./TemplateSetModal";
import { Typeahead } from "react-bootstrap-typeahead";
import { loadContacts } from "../../store/contacts/actions";
import { createDecisionSet } from "../../store/decision_set/actions"
import {
  decisionSetInputKeyDown,
  filteredContactsCallback,
  filterNewInvitesCallback
} from "../../decision_set_view/side_panel/sections/InviteInput";
import { checkLoadingEffect } from "../../helpers/callbacks_helpers";
import { DEFAULT_TYPEHEAD_ATTRIBUTES } from "../../tree_view/modals/helpers/share_helpers";
import { renderContactItem, onChangeInvites } from "../../tree_view/side_panel/helpers/collaborators_helpers";
import { renderTag } from "../../tree_wizard/steps_wizard/steps/helpers/collaboration_helpers";
import UserAvatarImage from "../../common/UserAvatarImage";
import TemplateSet from "../../models/template_set";

export const showDecisionSetModal = (modal) => modal.type === "DecisionSetModal" && modal.shown;
export const openDecisionSetModal = ({ openModal, template_set = null }) => openModal({ type: 'DecisionSetModal', template_set })

const SetUsersRow = ({ contactsData, user, invites, setInvites }) => {

  const ref = React.createRef();
  const filteredContacts = filteredContactsCallback({
    contactsData, current_user: user, invites
  })
  const filterNewInvites = filterNewInvitesCallback({ contactsData })
  const onKeyDown = decisionSetInputKeyDown({
    ref, filteredContacts, filterNewInvites,
    invites, setInvites
  })

  return <div className="mb-0 mt-3">
    <h3>Invite decision flow members</h3>
    <div className="d-flex mt-1 align-items-center">
      <span className="collaborator_img">
         <UserAvatarImage user={user} size='md' />
      </span>
      <span className="px-2 text-truncate">{user.full_name}&nbsp;<span className="text-muted">(manager)</span></span>
    </div>
    <div className="d-flex flex-column flex-sm-row mt-2">
      <div className="me-2 w-100">
        <Typeahead
          {...DEFAULT_TYPEHEAD_ATTRIBUTES} labelKey="value" filterBy={['value','full_name']}
          id="collaboration-invite-input" ref={ref}
          selected={invites}
          onChange={(selected) => onChangeInvites(selected, setInvites, filterNewInvites)}
          options={filteredContacts()}
          renderMenuItemChildren={renderContactItem} onKeyDown={onKeyDown}
          renderToken={(option, events, index) => renderTag(option, events, index, contactsData)}
        />
      </div>
    </div>
  </div>
}

const DecisionSetModal = ({
                            contactsData, shown, template_set = null,
                            onClose = () => {}, createDecisionSet = {},
                            loadContacts, user,
                            ...opts
                          }) => {
  const [name, setName] = useState(template_set?.name || '');
  const [description, setDescription] = useState(template_set?.description || '');
  const [invites, setInvites] = useState([]);
  const [submit, setSubmit] = useState(false);
  const history = useHistory();

  checkLoadingEffect(contactsData, loadContacts)

  useEffect(() => {
    if (isBlank(template_set)) return setInvites([]);

    const templateSetObj = new TemplateSet(template_set);
    const admissionsUserEmails = templateSetObj.collaborator_admissions.map(admission => admission.user_email);
    const assignedCollaboratorEmails = templateSetObj.templates.map(template => template.assigned_collaborator_email)
      .filter(a => isPresent(a));
    const uniqueInvitesSet = new Set([...admissionsUserEmails, ...assignedCollaboratorEmails]);

    setInvites(Array.from(uniqueInvitesSet).filter(email => user.email !== email));
  }, [isPresent(template_set), shown]);

  useEffect(() => {
    setName(template_set?.name || '')
    setDescription(template_set?.description || '')
  }, [template_set])

  const onCloseModal = () => {
    setInvites([]);
    onClose();
  }

  const fetchSubmitData = () => ({
    decision_set: { name, description, invites, template_set_id: template_set?.slug }
  })
  const onSetCreate = onSetCreateCallback(createDecisionSet, fetchSubmitData, 'decision_sets', onCloseModal, history, setSubmit)

  return <Modal size="md" backdrop="static" show={shown} onHide={onCloseModal}>
    <Modal.Body>
      <CloseIcon onClose={onCloseModal} />
      <h2>Create a decision flow</h2>
      <form>
        <NameRow {...{ name, setName }} placeholder={"Enter the name of the decision flow"} />
        <DescriptionRow {...{ description, setDescription }} />
        <SetUsersRow {...{ contactsData, invites, setInvites, user }} {...opts} />
      </form>
    </Modal.Body>
    <ModalDoneFooter onClose={onSetCreate} disabled={isBlank(name) || submit} />
  </Modal>
}
const mapStateToProps = ({ contacts, current_user, modal }) => ({
  contactsData: contacts, user: current_user, template_set: modal.template_set
});
export const mapDispatchToProps = (dispatch) => ({
  createDecisionSet: (data, callback) => dispatch(createDecisionSet(data, callback)),
  loadContacts: () => dispatch(loadContacts())
})
export default connect(mapStateToProps, mapDispatchToProps)(DecisionSetModal);

import React, { useState } from 'react';
import { connect } from "react-redux";
import { isBlank, isPresent } from "../../helpers/common";
import DriverDetailsModal, { modalDriverData } from "../../tree_editor/modals/DriverDetailsModal";
import { openModal, transferDataAndCloseModal } from "../../store/modals/actions";
import Driver, { DEFAULT_RATING_SCALE } from "../../models/driver";
import { driversToArray, editDriverTitle } from "../../helpers/drivers_helpers";
import { TREE_CHANNEL_ACTIONS } from "../../../../channels/tree_channel";
import {
  isDriverInEditModeByOthers,
  isDriverInEditModeByUser,
  performEditDriverDetailsAction,
  treeChannelIsConnected
} from "../../helpers/channel_helpers";
import { editingDriverAlert } from "../accordion_tree/shared/helpers";
import Decision from "../../models/decision";
import { saveSideBarData } from "../../store/sidebar/common_actions";
import { fetchContacts } from "../../tree_wizard/steps_wizard/steps/helpers/tree_builder_step";
import * as moment from "moment";

export const showModal = (modal, slug) => modal.type === "DriverDetailsModal" && modal.shown && modal.slug === slug;
export const openDriverEntryModal = (decision, slug, {drivers, data_sources}, openModal, channels, user) => {
  if(isDriverInEditModeByOthers(channels, TREE_CHANNEL_ACTIONS.edit_driver_details, slug, user))
    return editingDriverAlert(channels, TREE_CHANNEL_ACTIONS.edit_driver_details, slug)

  performEditDriverDetailsAction(user, slug, true)
  openModal({
    decision, slug,
    drivers, data_sources,
    type: 'DriverDetailsModal'
  })
};

const DriverEntryModal = ({
                            key = null, driverData= null, isTemplate,
                            decision, modal, channels, user, contactsData,
                            transferDataAndCloseModal
                          }) => {
  if (isBlank(driverData)) return null;

  const slug = driverData.driver.slug;
  const [inputValue, setInputValue] = useState(driverData.driver.question);
  const [assignedToUser, setAssignedToUser] = useState(driverData.driver.assign_to_user);
  const [notes, setNotes] = useState(driverData.driver.notes || '');
  const [driverTypeSlug, setDriverTypeSlug] = useState(driverData.driver.driver_type_slug);
  const [ratingScale, setRatingScale] = useState(driverData.driver.rating_scale || DEFAULT_RATING_SCALE.value)
  const [ratingLabels, setRatingLabels] = useState(driverData.driver.rating_labels || {})
  const initDate = isPresent(driverData?.driver.due_date) ? moment(driverData?.driver.due_date).format('DD MMM, yyyy') : null;
  const [dueDate, setDueDate] = useState(initDate);

  const driverObj = new Driver(driverData.driver, driverData.driver_sources_slugs, [], new Decision(decision));

  const onCloseModal = () => {
    if(treeChannelIsConnected() && isDriverInEditModeByUser(channels, TREE_CHANNEL_ACTIONS.edit_driver_details, user)) {
      performEditDriverDetailsAction(user, slug, false)
    }
    transferDataAndCloseModal()
  }

  return <DriverDetailsModal key={key || `driver-details-modal-${slug}`}
                             title={isPresent(driverObj.insightsData) ? 'Assign driver' : editDriverTitle(isTemplate)}
                             collaborators={isTemplate ?
                               fetchContacts({}, contactsData, false, false):
                               fetchContacts({}, contactsData, true)}
                             driverData={modalDriverData(modal)}
                             question={inputValue}
                             setQuestionValue={setInputValue}
                             notes={notes}
                             driverTypeSlug={driverTypeSlug}
                             setDriverTypeSlug={setDriverTypeSlug}
                             setNotes={setNotes}
                             show={showModal(modal, slug)}
                             assignedToUser={assignedToUser}
                             setAssignedToUser={setAssignedToUser}
                             onClose={onCloseModal}
                             isTemplate={isTemplate}
                             isDSight={isPresent(driverObj.insightsData)}
                             ratingScale={ratingScale}
                             setRatingScale={setRatingScale}
                             ratingLabels={ratingLabels}
                             setRatingLabels={setRatingLabels}
                             dueDate={dueDate}
                             setDueDate={setDueDate}
                             allowNew={!isTemplate}
  />
}
const mapStateToProps = ({ modal, tree, decision, channels, current_user, contacts }, ownProps) => {
  const driverData = ownProps.driverData || driversToArray(modal.drivers).find((driverData) => driverData.driver.slug === modal.slug)
  return { contactsData: contacts,
    modal, tree, decision, channels,
    driverData, user: current_user
  }
};
export default connect(mapStateToProps, { openModal, transferDataAndCloseModal, saveSideBarData })(DriverEntryModal);

import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { isPresent, successActions } from "../helpers/common";
import {
  loadDecisionSet,
  removeDecisionSet,
  updateDecisionSet
} from "../store/decision_set/actions";
import { closeModal } from "../store/modals/actions";
import { updateEntryPointData } from "../EntryPoint";
import DecisionSetContent from "./DecisionSetContent";
import { Loader } from "../common/Loader";
import DecisionSet from "../models/decision_set";
import { forceReloadHomepageDecisions } from "../store/homepage/actions";
import {
  setDecisionInputSidebarOpen,
  setRecommendationInputSidebarOpen,
  setSetDecisionsSidebarOpen
} from "../store/sidebar/actions";
import { resetDecisionState } from "../store/decision/common_actions";
import { checkLoadingEffect, isStoreLoading } from "../helpers/callbacks_helpers";
import { resetTree } from "../store/tree/common_actions";
import DecisionSetWrapper from "../common/DecisionSetWrapper";
import { loadContacts } from "../store/contacts/actions";
import { resetDecisionSetData } from "../store/decision_set/common_actions";
import { resetDriverInputSidebar } from "../store/sidebar/common_actions";

const DecisionSetView = ({
                           match, contactsData,
                           decision, decision_set, current_user,
                           loadDecisionSet, resetDecision, resetTree, resetDriverInputSidebar,
                           closeRecommendationInputSideBar, closeDecisionInputSideBar, loadContacts,
                           ...opts
                         }) => {
  useEffect(() => updateEntryPointData(match, 'decision_sets'), [match?.params?.id]);
  checkLoadingEffect(contactsData, () => loadContacts(() => {}, { invite_yourself: true }));
  useEffect(() => {
    if (contactsData.loaded && !contactsData.loading && !contactsData.invite_yourself) {
      setTimeout(loadContacts(() => {}, { invite_yourself: true }), 50)
    }
  }, [contactsData.loaded, contactsData.invite_yourself])
  useEffect(() => {
    if (isPresent(decision.slug))
      successActions(true, [
        resetDecision, resetTree, resetDriverInputSidebar
      ])
  }, [])
  checkLoadingEffect(decision_set, loadDecisionSet)
  const decisionSetObj = useMemo(() =>new DecisionSet(decision_set, current_user), [decision_set, current_user])

  const isManager = useMemo(() => decisionSetObj.isManager, [decisionSetObj]);
  const isSetUser = useMemo(() => decisionSetObj.isSetUser, [decisionSetObj]);

  return <DecisionSetWrapper {...{...opts, decision_set, isManager, isSetUser}} >
    {
      isStoreLoading(decision_set) ?
        <Loader /> :
        <div className="w-100 wrapper-container width-fit-content">
          <div className="home container mt-3">
            <DecisionSetContent />
          </div>
        </div>
    }
  </DecisionSetWrapper>
}
const mapStateToProps = ({ contacts, home, decision_set, decision, modal, current_user }) =>
  ({ home, decision_set, decision, modal, current_user, contactsData: contacts });
const mapDispatchToProps = (dispatch) => ({
  loadDecisionSet: () => dispatch(loadDecisionSet()),
  updateDecisionSet: (value, callback) => { dispatch(updateDecisionSet(value, callback)) },
  removeDecisionSet: (callback) => dispatch(removeDecisionSet(callback)),
  closeRecommendationInputSideBar: () => dispatch(setRecommendationInputSidebarOpen(false)),
  closeDecisionInputSideBar: () => dispatch(setDecisionInputSidebarOpen(false)),
  resetDecisionSet: () => dispatch(resetDecisionSetData()),
  resetDecision: () => dispatch(resetDecisionState()),
  resetTree: () => dispatch(resetTree()),
  forceReloadHomepageDecisions: () => { dispatch(forceReloadHomepageDecisions()) },
  setSetDecisionsSidebarOpen: (value) => { dispatch(setSetDecisionsSidebarOpen(value)) },
  resetDriverInputSidebar: (value) => { dispatch(resetDriverInputSidebar()) },
  closeModal: () => { dispatch(closeModal()) },
  loadContacts: (callback, data) => dispatch(loadContacts(callback, data))
});
export default connect(mapStateToProps, mapDispatchToProps)(DecisionSetView);

export default class AgGridApi {
  constructor(gridApi) {
    this.gridApi = gridApi;
  }

  get onBtShowLoading() {
    this.gridApi.showLoadingOverlay();
  }

  get onBtShowNoRows() {
    this.gridApi.showNoRowsOverlay();
  }

  get onBtHide() {
    this.gridApi.hideOverlay();
  }
}

import React from 'react';
import DetailsItem from "../../tree_view/navigation/DetailsItem";
import HelpTemplateItem from "./HelpTemplateItem";
import PlaybookTemplateItem from "../../template_wizard/navigation/PlaybookTemplateItem";
import DecisionSetItem from "../../template_set_view/navigation/DecisionSetItem";
import RatingsAndWeightsItem from "../../tree_view/navigation/RatingsAndWeightsItem";

const NavigationItems = ({ showDecisionSet = false }) =>
  <>
    <div className="sidebar-top">
      <ul className="list-unstyled m-0 p-3">
        <RatingsAndWeightsItem isTemplate={true} />
        <DetailsItem />
        <PlaybookTemplateItem />
        { showDecisionSet ? <DecisionSetItem /> : null }
      </ul>
    </div>
    <div className="sidebar-bottom">
      <ul className="list-unstyled m-0 p-3">
        <HelpTemplateItem />
      </ul>
    </div>
  </>

export const SidebarTreeNavigationTemplate = (opts) =>
  <div id="sidebar-navigation" className="tree-sidebar bg-light-gray d-none d-lg-block">
    <NavigationItems {...opts} />
  </div>

export default SidebarTreeNavigationTemplate;

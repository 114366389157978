import React, { useState } from 'react';
import { connect } from "react-redux";
import { isBlank, isPresent } from "../../helpers/common";
import Decision from '../../models/decision';
import Modal from "react-bootstrap/Modal";
import {
  isOpenEndedCallback,
  onChangeResponseTypeCallback
} from "../../tree_wizard/steps_wizard/steps/FramingDecisionStep";
import { ModalDoneFooter } from "../../common/modals";
import DescriptionSection from "./entry_modal/DescriptionSection";
import ResponseTypeSection from "./entry_modal/ResponseTypeSection";
import RateCompareChoicesSection from "./entry_modal/RateCompareChoicesSection";
import ChoicesSection from "./entry_modal/ChoicesSection";
import {
  buildResponseType,
  buildDecisionChoices,
  setModalDataEffect,
  submitEntryCallback
} from './entry_modal/helpers';
import DecisionAssignToBlock from "./helpers/DecisionAssignToBlock";
import ModalHeaderTitle from "../../common/ModalHeaderTitle";
import { reloadContacts } from "../../store/contacts/actions";
import { resetDecisionSetData } from "../../store/decision_set/common_actions";

export const decisionUpdateData = (description, responseType, rateCompareChoices, choices, isOpenEnded, assignedToUser = '') => {
  const data = {
    template: {
      description, choice_entry_widget_type: responseType,
      rate_compare_choices: rateCompareChoices,
      assigned_collaborator: assignedToUser
    }
  }
  if(!isOpenEnded()) data.choices = choices
  return data;
}
export const openEditDecisionEntryModal = ({ openModal, decision, ...opts }) => openModal({ ...opts, type: 'PanelDecisionEntryModal', decision })

const DecisionEntryModal = ({
                              show, onClose,
                              decision,
                              key = '', updateDecision, reloadContacts, resetDecisionSetData
                            }) => {
  if(isBlank(decision)) return null;

  const [description, setDescription] = useState(decision.description);
  const [responseType, setResponseType] = useState(buildResponseType(decision));
  const [choices, setChoices] = useState(buildDecisionChoices(decision));
  const [submitted, setSubmitted] = useState(false);
  const [assignedToUser, setAssignedToUser] = useState(decision.assigned_collaborator_email)

  const disabledSubmit = () => isBlank(description);
  const isOpenEnded = isOpenEndedCallback(responseType);
  const onChangeResponseType = onChangeResponseTypeCallback(() => buildDecisionChoices(decision), isOpenEnded, setChoices, setResponseType);
  const [rateCompareChoices, setRateCompareChoices] = useState(isPresent(decision.rate_compare_choices))

  setModalDataEffect(show, decision, setDescription, setResponseType, setChoices, setAssignedToUser)

  const isRateCompareFlagHidden = () => {
    if (new Decision(decision).isTreeRecommendation) return true;

    return isOpenEnded()
  }

  const submissionCallback = submitEntryCallback(updateDecision,
    decisionUpdateData(description, responseType, rateCompareChoices, choices, isOpenEnded, assignedToUser),
    setSubmitted, onClose, _, [reloadContacts, resetDecisionSetData]
  );

  return <Modal key={key} backdrop="static" size="lg" show={show} onHide={onClose}>
    <Modal.Body>
      <form>
        <ModalHeaderTitle {...{ onClose, title: 'Edit decision' }} />
        <DescriptionSection {...{ description, setDescription, submitted, className: 'mb-3' }} />
        <>
          <ResponseTypeSection {...{ responseType, onChangeResponseType, submitted }} />
          <RateCompareChoicesSection {...{ rateCompareChoices, setRateCompareChoices, hidden: isRateCompareFlagHidden() }} />
          <ChoicesSection {...{ choices, setChoices, submitted, hidden: isOpenEnded(), isOpenEnded }} />
          <DecisionAssignToBlock {...{ assignedToUser, setAssignedToUser, className: 'mt-3' }} />
        </>
      </form>
    </Modal.Body>
    <ModalDoneFooter onClose={submissionCallback} disabled={disabledSubmit() || submitted} />
  </Modal>
};

const mapStateToProps = ({ modal }) => ({ decision: modal.decision })
export default connect(mapStateToProps, { reloadContacts, resetDecisionSetData })(DecisionEntryModal);

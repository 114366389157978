import React from "react";

export const SectionName = ({ treeNode }) =>
  <span className="selectable-area decision-order text-truncate">
    <span id={`${treeNode.tId}_span`} className={'node_name'}>{treeNode.name}</span>
  </span>

const ZTreeSectionNode = ({ treeNode }) => {
  return <div className="d-flex align-items-center h-inherit justify-content-between">
    <SectionName treeNode={treeNode} />
  </div>
}
export default ZTreeSectionNode;

import React, {forwardRef, useRef} from 'react';
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.bubble.css'
import 'react-quill/dist/quill.snow.css';
import { isBlankRichTextValue } from "../helpers/rich_text_helpers";
import { isPresent } from "../helpers/common";
import Linkify from 'react-linkify';
import parse from 'html-react-parser';
import MoreLessText from "./MoreLessText";

export const RichMutedText = ({ text = '', showMoreLess = false }) => {
  const textMuted = <span className='text-muted note_paragraph' >{parse(text || '')}</span>

  return <MoreLessText {...{showMoreLess, textMuted}} />
}

export const RichTextSection = ({ text, objectComment, showMoreLess = false, ...opts }) => {
  const edited = objectComment?.is_edited ? '<span class="text-muted">(edited)</span>' : '';
  const textEdited = isPresent(text) ? text.replace(/(<\/?p>)$/, ' ') + edited : '';
  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} rel="noopener noreferrer" target="_blank">
      {text}
    </a>
  );

  const textMuted = <Linkify componentDecorator={componentDecorator} {...opts}>
    {parse(textEdited)}
  </Linkify>

  return <MoreLessText {...{showMoreLess, textMuted}} />
}

const FORMATS = ['link', 'size', 'bold', 'italic', 'underline', 'list', 'indent', 'strike']

const MODULES = {
  toolbar:{
    container: [
      ['bold', 'italic', 'underline', 'strike'],
      ['link'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }]
    ],
    handlers:{
      link: function(value) {
        const that = this
        const tooltip = that.quill.theme.tooltip
        const input = tooltip.root.querySelector("input[data-link]")
        input.dataset.link = "https://cloverpop.com"
        input.placeholder = "https://cloverpop.com"
        input.dataset.lpignore = true;

        if (value) {
          const range = that.quill.getSelection()
          if (range == null || range.length === 0)  return
          let preview = that.quill.getText(range)
          if (
              /^\S+@\S+\.\S+$/.test(preview) &&
              preview.indexOf("mailto:") !== 0
          ) {
            preview = `mailto:${preview}`
          }
          const { tooltip } = that.quill.theme
          tooltip.edit("link", preview)
        } else {
          that.quill.format("link", false)
        }
      }
    }
  }
}

export const RichTextEditor = forwardRef(({ quillId, setValue, maxLength = 0, theme = 'snow', ...opts }, ref) => {
  const reactQuillRef = ref || useRef();
  const filteredQuillId = quillId.replace(/'/g,'')

  const setInputValue = (editor, value) => {
    if (editor) {
      if (isBlankRichTextValue(value)) {
        setValue('')
      } else {
        setValue(value)
      }
    }
  }
  const checkCharacters = (value) => {
    const editor = reactQuillRef.current?.getEditor();
    if (maxLength > 0 && editor?.getLength() - 2 >= maxLength) {
      editor.deleteText(maxLength, editor.getLength() - 2);
      return;
    }
    setInputValue(editor, value)
  };

  return <div data-quill={`${filteredQuillId}`} className="position-relative w-100">
    <ReactQuill theme={theme}
                ref={reactQuillRef}
                modules={MODULES}
                formats={FORMATS}
                onChange={checkCharacters}
                bounds={`[data-quill='${filteredQuillId}']`}
                key={filteredQuillId}
                {...opts}/>
  </div>
})

import React from 'react';
import { DecisionDescription } from "./ZTreeDecisionNode";
import RemoveDecision from './actions/RemoveDecision';

const ZTreeHoverDecisionNode = ({ treeNode, ...opts }) => {
  return <div className="d-flex align-items-center h-inherit">
    <DecisionDescription treeNode={treeNode}/>
    <div className="ms-auto">
      <RemoveDecision treeNode={treeNode} {...opts} />
    </div>
  </div>
}
export default ZTreeHoverDecisionNode;

import React, {useEffect, useMemo, useRef, useState} from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {
  isHomepageSectionLoaded,
  isHomepageSectionLoading,
  loadAssignedObjectsSection,
  needToLoadHomepageSection
} from "../../../store/homepage/actions";
import {isBlank, paginateArray, qSortArray} from "../../../helpers/common";
import {Loader} from "../../../common/Loader";
import {safeDescription} from "../../../helpers/decision_helpers";
import DecisionTile, {buttonTextColor, inFlightButtonText} from "../tiles/DecisionTile";
import {decisionLink} from "../tiles/helpers";
import {Pagination} from "../../../common/Pagination";
import {scrollTopSection} from "./DecisionsSection";

const DRIVERS_PER_DECISION_LIMIT = 5
export const TABS_PER_PAGE = 12
const DEFAULT_TOTAL_PAGES = 1
const DEFAULT_INITIAL_PAGE = 1

const minDueDateDriver = (data) => qSortArray(data, true, d => d.driver.due_date)[0];

const collectGroupedDrivers = (drivers) => {
  const groupedDrivers = {};
  drivers.forEach((driver) => {
    if (isBlank(groupedDrivers[driver.decision.slug])) {
      groupedDrivers[driver.decision.slug] = { decision: driver.decision, drivers: [], marked: false }
    }
    groupedDrivers[driver.decision.slug].drivers.push(driver)
  })
  return groupedDrivers;
}

const prepareGroupedDriversDecisionsData = (drivers, groupedDrivers, decisions) => {
  const driversArr = [];
  const decisionsArr = [];
  drivers.forEach(hash => {
    const decisionData = groupedDrivers[hash.decision.slug]
    if (decisionData.drivers.length >= DRIVERS_PER_DECISION_LIMIT) {
      if (!decisionData.marked) {
        driversArr.push({...decisionData, type: 'GroupedDrivers',
          due_date: minDueDateDriver(decisionData.drivers).driver.due_date || hash.decision?.due_date})
        decisionData.marked = true
      }
    } else {
      driversArr.push({ ...hash, type: 'Driver', due_date: hash.driver?.due_date || hash.decision?.due_date})
    }
  })
  decisions.forEach(d => {decisionsArr.push({ ...d, type: 'Decision'})})
  const result = driversArr.concat(decisionsArr)
  return qSortArray(result, true, d => d.due_date);
}

const groupDriversDecisions = (assigned_drivers = [], assigned_decisions = []) => {
  const groupedDrivers = collectGroupedDrivers(assigned_drivers);
  return prepareGroupedDriversDecisionsData(assigned_drivers, groupedDrivers, assigned_decisions);
}

const DriverTile = ({ decision, type, driver = {}, drivers = [] }) => {
  const subTitle = type === 'GroupedDrivers' ? `${drivers.length} assigned drivers` : driver.question
  const link = type === 'GroupedDrivers' ? `${decisionLink(decision)}?side_bar_open=decision_tree` : `${decisionLink(decision)}?driver_open=${driver.slug}`
  const driverData = type === 'GroupedDrivers' ? minDueDateDriver(drivers).driver : driver

  return <div className="col col-md-6 col-lg-4 col-xl-3 homepage_tile mb-3">
    <Link to={link} target="_self" className="text-dark text-center">
      <div className="tile-content bg-white pt-3 px-2 pb-2">
        <h4 className="decision-title">
          {safeDescription(decision)}
        </h4>
        <div className="fw-bolder lh-normal assignedSubTitleRow mb-3">
          {subTitle}
        </div>
        <h5 className="rounded-pill bg-light w-100">
          <span className={buttonTextColor(decision, false, false, driverData)}>
            {inFlightButtonText(decision, driverData)}
          </span>
        </h5>
      </div>
    </Link>
  </div>
}

export const refreshPaginationCallback = (tiles, setTotalPages, page, setPage) => {
  useEffect(() => {
    if (isBlank(tiles)) {
      setTotalPages(DEFAULT_TOTAL_PAGES)
      setPage(DEFAULT_INITIAL_PAGE)
    } else {
      const pages = tiles.length / TABS_PER_PAGE;
      const newTotalPages = pages > parseInt(pages.toString()) ? parseInt(pages.toString()) + 1 : pages
      setTotalPages(newTotalPages)
      if (page > newTotalPages) setPage(DEFAULT_INITIAL_PAGE)
    }
  }, [tiles])
}

const AssignedDriversSection = ({
                                  home,
                                  loadAssignedObjectsSection
                               }) => {
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  useEffect(() => {
    if (needToLoadHomepageSection(home, 'assigned_objects')) { loadAssignedObjectsSection() }
  }, [home.assigned_objects.loaded])

  const { assigned_drivers, assigned_decisions } = home.assigned_objects
  const tiles = groupDriversDecisions(assigned_drivers, assigned_decisions)
  const recordsPerPage = useMemo(() => paginateArray(tiles, page, TABS_PER_PAGE), [page, tiles])
  const refHeader = useRef(null)

  refreshPaginationCallback(tiles, setTotalPages, page, setPage)

  if (isBlank(tiles) && home.assigned_objects.loaded) return null;

  return <div>
    <div className="row scroll-margin-tab" ref={refHeader}>
      <div className="col">
        <h1>Assigned to you</h1>
      </div>
    </div>
    <div className="row" hidden={isHomepageSectionLoading(home, 'assigned_objects')}>
      {
        recordsPerPage.map((t, i) =>
          t.type === 'Decision' ?
            <DecisionTile decision={t} key={`assigned-decisions-tile-${t.slug}`} /> :
            t.type === 'GroupedDrivers' ?
              <DriverTile {...t} key={`decisions-center-driver-tile-${i}`} />:
              <DriverTile {...t} key={`decisions-center-decision-drivers-tile-${t.decision.slug}-${i}`} />
        )
      }
    </div>
    <div className="row" hidden={isHomepageSectionLoaded(home, 'assigned_objects')}>
      <Loader />
    </div>
    <div className="row mb-3" hidden={home.assigned_objects.loading || totalPages < 2} onClick={() => scrollTopSection(refHeader)}>
      <Pagination page={page} totalPages={totalPages} setPage={setPage} />
    </div>
  </div>
}
const mapStateToProps = ({ home }) => ({ home });
export default connect(mapStateToProps, { loadAssignedObjectsSection })(AssignedDriversSection);

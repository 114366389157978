import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { loadContacts } from "../store/contacts/actions";
import { closeModal } from "../store/modals/actions";
import { loadForecastSimulatorScenario, updateScenarioData } from "../store/forecast_simulator_scenario/actions";
import { checkLoadingEffect, isStoreLoading } from "../helpers/callbacks_helpers";
import { useWhiteBackgroundEffect } from "./helpers/helpers";
import { Loader } from "../common/Loader";
import TabManager from "./TabManager";
import Wrapper from "./Wrapper";

export const TABS = {
  table: 'table',
  charts: 'charts',
  decomposition: 'decomposition'
}

export const TAB_TITLES = {
  table: 'Table',
  charts: 'Charts',
  decomposition: 'Decomposition'
}

const ForecastSimulatorScenario = ({
                                     contactsData, forecast_simulator_scenario, modal,
                                     loadContacts, loadForecastSimulatorScenario, closeModal, updateScenarioData
                                   }) => {
  const [activeTab, setActiveTab] = useState(TABS.table);
  const gridRef= useRef();
  const { scenario_id } = useParams();
  const wrapperOpts = { modal, closeModal, activeTab, setActiveTab, gridRef };
  checkLoadingEffect(contactsData, loadContacts);
  useEffect(() => {
    if (forecast_simulator_scenario.loaded && scenario_id !== forecast_simulator_scenario.scenario_id)
      updateScenarioData({ loaded: false })
  }, [scenario_id]);
  checkLoadingEffect(forecast_simulator_scenario, () => loadForecastSimulatorScenario(scenario_id));
  useWhiteBackgroundEffect();

  if (isStoreLoading(forecast_simulator_scenario)) {
    return <Wrapper {...wrapperOpts}>
      <Loader />
    </Wrapper>;
  } else {
    return <Wrapper {...wrapperOpts} scenarioIsLoaded={true}>
      <TabManager activeTab={activeTab} gridRef={gridRef} />
    </Wrapper>
  }
}

const mapStateToProps = ({ contacts, forecast_simulator_scenario, modal }) => ({
  contactsData: contacts, forecast_simulator_scenario, modal
});
export default connect(mapStateToProps, {
  loadContacts, loadForecastSimulatorScenario, closeModal, updateScenarioData
})(ForecastSimulatorScenario);

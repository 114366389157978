import React, { useCallback, useEffect, useState } from 'react';
import { connect } from "react-redux";
import { isBlank, isPresent } from "../../helpers/common";
import Modal from "react-bootstrap/Modal";
import Select from 'react-select';
import Button from "react-bootstrap/Button";
import CloseIcon from "../../common/CloseIcon";
import { DESCRIPTION_LIMIT } from "../../models/category_option";
import CharCounter from "../../common/CharCounter";
import { resetErrorsEffect } from "../../helpers/callbacks_helpers";
import { ChoiceEntryStyle } from "../../tree_wizard/styles/choice_entry_styles";
import { DISPLAY_NAME_LIMIT } from "../../models/forecast/ForecastScenario";

const NewScenarioModal = ({
                            shown, home,
                            onClose = () => {},
                            onSubmit = () => {}, setLoader = () => {}
                          }) => {
  const focusElement = React.useRef(null);
  useEffect(() => {
    if (shown && focusElement.current) focusElement.current.focus()
  }, [shown, focusElement])

  const { configs } = home.forecast_simulator.data;
  const configsOptions = configs.map(hash => ({ label: hash.data.attributes.name, value: hash.data.id }))

  const [name, setName] = useState('');
  const [config_id, setConfig] = useState(configs[0]?.data?.id);
  const [submit, setSubmit] = useState(false);
  const [errors, setErrors] = useState({})
  const [showChar, setShowChar] = useState(false);

  useEffect(() => {
    setConfig(configs[0]?.data?.id)
  }, [configs]);

  resetErrorsEffect(setErrors, {}, name);

  const submitModal = () => {
    setSubmit(true)
    onSubmit(config_id, name, (success, errors) => {
      setSubmit(false)
      if (success) {
        setLoader(true);
        close();
      } else if (isPresent(errors) && isPresent(Object.keys(errors))) {
        setErrors(errors)
      }
    })
  }

  const close = () => {
    setName('')
    setConfig(configs[0]?.data?.id)
    onClose()
  }

  useEffect(() => {
    console.log(config_id)
  }, [config_id]);

  return <Modal size="md" backdrop="static" show={shown} onHide={close}>
    <Modal.Body>
      <CloseIcon onClose={close} />
      <h2>New scenario</h2>
      <h3>Scenario Name</h3>
      <CharCounter show={showChar} field={name} limit={DISPLAY_NAME_LIMIT} id={`addBadgePill-${name}`}  />
      <div className="mb-3">
        <input className={`form-control ${errors['name'] ? 'is-invalid' : ''}`}
               placeholder="Enter the name of new scenario"
               ref={focusElement} value={name}
               autoFocus={shown} disabled={submit} maxLength={DISPLAY_NAME_LIMIT}
               onChange={(e) => setName(e.target.value)}
               onBlur={() => setShowChar(false)}
               onFocus={() => setShowChar(true)}
        />
        <span className={`d-block mt-1 ${ errors['name'] ? 'text-danger' : 'hide-text' }`}>
          {errors['name']}
        </span>
      </div>

      {
        configs.length < 2 ? <></> :
          <>
            <h3>Forecast model</h3>
            <div className="mb-3">
              <Select onChange={(option) => setConfig(option.value)}
                      defaultValue={configsOptions[0]}
                      value={configsOptions.find(hash => hash.value === config_id)}
                      isDisabled={submit}
                      components={{ IndicatorSeparator:() => null }}
                      options={configsOptions}
                      placeholder={'Make a selection'}
                      styles={ChoiceEntryStyle} />
            </div>
          </>
      }

      <Button onClick={submitModal} className="w-100 mt-3" disabled={isBlank(name) || isBlank(config_id) || submit}>
        Done
      </Button>
    </Modal.Body>
  </Modal>
}
const mapStateToProps = ({ home }) => ({ home });
export default connect(mapStateToProps)(NewScenarioModal);

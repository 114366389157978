import React from 'react';
import { connect } from "react-redux";
import * as moment from "moment";
import {RichTextSection} from "../../../common/RichTextEditor";
import {isBlank} from "../../../helpers/common";
import {isNil} from "ramda";
import UserAvatarImage from "../../../common/UserAvatarImage";
import { userName } from "../../../helpers/user_helpers";

const ApprovalRow = ({ approved, approved_comment, user, created_at, canceled }) => {
  if (isBlank(user) || canceled) return null;

  return <div className="mb-3">
    <h4>{ approved ? 'Approved' : 'Not approved' }</h4>
    {!isNil(approved) && <RichTextSection text={approved_comment}/>}
    <div className="d-flex align-items-center mt-2">
      <span className="collaborator_img">
        <UserAvatarImage user={user} size='sm' />
      </span>
      <div className="text-muted ms-2">
        Entered by {userName(user)} {moment(created_at).format('DD MMM, yyyy')}
      </div>
    </div>
  </div>
}
const mapStateToProps = ({ tree }) => ({
  default_user_avatar_url: tree.default_user_avatar_url
});
export default connect(mapStateToProps)(ApprovalRow);

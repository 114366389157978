import $ from "jquery";
import React, { useEffect, useRef } from "react";
import {connect, Provider} from "react-redux";
import { render } from "react-dom";
import { isBlank } from "../../helpers/common";
import EntryPoint from "../../EntryPoint";
import ZTreeSectionNode from "./nodes/ZTreeSectionNode";
import ZTreeHoverSectionNode from "./nodes/ZTreeHoverSectionNode";
import { MOVE_TYPES } from "../wizard/ZTree";
import {
  beforeCollapseCallback,
  onUnmountZTree,
} from "../tree_view/ZTree";
import { BASE_ZTREE_SETTING } from "../../template_set_view/side_panel/sections/TreeSection";
import {updateDecisionSetData} from "../../store/decision_set/common_actions";
import {resetCopyPaste} from "../tree_view/DriverActions";

const BASE_NODE_SETTINGS = {
  isRoot: true, isTemplateSet: false, children: [], slug: '',
  open: true, name: '', drag: true, drop: true, section: {}
}

const removeHoverDom = (treeId, treeNode) => addDiyDom(treeId, treeNode);

const addDiyDom = (treeId, treeNode) => {
  const $sectionRow = $(`#${treeNode.tId}_a`);
  render(<Provider store={EntryPoint.instance.store}>
    <ZTreeSectionNode key={`ztree-section-node-${treeNode.slug}`} treeNode={treeNode} />
  </Provider>, $sectionRow[0]);
}

const addHoverDom = (treeId, treeNode, isTemplateSet) => {
  if(treeNode.isRoot) return null;

  const $sectionRow = $(`#${treeNode.tId}_a`);
  render(<Provider store={EntryPoint.instance.store}>
    <ZTreeHoverSectionNode key={`ztree-hover-section-node-${treeNode.slug}`} treeNode={treeNode} isTemplateSet={isTemplateSet} />
  </Provider>, $sectionRow[0]);
}

const preventDropCallback = (targetNode, moveType) => isBlank(targetNode) || isBlank(moveType) ||
  targetNode.isRoot || moveType === MOVE_TYPES.inner;

const onDropNode = (treeNode, treeNodes, treeId, dragSectionAction, updateDecisionSetData) => {
  const treeObj = $.fn.zTree.getZTreeObj(treeId);
  const section_slugs = treeObj.getNodes()[0].children.map((node) => node.slug)
  dragSectionAction(treeNode.slug, { section_slugs })
  resetCopyPaste(updateDecisionSetData)
}

const zTreeEditModeSettings = (set, isTemplateSet, dragSectionAction, updateDecisionSetData) => {
  return {
    edit: { enable: true, drag: { isCopy: true, isMove: true }, showRemoveBtn: false, showRenameBtn: false },
    view: {
      addDiyDom: (treeId, treeNode) => addDiyDom(treeId, treeNode),
      addHoverDom: (treeId, treeNode) => addHoverDom(treeId, treeNode, isTemplateSet),
      removeHoverDom: (treeId, treeNode) => removeHoverDom(treeId, treeNode),
    },
    callback: {
      beforeCollapse: (treeId, treeNode) => beforeCollapseCallback(treeId, treeNode, set),
      beforeClick: (treeId, treeNode) => {
        updateDecisionSetData({ selected_ztree_node: treeNode });
        return true;
      },
      beforeDrag: (treeId, treeNodes) => !treeNodes.some((node) => !node.drag),
      beforeDrop: (treeId, treeNodes, targetNode, moveType) => !preventDropCallback(targetNode, moveType),
      onDrop: (event, treeId, treeNodes, targetNode, moveType, isCopy) => {
        if(isBlank(moveType)) return false;

        onDropNode(targetNode, treeNodes, treeId, dragSectionAction, updateDecisionSetData);
        return true;
      }
    }
  }
}

const generateSectionObject = ({
                                 section = {},
                                 isRoot = false,
                                 isTemplateSet =  false,
                                 children = [],
                                 open = false
                               }) => {
   const result = {
     ...BASE_NODE_SETTINGS,
     isRoot,
     isTemplateSet,
     open,
     slug: section.slug,
     name: section.name,
     drag: !isRoot,
     drop: !isRoot
  };

  if (children.length > 0) result['children'] = children.map(hash =>
    generateSectionObject({ section: { ...hash }, children: [], isTemplateSet })
  )
  return result;
}

const ZTree = ({ zTreeId = 'setSectionsTree', set, isTemplateSet = false, preview, dragSectionAction,
                 updateDecisionSetData, sidebar}) => {
  const ref = useRef(null)

  useEffect(() => {
    if (isBlank(set.sections)) return;

    const stateSettings = zTreeEditModeSettings(set, isTemplateSet, dragSectionAction, updateDecisionSetData)
    const setting = { ...BASE_ZTREE_SETTING, ...stateSettings };
    const nodes = generateSectionObject({
      ...BASE_NODE_SETTINGS,
      section: { ...set, name: set.name },
      children: set.sections,
      isTemplateSet,
      isRoot: true,
      open: true
    });
    $.fn.zTree.init($(ref.current), setting, nodes);
    return () => onUnmountZTree(() => resetCopyPaste(updateDecisionSetData), zTreeId);
  }, [set.sections])

  useEffect(() => {
    if(set.selected_ztree_node?.isRoot) {
      updateDecisionSetData({ selected_ztree_node: {} })
    }
  }, [set.selected_ztree_node])

  useEffect(() => {
    if (!sidebar.setSectionsSidebar) {
      resetCopyPaste(updateDecisionSetData)
    }
  }, [sidebar.setSectionsSidebar])

  return <div>
    <div className="text-muted my-1" hidden={preview}>Drag and drop a section title to change the display order. Removing a section will not remove decisions from the flow.</div>
    <div className={"tree-container decision-order"}>
      {
        isBlank(set.sections) ?
          <div className="fw-bolder py-2">{set.name}</div> :
          <ul id={zTreeId} className="ztree p-0" ref={ref} />
      }
    </div>
  </div>
}
const mapStateToProps = ({sidebar}) => ({sidebar});
const mapDispatchToProps = (dispatch) => ({
  updateDecisionSetData: (data) => dispatch(updateDecisionSetData(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(ZTree);

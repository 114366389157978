import {isBlank, sortSourceByType} from "../../../helpers/common";
import DataSource, {PDF_IMAGE_FILE_TYPES} from "../../../models/data_source";
import React from "react";
import {ImageRun, Paragraph, TextRun} from "docx";
import {mutedProps, PTS_4} from "../../styles/docx_styles";
import {baseHeadParagraph, externalLink, internalLink} from "./wrappers";
import {imageRow} from "../ImagesSupplement";

export const MAX_WIDTH = 695

export const dataSourcesBlockSection = ({data_sources, fromRecommendation= false}) => {
  const dataSources = fromRecommendation ?
    data_sources.filter((source) => source.hide_in_recommendation_panel === false) :
    data_sources.filter((source) => source.hide_in_decision_panel === false)

  if (isBlank(dataSources)) return [];
  const sortedDataSources = sortSourceByType(dataSources, PDF_IMAGE_FILE_TYPES)

  return dataSourcesSection(sortedDataSources)
}

export const dataSourcesSection = (sortedDataSources) => {
  return [
    baseHeadParagraph({text: 'Data Sources'}),
    ...sortedDataSources.map((data_source) => {
      const sourceObj = new DataSource(data_source);
      return sourceObj.isPdfImage ?
        dataSourceImage(data_source) :
        dataSourceBlock(data_source)
    })
  ]
}

export const dataSourceImage = (data_source) =>
  new Paragraph({
    spacing: { before: PTS_4, after: PTS_4 }, // eq 4.5pts,
    children: [
      imageRow(data_source)
    ],
  });

export const dataSourceBlock = (data_source) => {
  if (isBlank(data_source)) return null;

  const sourceObj = new DataSource(data_source);
  const host = window.location.protocol + "//" + window.location.host;

  return new Paragraph({
    style: "normalPara",
    children: [
      sourceObj.isLink && !sourceObj.isDSight ?
        sourceObj.isPdfImage ?
          internalLink({
            text: data_source.title || data_source.link_url,
            anchor: `data-source-${data_source.slug}`
          })
          :
          externalLink({
            linkText: data_source.title || data_source.link_url,
            link: data_source.link_url
          })
        :
        sourceObj.isDSight ?
          sourceObj.isPdfImage ?
            internalLink({
              text: data_source.title || data_source.link_url,
              anchor: `data-source-${data_source.slug}`
            })
            :
            externalLink({
              linkText: data_source.title || data_source.link_url,
              link: (host + data_source.link_url)
            })
          :
          sourceObj.isPdfImage ?
            internalLink({
              text: sourceObj.fileName,
              anchor: `data-source-${data_source.slug}`
            })
            :
            externalLink({
              linkText: sourceObj.fileName,
              link: (host + data_source.url)
            }),
      new TextRun({
        text: `${sourceObj.isFile ? ` (${sourceObj.fileExtension})` : ''}`,
        allCaps: true,
        ...mutedProps,
        bold: false
      }),
    ]
  })
}
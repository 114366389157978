import {isBlank} from "../../../helpers/common";
import {Paragraph} from "docx";
import {baseRichBlock, bulletParagraph} from "./wrappers";

const rankedDecisionBlock = (decisionObj) =>
  decisionObj.finalDecisions.map((c, index) =>
    new Paragraph({
      style: 'normalPara',
      text: `${index + 1}. ${c.description}`,
    })
  )

const pickyDecisionBlock = (decisionObj) => decisionObj.finalDecisions.map(choice =>
  bulletParagraph({text: choice.description})
)

export const finalDecisionSection = ({obj, instance}) => {
  if (isBlank(obj.finalDecisions)) return null;
  if (obj.isRankedList) return rankedDecisionBlock(obj)
  if (obj.isPicky) return pickyDecisionBlock(obj)

  const text = obj.finalDecisions.map(c => c.description).join(', ')

  return [
    ...baseRichBlock({text, instance}),
  ]
}
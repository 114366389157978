import {arrayToSentence, isBlank, isPresent} from "./common";

export const editDriverTitle = (allowedAssignTo) => allowedAssignTo ? 'Edit driver' : 'Edit or assign driver'

export function driversToArray(drivers) {
  const result = [];
  const addDriverToArray = (driver) => {
    result.push(driver);
    if (driver.children && driver.children.length > 0) {
      driver.children.forEach(d => addDriverToArray(d))
    }
  }
  drivers.forEach(d => addDriverToArray(d))
  result.forEach(h => {
    if (h.driver.parent_id !== null) {
      h.driver.parent = result.find(d => d.children.some(c => c.driver.slug === h.driver.slug))?.driver
    }
  })
  return result;
}

export function getParentsDrivers(drivers) {
  return drivers.filter(d => !d.driver.parent_id && d.driver.question)
}

export const RATING_SCALES_DATA = {
  '0-100 rating scale': {title: 'Rate this choice from 0 to 100.', start_scale: 0, end_scale: 100},
  '0-10 rating scale': {title: 'Rate this choice from 0 to 10.', start_scale: 0, end_scale: 10},
  '1-7 rating scale': {title: 'Rate this choice from 1 to 7.', start_scale: 1, end_scale: 7},
  '1-5 rating scale': {title: 'Rate this choice from 1 to 5.', start_scale: 1, end_scale: 5},
  '1-3 rating scale': {title: 'Rate this choice from 1 to 3.', start_scale: 1, end_scale: 3},
  'Yes or No buttons (rate 100 or 0)': {yes_or_no: true}
}

export function calculateRatingScore (rating, startScale, endScale) {
  return Math.round((rating - startScale) / (endScale - startScale) * 100)
}

export const MIN_DRIVERS_FOR_GPT_REQUEST = 2

const fullName = (user) => {
  const firstName = user?.first_name || '';
  const lastName = user?.last_name || '';

  return `${firstName} ${lastName}`.trim();
};

export const assignedByUsersToString = (drivers) => {
  if (isBlank(drivers)) return '';

  const uniqueUsers = drivers.reduce((uniqueUserMap, d) => {
    const assignedByUser = d.driver.assigned_by_user;
    if (isPresent(assignedByUser)) {
      const fullNameStr = fullName(assignedByUser)
      uniqueUserMap.set(fullNameStr, assignedByUser);
    }
    return uniqueUserMap;
  }, new Map());

  const uniqueUserArray = Array.from(uniqueUsers.values());
  const fullNames = uniqueUserArray.map(user => fullName(user));

  return arrayToSentence(fullNames)
};

export const filterHashEmptyValues = (data) => Object.fromEntries(
  Object.entries(data).filter(([_, value]) => isPresent(value))
);

export const TREE_MODES = { edit: 'Edit decision tree', assign: 'Assign pending items' }

import React, { useEffect } from 'react';
import { connect } from "react-redux";
import Button from 'react-bootstrap/Button'
import { resetWizard, saveWizardStepData, updateWizardData } from "../../../store/wizard/actions";
import DriverDetailsModal, { showDriverDetailsModal } from "../../modals/DriverDetailsModal";
import DecisionDetailsModal, { showDecisionDetailsModal } from "../../modals/DecisionDetailsModal";
import { closeModal, resetModal } from "../../../store/modals/actions";
import { isPresentTitleNotes } from "../../../tree_editor/wizard/nodes/actions/EditDecision";
import { categorizationStepState, getInitialCategoriesData } from "./TreeDecisionCategorizationStep";
import { isBlank } from "../../../helpers/common";
import { buildDrivers, saveDriversCallback, submitBuilderStepDataCallback } from "./helpers/tree_builder_step";
import { resetTree, updateTreeData } from "../../../store/tree/common_actions";
import { updateDecisionData } from "../../../store/decision/common_actions";
import { stepTreeData, wizardSetCallbacks } from "../../header/helpers";
import { isHistoricalFlow, stepWithDrivers } from "../../../helpers/wizard_helpers";
import WizardPreviewTree from "../../../tree_view/accordion_tree/WizardPreviewTree";
import { startDecisionCallback } from "../../header/ActionsDropdown";
import { forceReloadHomepageDecisions, resetSearchAndFilters } from "../../../store/homepage/actions";
import { resetSidebars } from "../../../store/sidebar/actions";
import { resetPlaybookNotes } from "../../../store/playbook_notes/actions";

export const TreePreviewStep = ({
                                  tree, wizard, decision, modal, org_categories, contactsData,
                                  stepRef,
                                  saveWizardStepData, closeModal, resetModal, updateWizardData, updateTreeData, updateDecisionData,
                                  forceReloadHomepageDecisions,
                                  resetSearchAndFilters, resetSidebars, resetWizard, resetTree, resetPlaybookNotes,
                                  ...opts
                               }) => {
  const submitStep = submitBuilderStepDataCallback({
    drivers: tree.drivers,
    decision: { description: decision.description },
    response: { step_index: wizard.step_index }
  }, stepWithDrivers(wizard), saveWizardStepData)

  stepRef.current.submitStep = (additional_data = {}, callback = () => {}) => {
    submitStep(additional_data, (success, wizardData) => callback(success, false, wizardData))
  };
  const onClose = () => {
    closeModal();
    resetModal();
  }

  const { disabledStep, categoriesData, setCategoriesData, stepData } = categorizationStepState({ wizard, org_categories })
  const disabledSubmit = () => {
    if (isBlank(decision.description)) return true;

    return disabledStep();
  }
  useEffect(() => {
    setCategoriesData(getInitialCategoriesData(org_categories, stepData));
  }, [stepData])
  useEffect(() => {
    updateWizardData({ disabledSubmit: disabledSubmit() })
  }, [decision.description, categoriesData])
  useEffect(() => {
    if (!tree.loaded && !isHistoricalFlow(wizard)) {
      const drivers = buildDrivers({ tree, wizard, fetchFromTree: false });
      const saveDrivers = saveDriversCallback(updateTreeData, drivers);

      saveDrivers();
      updateDecisionData(stepTreeData(wizard, contactsData, decision))
    }
  }, [tree.loaded])

  const {
    resetSetCallback, setObjectSlug, objectSlug
  } = wizardSetCallbacks({ ...opts, isTemplate: false, decision })
  const completeWizardHandler = startDecisionCallback( {
    stepRef, history, isTemplate: false, setSlug: setObjectSlug(), wizard, slug: objectSlug(),
    complete: true, finish_later: false, preview: true,
    actions: [
      forceReloadHomepageDecisions,
      resetSearchAndFilters, resetSidebars, resetWizard, resetTree, resetPlaybookNotes,
      resetSetCallback, () => updateTreeData({ loaded: false })
    ]
  })

  return <div>
    <div className="modals">
      <DriverDetailsModal shown={showDriverDetailsModal(modal)} onClose={onClose} ref={stepRef} />
      <DecisionDetailsModal shown={showDecisionDetailsModal(modal)} onClose={onClose} showTitleNotes={isPresentTitleNotes(decision)} />
    </div>
    <WizardPreviewTree>
      <div className="mt-1">
        <Button onClick={completeWizardHandler} className="btn-primary btn w-100" disabled={wizard.submit}>Start decision</Button>
      </div>
    </WizardPreviewTree>
  </div>
}
const mapStateToProps = ({ tree, wizard, decision, modal, org_categories, contacts }) => ({
  tree, wizard, decision, modal, org_categories, contactsData: contacts
});
const wrapper = React.forwardRef((props, ref) => <TreePreviewStep {...props} stepRef={ref} />)
export default connect(mapStateToProps, {
  saveWizardStepData, closeModal, resetModal, updateWizardData, updateTreeData, updateDecisionData,
  forceReloadHomepageDecisions, resetSearchAndFilters, resetSidebars, resetWizard, resetTree, resetPlaybookNotes
}, null, { forwardRef: true })(wrapper);

import React from 'react';
import { connect } from "react-redux";
import DataSources from '../../data_sources';
import {
  createSource,
  destroySource,
  replaceSource,
  saveUploadingSources,
  updateSource,
  attachReport,
  addSourceToDecision
} from "../../../store/sidebar/actions";

const DataSourcesRow = ({ driverData, createSource, updateSource, destroySource, saveUploadingSources, replaceSource,
                          attachReport, addSourceToDecision, addClass = '', isDriverPanel = false }) => {
  return <div className={`mb-3 ${addClass}`}>
    <DataSources driverData={driverData}
                 createSource={createSource}
                 updateSource={updateSource}
                 destroySource={destroySource}
                 replaceSource={replaceSource}
                 attachReport={attachReport}
                 detachReport={destroySource}
                 addSourceToDecision={addSourceToDecision}
                 saveUploadingSources={saveUploadingSources}
                 isDriverPanel={isDriverPanel}
                 isSidebar={true}
    />
  </div>
}
const mapStateToProps = (_state) => ({});
export default connect(mapStateToProps, {
  createSource, updateSource, destroySource, saveUploadingSources, replaceSource, attachReport, addSourceToDecision
})(DataSourcesRow);

import React from 'react';
import { connect } from "react-redux";
import { setTreeSidebarOpen } from "../../store/sidebar/actions";
import BaseHamburgerBtn from "../../common/BaseHamburgerBtn";

const DecisionTreeHamburgerButton = ({ openDecisionTreeSideBar, title = 'Show decision tree', hidden = false }) =>
  !hidden && <BaseHamburgerBtn onSelect={openDecisionTreeSideBar} title={title} />

const mapDispatchToProps = (dispatch) => ({
  openDecisionTreeSideBar: () => dispatch(setTreeSidebarOpen(true))
});
export default connect(null, mapDispatchToProps)(DecisionTreeHamburgerButton);


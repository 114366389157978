import React from 'react';
import { isPresent, qSortArray, uniqueBy } from "../../../helpers/common";
import Decision from "../../../models/decision";
import {connect} from "react-redux";
import * as moment from "moment";
import PendingApprovalInviteRow from "./PendingApprovalInviteRow";
import {
  approvalWithUser,
  collectPendingApprovalInvites,
} from "../../../helpers/decision_helpers";
import Button from "react-bootstrap/Button";
import ApprovalRow from "./ApprovalRow";
import {isVisitor} from "../../../helpers/user_helpers";
import {isEmpty} from "ramda";
import SectionWrapper from "./SectionWrapper";

export const onChangeApprovalInvites = (selected, setApprovalInvites, filterNewApprovalInvites) => {
  const newApprovalInvites = [];
  selected.forEach(contact => {
    if (typeof contact === "string") {
      newApprovalInvites.push(contact)
    } else if (isPresent(contact.emails)) {
      contact.emails.split(',').forEach(email => newApprovalInvites.push(email))
    } else {
      newApprovalInvites.push(contact.value)
    }
  })
  setApprovalInvites(filterNewApprovalInvites(newApprovalInvites))
}

const ApprovalSection = ({decision, current_user, setShowEnterApprovalModal, isLegacy = false}) => {
  const decisionObject = new Decision(decision);
  if (!decisionObject.isWithApprovalInvites && !decisionObject.isWithApproval) return null

  const pendingApprovalInvites = uniqueBy(collectPendingApprovalInvites(decision), 'email');
  const collectApprovals = uniqueBy(decision.user_approvals.map(approval => approvalWithUser(approval, decision)), 'user_email')
  const approvals = qSortArray(collectApprovals, true, (o) => moment(o.created_at))

  return <SectionWrapper>
    <div className="px-3">
      <div className="d-flex">
        <h3>Approval</h3>
      </div>
      {!isEmpty(approvals) && <div>
        { approvals.map((approval, index) => <ApprovalRow {...approval} key={`approved-${index}`} />) }
      </div>}
      {!isEmpty(pendingApprovalInvites) && <div className='mb-3'>
        <h3>Pending</h3>
        { pendingApprovalInvites.map(invite => <PendingApprovalInviteRow {...invite} {...{isLegacy}} key={`approval-invite-${invite.slug}`} />) }
      </div>}
      <Button hidden={isVisitor(current_user) || isLegacy} onClick={() => { setShowEnterApprovalModal(true) }} className="btn btn-secondary w-100 mb-3">
        Enter approval
      </Button>
    </div>
  </SectionWrapper>
}

const mapStateToProps = ({ decision, current_user }) => ({ decision, current_user });

export default connect(mapStateToProps)(ApprovalSection);
import React from 'react';
import { connect } from "react-redux";
import { isHistoricalDecision, isLastStep, isWizardV3 } from "../../../helpers/wizard_helpers";
import { Button } from 'react-bootstrap'

export const SubmitStepWrapper = ({ className = 'w-100 mt-3', onClick, disabled, children }) =>
  <Button {...{ className, onClick, disabled }}>
    {children}
  </Button>

export const processStep = (wizard) => `Proceed to Step ${wizard.step_index + 2}`

export const StartBtn = ({wizard}) =>
  <>
    {
      isHistoricalDecision(wizard) ?
        <>Done</> :
        <><i className="fas fa-plus fa-lg m-0 align-middle" />&nbsp;Start decision</>
    }
  </>

export const PreviewBtn = () => 'Preview decision'

const SubmitStepButton = ({ wizard, children = null, ...opts }) =>
  <SubmitStepWrapper {...opts}>
    {
      children ||
      (
        isLastStep(wizard) ?
          isWizardV3(wizard) && !isHistoricalDecision(wizard) ? <PreviewBtn /> : <StartBtn wizard={wizard}/> :
          processStep(wizard)
      )
    }
  </SubmitStepWrapper>

const mapStateToProps = ({ wizard }) => ({ wizard });
export default connect(mapStateToProps)(SubmitStepButton);

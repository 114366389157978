import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import StyledDropzone from './DropFiles';
import { AddLinkRow } from '../../tree_view/data_sources';
import { DataSourcesList } from "./DataSourcesList";
import { UploadingSourcesList } from "../../template_view/side_panel/sections/DataSourcesSection";
import { generateBatchDataSourceUploadState } from "../../helpers/uploads_callbacks";
import Button from "react-bootstrap/Button";
import {qSortArray} from "../../helpers/common";

const DataSources = ({ report,
                       current_user,
                       dataSources, uploadingSources, saveUploadingSources,
                       createSource,
                       ...opts
                    }) => {
  const filterSourcesCriteria = (source) => !source.deleted && !source.hidden_on_wizard;
  const sorterDataSources = qSortArray(dataSources.filter(filterSourcesCriteria), true, (s) => s.created_at);
  const uploadOptions = generateBatchDataSourceUploadState(sorterDataSources, uploadingSources, saveUploadingSources, current_user)
  const [showDropzone, setShowDropzone] = useState(false);
  const focusDropzone = useRef(null);

  const scrollToBottom = () => {
    focusDropzone.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [showDropzone])

  const submitLink = (value, callback) => {
    let createParams = { report_data_source: { link_url: value } };
    createSource(createParams, {}, callback);
  }

  return <Fragment>
    <div className="mb-0">
      <h3 htmlFor="driverDataSources">Links & files</h3>
      <ul className="list-group">
        <DataSourcesList report={report} data_sources={sorterDataSources} user={current_user} {...uploadOptions} {...opts} />
        <UploadingSourcesList isReport={true} isTemplate={false}
                              uploading_sources={uploadingSources} user={current_user}
                              {...uploadOptions} {...opts} />
      </ul>
    </div>
    <AddLinkRow onClick={submitLink} />
    <Button className="btn btn-secondary w-100 mt-2"
            onClick={() => setShowDropzone(true)}
            hidden={showDropzone}>
      Add files
    </Button>
    <div className={`mt-2`} hidden={!showDropzone} ref={focusDropzone}>
      <StyledDropzone onCreateSource={createSource}
                      onStartUpload={uploadOptions.onStartUpload}
                      onFinishUpload={uploadOptions.onFinishUpload}
                      onProcessUpload={uploadOptions.onProcessUpload} />
    </div>
  </Fragment>
};

const mapStateToProps = ({ report, current_user }) => {
  const reportDataSources = report.data_sources || [];
  const uploadingSources = report.uploading_sources || [];

   return {
    report, current_user, uploadingSources,
    dataSources: reportDataSources,
  }
};
export default connect(mapStateToProps)(DataSources);


import React, { useState } from 'react';
import { connect } from "react-redux";
import { isLastStep, stepWithDrivers } from "../../../helpers/wizard_helpers";
import { saveWizardStepData } from "../../../store/wizard/actions";
import { loadContacts } from "../../../store/contacts/actions";
import { loadOrgDriverTypes } from "../../../store/org_driver_types/actions";
import Help from "../../help";
import { Table } from "react-bootstrap";
import WizardTreeEditor from '../../../tree_editor/wizard/WizardTreeEditor'
import SubmitStepButton from "./SubmitStepButton";
import { checkLoadingEffect } from "../../../helpers/callbacks_helpers";
import {
  buildDrivers, fetchContacts, onCopyDriverCallback,
  onRemoveDriverCallback,
  submitBuilderStepDataCallback, useAddedRootDriverEffect
} from "./helpers/tree_builder_step";
import { submitPreviewStepParam, submitTreeStep } from "../../helpers/decision_wizard_helpers";

const V2Header = () =>
  <>
    <span className="text-primary">Let’s make a decision! </span>
    Start with a tree including supporting data and insights.
  </>

const V1Header = ({ decision }) =>
  <>
    <span className="text-primary">Almost there! </span>
    {
      decision.from_template ? "Review the tree supporting the decision with data and insights." :
        "Create a tree supporting the decision with data and insights."
    }
  </>
export const TreeBuilderStep = ({
                                  tree, wizard, decision, contactsData, stepRef, loadContacts,
                                  saveWizardStepData, org_driver_types, loadOrgDriverTypes
                               }) => {
  checkLoadingEffect(contactsData, loadContacts);
  checkLoadingEffect(org_driver_types, loadOrgDriverTypes);
  const [submitState, setSubmitState] = useState(false)
  const [newAddedRootDriver, setNewAddedRootDriver] = useState(false)
  const [drivers, setDrivers] = useState(buildDrivers({ tree, wizard, setNewAddedRootDriver, newAddedRootDriver }))

  const onRemoveDriver = onRemoveDriverCallback(drivers, setDrivers);
  const onCopyDriver = onCopyDriverCallback(drivers, setDrivers);

  useAddedRootDriverEffect({
    step: stepWithDrivers(wizard),
    newAddedRootDriver, drivers,
    submitDrivers: saveWizardStepData
  })

  const submitStep = submitBuilderStepDataCallback({
    drivers,
    decision: { description: decision.description },
    response: { step_index: wizard.step_index },
    complete: isLastStep(wizard),
    preview: submitPreviewStepParam(wizard),
    finish_later: submitPreviewStepParam(wizard)
  }, stepWithDrivers(wizard), saveWizardStepData, setSubmitState, setDrivers);

  stepRef.current.submitStep = (additional_data = {}, callback = () => {}) => {
    submitStep(additional_data, (success, wizardData) => {
      setSubmitState(false)
      callback(success, false, wizardData)
    })
  };
  stepRef.current.submitDrivers = (new_drivers) => setDrivers(new_drivers);

  return <div className="bg-white rounded p-3 my-3 mx-auto tree_wizard">
    <div>
      <Table borderless className="mb-2">
        <tbody>
          <tr>
            <td className="d-flex p-0">
              <h1 className="d-table-cell mb-0">
                {
                  isLastStep(wizard) ? <V1Header decision={decision}/> : <V2Header/>
                }
              </h1>
              <div className="d-table-cell ms-auto">
                <Help />
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
      <WizardTreeEditor collaborators={fetchContacts(wizard, contactsData, true)}
                        ref={stepRef}
                        onRemoveDriver={onRemoveDriver}
                        onCopyDriver={onCopyDriver}
                        drivers={drivers}
                        setDrivers={setDrivers} />
      <SubmitStepButton onClick={() => submitTreeStep(wizard, stepRef)} disabled={wizard.submit || submitState} className="w-100 mt-4" />
    </div>
  </div>
}
const mapStateToProps = ({ tree, wizard, decision, contacts, org_driver_types }) => ({
  tree, wizard, decision, org_driver_types,
  contactsData: contacts
});
const mapDispatchToProps = (dispatch) => ({
  saveWizardStepData: (step, data, callback) => { dispatch(saveWizardStepData(step, data, callback)) },
  loadContacts: (callback) => { dispatch(loadContacts(callback)); },
  loadOrgDriverTypes: () => { dispatch(loadOrgDriverTypes()) }
});
const wrapper = React.forwardRef((props, ref) => <TreeBuilderStep {...props} stepRef={ref} />)
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(wrapper);

import React from "react";
import ExpandDriver from "./ExpandDriver";

export default ({ description, driverData, expanded, onClick = () => {} }) =>
  <div onClick={onClick} className="pointer d-inline-flex w-100" style={{maxWidth: `${expanded ? 'calc(100% - 36px)' : ''}`}}>
    <div className={`note_paragraph ${expanded ? 'h3' : ''} fw-bolder text-ellipsis`}>
      {description}
      <ExpandDriver {...{ expanded, driverData } } />
    </div>
  </div>

import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import styles from '../../styles/common'
import * as moment from "moment";
import {isBlank, isPresent} from "../../../helpers/common";
import Recommendation from "../../../models/recommendation";
import Decision from "../../../models/decision";
import {
  baseBlockMargin,
  DataSourcesBlockSection,
  DSightAnalysisBlock, ExpectedInvestmentBlock, ExpectedOpportunityBlock,
  ExpectedResultsBlock,
  FinalDecisionBlock,
  NextStepsDescriptionBlock,
  OtherChoicesBlock,
  RationaleBlock
} from './DecisionBlockSection'
import {SectionBlockRow} from "./DecisionDetailsPage";
import { userName } from "../../../helpers/user_helpers";

const DeciderBlock = ({ recommendation }) =>
  <View style={[styles.row, baseBlockMargin]}>
    <Text style={[styles.text, styles.muted]}>
      Entered by {userName(recommendation.user)}&nbsp;{moment(recommendation.recommended_at).format('DD MMM, yyyy')}
    </Text>
  </View>

const AdditionalSections = ({ decisionObj }) => {
  if(decisionObj.isDecisionRecorded) return null;

  return <DSightAnalysisBlock decision={decisionObj.decision} />
}

export default ({ decision, data_sources, drivers = [], subStyles = [] }) => {
  if (isBlank(decision.recommendation)) return null;

  const recommendation = decision.recommendation;
  const decisionObj = new Decision(decision, drivers);
  const recommendationObj = new Recommendation(recommendation);

  if (!decisionObj.isRecordedOrRecommended || isBlank(recommendation.recommended_at)) return null;

  return <View style={isPresent(decision.collaboration_context) ? '' : [...subStyles]}>
    <SectionBlockRow id={'recommendation_page'} header={'Recommendation'} blockSeparateMarginTop={false}>
      <FinalDecisionBlock decisionObj={recommendationObj} />
      <RationaleBlock decision={recommendation} />
      <NextStepsDescriptionBlock decision={recommendation} />
      <ExpectedResultsBlock decision={recommendation} />
      <ExpectedOpportunityBlock object={recommendation} />
      <ExpectedInvestmentBlock object={recommendation} />
      <DeciderBlock recommendation={recommendation} />
      <OtherChoicesBlock obj={recommendationObj} isRecommendation={true} />
      <AdditionalSections {...{ decisionObj }} />
      <DataSourcesBlockSection fromRecommendation={true} data_sources={data_sources}/>
    </SectionBlockRow>
  </View>
}

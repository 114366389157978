import React, {useEffect, useRef, useState} from 'react';
import { connect } from "react-redux";
import { updateRecommendation } from "../../../store/sidebar/actions";
import { isBlank } from "../../../helpers/common";
import { RichTextEditor } from "../../../common/RichTextEditor";

const RationaleInput = ({ decision, updateRecommendation }) => {
  if (isBlank(decision) || isBlank(decision.recommendation)) return null;
  const recommendation = decision.recommendation;
  if (isBlank(recommendation)) return null;

  const [reasons, setReasons] = useState(recommendation.final_decision_reasons || '');
  const onChangeReasons = (_e) => { updateRecommendation({ decision_recommendation: { final_decision_reasons: reasons, slug: recommendation.slug } }); };

  return <div className="mb-3 px-3" onBlur={onChangeReasons}>
    <h3>Rationale</h3>
    <RichTextEditor quillId={'Rationale'} value={reasons} setValue={setReasons}
                    placeholder="Enter a detailed explanation"
    />
  </div>
}

const mapStateToProps = (state) => ({ decision: state.sidebar.decision });
export default connect(mapStateToProps, { updateRecommendation })(RationaleInput);

import React from 'react';
import { connect } from "react-redux";
import {
  openModal
} from "../../../store/modals/actions";
import {isBlank, isPresent} from "../../../helpers/common";
import Decision from "../../../models/decision";
import { setDecisionInputSidebarOpen, updateFinalChoices, updateChoicesDraft } from "../../../store/sidebar/actions";
import { isDSightDecision } from "../../../helpers/home_decision_helpers";
import {BaseDropdownBtn} from "../../../common/BaseHamburgerBtn";
import {IconBtnDropdown} from "../../../common/dropdowns";

const MoreActionsFinalSection = ({ decision, openModal, modal, setDecisionInputSidebarOpen, updateFinalChoices, updateChoicesDraft, children, isDsight = false}) => {
  if (isBlank(decision) || isDsight) return null
  const decisionObj = new Decision(decision);
  const openEndedChoice = decisionObj.openEndedChoice[0];
  const needChoiceSubmission = () => isPresent(openEndedChoice) && !decisionObj.isDecisionRecorded && decisionObj.isOpenEnded;
  const onEditDecision = (e) => {
    e.preventDefault();
    openModal({
      decision,
      drivers: [],
      data_sources: [],
      slug: modal.slug,
      type: 'PanelDecisionEntryModal'})
  }
  const updateChoice = (choice) => {
    updateFinalChoices(choice.slug, {
      draft: true
    })
  }
  const submitChoices = () => {
    updateChoice(openEndedChoice)
  }

  const updateDecisionDraftState = () => {
    if (needChoiceSubmission()) {
      submitChoices();
    } else {
      updateChoicesDraft({ draft: true });
    }
    setDecisionInputSidebarOpen(false)
  }

  return <IconBtnDropdown id={'decision-MoreActionsFinalSection'}>
    <BaseDropdownBtn hidden={isDSightDecision(decision)} onSelect={onEditDecision}>
      Edit or assign decision
    </BaseDropdownBtn>
    { children }
    <BaseDropdownBtn onSelect={updateDecisionDraftState}>
      Save draft
    </BaseDropdownBtn>
  </IconBtnDropdown>
}
const mapStateToProps = (state) => ({ decision: state.sidebar.decision, modal: state.modal });

export default connect(mapStateToProps, {openModal, setDecisionInputSidebarOpen, updateFinalChoices, updateChoicesDraft})(MoreActionsFinalSection);

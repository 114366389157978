import React, { useEffect } from "react";
import { isBlank, successActions } from "../../../helpers/common";
import Decision, { ChoiceEntryType } from "../../../models/decision";
import Recommendation from "../../../models/recommendation";

export const defaultChoices = () =>
  [
    { slug: `new-${Date.now()}`, description: '' },
    { slug: `new-${Date.now() + 1}`, description: '' },
    { slug: `new-${Date.now() + 2}`, description: '' }
  ];

export const onChangeChoiceCallback = (choices, setChoices) => (value, slug) => {
  const updatedChoices = choices.map(choice => {
    if (choice.slug === slug) {
      return Object.assign({}, choice, { description: value });
    }
    return choice;
  });
  setChoices(updatedChoices);
};

const generateUniqueSlug = () => {
  return `new-${Date.now()}-${Math.random().toString(36).slice(2)}`;
};

export const addNewChoiceCallback = (setNewChoice, choices, setChoices) => () => {
  const newSlug = generateUniqueSlug();
  setNewChoice(true);
  setChoices([...choices, { slug: newSlug, description: '' }]);
};

const singleLinePaste = (text, activeElement, onChangeChoice, slug) => {
  const startPos = activeElement.selectionStart;
  const endPos = activeElement.selectionEnd;
  const newValue = activeElement.value.substring(0, startPos) + text + activeElement.value.substring(endPos);
  activeElement.value = newValue;
  activeElement.selectionStart = startPos + text.length;
  activeElement.selectionEnd = startPos + text.length;

  const inputEvent = new Event('input', { bubbles: true });
  activeElement.dispatchEvent(inputEvent);
  onChangeChoice(newValue, slug);
};

const multiLinePaste = (lines, choices, slug, setChoices, setNewChoice) => {
  let newChoicesAdded = false;
  let updatedChoices = [...choices];
  let choiceIndex = choices.findIndex(choice => choice.slug === slug);

  for (let i = choiceIndex; i < updatedChoices.length && lines.length > 0; i++) {
    if (!updatedChoices[i] || $('#rowDecisionChoice-' + updatedChoices[i].slug).hasClass('d-none')) {
      continue;
    }

    if (updatedChoices[i].description.trim() === '') {
      updatedChoices[i] = { ...updatedChoices[i], description: lines.shift() };
      newChoicesAdded = true;
    }
  }

  updatedChoices = [...updatedChoices, ...lines.map(line => ({ slug: generateUniqueSlug(), description: line }))];
  newChoicesAdded = newChoicesAdded || lines.length > 0;

  setChoices(updatedChoices);
  if (newChoicesAdded) {
    setNewChoice(true);
  }
};

export const onPasteChoiceCallback = (choices, setChoices, setNewChoice, onChangeChoice) => (event, slug) => {
  event.preventDefault();
  const pastedText = event.clipboardData.getData('text');
  const lines = pastedText.split(/\r?\n/).map(line => line.trim()).filter(line => line);

  if (lines.length === 0) return;

  const activeElement = document.activeElement;
  if (lines.length === 1 && activeElement) {
    singleLinePaste(pastedText, activeElement, onChangeChoice, slug);
  } else {
    multiLinePaste(lines, choices, slug, setChoices, setNewChoice);
  }
};

export const buildDecisionChoices = (decision) => {
  if(isBlank(decision.choices)) return defaultChoices();

  return new Decision(decision).sortedChoices(true).map((choice) => {
    return { slug: choice.slug, description: choice.description };
  });
};
export const buildRecommendationChoices = (recommendation) => {
  if(isBlank(recommendation.recommendation_choices)) return defaultChoices();

  return new Recommendation(recommendation).sortedChoices(true).map((choice) => {
    return { slug: choice.slug, description: choice.description };
  });
};
export const buildTemplateChoices = (template) => {
  if(isBlank(template.choices)) return defaultChoices();

  return template.choices.map((choice) => {
    return { slug: choice.slug, description: choice.description };
  });
};
export const buildResponseType = (decision) => {
  return decision.choice_entry_widget_type || ChoiceEntryType.OPEN_ENDED;
};
export const setModalDataEffect = (show, decision, setDescription = () => {}, setResponseType = () => {}, setChoices = () => {}, setAssignedToUser = () => {}) => {
  useEffect(() => {
    if (show) {
      setDescription(decision.description);
      setResponseType(buildResponseType(decision));
      setChoices(buildDecisionChoices(decision));
      setAssignedToUser(decision.assigned_collaborator_email);
    }
  }, [show, decision]);
};
export const submitEntryCallback = (updateAction, updateData, setSubmitted, onClose, callback, actions = []) => () => {
  setSubmitted(true);
  updateAction(updateData, success => {
    if (typeof callback === "function") {
      callback()
      successActions(success, actions);
    } else {
      setSubmitted(false);
      successActions(success, actions);
      onClose();
    }
  });
};

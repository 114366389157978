import React from 'react';
import { SectionName } from "./ZTreeSectionNode";
import AddDecision from './actions/AddDecision';

const ZTreeHoverSectionNode = ({ treeNode, ...opts }) => {
  return <div className="d-flex align-items-center h-inherit">
    <SectionName treeNode={treeNode}/>
    <div className="ms-auto">
      <AddDecision treeNode={treeNode} {...opts} />
    </div>
  </div>
}
export default ZTreeHoverSectionNode;

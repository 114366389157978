import React, { useEffect, useState } from 'react';
import { RichTextEditor } from "../../../common/RichTextEditor";

export const NOTES_PLACEHOLDER = "Enter any comments or instructions for completing the driver"

export const updateNotesCallback = (prevNotes, slug, notes, debouncedUpdateDriver) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if(!loading) {
      if (prevNotes !== notes) debouncedUpdateDriver()
      return () => {
        debouncedUpdateDriver.cancel()
      }
    }
    setLoading(false);
  }, [notes])
}

export default ({ notes, setNotes, addClass = '' }) =>
  <div className={`${addClass ? 'mb-0' : 'mb-3' }`}>
    <h3>Notes</h3>
    <RichTextEditor quillId={'Notes'}
                    value={notes} setValue={setNotes}
                    placeholder={NOTES_PLACEHOLDER}
    />
  </div>

import React, { useMemo, useState } from "react";
import { connect} from "react-redux";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../common/CloseIcon";
import { ModalDoneFooterP0 } from "../../common/modals";
import ScenarioSelector from "./settings/ScenarioSelector";
import TimeScaleSelector from "./settings/TimeScaleSelector";
import PeriodSelectors from "./settings/PeriodSelectors";
import { ForecastConfig } from "../../models/forecast/ForecastConfig";
import { ForecastScenario, TIME_SCALES } from "../../models/forecast/ForecastScenario";
import { updateViewOptions } from "../../store/forecast_simulator_scenario/actions";
import AgGridApi from "../helpers/ag_grid_api";
import { isPresent } from "../../helpers/common";

export const showSettingsModal = (modal) => modal.type === "SettingsModal" && modal.shown
export const openSettingsModal = ({ openModal, ...opts }) => openModal({ ...opts, type: 'SettingsModal' })

const SettingsModal = ({ key, forecast_simulator_scenario, show, onClose, updateViewOptions, gridRef }) => {
  if(!forecast_simulator_scenario.loaded) return null;

  const forecastConfig = useMemo(() =>
    new ForecastConfig(forecast_simulator_scenario.config),
    [forecast_simulator_scenario.config]
  )
  const forecastScenario = useMemo(() =>
    new ForecastScenario(forecast_simulator_scenario.scenario, forecastConfig, null),
    [forecast_simulator_scenario.scenario, forecastConfig]
  )

  const [scenario, setScenario] = useState(null);
  const prevTimeScale = forecastScenario.viewOptions.timeScale || TIME_SCALES[0];
  const prevFrom = forecastScenario.viewOptions.from;
  const prevTo = forecastScenario.viewOptions.to;
  const [timeScale, setTimeScale] = useState(prevTimeScale);
  const [from, setFrom] = useState(prevFrom);
  const [to, setTo] = useState(prevTo);
  const [disabled, setDisabled] = useState(false);

  const dataChanged = () => {
    return prevTimeScale !== timeScale || prevFrom !== from || prevTo !== to;
  }

  const onSubmit = () => {
    if(dataChanged()) {
      if (isPresent(gridRef.current)) {
        const gridApi = new AgGridApi(gridRef.current.api);
        gridApi.onBtShowLoading;
      }
      setDisabled(true);
      onClose();
      updateViewOptions({ timeScale, from, to }, (status) => {
        setDisabled(false);
      })
    } else {
      onClose();
    }
  }

  return <Modal backdrop="static" key={key} size="md" show={show} onHide={onClose}>
    <Modal.Body>
      <CloseIcon onClose={onClose} />
      <h2>Settings</h2>
      <div className="row">
        <div className="col">
          <ScenarioSelector {...{ scenario, setScenario, options: [] }} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <TimeScaleSelector {...{ timeScale, setTimeScale, options: forecastConfig.timeScalesOptions }} />
        </div>
      </div>
      <PeriodSelectors  {...{ to, setTo, from, setFrom, options: forecastConfig.periodOptions(timeScale) }} />
    </Modal.Body>
    <ModalDoneFooterP0 onClose={onSubmit} disabled={disabled} />
  </Modal>
}
const mapStateToProps = ({ modal, forecast_simulator_scenario }) => ({ modal, forecast_simulator_scenario });
const mapDispatchToProps = (dispatch) => ({
  updateViewOptions: (params, callback) => dispatch(updateViewOptions(params, callback))
});
export default connect(mapStateToProps, mapDispatchToProps)(SettingsModal);

import React from 'react';
import {
  ContextEditForm,
  contextSectionState,
  DisplayContext
} from "../../../tree_view/side_panel/sections/ContextSection";

export const SetDescriptionSection = ({
                                        set, set_key, preview = false,
                                        updateSet, isManager = true
                                     }) => {
  const {
    value, editMode,
    openEditForm, closeEditForm, saveForm,
    ...args
  } = contextSectionState({
    context: set.description,
    saveFormCallback: (value, callback) => {
      const updateData = {}
      updateData[set_key] = { description: value }
      updateSet(updateData, callback)
    }
  })

  return <div className={`px-3 pt-1 pb-3 ${ preview ? '' : 'border-bottom' }`}>
    {
      preview || !editMode || !isManager ?
        <DisplayContext text={set.description} onClick={openEditForm} title='Description' hideEdit={preview || !isManager} /> :
        <ContextEditForm onCancel={closeEditForm} {...args} {...{ value, saveForm }}
                         title='Description' placeholder='Enter any comments or instructions' />
    }
  </div>
}
export default SetDescriptionSection;

import React from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {resetSidebars, setTreeSidebarOpen} from "../../store/sidebar/actions";
import { openModal } from "../../store/modals/actions";
import { createNewTemplate, deleteTemplate, removeFromTemplateSet } from "../../store/template/actions";
import { resetTemplate } from "../../store/template/common_actions";
import { forceReloadHomepageTemplates, reloadTemplatesData } from "../../store/homepage/actions";
import { resetTree } from "../../store/tree/common_actions";
import { resetPlaybookNotes } from "../../store/playbook_notes/actions";
import PlaybookHamburgerButton from "./PlaybookHamburgerButton";
import DetailsHamburgerButton from "./DetailsHamburgerButton";
import BaseHamburgerBtn, { BaseDropdownBtn } from "../../common/BaseHamburgerBtn";
import { HamburgerMenuIconBtn } from "../../common/dropdowns";
import { DeleteItemSpan } from "../../tree_wizard/header/ActionsDropdown";
import HelpHamburgerButton from "./HelpHamburgerButton";
import { isBlank, isPresent } from "../../helpers/common";
import DecisionSetPanelHamburgerButton from "./DecisionSetPanelHamburgerButton";
import { resetTemplateSetData } from "../../store/template_set/common_actions";
import { deleteTemplateCallback, duplicateTemplateCallback, removeFromTemplateSetCallback } from "./helpers";
import { goToHomepageCallback } from "../../tree_wizard/header/helpers";
import RateCompareHamburgerButton from "../../tree_view/header/buttons/RateCompareHamburgerButton";
import Decision from "../../models/decision";

export const GoToHomepageItem = ({
                                   resetSidebars, resetTree, resetTemplate, resetPlaybookNotes
                                 }) => {
  const history = useHistory();
  const goToHomepage = goToHomepageCallback([resetSidebars, resetTree, resetTemplate, resetPlaybookNotes], history)

  return <BaseHamburgerBtn onSelect={goToHomepage} title="Go to homepage" />
}

const DuplicateTemplateItem = ({
                                        template,
                                        createNewTemplate, resetSidebars, resetTree, resetTemplateSet, reloadTemplatesData
                                     }) => {
  if (isPresent(template.template_set_slug)) return null;

  const history = useHistory();
  const duplicateTemplate = duplicateTemplateCallback(createNewTemplate, template,
    [resetSidebars, resetTree, resetTemplateSet, reloadTemplatesData], history)

  return <BaseDropdownBtn eventKey={template.slug} onSelect={duplicateTemplate} title="Duplicate template" />
}

export const RemoveFromSetItem = ({
                                    template, removeFromTemplateSet, resetTemplateSet,
                                    forceReloadHomepageTemplates, resetSidebars, resetTree
                                  }) => {
  if (isBlank(template.template_set_slug)) return null;

  const history = useHistory();
  const removeFromSetHandler = removeFromTemplateSetCallback(template.slug, removeFromTemplateSet, history, template, [forceReloadHomepageTemplates, resetSidebars, resetTree, resetTemplateSet])

  return <BaseDropdownBtn eventKey={template.slug} onSelect={removeFromSetHandler} title="Remove from decision flow" />
}

export const DeleteTemplateItem = ({
                                     template,
                                     forceReloadHomepageTemplates, resetTemplate, resetSidebars, resetTree, deleteTemplate, resetPlaybookNotes
                                  }) => {
  const history = useHistory();
  const onDeleteTemplate = deleteTemplateCallback(deleteTemplate,
    [forceReloadHomepageTemplates, resetTemplate, resetSidebars, resetTree, resetPlaybookNotes],
    history
  )

  return <BaseDropdownBtn eventKey={template.slug} onSelect={onDeleteTemplate}>
    <DeleteItemSpan isTemplate={true} />
  </BaseDropdownBtn>
}

export const CommonHamburgerItems = ({
                                       template, openModal, setTreeSidebarOpen,
                                       ...opts
                                    }) =>
  <>
    <BaseDropdownBtn eventKey={template.slug}  onSelect={() => openModal({decision: template, drivers: [], slug: template.slug, type: 'TemplateEntryModal'})} title="Edit decision" />
    <BaseDropdownBtn eventKey={template.slug}  onSelect={() => setTreeSidebarOpen(true)} title="Edit decision tree" />
    <BaseDropdownBtn eventKey={template.slug}  onSelect={() => openModal({type: 'TemplateUsersModal'})} title="Edit template users" hidden={isPresent(template.template_set_slug)} />
    <DuplicateTemplateItem template={template} {...opts} />
    <RemoveFromSetItem template={template} {...opts} />
    <DeleteTemplateItem template={template} {...opts} />
  </>

export const HamburgerDropdown = ({ showDecisionSet, ...props }) =>
  <HamburgerMenuIconBtn>
    <RateCompareHamburgerButton hidden={new Decision(props.template).isRateAndCompareDriverChoicesHidden} />
    <DetailsHamburgerButton />
    <PlaybookHamburgerButton />
    <CommonHamburgerItems {...props} />
    {
      showDecisionSet ? <DecisionSetPanelHamburgerButton /> : null
    }
    <HelpHamburgerButton />
    <GoToHomepageItem {...props} />
  </HamburgerMenuIconBtn>

const mapStateToProps = ({template}) => ({ template });
export const mapDispatchToProps = (dispatch) => ({
  openModal: (data) => dispatch(openModal(data)),
  deleteTemplate: (callback) => dispatch(deleteTemplate(callback)),
  removeFromTemplateSet: (slug, callback) => dispatch(removeFromTemplateSet(slug, callback)),
  createNewTemplate: (data, callback) => dispatch(createNewTemplate(data, callback)),
  forceReloadHomepageTemplates: () => dispatch(forceReloadHomepageTemplates()),
  resetSidebars: () => dispatch(resetSidebars()),
  resetTree: () => dispatch(resetTree()),
  resetTemplate: () => dispatch(resetTemplate()),
  resetPlaybookNotes: () => dispatch(resetPlaybookNotes()),
  reloadTemplatesData: () => dispatch(reloadTemplatesData()),
  resetTemplateSet: () => dispatch(resetTemplateSetData()),
  setTreeSidebarOpen: (flag, mode) => dispatch(setTreeSidebarOpen(flag, mode))
});
export default connect(mapStateToProps, mapDispatchToProps)(HamburgerDropdown);

import React from 'react';
import { connect } from "react-redux";
import Select, { createFilter } from 'react-select';
import { ChoiceEntryStyle } from "../styles/choice_entry_styles";
import DecisionCategory from "../../models/decision_category";
import { isBlank } from "../../helpers/common";

const filterOption = createFilter({
  matchFrom: 'any',
  stringify: option => option.label,
})

const SelectCategory = ({
                          wizard, org_categories,
                          onSelect, selected, submitState
                        }) => {
  const options = org_categories.available_categories.filter(c => !selected.some(h => h.slug === c.slug))
                                .map(c => new DecisionCategory(c).selectOption)

  return <div className={`w-100 my-3`} hidden={isBlank(options)}>
    <Select value={null}
            autoFocus={false}
            onChange={onSelect}
            isDisabled={wizard.submit || submitState}
            components={{ IndicatorSeparator:() => null }}
            options={options} filterOption={filterOption}
            placeholder={'Select a category to add to this decision'}
            styles={ChoiceEntryStyle} />
  </div>
}
const mapStateToProps = ({ wizard, org_categories }) => ({ wizard, org_categories });
export default connect(mapStateToProps)(SelectCategory);

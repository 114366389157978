import React  from 'react';
import { connect } from "react-redux";
import { isBlank } from "../../../helpers/common";
import {
  createSource,
  destroySource,
  replaceSource,
  saveUploadingSources,
  updateSource,
  attachReport,
  detachReport
} from "../../../store/decision_set/actions";
import NAElement from "../../../tree_view/side_panel/sections/NAElement";
import { decisionDataSources } from "../../../models/decision";
import DataSource, { DecisionSetSourcesTypes, Type } from "../../../models/data_source";
import {
  AddSourceRows,
  UploadingSourceRow,
} from "../../../template_view/side_panel/sections/DataSourcesSection";
import { generateBatchDataSourceUploadState } from "../../../helpers/uploads_callbacks";
import DecisionSet from "../../../models/decision_set";
import SourceItem from "../../../report_wizard/data_sources/Item";

const HeaderElement = () => <h3>Data sources</h3>

const LocalAddSourceRowsDecision = ({ user, isManager, isSetUser, ...opts }) => {
  if(!isManager && !isSetUser) return null;

  return <AddSourceRows {...opts} dataSourceType={Type.DECISION_SET_DATA_SOURCE}
                        formWrapper={'set_data_source'} isTemplate={false} />
};

const DataSourcesList = ({ decision_set,
                           data_sources,
                           hideMenu= false,
                           uploadingFile,
                           isManager, isSetUser,
                           ...opts
                         }) => {
  return data_sources.map(data_source =>
    <SourceItem source={data_source}
                users={decision_set.users}
                dropFilesFormWrapper={'set_data_source'}
                forceEditMode={data_source.just_created}
                hideMenu={!isManager && !isSetUser || new DataSource(data_source).isDSight}
                key={`decision-set-data-source-${data_source.slug}-${data_source.key}`}
                { ...opts }
    />
  )
}

const UploadingSourcesList = ({
                                uploading_sources, user,
                                ...opts
                              }) => {
  return uploading_sources.map((sourceData, i) =>
    <UploadingSourceRow uploading_source={sourceData.uploadingSource}
                        isTemplate={false}
                        key={`decision-set-details-uploading-source-${sourceData.uploadingSource.slug}-${i}`}
                        {...opts}
    />
  )
};

// TODO: implement once allow adding reports to decision set
// const SelectExistingSourceRow = ({ selectedSource, onSelectSource, onSelectReport, orgReports = [], dataSources = [] }) => {
//   const combinedSources =
//     [
//       ...dataSources.map(h => ({ ...h, sourceable_type: 'decision_set' })),
//       ...orgReports.map(h => ({ ...h, sourceable_type: 'report' }))
//     ]
//   const list = qSortArray(combinedSources, false, d => d.created_at)
//   const decisionSetOption = (option) => option.sourceable_type === 'decision_set'
//   return <div className="existing-source-dropdown">
//     <Select
//       value={selectedSource}
//       onChange={(option) => decisionSetOption(option) ? onSelectSource(option) : onSelectReport(option)}
//       components={{IndicatorSeparator: () => null}}
//       styles={BorderedFilterStyle}
//       placeholder="Select a data source"
//       options={list.map(source =>
//         ({value: source.slug, label: decisionSetOption(source) ? (source.title || source.link_url) : source.name, sourceable_type: source.sourceable_type})
//       )}/>
//   </div>
// }

export const DataSourcesSection = ({ current_org,
                                     decision_set, data_sources, user,
                                     createSource, uploading_sources,  decision_sources,
                                     saveUploadingSources, addClass, recommendation_sources,
                                     updateSource, attachReport,
                                     ...opts
                                   }) => {
  const { isManager, isSetUser } = new DecisionSet(decision_set, user)

  if (!isManager && !isSetUser && isBlank(data_sources))
    return <div className="p-3">
      <HeaderElement />
      <NAElement />
    </div>

  const submitLink = (value, callback) => { createSource({ set_data_source: { link_url: value } }, {}, callback); };
  const uploadOptions = generateBatchDataSourceUploadState(data_sources, uploading_sources, saveUploadingSources, user)

  return <div className={`${addClass}`}>
    <HeaderElement />
    <div className="d-flex flex-column" hidden={isBlank(data_sources)}>
      <DataSourcesList data_sources={data_sources}
                       user={user}
                       decision_set={decision_set}
                       updateSource={updateSource}
                       isManager={isManager}
                       isSetUser={isSetUser}
                       {...uploadOptions}
                       {...opts} />
      <UploadingSourcesList uploading_sources={uploading_sources} user={user} {...uploadOptions} {...opts} />
    </div>
    {/*<div className="mt-2">*/}
    {/*  <div className="new-source-entries">*/}
    {/*    <SelectExistingSourceRow selectedSource={selectedSource}*/}
    {/*                             onSelectSource={onSelectDecisionSetSource}*/}
    {/*                             dataSources={decision_sources}*/}
    {/*    />*/}
    {/*  </div>*/}
    {/*</div>*/}
    <LocalAddSourceRowsDecision user={user}
                                submitLink={submitLink}
                                isManager={isManager}
                                isSetUser={isSetUser}
                                createSource={createSource} {...uploadOptions} />
  </div>
};
const mapStateToProps = ({ decision_set, current_org, current_user }) => {
  const sortedRelatedDataSources = decisionDataSources(decision_set.data_sources, [], DecisionSetSourcesTypes)
  return {
    current_org: current_org,
    data_sources: sortedRelatedDataSources,
    uploading_sources: decision_set.uploading_sources,
    user: current_user,
    decision_set
  }
};
export default connect(mapStateToProps, {
  createSource, updateSource, destroySource, saveUploadingSources, replaceSource, attachReport, detachReport
})(DataSourcesSection);

import React, {Fragment} from "react";
import Header from "./header";
import SettingsModal, {showSettingsModal} from "./modals/SettingsModal";
import EditNameModal, { showEditNameModal } from "./modals/EditNameModal";
import LargeScaleInput from "./side_panel/LargeScaleInput";

const Sidebars = ({...opts}) => <div className="sidebars">
  <LargeScaleInput key="large-scale-input-side-panel" {...opts}/>
</div>

const Wrapper = ({ modal, closeModal, children, activeTab, setActiveTab, gridRef, scenarioIsLoaded = false }) =>
  <Fragment>
    <Sidebars gridRef={gridRef} />
    <Header {...{ activeTab, setActiveTab, scenarioIsLoaded }} />
    {children}
    <div className="modals">
      <SettingsModal show={showSettingsModal(modal)} onClose={closeModal} gridRef={gridRef} />
      <EditNameModal show={showEditNameModal(modal)} onClose={closeModal} />
    </div>
  </Fragment>
export default Wrapper;

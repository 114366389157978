import React from 'react';
import Wrapper from '../Wrapper';
import AggregatedData from "../driver/AggregatedData";
import Header from "../template_driver/Header";
import Content from "../template/Content";
import { driverInitData } from "../shared/helpers";

const TemplatePreviewWizardDriverCard = ({ driverData, template, tree, indentStep = 1, user, contactsData, openModal }) => {
  const { driverObject, childrenDrivers, setShowChildren, showChildren } = driverInitData(tree, driverData);

  const onDriverClick = () => openModal({
    decision: template,
    drivers: tree.drivers,
    data_sources: tree.data_sources,
    slug: driverData.driver.slug,
    type: 'TemplateDriverDetailsModal'
  })

  return <>
    <Wrapper indentStep={indentStep} >
      <div className={`d-flex`}>
        <div className="my-auto lh-sm w-100 me-1">
          <Header {...{ driverData, driverObject, childrenDrivers, setShowChildren, onEditClick: onDriverClick }} />
          <AggregatedData {...{ driverData, hidden: false }} />
        </div>
        <Content {...{ driverData, assignedUser: {} }} />
      </div>
    </Wrapper>
    {showChildren && childrenDrivers.map((child) => {
      return <TemplatePreviewWizardDriverCard key={`driver-card-${child.driver.slug}`}
                                              {...{
                                                driverData: child,
                                                indentStep: indentStep + 1,
                                                template, tree, user, contactsData, openModal
                                              } } />
    })}
  </>
}
export default TemplatePreviewWizardDriverCard;

import React from 'react';
import { connect } from "react-redux";
import { updateDriver } from "../../../store/sidebar/actions";
import DriverRichTextInput from "./DriverRichTextInput";

export const DETAILS_PLACEHOLDER = "Enter any additional details, explanation, or analysis"

const DetailsInput = ({ innerRef, ...opts }) => <DriverRichTextInput {...opts} header="Details" field="explanation" placeholder={DETAILS_PLACEHOLDER} ref={innerRef} />

const mapStateToProps = ({ decision }) => ({ decision })
const wrapper = React.forwardRef((props, ref) => <DetailsInput {...props} innerRef={ref} />)
export default connect(mapStateToProps, { updateDriver }, null, { forwardRef: true })(wrapper);


import React from 'react';
import { connect } from "react-redux";
import { successActions } from "../../../helpers/common";
import { removeDecisionInvite } from "../../../store/decision/actions";
import { reloadContacts } from "../../../store/contacts/actions";
import CloseIcon from "../../../common/CloseIcon";
import { isVisitor, userName } from "../../../helpers/user_helpers";
import {deleteApprovalInviteWarning} from "../../../helpers/alert_helpers";
import UserAvatarImage from "../../../common/UserAvatarImage";
import { resetDecisionSetData } from "../../../store/decision_set/common_actions";

const PendingApprovalInviteRow = ({
                                    slug, user, email, current_user,
                                    removeDecisionInvite,
                                    reloadContacts, resetDecisionSetData, isLegacy = false
                                  }) => {
  const removeApprovalInvite = () => {
    deleteApprovalInviteWarning(confirmed => {
      if (!confirmed) return;

      removeDecisionInvite(slug, (success) =>
        successActions(success, [reloadContacts, resetDecisionSetData])
      )
    })
  }
  const name = userName(user, email)

  return <div className="d-flex align-items-center mb-1">
    <span className="collaborator_img">
     <UserAvatarImage user={user} size='sm' pendingUserEmail={email} />
    </span>
    <span className="px-2 text-truncate">{name}</span>
    <div className="ms-auto" hidden={isLegacy || isVisitor(current_user)}>
      <CloseIcon onClose={removeApprovalInvite} classParams={"d-inline-block"} danger={true} />
    </div>
  </div>
}
const mapStateToProps = ({ tree, current_user }) => ({
  current_user,
  default_user_avatar_url: tree.default_user_avatar_url
});
export default connect(mapStateToProps, {
  removeDecisionInvite, reloadContacts, resetDecisionSetData
})(PendingApprovalInviteRow);
